import { GetTameeniRedirectionUrlResponse } from 'api/eContractingAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  redirectionUrl: string
} & Status

const initialState = {
  redirectionUrl: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_TAMEENI_REDIRECTION_URL_ASYNC,
    Actions.GET_TAMEENI_REDIRECTION_URL_SUCCESS,
    Actions.GET_TAMEENI_REDIRECTION_URL_FAIL,
    GetTameeniRedirectionUrlResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_TAMEENI_REDIRECTION_URL_ASYNC:
    case Actions.GET_TAMEENI_REDIRECTION_URL_FAIL:
      return {
        ...state,
        redirectionUrl: '',
        ...action.status,
      }
    case Actions.GET_TAMEENI_REDIRECTION_URL_SUCCESS: {
      return {
        ...state,
        ...action.status,
        redirectionUrl: action.payload.redirection_url,
      }
    }
    default:
      return state
  }
}
