import { CallAPIAction, Status } from 'api/types'
import authManager from 'common/utils/auth/authManager'
import type { UpdateAuthParams } from 'redux/actionCreators/evisaAPI/auth'
import Actions from 'redux/actions'

type State = {
  loggedIn: boolean
  logOutWarning: boolean
  userProfile: ReturnType<typeof authManager.getUserProfileData>
  tokensUpdateStatus: Status
}

const getInitialState = (): State => ({
  loggedIn: authManager.isAuthenticated(),
  logOutWarning: authManager.isReadyForAutoLogOut(),
  userProfile: authManager.getUserProfileData(),
  tokensUpdateStatus: {
    isLoading: false,
    success: false,
    fail: false,
  },
})

export default (
  state: State = getInitialState(),
  action:
    | {
        type: Actions.GET_AUTH_DATA_SUCCESS
      }
    | {
        type: Actions.UPDATE_AUTH
        payload: UpdateAuthParams
      }
    | CallAPIAction<Actions.KEEP_ALIVE_ASYNC, Actions.KEEP_ALIVE_SUCCESS, Actions.KEEP_ALIVE_FAIL>,
): State => {
  switch (action.type) {
    case Actions.GET_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        logOutWarning: false,
      }
    }
    case Actions.UPDATE_AUTH: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case Actions.KEEP_ALIVE_ASYNC:
    case Actions.KEEP_ALIVE_SUCCESS:
    case Actions.KEEP_ALIVE_FAIL: {
      return {
        ...state,
        tokensUpdateStatus: {
          ...action.status,
        },
      }
    }
    default:
      return state
  }
}
