import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetAuthContractTextResponse } from 'api/laborerPortalAPI/authContract'

type State = {
  id: number
  legalTextAr: string
  legalTextEn: string
} & Status

const initialState = {
  id: 0,
  legalTextAr: '',
  legalTextEn: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_AUTH_CONTRACT_TEXT_ASYNC,
    Actions.GET_LABORER_AUTH_CONTRACT_TEXT_SUCCESS,
    Actions.GET_LABORER_AUTH_CONTRACT_TEXT_FAIL,
    GetAuthContractTextResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_AUTH_CONTRACT_TEXT_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LABORER_AUTH_CONTRACT_TEXT_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        ...action.status,
        id: data.id,
        legalTextAr: data.label_ar,
        legalTextEn: data.label_en,
      }
    }
    case Actions.GET_LABORER_AUTH_CONTRACT_TEXT_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
