import { MobileCheckFailResponse, MobileCheckResponse } from 'api/evisaAPI/auth'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
  token: string
} & Status

const getInitialState = (): State => ({
  token: '',
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
})

export default (
  state: State = getInitialState(),
  action:
    | CallAPIAction<
        Actions.MOBILE_CHECK_ASYNC,
        Actions.MOBILE_CHECK_SUCCESS,
        Actions.MOBILE_CHECK_FAIL,
        MobileCheckResponse,
        MobileCheckFailResponse
      >
    | { type: Actions.MOBILE_VERIFY },
): State => {
  switch (action.type) {
    case Actions.MOBILE_CHECK_ASYNC:
      return {
        ...state,
        ...action.status,
        errorMessage: '',
      }
    case Actions.MOBILE_CHECK_SUCCESS: {
      return {
        ...state,
        ...action.status,
        token: action.payload.payload,
      }
    }
    case Actions.MOBILE_CHECK_FAIL: {
      const { mobile = [] } = action.payload || {}
      return {
        ...state,
        ...action.status,
        errorMessage: mobile[0] || '',
      }
    }
    default:
      return state
  }
}
