import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'ui-components/buttons/Button/Button'
import Modal, { ModalProps } from 'ui-components/modals/Modal/Modal'
import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'

import ModalMessageContent, { ModalMessageContentProps } from './ModalMessageContent/ModalMessageContent'
import modalMessageEn from './ModalMessage_en.json'
import modalMessageAr from './ModalMessage_ar.json'

export type ModalMessageProps = {
  singleCloseButtonText?: string
  className?: string
  icon?: React.ReactNode
} & Pick<ModalProps, 'onClose' | 'open'> &
  ModalMessageContentProps

const ModalMessage: FunctionComponent<ModalMessageProps> = ({
  open,
  onClose,
  title,
  description,
  actions,
  type,
  singleCloseButtonText,
  classNameDescription,
  className,
  icon,
}) => {
  const { t } = useTranslation(TranslationNamespace.modalMessage)
  return (
    <Modal open={open} onClose={onClose}>
      <ModalMessageContent
        icon={icon}
        className={className}
        title={open && title}
        description={open && description}
        classNameDescription={classNameDescription}
        type={type}
        actions={
          open &&
          (actions || (
            <Button dataTestid='close-modal-button' onClick={onClose}>
              {singleCloseButtonText || t('ok')}
            </Button>
          ))
        }
      />
    </Modal>
  )
}
export default ModalMessage

addTranslationNamespace(TranslationNamespace.modalMessage, modalMessageEn, modalMessageAr)
