import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'

type State = { errorMessage: string } & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.I_TO_E_REQUEST_ACTIONS_ASYNC,
    Actions.I_TO_E_REQUEST_ACTIONS_SUCCESS,
    Actions.I_TO_E_REQUEST_ACTIONS_FAIL,
    {},
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.I_TO_E_REQUEST_ACTIONS_ASYNC:
    case Actions.I_TO_E_REQUEST_ACTIONS_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.I_TO_E_REQUEST_ACTIONS_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    }
    default:
      return state
  }
}
