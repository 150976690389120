import moment from 'moment'

import {
  EToIRequestStatus,
  EToIAppendixStatus,
  EToILegacyRequestsStatus,
  EToIReplacementStatus,
  EToITransferRequestStatus,
} from 'common/types/commonTypes'
import { EToINewRequest } from 'common/utils/convertResponse/convertEToIResponse'
import { formatCurrency } from 'common/utils/currency'
import { CONTRACT_DRAFT_DATE, FULL_INVOICE_EDGE_PRICE } from 'common/constants'
import { Routes } from 'containers/Routes/routePaths'
import { EtoIActionRequest } from 'api/newDLTransferAPI'

export const getStylesForEToINewRequest = (status: EToIRequestStatus) => {
  switch (status) {
    case EToIRequestStatus.COMPLETE:
    case EToIRequestStatus.VALID:
      return 'success'
    case EToIRequestStatus.CANCELLED:
    case EToIRequestStatus.CANCELLED_FOR_INELIGIBILITY:
    case EToIRequestStatus.REJECTED:
    case EToIRequestStatus.REJECTED_FOR_FINANCIAL_INELIGIBILITY:
    case EToIRequestStatus.CANCELLED_FOR_INCOMPLETE_PROCEDURES:
    case EToIRequestStatus.CANCELED_BY_PRO:
    case EToIRequestStatus.CANCELLED_BEFORE_PAYMENT_BY_PRO:
      return 'danger'
    case EToIRequestStatus.PENDING_BACKOFFICE:
    case EToIRequestStatus.WAITING_PAYMENT:
    case EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS:
    case EToIRequestStatus.UNDER_FINAL_REVIEW:
    case EToIRequestStatus.WAITING_ELIGIBILITY_CORRECTION:
      return 'warning'
    default:
      return ''
  }
}

export const getStylesForEToIRequestAppendix = (status: EToIAppendixStatus | string) => {
  switch (status) {
    case EToIAppendixStatus.VALID:
    case EToIAppendixStatus.TRANSFERRED:
      return 'success'
    case EToIAppendixStatus.CANCELLED:
    case EToIAppendixStatus.REPLACED:
    case EToIAppendixStatus.REJECTED:
      return 'danger'
    case EToIAppendixStatus.NEW:
      return 'highlight'
    default:
      return ''
  }
}

export const checkIsOffer = (status: EToIRequestStatus | string) =>
  [
    EToIRequestStatus.REJECTED,
    EToIRequestStatus.WAITING_EMPLOYER_RESPONSE,
    EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS,
    EToIRequestStatus.WAITING_PAYMENT,
    EToIRequestStatus.PAYMENT_EXPIRED,
    EToIRequestStatus.PENDING_BACKOFFICE,
    EToIRequestStatus.WAITING_ELIGIBILITY_CORRECTION,
    EToIRequestStatus.RESPONSE_EXPIRED,
  ].includes(status as EToIRequestStatus)

export const checkIsPaymentBtnAvailable = (status: EToIRequestStatus) =>
  [EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS, EToIRequestStatus.WAITING_PAYMENT].includes(status)

export const isContractPaid = (request: EToINewRequest) => !!request.bill?.paidAt

export const checkIsValidContract = (request: EToINewRequest) =>
  isContractPaid(request) && request.status !== EToIRequestStatus.PENDING_BACKOFFICE

export const billCalcHelper = (request: EToINewRequest) => {
  const { breakdown } = request
  const totalWithoutVAT = breakdown.amount - breakdown.vat
  const vatValue = formatCurrency(breakdown.vat / totalWithoutVAT) // 2 digits after comma

  const getInforAboutMainBill = () => {
    const amount = formatCurrency(breakdown.amount - breakdown.operationalCost * (1 + vatValue))
    const amountWithoutVAT = totalWithoutVAT - breakdown.operationalCost
    const vat = formatCurrency(amount - amountWithoutVAT)
    const isFullInvoice = amount >= FULL_INVOICE_EDGE_PRICE
    return {
      amount,
      vat,
      isFullInvoice,
    }
  }

  return {
    vatValue,
    mainBill: getInforAboutMainBill(),
  }
}

export const getPaymentRoute = (status: string | EToIRequestStatus) => {
  switch (status) {
    case EToIRequestStatus.WAITING_FINANCIAL_DOCUMENTS:
      return Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS
    case EToIRequestStatus.WAITING_PAYMENT:
      return Routes.SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT
    default:
      return Routes.SERVICE_TRANSFER_E_TO_I_REQUEST
  }
}

export const isCancelled = (status: EToIRequestStatus | string) =>
  [
    EToIRequestStatus.CANCELLED_FOR_INCOMPLETE_PROCEDURES,
    EToIRequestStatus.CANCELLED,
    EToIRequestStatus.CANCELLED_FOR_INELIGIBILITY,
  ].includes(status as EToIRequestStatus)

export const checkIsRequestIncludeStatusNew = (requests: Array<EtoIActionRequest>) =>
  !requests.find((request) => request.status === EToIReplacementStatus.NEW)

export const getStylesForEToILegacyRequest = (status: EToILegacyRequestsStatus | string) => {
  switch (status) {
    case EToILegacyRequestsStatus.APPROVED:
      return 'success'
    case EToILegacyRequestsStatus.REJECTED:
      return 'danger'
    default:
      return 'warning'
  }
}

export const getContractDuration = (duration: number, status: string) => {
  return duration &&
    ![EToIRequestStatus.COMPLETE, EToIRequestStatus.UNDER_FINAL_REVIEW].includes(status as EToIRequestStatus)
    ? duration
    : '-'
}

export const checkIsNeedReplacementColumn = (request: EToINewRequest) =>
  request.replacementRequests?.at(-1)?.status === EToIReplacementStatus.NEW ||
  request.replacementRequests?.at(-1)?.status === EToIReplacementStatus.CANCELED_BY_EMPLOYER

export const checkTransferRequestStatus = (request: EToINewRequest) =>
  request.transferRequest?.status === EToITransferRequestStatus.REJECTED ||
  request.transferRequest?.status === EToITransferRequestStatus.CANCELLED_FOR_INELIGIBILITY ||
  request.transferRequest?.status === EToITransferRequestStatus.EXPIRED

export const checkIsDraftAvailable = (request: EToINewRequest) => {
  return (
    request.status === EToIRequestStatus.WAITING_EMPLOYER_RESPONSE &&
    request.legalText &&
    request.legalText.type === 'contract' &&
    moment(request.createdAt).isAfter(CONTRACT_DRAFT_DATE)
  )
}

export const checkIsAppendixFromBEAvailable = (request: EToINewRequest, index: number) => {
  return (
    request?.laborerStatus === EToIAppendixStatus.REPLACED &&
    request.appendixes[index] &&
    request.appendixes[index].legalText &&
    request.appendixes[index].legalText?.type === 'appendix' &&
    moment(request.createdAt).isAfter(CONTRACT_DRAFT_DATE)
  )
}
