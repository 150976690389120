import { AxiosRequestConfig } from 'axios'

import { REQUEST_TYPE_ID, SERVICE_DESK_ID, JIRA_MUSANED_API_URL } from 'common/envConstants'
import { FileStatus } from 'ui-components/inputs/DropArea/types'
import {
  ComplaintTicketStatus,
  ComplaintTicketClosureCategory,
  ComplaintTicketCountDownStatus,
  SupportTicketStatus,
  SurveyServiceTypes,
} from 'common/types/commonTypes'
import authManager from 'common/utils/auth/authManager'

import { callAPI, jiraMusanedAPI, jiraMusanedAPIWithCredentials } from './axios'

export const getTicketAttachment = (id: string) =>
  `${JIRA_MUSANED_API_URL}/api/musaned/view/${id}?token=${authManager.getJiraToken()}`

export const getSupportTicketAttachment = (id: string) =>
  `${JIRA_MUSANED_API_URL}/api/musaned/support/view/${id}?token=${authManager.getJiraToken()}`

export type GetTicketListParams = {
  show: number
  status: string
  page: number
}

export type TicketTruncatedFromBE = {
  id: string
  key: string
  fields: {
    contract_id: string
    category: {
      id: string
    } | null
    status: {
      id: string
      name: ComplaintTicketStatus
    }
    created: {
      date: string
    }
    countdown: {
      remaining: number
      status: ComplaintTicketCountDownStatus
      show_no_answer?: boolean
    }
  }
}

export type GetTicketListResponse = {
  issues: Array<TicketTruncatedFromBE>
  pages: number
}

const getTicketsList = (params: GetTicketListParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetTicketListResponse>('/api/musaned/list', { params, ...config })

export type GetTicketParams = {
  ticketKey: string
}

type ComplaintAttachment = {
  author: {
    name: string
  }
  filename: string
  id: string
  mimeType: string
}

export type ComplaintActionItemFromBE = {
  attachments: Array<ComplaintAttachment>
  created: string
  to_status_id: string
  from_status_id: string
  response: string
}

export type TicketFromBE = {
  id: string
  key: string
  fields: {
    pro_now_suspended: boolean | null
    employer_did_not_respond: string | null
    attachment: Array<ComplaintAttachment>
    workflow: Array<ComplaintActionItemFromBE>
    contract_id: string
    pro_id: string
    description: string
    pro_response: {
      value: string
      created: string
    } | null
    final_decision: {
      value: string
      created: string
    } | null
    cc_response: {
      value: string
      created: string
    } | null
    mlsd_response: {
      value: string
      created: string
    } | null
    second_pro_solution?: Array<string>
    category: {
      id: string
    } | null
    muc_resolution_category?: {
      id: string
    }
    status: {
      id: string
      name: ComplaintTicketStatus
    }
    created: {
      date: string
    }
    countdown: {
      remaining: number
      status: ComplaintTicketCountDownStatus
      show_no_answer?: boolean
    }
    reporter: {
      displayName: string
    }
    missing_attachments_cc_notes?: string
    missing_attachments_mhrsd_notes?: string
    verify_pro_attach: boolean
    closure_category: ComplaintTicketClosureCategory
  }
}

export type GetTicketResponse = TicketFromBE

const getTicket = ({ ticketKey }: GetTicketParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetTicketResponse>(`/api/musaned/${ticketKey}/show`, config)

export type RespondToComplaintSolutionParams = {
  id: string | number
  accept: boolean
}

export type RespondToComplaintSolutionResponse = {}

const respondToComplaintSolution = (body: RespondToComplaintSolutionParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<RespondToComplaintSolutionResponse>(
    `/api/musaned/${body.id}/${body.accept ? 'accept' : 'reject'}`,
    '',
    config,
  )
export type RespondToOfficeComplaintParams = {
  id: string | number
  accept: boolean
}

export type RespondToOfficeComplaintResponse = {}

const respondToOfficeComplaint = (body: RespondToOfficeComplaintParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<RespondToOfficeComplaintResponse>(
    `/api/musaned/${body.id}/${body.accept ? 'satisfied' : 'unsatisfied'}`,
    '',
    config,
  )

export type GetSupportTicketsListParams = {
  page: number
  show: number
  status?: SupportTicketStatus
}

export type SupportTicketFromBE = {
  id: string
  key: string
  fields: {
    created: {
      date: string
    }
    request_type: {
      id: string
      name: string
    }
    status: {
      id: string
      name: SupportTicketStatus
    }
  }
}

export type GetSupportTicketsListResponse = {
  issues: Array<SupportTicketFromBE>
  pages: number
}

const getSupportTicketsList = (params: GetSupportTicketsListParams, config: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetSupportTicketsListResponse>('/api/musaned/support/list', {
    params,
    ...config,
  })

export type GetSurveyQuestionsParams = {
  serviceType: SurveyServiceTypes
}

export type GetSurveyQuestionsResponse = {
  question_one_ar: string
  question_one_en: string
  question_two_ar: string
  question_two_en: string
}

const getSurveyQuestions = (params: GetSurveyQuestionsParams, config: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetSurveyQuestionsResponse>(
    `api/musaned/survey/show/${params.serviceType}`,
    config,
  )

export type PostSurveyQuestionsParams = {
  answers: Record<number, number>
  requestId: string
  userId: number
  serviceType: SurveyServiceTypes
}

const mapping = {
  1: { en: 'yes', ar: 'نعم' },
  2: { en: 'no', ar: 'لا' },
}

const postSurveyQuestions = (params: PostSurveyQuestionsParams, config: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<{}>(
    'api/musaned/survey/create',
    {
      answer_one_en: mapping[params.answers[0] as keyof typeof mapping].en,
      answer_one_ar: mapping[params.answers[0] as keyof typeof mapping].ar,
      answer_two_en: mapping[params.answers[1] as keyof typeof mapping].en,
      answer_two_ar: mapping[params.answers[1] as keyof typeof mapping].ar,
      request_id: params.requestId,
      user_id: params.userId,
      service_enum: params.serviceType,
    },
    config,
  )

export type CreateSupportTicketParams = {
  requestType: string
  description: string
  email: string
  attachments: Map<string, FileStatus>
}

export type CreateSupportTicketResponse = {}

export type CreateSupportTicketFailResponse = {
  message: string
}

const createSupportTicketFormData = (params: CreateSupportTicketParams) => {
  const { requestType, description, email, attachments } = params

  const formData = new FormData()
  formData.append('request_type', requestType)
  formData.append('body', description)
  formData.append('email', email)
  Array.from(attachments.values()).forEach((value, index) => formData.append(`attachments[${index}]`, value.file))
  return formData
}

const createSupportTicket = (params: CreateSupportTicketParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post('/api/musaned/support/create', createSupportTicketFormData(params), config)

export type CreateSupportTicketGuestParams = {
  name: string
  nationalId: string
  mobileNumber: string
  email: string
  requestType: string
  description: string
  attachments: Map<string, FileStatus>
}

const createSupportTicketGuestFormData = (params: CreateSupportTicketGuestParams) => {
  const { name, nationalId, mobileNumber, email, requestType, description, attachments } = params

  const formData = new FormData()
  formData.append('name', name)
  formData.append('national_id', nationalId)
  formData.append('mobile_number', mobileNumber)
  formData.append('request_type', requestType)
  formData.append('body', description)
  formData.append('email', email)
  Array.from(attachments.values()).forEach((value, index) => formData.append(`attachments[${index}]`, value.file))
  return formData
}

const createSupportTicketGuest = (params: CreateSupportTicketGuestParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPI.post('/api/musaned/support/guest/create', createSupportTicketGuestFormData(params), config)

export type GetSupportTicketParams = {
  ticketId: string
}

export type GetSupportTicketDetailsResponse = {
  id: string
  key: string
  fields: {
    summary: string
    attachment: Array<{
      id: string
      mimeType: string
      filename: string
      url: string
    }>
    created: {
      date: string
    }
    description: string | null
    reporter: {
      emailAddress: string
      displayName: string
    }
    status: {
      description: string
      name: SupportTicketStatus
      id: string
    }
    request_type: {
      id: string
    }
    comment: {
      total: number
      comments?: Array<{
        author: {
          emailAddress: string
          displayName: string
          key: string
        }
        body: string
        renderedBody: string
        updateAuthor: {
          emailAddress: string
          displayName: string
          key: string
        }
        created: string
        updated: string
      }>
    }
  }
}

const getSupportTicketDetails = ({ ticketId }: GetSupportTicketParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get(`/api/musaned/support/${ticketId}/show`, config)

export type CreateComplaintTicketBody = {
  email: string
  borderNumber: string | number
  complaintBody: string
  contractId: string | number
  proId: string | number
  categoryId: string | number
  files: Array<File>
}

export type CreateComplaintTicketResponse = {
  issueKey: string
}

export type CreateComplaintTicketResponseFail = {
  message: string
}

export type UpdateSupportTicketDetailsParams = {
  files: Map<string, FileStatus>
  comment: string
  ticketId: string
}

const updateSupportTicketDetailsFormData = ({ comment, files, ticketId }: UpdateSupportTicketDetailsParams) => {
  const formData = new FormData()
  formData.append('body', comment)
  formData.append('issue_key', ticketId)
  Array.from(files.values()).forEach((item, index) => formData.append(`attachments[${index}]`, item.file))
  return formData
}

export type UpdateSupportTicketDetailsResponse = {}

export type UpdateSupportTicketDetailsFailResponse = {}

const updateSupportTicketDetails = (params: UpdateSupportTicketDetailsParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post(
    `/api/musaned/support/${params.ticketId}/comment`,
    updateSupportTicketDetailsFormData(params),
    config,
  )

const createComplaintTicketBodyToFormData = (body: CreateComplaintTicketBody): FormData => {
  const form = new FormData()
  form.append('serviceDeskId', SERVICE_DESK_ID.toString())
  form.append('requestTypeId', REQUEST_TYPE_ID.toString())
  form.append('email', body.email)
  form.append('complain[border_number]', body.borderNumber as string)
  form.append('complain[body]', body.complaintBody)
  form.append('complain[contract_id]', body.contractId as string)
  form.append('complain[pro_id]', body.proId as string)
  form.append('complain[category_id]', body.categoryId as string)
  body.files.forEach((file, index) => {
    form.append(`complain[attachments][${index}]`, file)
  })
  return form
}

const createComplaintTicket = (body: CreateComplaintTicketBody, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<CreateComplaintTicketResponse>(
    '/api/musaned/create',
    createComplaintTicketBodyToFormData(body),
    config,
  )

export type AddFeedbackParams = {
  ticketKey: string
  isSatisfied?: number
  isRequestProceed?: number
  feedback?: string
}

const addFeedback = (params: AddFeedbackParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<CreateComplaintTicketResponse>(
    `api/musaned/${params.ticketKey}/feedback`,
    {
      ...(params.isSatisfied ? { employer_is_satisfied: params.isSatisfied.toString() } : {}),
      ...(params.isRequestProceed ? { employer_request_is_processed: params.isRequestProceed.toString() } : {}),
      ...(params.feedback ? { employer_feedback_text: params.feedback } : {}),
    },
    config,
  )

export type AddMissingAttachmentBody = {
  ticketID: string
  files: Array<File>
}

const missingAttachmentsFormData = (body: AddMissingAttachmentBody): FormData => {
  const form = new FormData()
  body.files.forEach((file, index) => {
    form.append(`attachments[${index}]`, file)
  })
  return form
}

const addMissingAttachment = (body: AddMissingAttachmentBody, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.post<{}>(
    `/api/musaned/${body.ticketID}/attachment`,
    missingAttachmentsFormData(body),
    config,
  )

export type GetComplaintRatingsParams = {}

export type GetComplaintRatingsResponse = {
  query_results: {
    row_positions: Array<Array<{ name: string }>>
    formatted_values: Array<Array<string>>
  }
}

const getComplaintRatings = (params: GetComplaintRatingsParams, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetComplaintRatingsResponse>('/api/musaned/pro-performance-report', {
    params,
    ...config,
  })

export type GetComplaintRatingsV2Params = {}

export type GetComplaintRatingsV2Response = {
  row_positions: Array<string>
  values: Array<number>
}

const getComplaintRatingsV2 = (params: GetComplaintRatingsV2Params, config?: AxiosRequestConfig) =>
  jiraMusanedAPIWithCredentials.get<GetComplaintRatingsV2Response>('/api/musaned/pro-performance-report-v2', {
    params,
    ...config,
  })

export type ComplaintInquiryParams = {
  idNumber: string
  issueKey: string
}

export type ComplaintInquiryResponse = {
  issue: {
    id: string
    status: string
    reporter_name: string
    created: string
  }
}

const complaintInquiry = (params: ComplaintInquiryParams, config: AxiosRequestConfig) =>
  jiraMusanedAPI.post<ComplaintInquiryResponse>(
    '/api/musaned/guest/show',
    {
      national_id: params.idNumber,
      issue_key: params.issueKey,
    },
    config,
  )

export type GetProPerformanceParams = {
  nationalityId?: number
}

export type ProPerformanceFromBE = {
  avg_arrival_duration_commitment_rate: string
  avg_arrival_rate: string
  avg_late_contracts_resolution_rate: string
  avg_resolution_avg: string
  pro_id: number
}

export type GetProPerformanceResponse = Array<ProPerformanceFromBE>

const getProPerformance = (params: GetProPerformanceParams, config: AxiosRequestConfig) =>
  jiraMusanedAPI.get<GetProPerformanceResponse>('/api/musaned/pro-performance', {
    params: {
      nationality_id: params.nationalityId,
    },
    ...config,
  })

const getProPerformanceOverall = (config: AxiosRequestConfig) =>
  jiraMusanedAPI.get<GetProPerformanceResponse>('/api/musaned/pro-performance', {
    ...config,
  })

export const jiraAPI = {
  getTicketsList: callAPI<typeof getTicketsList, GetTicketListResponse>(getTicketsList),
  getTicket: callAPI<typeof getTicket, GetTicketResponse>(getTicket),
  addFeedback: callAPI<typeof addFeedback, {}>(addFeedback),
  getSurveyQuestions: callAPI<typeof getSurveyQuestions, GetSurveyQuestionsResponse>(getSurveyQuestions),
  postSurveyQuestions: callAPI<typeof postSurveyQuestions, {}>(postSurveyQuestions),
  respondToComplaintSolution: callAPI<typeof respondToComplaintSolution, RespondToComplaintSolutionResponse>(
    respondToComplaintSolution,
  ),
  respondToOfficeComplaint: callAPI<typeof respondToOfficeComplaint, RespondToOfficeComplaintResponse>(
    respondToOfficeComplaint,
  ),
  getSupportTicketsList: callAPI<typeof getSupportTicketsList, GetSupportTicketsListResponse>(getSupportTicketsList),
  createSupportTicket: callAPI<
    typeof createSupportTicket,
    CreateSupportTicketResponse,
    CreateSupportTicketFailResponse
  >(createSupportTicket),
  createSupportTicketGuest: callAPI<
    typeof createSupportTicketGuest,
    CreateSupportTicketResponse,
    CreateSupportTicketFailResponse
  >(createSupportTicketGuest),
  createComplaintTicket: callAPI<
    typeof createComplaintTicket,
    CreateComplaintTicketResponse,
    CreateComplaintTicketResponseFail
  >(createComplaintTicket),
  getSupportTicketDetails: callAPI<typeof getSupportTicketDetails, GetSupportTicketDetailsResponse>(
    getSupportTicketDetails,
  ),
  updateSupportTicketDetails: callAPI<
    typeof updateSupportTicketDetails,
    UpdateSupportTicketDetailsResponse,
    UpdateSupportTicketDetailsFailResponse
  >(updateSupportTicketDetails),
  getComplaintRatings: callAPI<typeof getComplaintRatings, GetComplaintRatingsResponse>(getComplaintRatings),
  getComplaintRatingsV2: callAPI<typeof getComplaintRatingsV2, GetComplaintRatingsV2Response>(getComplaintRatingsV2),
  addMissingAttachment: callAPI<typeof addMissingAttachment, {}>(addMissingAttachment),
  complaintInquiry: callAPI<typeof complaintInquiry, ComplaintInquiryResponse>(complaintInquiry),
  getProPerformance: callAPI<typeof getProPerformance, GetProPerformanceResponse>(getProPerformance),
  getProPerformanceOverall: callAPI<typeof getProPerformanceOverall, GetProPerformanceResponse>(
    getProPerformanceOverall,
  ),
}

// used only for labia chatbot
export const rawJiraAPI = {
  getTicketsList,
  getTicket,
}
