import { MobileCheckResponse } from 'api/evisaAPI/auth'
import { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage?: string
} & Status

const initialState: State = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.MOBILE_VERIFY_ASYNC,
    Actions.MOBILE_VERIFY_SUCCESS,
    Actions.MOBILE_VERIFY_FAIL,
    MobileCheckResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.MOBILE_VERIFY_SUCCESS:
    case Actions.MOBILE_VERIFY_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.MOBILE_VERIFY_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message,
      }
    }
    default:
      return state
  }
}
