import { takeLatest, call, select, put, takeLeading } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  maarofaSubmit as maarofaSubmitAction,
  maarofaPresubmit as maarofaPresubmitAction,
  getContractRequestsInfo as getContractRequestsInfoAction,
  getContractRequestsAndVisaInfo as getContractRequestsAndVisaInfoAction,
  getPaymentInfo as getPaymentInfoAction,
  payForEContract as payForEContractAction,
  openPaymentChannel as openPaymentChannelAction,
  getContractAndPreparePayment as getContractAndPreparePaymentAction,
  acceptOfferRequest as acceptOfferRequestAction,
  getContractRequestsList as getContractRequestsListAction,
  cancelContract as cancelContractAction,
  rejectOffer as rejectOfferAction,
  cancelRequest as cancelRequestAction,
  getVATValue as getVATValueAction,
  sendPaymentSurvey as sendPaymentSurveyAction,
  getBanksInfo as getBanksInfoAction,
  extendContract as extendContractAction,
  getRequestIdForContractId as getRequestIdForContractIdAction,
  getETawtheeqContract as getETawtheeqContractAction,
  getNajmRedirectionUrl as getNajmRedirectionUrlAction,
  rejectContract as rejectConractAction,
  acceptContract as acceptConractAction,
  getRefundDetails as getRefundDetailsAction,
  getContractInfo as getContractInfoAction,
  createRefundTicket as createRefundTicketAction,
  getContractInvoiceFile as getContractInvoiceFileAction,
  getAcceptanceRate as getAcceptanceRateAction,
  getTameeniRedirectionUrl as getTameeniRedirectionUrlAction,
  getDelayedContractCounts as getDelayedContractCountsAction,
} from 'redux/actionCreators/eContractingAPI'
import { RootState } from 'redux/rootReducer'
import { getVisaInfo as getVisaInfoAction } from 'redux/actionCreators/evisaAPI/visas'
import {
  eContractingAPI,
  GetContractRequestsInfoResponse,
  GetRequestIdForContractIdResponse,
  MaarofaSubmitResponse,
} from 'api/eContractingAPI'
import { Await } from 'common/types/commonTypes'
import { history } from 'redux/browserHistory'
import { Routes, routeWithParams } from 'containers/Routes/routePaths'
import { resetReducerState } from 'redux/actionCreators/resetReducerState'
import { getProServiceAddons as getProServiceAddonsAction } from 'redux/actionCreators/prosAPI'
import { CONTRACT_AUTH_FLOW_RELATED_REDUCERS } from 'containers/ContractPages/IssueNewRequest/AuthRequestPages/utils'

import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from '../utils'

import { getVisaInfo } from './evisaAPI/visasSaga'
import { getProServiceAddons } from './prosAPISagas'

const getContractRequestsInfo = getSimpleRequestHandler<
  typeof eContractingAPI.getContractRequestsInfo,
  ReturnType<typeof getContractRequestsInfoAction>
>({
  actionLoading: Actions.GET_CONTRACT_REQUEST_INFO_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_REQUEST_INFO_SUCCESS,
  actionFail: Actions.GET_CONTRACT_REQUEST_INFO_FAIL,
  callApiFn: eContractingAPI.getContractRequestsInfo,
})

export const getPaymentInfo = getSimpleRequestHandler<
  typeof eContractingAPI.getPaymentInfo,
  ReturnType<typeof getPaymentInfoAction>
>({
  actionLoading: Actions.GET_PAYMENT_INFO_ASYNC,
  actionSuccess: Actions.GET_PAYMENT_INFO_SUCCESS,
  actionFail: Actions.GET_PAYMENT_INFO_FAIL,
  callApiFn: eContractingAPI.getPaymentInfo,
})

const payForEContract = getSimpleRequestHandler<
  typeof eContractingAPI.payForEContract,
  ReturnType<typeof payForEContractAction>
>({
  actionLoading: Actions.PAY_FOR_ECONTRACT_ASYNC,
  actionSuccess: Actions.PAY_FOR_ECONTRACT_SUCCESS,
  actionFail: Actions.PAY_FOR_ECONTRACT_FAIL,
  callApiFn: eContractingAPI.payForEContract,
})

export const openPaymentChannel = getSimpleRequestHandler<
  typeof eContractingAPI.openPaymentChannel,
  ReturnType<typeof openPaymentChannelAction>
>({
  callApiFn: eContractingAPI.openPaymentChannel,
})

const maarofaPresubmit = getSimpleRequestHandler<
  typeof eContractingAPI.maarofaPresubmit,
  ReturnType<typeof maarofaPresubmitAction>
>({
  callApiFn: eContractingAPI.maarofaPresubmit,
  actionLoading: Actions.MAAROFA_PRESUBMIT_ASYNC,
  actionSuccess: Actions.MAAROFA_PRESUBMIT_SUCCESS,
  actionFail: Actions.MAAROFA_PRESUBMIT_FAIL,
})

function* maarofaSubmit(action: ReturnType<typeof maarofaSubmitAction>) {
  yield putLoading({ type: Actions.MAAROFA_SUBMIT_ASYNC, callAPIActionPayload: action.payload })
  const directContract: Await<ReturnType<typeof eContractingAPI.maarofaSubmit>> = yield call<
    typeof eContractingAPI.maarofaSubmit
  >(eContractingAPI.maarofaSubmit, action.payload.params)
  const { response, status: directContractStatus } = directContract
  if (directContractStatus.fail) {
    yield putFail({ type: Actions.MAAROFA_SUBMIT_FAIL, payload: response, callAPIActionPayload: action.payload })
    return
  }
  const offerId: { response: GetContractRequestsInfoResponse } = yield getContractRequestsInfo(
    getContractRequestsInfoAction({ requestId: (response as MaarofaSubmitResponse).id as number }),
  )
  const {
    response: {
      contractRequests: {
        offers: [offer],
      },
    },
  } = offerId
  yield putSuccess({
    type: Actions.MAAROFA_SUBMIT_SUCCESS,
    payload: {
      offerId: offer.id,
      requestId: (response as MaarofaSubmitResponse).id as number,
    },
  })
}

const sendCvReport = getSimpleRequestHandler({
  callApiFn: eContractingAPI.sendCvReport,
  actionLoading: Actions.SEND_CV_REPORT_ASYNC,
  actionSuccess: Actions.SEND_CV_REPORT_SUCCESS,
  actionFail: Actions.SEND_CV_REPORT_FAIL,
})

const getVisaId = (state: RootState) => state.contractRequestInfo.contractRequests.visaId

const getContractId = (id: number) => (state: RootState) =>
  state.contractRequestInfo.contractRequests.offers[id]?.contract?.id

const getServiceId = (id: number) => (state: RootState) =>
  state.contractRequestInfo.contractRequests.offers[id]?.serviceId

function* getContractRequestsAndVisasInfo(action: ReturnType<typeof getContractRequestsAndVisaInfoAction>) {
  yield putLoading({ type: Actions.GET_VISA_INFO_ASYNC })
  yield getContractRequestsInfo(action)
  const visaId: unknown = yield select(getVisaId)
  if (typeof visaId !== 'number') {
    yield put({ type: Actions.GET_VISA_INFO_FAIL })
    return
  }
  yield call(getVisaInfo, getVisaInfoAction({ visaId, unissued: 1, delegated: 1 }))
}

function* acceptOfferRequestSaga(action: ReturnType<typeof acceptOfferRequestAction>) {
  const acceptOfferRequest = getSimpleRequestHandler<
    typeof eContractingAPI.acceptOfferRequest,
    ReturnType<typeof acceptOfferRequestAction>
  >({
    actionLoading: Actions.ACCEPT_OFFER_REQUEST_ASYNC,
    actionSuccess: Actions.ACCEPT_OFFER_REQUEST_SUCCESS,
    actionFail: Actions.ACCEPT_OFFER_REQUEST_FAIL,
    callApiFn: eContractingAPI.acceptOfferRequest,
  })
  yield acceptOfferRequest(action)
  const acceptOfferRequestStatus: RootState['acceptOfferRequestStatus'] = yield select(
    (state: RootState) => state.acceptOfferRequestStatus,
  )
  if (acceptOfferRequestStatus.success) {
    if (
      action.payload.params.insuranceEnabled &&
      action.payload.params.language &&
      action.payload.params.previousPageURL
    ) {
      yield put(
        getNajmRedirectionUrlAction({
          offerId: action.payload.params.offerId,
          requestId: action.payload.params.requestId,
          contractId: acceptOfferRequestStatus.contractId,
          language: action.payload.params.language,
          previousPageURL: action.payload.params.previousPageURL,
        }),
      )
    } else {
      history.push(
        routeWithParams(Routes.CONTRACT_DETAILS, {
          offerId: action.payload.params.offerId,
          requestId: action.payload.params.requestId,
        }),
      )
    }
  }
}

function* vipAcceptOfferRequestSaga(action: ReturnType<typeof acceptOfferRequestAction>) {
  const acceptOfferRequest = getSimpleRequestHandler<
    typeof eContractingAPI.acceptOfferRequest,
    ReturnType<typeof acceptOfferRequestAction>
  >({
    actionLoading: Actions.ACCEPT_OFFER_REQUEST_ASYNC,
    actionSuccess: Actions.ACCEPT_OFFER_REQUEST_SUCCESS,
    actionFail: Actions.ACCEPT_OFFER_REQUEST_FAIL,
    callApiFn: eContractingAPI.acceptOfferRequest,
  })
  yield acceptOfferRequest(action)
  const acceptOfferRequestStatus: RootState['acceptOfferRequestStatus'] = yield select(
    (state: RootState) => state.acceptOfferRequestStatus,
  )
  if (acceptOfferRequestStatus.success) {
    history.push(
      routeWithParams(Routes.VIP_SELECT_INSURANCE, {
        offerId: action.payload.params.offerId,
        requestId: action.payload.params.requestId,
        cvId: action.payload.params.cvId!,
      }),
    )
  }
}

const getContractRequestsList = getSimpleRequestHandler<
  typeof eContractingAPI.getContractRequestsList,
  ReturnType<typeof getContractRequestsListAction>
>({
  actionLoading: Actions.GET_CONTRACT_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_CONTRACT_REQUESTS_LIST_FAIL,
  callApiFn: eContractingAPI.getContractRequestsList,
})

function* getContractAndPreparePayment(action: ReturnType<typeof getContractAndPreparePaymentAction>) {
  yield getContractRequestsInfo(action)
  const {
    payload: {
      params: { offerId },
    },
  } = action
  const contractId: number | undefined = yield select(getContractId(offerId))
  if (typeof contractId !== 'number') {
    yield put({ type: Actions.GET_CONTRACT_REQUEST_INFO_FAIL })
    return
  }
  const serviceId: number | undefined = yield select(getServiceId(offerId))
  if (typeof serviceId !== 'number') {
    yield put({ type: Actions.GET_PRO_SERVICE_ADDONS_FAIL })
  } else {
    yield call(getProServiceAddons, getProServiceAddonsAction({ serviceId }))
  }
  yield call(getPaymentInfo, getPaymentInfoAction({ contractId }))
  yield call(openPaymentChannel, openPaymentChannelAction({ contractId, channel: 'cc' }))
}

const cancelContract = getSimpleRequestHandler<
  typeof eContractingAPI.cancelContract,
  ReturnType<typeof cancelContractAction>
>({
  actionLoading: Actions.CANCEL_CONTRACT_ASYNC,
  actionSuccess: Actions.CANCEL_CONTRACT_SUCCESS,
  actionFail: Actions.CANCEL_CONTRACT_FAIL,
  callApiFn: eContractingAPI.cancelContract,
})

const rejectOffer = getSimpleRequestHandler<typeof eContractingAPI.rejectOffer, ReturnType<typeof rejectOfferAction>>({
  actionLoading: Actions.REJECT_OFFER_ASYNC,
  actionSuccess: Actions.REJECT_OFFER_SUCCESS,
  actionFail: Actions.REJECT_OFFER_FAIL,
  callApiFn: eContractingAPI.rejectOffer,
})

const cancelRequest = getSimpleRequestHandler<
  typeof eContractingAPI.cancelRequest,
  ReturnType<typeof cancelRequestAction>
>({
  actionLoading: Actions.CANCEL_REQUEST_ASYNC,
  actionSuccess: Actions.CANCEL_REQUEST_SUCCESS,
  actionFail: Actions.CANCEL_REQUEST_FAIL,
  callApiFn: eContractingAPI.cancelRequest,
})

const getVATValue = getSimpleRequestHandler<typeof eContractingAPI.getVATValue, ReturnType<typeof getVATValueAction>>({
  actionLoading: Actions.GET_VAT_VALUE_ASYNC,
  actionSuccess: Actions.GET_VAT_VALUE_SUCCESS,
  actionFail: Actions.GET_VAT_VALUE_FAIL,
  callApiFn: eContractingAPI.getVATValue,
})

const sendPaymentSurvey = getSimpleRequestHandler<
  typeof eContractingAPI.sendPaymentSurvey,
  ReturnType<typeof sendPaymentSurveyAction>
>({
  callApiFn: eContractingAPI.sendPaymentSurvey,
})

const getBanksInfo = getSimpleRequestHandler<
  typeof eContractingAPI.getBanksInfo,
  ReturnType<typeof getBanksInfoAction>
>({
  actionLoading: Actions.GET_BANKS_INFO_ASYNC,
  actionSuccess: Actions.GET_BANKS_INFO_SUCCESS,
  actionFail: Actions.GET_BANKS_INFO_FAIL,
  callApiFn: eContractingAPI.getBanksInfo,
})

const extendContract = getSimpleRequestHandler<
  typeof eContractingAPI.extendContract,
  ReturnType<typeof extendContractAction>
>({
  actionLoading: Actions.EXTEND_CONTRACT_ASYNC,
  actionSuccess: Actions.EXTEND_CONTRACT_SUCCESS,
  actionFail: Actions.EXTEND_CONTRACT_FAIL,
  callApiFn: eContractingAPI.extendContract,
})

const getRefundDetails = getSimpleRequestHandler<
  typeof eContractingAPI.getRefundDetails,
  ReturnType<typeof getRefundDetailsAction>
>({
  actionLoading: Actions.GET_REFUND_DETAILS_ASYNC,
  actionSuccess: Actions.GET_REFUND_DETAILS_SUCCESS,
  actionFail: Actions.GET_REFUND_DETAILS_FAIL,
  callApiFn: eContractingAPI.getRefundDetails,
})

const createRefundTicket = getSimpleRequestHandler<
  typeof eContractingAPI.createRefundTicket,
  ReturnType<typeof createRefundTicketAction>
>({
  actionLoading: Actions.CREATE_REFUND_TICKET_ASYNC,
  actionSuccess: Actions.CREATE_REFUND_TICKET_SUCCESS,
  actionFail: Actions.CREATE_REFUND_TICKET_FAIL,
  callApiFn: eContractingAPI.createRefundTicket,
})

function* getRequestIdForContractId(action: ReturnType<typeof getRequestIdForContractIdAction>) {
  try {
    yield putLoading({
      type: Actions.GET_REQUEST_ID_FOR_CONTRACT_ID_ASYNC,
      callAPIActionPayload: action.payload,
    })
    const { response, headers } = yield call<typeof eContractingAPI.getRequestIdForContractId>(
      eContractingAPI.getRequestIdForContractId,
      action.payload.params,
      {},
      { silenceError: false },
    )
    yield putSuccess({
      type: Actions.GET_REQUEST_ID_FOR_CONTRACT_ID_SUCCESS,
      callAPIActionPayload: action.payload,
      payload: {},
      headers,
    })
    history.push(
      routeWithParams(Routes.CONTRACT_REQUEST, {
        requestId: (response as GetRequestIdForContractIdResponse).contract_request_id,
      }),
    )
    yield put(resetReducerState(['requestIdForContractIdStatus', ...CONTRACT_AUTH_FLOW_RELATED_REDUCERS]))
  } catch (err) {
    yield putFail({
      type: Actions.GET_REQUEST_ID_FOR_CONTRACT_ID_FAIL,
      callAPIActionPayload: action.payload,
      payload: {},
      headers: {},
    })
  }
}

function* getETawtheeqContract(action: ReturnType<typeof getETawtheeqContractAction>) {
  try {
    yield putLoading({
      type: Actions.GET_ETAWTHEQ_CONTRACT_ASYNC,
      callAPIActionPayload: action.payload,
    })
    const { response, headers } = yield call<typeof eContractingAPI.getETawtheeqContract>(
      eContractingAPI.getETawtheeqContract,
      action.payload.params,
      {
        responseType: 'blob',
      },
      { silenceError: false },
    )
    const eTawseqContractUrl = URL.createObjectURL(response)
    yield putSuccess({
      type: Actions.GET_ETAWTHEQ_CONTRACT_SUCCESS,
      callAPIActionPayload: action.payload,
      payload: {
        eTawseqContractUrl,
      },
      headers,
    })
  } catch (err) {
    yield putFail({
      type: Actions.GET_ETAWTHEQ_CONTRACT_FAIL,
      callAPIActionPayload: action.payload,
      payload: {},
      headers: {},
    })
  }
}

function* getNajmRedirectionURLSaga(action: ReturnType<typeof getNajmRedirectionUrlAction>) {
  const getNajmRedirectionUrl = getSimpleRequestHandler<
    typeof eContractingAPI.getNajmRedirectionUrl,
    ReturnType<typeof getNajmRedirectionUrlAction>
  >({
    actionLoading: Actions.GET_NAJM_REDIRECTION_URL_ASYNC,
    actionSuccess: Actions.GET_NAJM_REDIRECTION_URL_SUCCESS,
    actionFail: Actions.GET_NAJM_REDIRECTION_URL_FAIL,
    callApiFn: eContractingAPI.getNajmRedirectionUrl,
  })

  const gotToNajmPortal = (najmUrl: string, musanedCallBackLink: string) => {
    const url = `${najmUrl}?ReturnURL=${encodeURIComponent(window.location.origin + musanedCallBackLink)}`
    window.location.href = url
  }

  yield getNajmRedirectionUrl(action)
  const najmRedirectionUrlStatus: RootState['najmRedirectionUrlStatus'] = yield select(
    (state: RootState) => state.najmRedirectionUrlStatus,
  )
  if (najmRedirectionUrlStatus.success) {
    gotToNajmPortal(najmRedirectionUrlStatus.redirectionUrl, action.payload.params.callBackLink)
  }
}

const rejectContract = getSimpleRequestHandler<
  typeof eContractingAPI.rejectContract,
  ReturnType<typeof rejectConractAction>
>({
  actionLoading: Actions.REJECT_CONTRACT_ASYNC,
  actionSuccess: Actions.REJECT_CONTRACT_SUCCESS,
  actionFail: Actions.REJECT_CONTRACT_FAIL,
  callApiFn: eContractingAPI.rejectContract,
})

const acceptContract = getSimpleRequestHandler<
  typeof eContractingAPI.acceptContract,
  ReturnType<typeof acceptConractAction>
>({
  actionLoading: Actions.ACCEPT_CONTRACT_ASYNC,
  actionSuccess: Actions.ACCEPT_CONTRACT_SUCCESS,
  actionFail: Actions.ACCEPT_CONTRACT_FAIL,
  callApiFn: eContractingAPI.acceptContract,
})

const getContractInfo = getSimpleRequestHandler<
  typeof eContractingAPI.getContractInfo,
  ReturnType<typeof getContractInfoAction>
>({
  actionLoading: Actions.GET_CONTRACT_INFO_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_INFO_SUCCESS,
  actionFail: Actions.GET_CONTRACT_INFO_FAIL,
  callApiFn: eContractingAPI.getContractInfo,
})

const getContractInvoiceFile = getSimpleRequestHandler<
  typeof eContractingAPI.getContractInvoiceFile,
  ReturnType<typeof getContractInvoiceFileAction>
>({
  actionLoading: Actions.GET_CONTRACT_INVOICE_FILE_ASYNC,
  actionSuccess: Actions.GET_CONTRACT_INVOICE_FILE_SUCCESS,
  actionFail: Actions.GET_CONTRACT_INVOICE_FILE_FAIL,
  callApiFn: eContractingAPI.getContractInvoiceFile,
})

const getAcceptanceRate = getSimpleRequestHandler<
  typeof eContractingAPI.getAcceptanceRate,
  ReturnType<typeof getAcceptanceRateAction>
>({
  actionLoading: Actions.GET_ACCEPTANCE_RATE_ASYNC,
  actionSuccess: Actions.GET_ACCEPTANCE_RATE_SUCCESS,
  actionFail: Actions.GET_ACCEPTANCE_RATE_FAIL,
  callApiFn: eContractingAPI.getAcceptanceRate,
})

function* getTameeniRedirectionURLSaga(action: ReturnType<typeof getTameeniRedirectionUrlAction>) {
  const getTameeniRedirectionUrl = getSimpleRequestHandler<
    typeof eContractingAPI.getTameeniRedirectionUrl,
    ReturnType<typeof getTameeniRedirectionUrlAction>
  >({
    actionLoading: Actions.GET_TAMEENI_REDIRECTION_URL_ASYNC,
    actionSuccess: Actions.GET_TAMEENI_REDIRECTION_URL_SUCCESS,
    actionFail: Actions.GET_TAMEENI_REDIRECTION_URL_FAIL,
    callApiFn: eContractingAPI.getTameeniRedirectionUrl,
  })

  const gotToTameeniPortal = (tameeniUrl: string, musanedCallBackLink: string) => {
    const url = `${tameeniUrl}?ReturnURL=${encodeURIComponent(window.location.origin + musanedCallBackLink)}`
    window.location.href = url
  }

  yield getTameeniRedirectionUrl(action)
  const tameeniRedirectionUrlStatus: RootState['tameeniRedirectionUrlStatus'] = yield select(
    (state: RootState) => state.tameeniRedirectionUrlStatus,
  )
  if (tameeniRedirectionUrlStatus.success) {
    gotToTameeniPortal(tameeniRedirectionUrlStatus.redirectionUrl, action.payload.params.callBackLink)
  }
}

const getDelayedContractCounts = getSimpleRequestHandler<
  typeof eContractingAPI.getDelayedContractCounts,
  ReturnType<typeof getDelayedContractCountsAction>
>({
  actionLoading: Actions.GET_DELAYED_CONTRACTS_COUNTS_ASYNC,
  actionSuccess: Actions.GET_DELAYED_CONTRACTS_COUNTS_SUCCESS,
  actionFail: Actions.GET_DELAYED_CONTRACTS_COUNTS_FAIL,
  callApiFn: eContractingAPI.getDelayedContractCounts,
})

export default [
  takeLatest(Actions.MAAROFA_PRESUBMIT, maarofaPresubmit),
  takeLeading(Actions.MAAROFA_SUBMIT, maarofaSubmit),
  takeLatest(Actions.GET_CONTRACT_REQUEST_INFO, getContractRequestsInfo),
  takeLatest(Actions.GET_CONTRACT_REQUEST_AND_VISA_INFO, getContractRequestsAndVisasInfo),
  takeLatest(Actions.GET_CONTRACT_AND_PREPARE_PAYMENT, getContractAndPreparePayment),
  takeLatest(Actions.SEND_CV_REPORT, sendCvReport),
  takeLatest(Actions.ACCEPT_OFFER_REQUEST, acceptOfferRequestSaga),
  takeLatest(Actions.VIP_ACCEPT_OFFER_REQUEST, vipAcceptOfferRequestSaga),
  takeLatest(Actions.GET_CONTRACT_REQUESTS_LIST, getContractRequestsList),
  takeLatest(Actions.CANCEL_CONTRACT, cancelContract),
  takeLatest(Actions.REJECT_OFFER, rejectOffer),
  takeLatest(Actions.CANCEL_REQUEST, cancelRequest),
  takeLatest(Actions.GET_VAT_VALUE, getVATValue),
  takeLatest(Actions.SEND_PAYMENT_SURVEY, sendPaymentSurvey),
  takeLatest(Actions.GET_PAYMENT_INFO, getPaymentInfo),
  takeLatest(Actions.GET_BANKS_INFO, getBanksInfo),
  takeLeading(Actions.PAY_FOR_ECONTRACT, payForEContract),
  takeLeading(Actions.EXTEND_CONTRACT, extendContract),
  takeLeading(Actions.GET_REQUEST_ID_FOR_CONTRACT_ID, getRequestIdForContractId),
  takeLeading(Actions.GET_ETAWTHEQ_CONTRACT, getETawtheeqContract),
  takeLeading(Actions.GET_NAJM_REDIRECTION_URL, getNajmRedirectionURLSaga),
  takeLatest(Actions.REJECT_CONTRACT, rejectContract),
  takeLatest(Actions.ACCEPT_CONTRACT, acceptContract),
  takeLeading(Actions.GET_REFUND_DETAILS, getRefundDetails),
  takeLeading(Actions.CREATE_REFUND_TICKET, createRefundTicket),
  takeLatest(Actions.GET_CONTRACT_INFO, getContractInfo),
  takeLatest(Actions.GET_CONTRACT_INVOICE_FILE, getContractInvoiceFile),
  takeLatest(Actions.GET_ACCEPTANCE_RATE, getAcceptanceRate),
  takeLatest(Actions.GET_TAMEENI_REDIRECTION_URL, getTameeniRedirectionURLSaga),
  takeLatest(Actions.GET_DELAYED_CONTRACTS_COUNTS, getDelayedContractCounts),
]
