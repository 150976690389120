import Actions from 'redux/actions'
import { GetProPerformanceParams } from 'api/jiraAPI'
import { setProPerformance } from 'redux/actionCreators/jiraAPI'

const initialState = {
  nationalityId: undefined,
}

const proPerformanceFilter = (
  state: GetProPerformanceParams = initialState,
  action: ReturnType<typeof setProPerformance>,
): {
  nationalityId?: number
} => {
  switch (action.type) {
    case Actions.SET_PRO_PERFORMANCE:
      return {
        ...state,
        nationalityId: action.payload.params.nationalityId,
      }
    default:
      return state
  }
}

export default proPerformanceFilter
