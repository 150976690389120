import { CallAPIAction, Status } from 'api/types'
import { IssueNewVisaRequestStatusError, UserEligibilityErrorMessages } from 'common/types/commonTypes'
import type { IssueNewVisaRequestFailResponse, IssueNewVisaRequestResponse } from 'api/evisaAPI/visas'
import { Bill } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage: IssueNewVisaRequestStatusError | UserEligibilityErrorMessages | string
  issueRequestId: number
  bill?: Bill
} & Status

const initialState = {
  errorMessage: '',
  issueRequestId: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ISSUE_NEW_VISA_REQUEST_ASYNC,
    Actions.ISSUE_NEW_VISA_REQUEST_SUCCESS,
    Actions.ISSUE_NEW_VISA_REQUEST_FAIL,
    IssueNewVisaRequestResponse,
    IssueNewVisaRequestFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ISSUE_NEW_VISA_REQUEST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.ISSUE_NEW_VISA_REQUEST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        issueRequestId: payload.request.id,
        bill: !payload.request.bill
          ? undefined
          : {
              id: payload.request.bill.id,
              billNumber: payload.request.bill.bill_number,
              vat: payload.request.bill.vat,
              amount: payload.request.bill.amount,
              status: payload.request.bill.status,
              createdAt: payload.request.bill.created_at,
              expiryDate: payload.request.bill.expiry_date,
              paidAt: payload.request.bill.paid_at,
            },
      }
    }
    case Actions.ISSUE_NEW_VISA_REQUEST_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
