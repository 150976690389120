import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { TranslationNamespace } from 'common/utils/translation'

import MusanedLogoEn from 'images/logo-musaned.svg'
import MusanedLogoAr from 'images/logo-musaned-ar.svg'
import MusanedLogoVip from 'images/vip/logo-musaned.svg'
import MusanedWhiteLogoEn from 'images/logo-musaned-white.svg'
import MusanedWhiteLogoAr from 'images/logo-musaned-white-ar.svg'

type MusanedLogoProps = {
  className?: string
  isVip?: boolean
  color?: 'white'
}

const MusanedLogo: FunctionComponent<MusanedLogoProps> = ({ className, isVip, color }) => {
  const { t } = useTranslation(TranslationNamespace.uiComponents)
  const isRtl = document.dir === 'rtl'
  const logoEn = color === 'white' ? MusanedWhiteLogoEn : MusanedLogoEn
  const logoAr = color === 'white' ? MusanedWhiteLogoAr : MusanedLogoAr

  const logoSrc = isVip ? MusanedLogoVip : isRtl ? logoAr : logoEn

  return <img src={logoSrc} className={className} alt={t('musanedAlt')} />
}

export default MusanedLogo
