import { GetEtoINewRequestResponse } from 'api/newDLTransferAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import {
  getEmptyEToINewRequest,
  convertEToIRequestFromResponse,
  EToINewRequest,
} from 'common/utils/convertResponse/convertEToIResponse'

type State = {
  request: EToINewRequest
} & Status

const initialState: State = {
  request: getEmptyEToINewRequest(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_E_TO_I_REQUEST_DETAILS_ASYNC,
    Actions.GET_E_TO_I_REQUEST_DETAILS_SUCCESS,
    Actions.GET_E_TO_I_REQUEST_DETAILS_FAIL,
    GetEtoINewRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_E_TO_I_REQUEST_DETAILS_ASYNC:
      return {
        ...state,
        ...action.status,
        request: getEmptyEToINewRequest(),
      }
    case Actions.GET_E_TO_I_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        request: convertEToIRequestFromResponse(action.payload),
      }
    }
    case Actions.GET_E_TO_I_REQUEST_DETAILS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
