import { CreateSupportTicketFailResponse, CreateSupportTicketResponse } from 'api/jiraAPI'
import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_SUPPORT_TICKET_GUEST_ASYNC,
    Actions.CREATE_SUPPORT_TICKET_GUEST_SUCCESS,
    Actions.CREATE_SUPPORT_TICKET_GUEST_FAIL,
    CreateSupportTicketResponse,
    CreateSupportTicketFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_SUPPORT_TICKET_GUEST_ASYNC:
    case Actions.CREATE_SUPPORT_TICKET_GUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CREATE_SUPPORT_TICKET_GUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message || '',
      }
    }
    default:
      return state
  }
}
