import { GetDelayedContractCountsResponse } from 'api/eContractingAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  delayedDuration: number 
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  delayedDuration: 0,
}

const countDelayedDuration = (delayed: { [key: string]: number }) => {
  const keys = Object.keys(delayed)

  if (keys.length > 0) {
    return Math.max(...keys.map(delay => parseInt(delay, 10))) + 30
  }

  return 0
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_DELAYED_CONTRACTS_COUNTS_ASYNC,
    Actions.GET_DELAYED_CONTRACTS_COUNTS_SUCCESS,
    Actions.GET_DELAYED_CONTRACTS_COUNTS_FAIL,
    GetDelayedContractCountsResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_DELAYED_CONTRACTS_COUNTS_SUCCESS:
      return {
        ...state,
        ...action.status,
        delayedDuration: countDelayedDuration(action.payload.delayed),
      }
    case Actions.GET_DELAYED_CONTRACTS_COUNTS_ASYNC:
    case Actions.GET_DELAYED_CONTRACTS_COUNTS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
