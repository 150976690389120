import type { CallAPIAction, Status } from 'api/types'
import type { GetAllMarketOffersResponse } from 'api/prosAPI'
import { getMarketOffers } from 'redux/actionCreators/prosAPI'
import type { Offer } from 'common/types/transformedRespTypes'
import { convertRating } from 'common/utils/common'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  offers: StringRecord<Offer>
  allOffersIds: Array<number>
  lastPage: number
  offersCount: number
} & Status

const convertResponseToState = (resp: GetAllMarketOffersResponse) => {
  const [offers, allOffersIds] = resp.data.reduce<[StringRecord<Offer>, Array<number>]>(
    ([offersAcc, idsAcc], offerFromBE) => [
      {
        ...offersAcc,
        [offerFromBE.id]: {
          id: offerFromBE.id,
          fullName: offerFromBE.name,
          nationalityId: offerFromBE.religion_id,
          occupationId: offerFromBE.occupation_id,
          religionId: offerFromBE.religion_id,
          price: offerFromBE.price,
          acceptanceRate: Math.round(offerFromBE.acceptance_rate),
          duration: offerFromBE.recruitment_period,
          rating: convertRating(offerFromBE.rating),
          logoUrl: offerFromBE.address?.logo_url,
          phoneNumber: offerFromBE.address?.phone_number,
          mobileNumber: offerFromBE.address?.mobile_number,
          email: offerFromBE.address?.email,
          location: offerFromBE.address?.location,
          address: offerFromBE.address?.address,
          proId: offerFromBE.id,
          cityId: offerFromBE.address?.city_id,
          isActive: offerFromBE.active,
          eContract: offerFromBE.eContract,
          laborOffice: offerFromBE.labor_office,
          sequenceNumber: offerFromBE.sequence_number,
          cities: [
            ...offerFromBE.transport_services.map((city) => ({
              cityId: city.city_id,
              proId: city.pro_id,
            })),
            {
              cityId: offerFromBE.address?.city_id,
              proId: offerFromBE.address?.pro_id,
            },
          ],
        },
      },
      idsAcc.concat(offerFromBE.id),
    ],
    [{}, []],
  )
  return {
    offers,
    allOffersIds,
    lastPage: resp.last_page,
    offersCount: resp.total,
  }
}

const initialState = {
  offers: {},
  allOffersIds: [],
  lastPage: 0,
  offersCount: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ALL_MARKET_OFFERS_ASYNC,
    Actions.GET_ALL_MARKET_OFFERS_SUCCESS,
    Actions.GET_ALL_MARKET_OFFERS_FAIL,
    GetAllMarketOffersResponse,
    {},
    {},
    ReturnType<typeof getMarketOffers>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_ALL_MARKET_OFFERS_ASYNC: {
      const { params } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        lastPage: params.clearPagesCount ? 0 : state.lastPage,
      }
    }
    case Actions.GET_ALL_MARKET_OFFERS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_ALL_MARKET_OFFERS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
