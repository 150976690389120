import type {
  ObjectWithTranslationFromBEWithoutId,
  ObjectWithTranslationFromBE,
  OccupationFromBE,
  NationalityFromBE,
} from '../types/transformedRespTypes'

export const formatObjectWithTranslationFromBE = (
  label: ObjectWithTranslationFromBEWithoutId | ObjectWithTranslationFromBE,
) => ({
  id: (label as ObjectWithTranslationFromBE).id || 0,
  label: label.label,
  labelEn: label.label_en,
})

export const formatOccupationsFromBE = (occupationFromBE: OccupationFromBE) => ({
  gender: occupationFromBE.gender,
  ...formatObjectWithTranslationFromBE(occupationFromBE),
})

export const formatNationalityFromBE = (nationalityFromBE: NationalityFromBE) => ({
  ...formatObjectWithTranslationFromBE(nationalityFromBE),
  abbreviation: nationalityFromBE.abbreviation,
  code: nationalityFromBE.code,
  isOnlineContractingAllowed: Boolean(Number(nationalityFromBE.is_online_contracting_allowed)),
})
