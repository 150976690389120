import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { getIToILaborerList } from 'redux/actionCreators/newDLTransferAPI'
import { IToILaborerListResponse, IToILaborerListItem, IToILaborerListFailResponse } from 'api/newDLTransferAPI'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

type State = {
  data: Array<Request>
  error?: string
} & Status

const initialState = {
  data: [],
  isLoading: false,
  success: false,
  fail: false,
  error: '',
}

type Request = {
  id: number
  name: string
  nationality: ObjectWithTranslation
  occupation: ObjectWithTranslation
  birthDate: string
}

const convertRequestToState = (resp: IToILaborerListItem): Request => ({
  id: resp.idNumber,
  name: resp.name,
  nationality: {
    id: parseInt(resp.nationality.code),
    label: resp.nationality.label,
    labelEn: resp.nationality.labelEn,
  },
  occupation: {
    id: parseInt(resp.occupation.code),
    label: resp.occupation.label,
    labelEn: resp.occupation.labelEn,
  },
  birthDate: resp.birthDate,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_LABORER_LIST_ASYNC,
    Actions.GET_I_TO_I_LABORER_LIST_SUCCESS,
    Actions.GET_I_TO_I_LABORER_LIST_FAIL,
    IToILaborerListResponse,
    IToILaborerListFailResponse,
    {},
    ReturnType<typeof getIToILaborerList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_I_LABORER_LIST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_I_TO_I_LABORER_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload?.map(convertRequestToState),
      }
    case Actions.GET_I_TO_I_LABORER_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    }
    default:
      return state
  }
}
