import React, { FunctionComponent, memo, ReactNode } from 'react'
import clsx from 'clsx'

import { useVipRoute } from 'common/hooks/useVipRoute'

import styles from './Button.module.scss'

export type ButtonProps = {
  type?: 'primary' | 'secondary' | 'tertiary' | 'custom'
  onClick?: () => void
  disabled?: boolean
  className?: string
  iconEnhancer?: ReactNode
  buttonContentClassName?: string
  withEnhancerStartClassName?: string
  withEnhancerEndClassName?: string
  iconEnhancerPosition?: 'start' | 'end'
  dataTestid?: string
}

const Button: FunctionComponent<ButtonProps> = ({
  type: buttonType = 'primary',
  children,
  className,
  onClick = () => null,
  disabled,
  iconEnhancer,
  iconEnhancerPosition = 'end',
  buttonContentClassName,
  withEnhancerStartClassName,
  withEnhancerEndClassName,
  dataTestid,
  ...rest
}) => {
  const startComponent = iconEnhancerPosition === 'start' && iconEnhancer
  const endComponent = iconEnhancerPosition === 'end' && iconEnhancer
  const { isVip } = useVipRoute()
  const type = isVip ? `vip-${buttonType}` : buttonType
  return (
    <button
      type='button'
      className={clsx(
        styles[type],
        className,
        disabled && (type.startsWith('vip') ? styles.vipDisabled : styles.disabled),
        isVip && buttonType === 'tertiary' && styles.noBgr,
        isVip && styles.vipOverrides,
      )}
      onClick={() => onClick()}
      disabled={disabled}
      data-testid={dataTestid}
      {...rest}
    >
      <div className={clsx(styles.buttonContent, buttonContentClassName)}>
        {startComponent}
        <div
          className={clsx(
            startComponent && [styles.withEnhancerStart, withEnhancerStartClassName],
            endComponent && [styles.withEnhancerEnd, withEnhancerEndClassName],
          )}
        >
          {children}
        </div>
        {endComponent}
      </div>
    </button>
  )
}

export default memo<typeof Button>(Button)
