import { GetEtoIRequestsResponse } from 'api/newDLTransferAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { getEtoIRequests } from 'redux/actionCreators/newDLTransferAPI'
import { EToIRequestStatus } from 'common/types/commonTypes'
import { convertEToIRequestFromResponse, EToIRequest } from 'common/utils/convertResponse/convertEToIResponse'

type State = {
  eToIRequestsParams: {
    page: number
    status: string | EToIRequestStatus
  }
  requests: Array<EToIRequest>
  lastPage: number
} & Status

const initialState: State = {
  eToIRequestsParams: {
    page: 0,
    status: '',
  },
  requests: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_E_TO_I_REQUESTS_ASYNC,
    Actions.GET_E_TO_I_REQUESTS_SUCCESS,
    Actions.GET_E_TO_I_REQUESTS_FAIL,
    GetEtoIRequestsResponse,
    {},
    {},
    ReturnType<typeof getEtoIRequests>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_E_TO_I_REQUESTS_ASYNC: {
      const {
        params: { status = state.eToIRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        eToIRequestsParams: {
          page,
          status,
        },
        lastPage: status !== state.eToIRequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_E_TO_I_REQUESTS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        requests: action.payload.data.map(convertEToIRequestFromResponse),
        lastPage: action.payload.last_page,
      }
    }
    case Actions.GET_E_TO_I_REQUESTS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
