import { CallAPIAction, Status } from 'api/types'
import { GetLocationDetailsResponse, LocationItem } from 'api/evisaAPI/evisaAPI'
import Actions from 'redux/actions'

type State = {
  city: string
  neighborhood: string
  street: string
  streetNumber: string
  postalCode: string
  altNumber: string
} & Status

type Location = {
  address_components: LocationItem[]
  formatted_address: string
}

const initialState = {
  city: '',
  neighborhood: '',
  street: '',
  streetNumber: '',
  postalCode: '',
  altNumber: '',
  isLoading: false,
  success: false,
  fail: false,
}

const getData = (source: Array<LocationItem>, label: string) =>
  source.find((location) => location.types.find((type) => type === label)) || { long_name: '' }

const convertResponseToState = (location: Location) => {
  const resp = location?.address_components

  return {
    city: getData(resp, 'locality')?.long_name,
    neighborhood: getData(resp, 'sublocality')?.long_name,
    street: getData(resp, 'route')?.long_name,
    streetNumber: getData(resp, 'street_number')?.long_name,
    postalCode: getData(resp, 'postal_code')?.long_name,
    altNumber: getData(resp, 'postal_code_suffix')?.long_name,
  }
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LOCATION_DETAILS_ASYNC,
    Actions.GET_LOCATION_DETAILS_SUCCESS,
    Actions.GET_LOCATION_DETAILS_FAIL,
    GetLocationDetailsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LOCATION_DETAILS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LOCATION_DETAILS_SUCCESS: {
      const { payload } = action
      const [location] = payload.results
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(location),
      }
    }
    case Actions.GET_LOCATION_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
