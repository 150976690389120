import { GetLaborsResponse } from 'api/evisaAPI/evisaAPI'
import { StringRecord, Labor } from 'common/types/commonTypes'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  labors: StringRecord<Labor>
  error?: {
    message: string
    code: number
  }
} & Status

const initialState: State = {
  labors: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: GetLaborsResponse) =>
  resp.reduce(
    (acc, labor) => ({
      ...acc,
      [labor.LaborID]: {
        amountOutstanding: labor.AmountOutstanding,
        contractNo: labor.ContractNo,
        laborBorder: labor.LaborBorder,
        laborID: labor.LaborID,
        laborName: labor.LaborName,
        laborNationality: labor.LaborNationality,
        occupationName: labor.OccupationName,
        payrollAmt: parseInt(labor.PayrollAmt || '0', 10),
      },
    }),
    {},
  )

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORS_ASYNC,
    Actions.GET_LABORS_SUCCESS,
    Actions.GET_LABORS_FAIL,
    GetLaborsResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        labors: convertResponseToState(action.payload),
      }
    }
    case Actions.GET_LABORS_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_LABORS_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }

    default:
      return state
  }
}
