import { GetVisaInfoResponse, VisaIssueRequestFromBE } from 'api/evisaAPI/visas'
import { PaymentMethods } from 'common/types/commonTypes'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'

import { Visa, VisaIssueRequest } from '../../types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from '../objectWithTranslation'

export const formatVisaIssueRequestFromBE = (visaIssueRequest: VisaIssueRequestFromBE): VisaIssueRequest => ({
  id: visaIssueRequest.id,
  bill: visaIssueRequest.bill
    ? {
        billNumber: visaIssueRequest.bill.bill_number,
        id: visaIssueRequest.bill.id,
        createdAt: visaIssueRequest.bill.created_at,
        paidAt: visaIssueRequest.bill.paid_at,
        vat: visaIssueRequest.bill.vat,
        amount: visaIssueRequest.bill.amount,
        status: visaIssueRequest.bill.status,
        expiryDate: visaIssueRequest.bill.expiry_date,
        tbsId: visaIssueRequest.bill.tbs_id,
        paymentMethod: (visaIssueRequest.bill.payment_method === 'SADAD' // TODO later should be renamed on the backend
          ? PaymentMethods.SADADBILL
          : visaIssueRequest.bill.payment_method) as PaymentMethods | undefined,
      }
    : null,
  createdAt: visaIssueRequest.created_at,
  status: visaIssueRequest.status,
  occupation: formatOccupationsFromBE(visaIssueRequest.occupation),
  nationality: formatNationalityFromBE(visaIssueRequest.nationality),
  isDisable: visaIssueRequest.is_disable,
  visaReplacementId: visaIssueRequest.visa_replacement_id,
  visaIssuePlace: formatObjectWithTranslationFromBE(visaIssueRequest.visaIssuePlace),
  rejectionReasons: visaIssueRequest.rejectionReasons?.map((reason) => formatObjectWithTranslationFromBE(reason)),
  pendingUnifiedEligibilityIssue: visaIssueRequest.pending_unified_eligiblity_issue,
  user: {
    email: visaIssueRequest.user?.email,
  },
  visa: visaIssueRequest.visa ? convertVisaResponseToState(visaIssueRequest as GetVisaInfoResponse) : undefined,
})

export const convertVisaResponseToState = (resp: GetVisaInfoResponse): Visa => {
  const { issueRequest, id } = resp.visa
  if (!issueRequest) {
    throw new Error(`Issue request is missing for visaId: ${id}. Please fix it ASAP`)
  }
  return {
    id,
    status: resp.visa.status,
    cancellationRequest: resp.visa.cancellationRequest && {
      createdAt: resp.visa.cancellationRequest.created_at,
      id: resp.visa.cancellationRequest.id,
      reason: resp.visa.cancellationRequest.reason,
      status: resp.visa.cancellationRequest.status,
      userUploadId: resp.visa.cancellationRequest.user_upload_id,
      visaId: resp.visa.cancellationRequest.visa_id,
    },
    followupNumber: resp.visa.followup_number,
    followupDate: resp.visa.followup_date,
    borderNumber: resp.visa.border_number,
    occupation: formatOccupationsFromBE(resp.visa.occupation),
    nationality: formatNationalityFromBE(resp.visa.nationality),
    visaIssuePlace: {
      id: resp.visa.visaIssuePlace.id,
      label: resp.visa.visaIssuePlace.label,
      labelEn: resp.visa.visaIssuePlace.label_en,
    },
    issueRequest: formatVisaIssueRequestFromBE(issueRequest),
    owner: {
      name: resp.visa.owner?.name,
      idNumber: resp.visa.owner?.id_number,
    },
    createdAt: resp.visa.created_at || '',
    pdfId: resp.visa.pdf_id,
  }
}
