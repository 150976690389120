import Actions from 'redux/actions'
import { showComplaintsSurvey } from 'redux/actionCreators/jiraAPI'

type State = { open: boolean }

const initialState = {
  open: false,
}

export default (state: State = initialState, action: ReturnType<typeof showComplaintsSurvey>): State => {
  switch (action.type) {
    case Actions.SHOW_COMPLAINTS_SURVEY: {
      return {
        open: true,
      }
    }
    default:
      return state
  }
}
