import { GetRunawaysResponse, RunawayLaborFromBE, RunawayStatus } from 'api/evisaAPI/evisaAPI'
import { Status, CallAPIAction } from 'api/types'
import type { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

export type RunawayLabor = {
  id: string
  name: string
  occupation: ObjectWithTranslation
  nationality: ObjectWithTranslation & { abbreviation: string }
  status: RunawayStatus
  statusLabel: ObjectWithTranslation
  pendingCancelReport: boolean
  pendingRunnerReport: boolean
  isCancelable: boolean
}

const transformResponse = (labor: RunawayLaborFromBE): RunawayLabor => ({
  id: labor.id,
  name: labor.name,
  pendingRunnerReport: labor.pending_runner_report,
  pendingCancelReport: labor.pending_cancel_report,
  status: labor.status.Name,
  isCancelable: labor.cancelable,
  statusLabel: {
    id: Number(labor.status.Code),
    label: labor.status.NameLocal,
    labelEn: labor.status.Name,
  },
  nationality: {
    ...formatObjectWithTranslationFromBE(labor.nationality),
    abbreviation: labor.nationality.abbreviation,
  },
  occupation: formatObjectWithTranslationFromBE(labor.occupation),
})

type State = {
  runaways: Array<RunawayLabor>
} & Status

const initialState: State = {
  runaways: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_RUNAWAYS_ASYNC,
    Actions.GET_RUNAWAYS_SUCCESS,
    Actions.GET_RUNAWAYS_FAIL,
    GetRunawaysResponse
  >,
): State => {
  const { status } = action
  switch (action.type) {
    case Actions.GET_RUNAWAYS_SUCCESS: {
      return {
        ...state,
        ...status,
        runaways: action.payload.farmerWorkers.map(transformResponse),
      }
    }
    case Actions.GET_RUNAWAYS_ASYNC:
    case Actions.GET_RUNAWAYS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default: {
      return state
    }
  }
}
