import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { getIndividualToEntityList } from 'redux/actionCreators/evisaAPI/dlAPI'
import { GetRefundDetailsResponse, RefundItemFomBE } from 'api/eContractingAPI'
import { TicketAttachment } from 'common/utils/convertResponse/convertComplaintResponse'

export type RefundItem = {
  contractId: string
  createdAt: string
  employerName: string
  id: string
  proName: string
  refundAmount: string | null
  refundRequiredContractAmount: string | null
  remainingPeriod: number
  status: string
  attachments: Array<TicketAttachment>
  typeOfProof: 'OTP' | 'Attachment' | null
}

type State = {
  data: Array<RefundItem>
} & Status

const initialState: State = {
  data: [],
  isLoading: false,
  success: false,
  fail: false,
}

const convertRequestToState = (resp: RefundItemFomBE): RefundItem => ({
  id: resp.id,
  createdAt: resp.created_at,
  employerName: resp.employer_name,
  typeOfProof: resp.type_of_proof,
  status: resp.status,
  refundAmount: resp.refund_amount,
  proName: resp.pro_name,
  remainingPeriod: resp.remaining_period,
  contractId: resp.contract_id,
  attachments: resp.attachments,
  refundRequiredContractAmount: resp.refund_required_contract_amount,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_REFUND_DETAILS_ASYNC,
    Actions.GET_REFUND_DETAILS_SUCCESS,
    Actions.GET_REFUND_DETAILS_FAIL,
    GetRefundDetailsResponse,
    {},
    {},
    ReturnType<typeof getIndividualToEntityList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_REFUND_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertRequestToState),
      }
    }
    case Actions.GET_REFUND_DETAILS_ASYNC:
    case Actions.GET_REFUND_DETAILS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
