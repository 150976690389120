import { Status, CallAPIAction } from 'api/types'
import type { CheckEligibilityResponse, CheckEligibilityFailResponse } from 'api/evisaAPI/visas'
import type { Occupation } from 'common/types/transformedRespTypes'
import { formatOccupationsFromBE } from 'common/utils/objectWithTranslation'
import { StringRecord, UserEligibilityErrorMessages } from 'common/types/commonTypes'
import Actions from 'redux/actions'

const convertResponseToState = (resp: CheckEligibilityResponse) => {
  const occupations = resp.occupationsList.reduce(
    (acc, occupationFromBE) => ({
      ...acc,
      [occupationFromBE.id]: {
        ...formatOccupationsFromBE(occupationFromBE),
        gender: occupationFromBE.gender,
      },
    }),
    {},
  )
  return {
    occupations,
    isFinanciallyCapable: resp.isFinanciallyCapable,
    hasRefundableBill: resp.hasRefundableBill,
    totalNumberOfVisa: resp.totalNumberOfVisa,
    numberOfRemainingVisa: resp.numberOfRemainingVisa,
    unallowedNationalities: resp.unallowedNationalities,
    minExpectedBankBalance: resp.getMinExpectedBankBalance,
    minExpectedSalaryRange: resp.getMinExpectedSalaryRange,
    isSaudi: resp.isSaudi,
    vipUser: resp.vipUser,
  }
}

type State = {
  occupations: StringRecord<Occupation>
  minExpectedBankBalance: number
  minExpectedSalaryRange: number
  isFinanciallyCapable?: boolean
  unallowedNationalities: Array<number>
  hasRefundableBill?: boolean
  totalNumberOfVisa?: number
  numberOfRemainingVisa?: number
  isSaudi?: boolean
  vipUser?: boolean
  errorMessage?: UserEligibilityErrorMessages
} & Status

const initialState = {
  occupations: {},
  isFinanciallyCapable: undefined,
  unallowedNationalities: [],
  hasRefundableBill: undefined,
  totalNumberOfVisa: undefined,
  numberOfRemainingVisa: undefined,
  isSaudi: undefined,
  vipUser: undefined,
  isLoading: false,
  success: false,
  fail: false,
  minExpectedBankBalance: 0,
  minExpectedSalaryRange: 0,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_ELIGIBILITY_ASYNC,
    Actions.CHECK_ELIGIBILITY_SUCCESS,
    Actions.CHECK_ELIGIBILITY_FAIL,
    CheckEligibilityResponse,
    CheckEligibilityFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_ELIGIBILITY_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || UserEligibilityErrorMessages.DEFAULT_ERROR,
      }
    }
    default:
      return state
  }
}
