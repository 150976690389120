import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getManagerInfo as getManagerInfoAction,
  updateManagerAuthStatus as updateManagerAuthStatusAction,
} from 'redux/actionCreators/premiumUserAPI/relationshipManager'
import relationshipManagerAPI from 'api/premiumUserAPI/relationshipManagerAPI'

import { getSimpleRequestHandler } from '../../utils'

const getManagerInfo = getSimpleRequestHandler<
  typeof relationshipManagerAPI.getManagerInfo,
  ReturnType<typeof getManagerInfoAction>
>({
  actionLoading: Actions.GET_MANAGER_INFO_ASYNC,
  actionSuccess: Actions.GET_MANAGER_INFO_SUCCESS,
  actionFail: Actions.GET_MANAGER_INFO_FAIL,
  callApiFn: relationshipManagerAPI.getManagerInfo,
})

const updateManagerAuthStatus = getSimpleRequestHandler<
  typeof relationshipManagerAPI.updateManagerAuthStatus,
  ReturnType<typeof updateManagerAuthStatusAction>
>({
  actionLoading: Actions.UPDATE_MANAGER_AUTH_STATUS_ASYNC,
  actionSuccess: Actions.UPDATE_MANAGER_AUTH_STATUS_SUCCESS,
  actionFail: Actions.UPDATE_MANAGER_AUTH_STATUS_FAIL,
  callApiFn: relationshipManagerAPI.updateManagerAuthStatus,
})

export default [
  takeLatest(Actions.GET_MANAGER_INFO, getManagerInfo),
  takeLatest(Actions.UPDATE_MANAGER_AUTH_STATUS, updateManagerAuthStatus),
]
