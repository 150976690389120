import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<Actions.CANCEL_REQUEST_ASYNC, Actions.CANCEL_REQUEST_SUCCESS, Actions.CANCEL_REQUEST_FAIL>,
): State => {
  switch (action.type) {
    case Actions.CANCEL_REQUEST_ASYNC:
    case Actions.CANCEL_REQUEST_SUCCESS:
    case Actions.CANCEL_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
