import { NoticeStatus, PaymentMethods } from 'common/types/commonTypes'
import { Nationality, NoticeBill, NoticeBillFromBE, Occupation } from 'common/types/transformedRespTypes'
import { GetNoticeResponse, NoticeFromBE } from 'api/evisaAPI/noticesAPI'
import { CallAPIAction, Status } from 'api/types'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

type Notice = {
  id: number
  created_at: string
  occupation: Occupation
  employee: {
    nationality: Nationality
  }
  status: NoticeStatus
  bill?: NoticeBill
}

type State = {
  notice?: Notice
  show_current_balance: boolean
  show_current_balance_and_refund_request: boolean
} & Status

const initialState: State = {
  show_current_balance: false,
  show_current_balance_and_refund_request: false,
  isLoading: false,
  success: false,
  fail: false,
}

export const formatNoticeBillFromBE = (bill: NoticeBillFromBE | null): NoticeBill | undefined =>
  bill
    ? {
        id: bill.id,
        billNumber: bill.bill_number,
        createdAt: bill.created_at,
        expiryDate: bill.expiry_date,
        amount: bill.amount,
        vat: bill.vat,
        status: bill.status,
        paidAt: bill.paid_at,
        tbsId: bill.tbs_id,
        paymentMethod: (bill.payment_method === 'SADAD'
          ? PaymentMethods.SADADBILL
          : bill.payment_method) as PaymentMethods,
      }
    : undefined

const convertNoticeFromBEToState = (notice: NoticeFromBE): Notice => ({
  id: notice.id,
  created_at: notice.created_at,
  occupation: formatOccupationsFromBE(notice.occupation),
  employee: {
    nationality: formatNationalityFromBE(notice.employee.nationality),
  },
  status: notice.status,
  bill: formatNoticeBillFromBE(notice.bill),
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_NOTICE_ASYNC,
    Actions.GET_NOTICE_SUCCESS,
    Actions.GET_NOTICE_FAIL,
    GetNoticeResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_NOTICE_FAIL:
    case Actions.GET_NOTICE_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_NOTICE_SUCCESS:
      return {
        ...state,
        ...action.status,
        notice: convertNoticeFromBEToState(action.payload.noticeRequest),
        show_current_balance: action.payload.show_current_balance,
        show_current_balance_and_refund_request: action.payload.show_current_balance_and_refund_request,
      }
    default:
      return state
  }
}
