import { LaborerLoginParams, LaborerOtpVerifyParams } from 'api/laborerPortalAPI/auth'
import Actions from 'redux/actions'

export const laborerLogin = (params: LaborerLoginParams) => ({
  type: Actions.LABORER_LOGIN,
  payload: { params },
})

export const laborerOtpVerify = (params: LaborerOtpVerifyParams) => ({
  type: Actions.LABORER_OTP_VERIFY,
  payload: { params },
})

export const laborerRefreshToken = () => ({
  type: Actions.REFRESH_LABORER_TOKEN,
})

export const updateLaborerAuthData = () => ({
  type: Actions.UPDATE_LABORER_AUTH_DATA,
})
