import type { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { callAPI, laborerAuthAPIWithCredentials } from 'api/axios'
import { GeneralFailResponse } from 'common/types/commonTypes'
import {
  LaborerAuthAppendixFromBE,
  LaborerAuthRequestFromBE,
} from 'common/utils/convertResponse/convertLaborerAuthResponse'
import { ObjectWithTranslationFromBE } from 'common/types/transformedRespTypes'

export type GetAuthContractListParams = {
  perPage?: number
  page?: number
  status?: string
}

export type GetAuthContractListResponse = {
  data: Array<LaborerAuthRequestFromBE>
  meta: {
    last_page: number
    current_page: number
  }
}

const getAuthContractList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetAuthContractListParams,
  config?: AxiosRequestConfig,
) =>
  laborerAuthAPIWithCredentials.get<GetAuthContractListResponse>('api/laborer/contracts', {
    params: {
      page: page + 1,
      per_page: perPage,
      status,
    },
    ...config,
  })

export type GetAuthRequestDetailsParams = {
  requestId: number
}

export type GetAuthRequestDetailsResponse = {
  data: LaborerAuthRequestFromBE
}

export const getAuthRequestDetails = ({ requestId }: GetAuthRequestDetailsParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetAuthRequestDetailsResponse>(`api/laborer/contracts/${requestId}`, config)

export type RejectAuthRequestParams = {
  requestId: number
  rejectionReason: number
}

export type RejectAuthRequestResponse = {}

const rejectAuthRequest = ({ requestId, rejectionReason }: RejectAuthRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<RejectAuthRequestResponse>(
    `api/laborer/contracts/${requestId}/reject`,
    {
      rejection_reason: rejectionReason,
    },
    config,
  )

export type GetRejectionReasonListParams = {}

export type GetRejectionReasonListResponse = Array<ObjectWithTranslationFromBE>

const getRejectionReasonList = (params: GetRejectionReasonListParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetRejectionReasonListResponse>('api/lookups/rejection-reasons', config)

export type AcceptAuthRequestParams = {
  requestId: number
}

export type AcceptAuthRequestResponse = {}

const acceptAuthRequest = ({ requestId }: AcceptAuthRequestParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<AcceptAuthRequestResponse>(`api/laborer/contracts/${requestId}/approve`, config)

export type GetAuthContractTextParams = {
  requestId: string
}

export type GetAuthContractTextResponse = {
  data: {
    id: number
    label_ar: string
    label_en: string
  }
}

const getAuthContractText = ({ requestId }: GetAuthContractTextParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetAuthContractTextResponse>(
    `api/laborer/contracts/${requestId}/legalText`,
    config,
  )

export type GetAuthAppendixListParams = {
  requestId: string
}

export type GetAuthAppendixListResponse = {
  data: Array<LaborerAuthAppendixFromBE>
}

const getAuthAppendixList = ({ requestId }: GetAuthAppendixListParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetAuthAppendixListResponse>(
    `api/laborer/contracts/${requestId}/appendixes/index`,
    config,
  )

export type GetAuthAppendixDetailsParams = {
  requestId: string
  appendixId: string
}

export type GetAuthAppendixDetailsResponse = {
  data: LaborerAuthAppendixFromBE
}

const getAuthAppendixDetails = (
  { requestId, appendixId }: GetAuthAppendixDetailsParams,
  config?: AxiosRequestConfig,
) =>
  laborerAuthAPIWithCredentials.get<GetAuthAppendixDetailsResponse>(
    `api/laborer/contracts/${requestId}/appendixes/${appendixId}`,
    config,
  )

export type ApproveAuthAppendixParams = {
  requestId: number
  appendixId: number
}

export type ApproveAuthAppendixResponse = {}

const approveAuthAppendix = ({ requestId, appendixId }: ApproveAuthAppendixParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<ApproveAuthAppendixResponse>(
    `api/laborer/contracts/${requestId}/appendixes/${appendixId}/approve`,
    config,
  )

export type RejectAuthAppendixParams = {
  requestId: number
  appendixId: number
  rejectionReason: number
}

export type RejectAuthAppendixResponse = {}

const rejectAuthAppendix = (
  { requestId, appendixId, rejectionReason }: RejectAuthAppendixParams,
  config?: AxiosRequestConfig,
) =>
  laborerAuthAPIWithCredentials.post<RejectAuthAppendixResponse>(
    `api/laborer/contracts/${requestId}/appendixes/${appendixId}/reject`,
    {
      rejection_reason: rejectionReason,
    },
    config,
  )

export type GetAuthContractPdfParams = {
  requestId: string
  fileId: string
}

export type GetAuthContractPdfResponse = {
  url: string
}

const getAuthContractPdf = ({ requestId, fileId }: GetAuthContractPdfParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetAuthContractPdfResponse>(
    `api/laborer/contracts/${requestId}/pdf/${fileId}`,
    config,
  )

export type GetPendingAppendixParams = {
  requestId: string
  appendixId: string
}

export type GetPendingAppendixResponse = {
  legalTextAr: string
  legalTextEn: string
}

const getPendingAppendix = ({ requestId, appendixId }: GetPendingAppendixParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetPendingAppendixResponse>(
    `api/laborer/contracts/${requestId}/appendixes/${appendixId}/draft`,
    config,
  )

export default {
  getAuthContractList: callAPI<typeof getAuthContractList, GetAuthContractListResponse, GeneralFailResponse>(
    getAuthContractList,
  ),
  getAuthRequestDetails: callAPI<typeof getAuthRequestDetails, GetAuthRequestDetailsResponse, GeneralFailResponse>(
    getAuthRequestDetails,
  ),
  getAuthContractText: callAPI<typeof getAuthContractText, GetAuthContractTextResponse, GeneralFailResponse>(
    getAuthContractText,
  ),
  rejectAuthRequest: callAPI<typeof rejectAuthRequest, RejectAuthRequestResponse, GeneralFailResponse>(
    rejectAuthRequest,
  ),
  acceptAuthRequest: callAPI<typeof acceptAuthRequest, AcceptAuthRequestResponse, GeneralFailResponse>(
    acceptAuthRequest,
  ),
  getRejectionReasonList: callAPI<typeof getRejectionReasonList, GetRejectionReasonListResponse, GeneralFailResponse>(
    getRejectionReasonList,
  ),
  getAuthAppendixList: callAPI<typeof getAuthAppendixList, GetAuthAppendixListResponse, GeneralFailResponse>(
    getAuthAppendixList,
  ),
  getAuthAppendixDetails: callAPI<typeof getAuthAppendixDetails, GetAuthAppendixDetailsResponse, GeneralFailResponse>(
    getAuthAppendixDetails,
  ),
  approveAuthAppendix: callAPI<typeof approveAuthAppendix, ApproveAuthAppendixResponse, GeneralFailResponse>(
    approveAuthAppendix,
  ),
  rejectAuthAppendix: callAPI<typeof rejectAuthAppendix, RejectAuthAppendixResponse, GeneralFailResponse>(
    rejectAuthAppendix,
  ),
  getAuthContractPdf: callAPI<typeof getAuthContractPdf, GetAuthContractPdfResponse, GeneralFailResponse>(
    getAuthContractPdf,
  ),
  getPendingAppendix: callAPI<typeof getPendingAppendix, GetPendingAppendixResponse, GeneralFailResponse>(
    getPendingAppendix,
  ),
}
