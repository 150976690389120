import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Lang } from 'common/types/commonTypes'
import uiComponents from 'ui-components/uiComponents_en.json'
import validation from 'common/utils/validation/validation_en.json'
import labibaChatBot from 'common/utils/labibaChatBot/labibaChatBot_en.json'
import common from 'common/Common_en.json'
import secondaryHeader from 'components/SecondaryHeader/SecondaryHeader_en.json'
import cities from 'common/utils/cities/cities_en.json'
// Arabic lang
import uiComponentsAr from 'ui-components/uiComponents_ar.json'
import validationAr from 'common/utils/validation/validation_ar.json'
import commonAr from 'common/Common_ar.json'
import secondaryHeaderAr from 'components/SecondaryHeader/SecondaryHeader_ar.json'
import labibaChatBotAr from 'common/utils/labibaChatBot/labibaChatBot_ar.json'
import citiesAr from 'common/utils/cities/cities_ar.json'
// Urdu lang
import validationUr from 'common/utils/validation/validation_ur.json'
import commonUr from 'common/Common_ur.json'
// Amharic lang
import validationAm from 'common/utils/validation/validation_am.json'
import commonAm from 'common/Common_am.json'
// Filipino lang
import validationFil from 'common/utils/validation/validation_fil.json'
import commonFil from 'common/Common_fil.json'
// Hindi lang
import commonHi from 'common/Common_hi.json'

i18n.use(initReactI18next).init({
  fallbackLng: Lang.en,
  lng: Lang.ar,

  resources: {
    en: {
      uiComponents,
      validation,
      common,
      secondaryHeader,
      labibaChatBot,
      cities,
    },
    ar: {
      uiComponents: uiComponentsAr,
      validation: validationAr,
      common: commonAr,
      secondaryHeader: secondaryHeaderAr,
      labibaChatBot: labibaChatBotAr,
      cities: citiesAr,
    },
    ur: {
      uiComponents: uiComponentsAr,
      validation: validationUr,
      common: commonUr,
      secondaryHeader: secondaryHeaderAr,
      labibaChatBot: labibaChatBotAr,
      cities: citiesAr,
    },
    am: {
      uiComponents,
      validation: validationAm,
      common: commonAm,
      secondaryHeader,
      labibaChatBot,
      cities,
    },
    fil: {
      uiComponents,
      validation: validationFil,
      common: commonFil,
      secondaryHeader,
      labibaChatBot,
      cities,
    },
    hi: {
      uiComponents,
      validation,
      common: commonHi,
      secondaryHeader,
      labibaChatBot,
      cities,
    },
  },
})

export default i18n
