import { GetEligibleWorkersResponse, WorkerFromBE } from 'api/evisaAPI/evisaAPI'
import { Status, CallAPIAction } from 'api/types'
import { StringRecord } from 'common/types/commonTypes'
import type { Worker } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = {
  workers: StringRecord<Worker>
} & Status

const initialState: State = {
  workers: {},
  isLoading: false,
  success: false,
  fail: false,
}

export const createProperWorker = (worker: WorkerFromBE): Worker => ({
  id: worker.Id,
  occupationId: 0,
  nationality: worker.Nationality,
  occupation: worker.Occupation.Name,
  status: worker.Status,
  sex: worker.Sex,
  entryDate: worker.entryDate,
  exitDate: worker.exitDate,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ELIGIBLE_WORKERS_ASYNC,
    Actions.GET_ELIGIBLE_WORKERS_SUCCESS,
    Actions.GET_ELIGIBLE_WORKERS_FAIL,
    GetEligibleWorkersResponse
  >,
): State => {
  const { status } = action

  switch (action.type) {
    case Actions.GET_ELIGIBLE_WORKERS_SUCCESS: {
      const { payload } = action
      return {
        ...status,
        workers: payload.reduce((acc, worker) => ({ ...acc, [worker.Id]: createProperWorker(worker) }), {}),
      }
    }
    case Actions.GET_ELIGIBLE_WORKERS_ASYNC:
    case Actions.GET_ELIGIBLE_WORKERS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default: {
      return state
    }
  }
}
