import { EmploymentStatus } from 'common/types/commonTypes'
import { setRegularVisaFlowData } from 'redux/actionCreators/regularVisaFlow'
import Actions from 'redux/actions'

export type State = {
  acknowledge: boolean
  employmentStatus?: EmploymentStatus
  gender: string
  occupationId: number
  nationalityId: number
  visaIssuePlaceId: number
}

const initialState: State = {
  acknowledge: false,
  employmentStatus: undefined,
  gender: '',
  occupationId: 0,
  nationalityId: 0,
  visaIssuePlaceId: 0,
}

export default (state: State = initialState, action: ReturnType<typeof setRegularVisaFlowData>) => {
  switch (action.type) {
    case Actions.SET_REGULAR_VISA_FLOW_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
