export default class LocalStorageData<T> {
  private key: string
  private initialValue: T
  constructor(valueKey: string, value: T) {
    this.key = valueKey
    this.initialValue = value
  }
  getItemKey() {
    return this.key
  }
  get data(): T {
    const data = localStorage.getItem(this.key)
    if (data) {
      return JSON.parse(data) as T
    }
    return this.initialValue
  }
  set data(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value))
  }

  removeData() {
    localStorage.removeItem(this.key)
  }
}
