import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import {
  BillStatus,
  DisabilityVisaEligibilityErrorMessages,
  EmploymentStatus,
  IssueRequestTypes,
  PaginatorBE,
  PendingUnifiedEligibilityIssue,
  UserEligibilityErrorMessages,
  VisaIssueRequestStatus,
  VisaStatus,
  IssueNewVisaRequestStatusError,
  SubmitVisaWithContractRequestError,
  ServiceTypes,
  ReligionTypes,
  PaymentMethods,
  UploadFinancialDocumentErrorMessage,
  Platform,
  VisaTypes,
} from 'common/types/commonTypes'
import type {
  NationalityFromBE,
  OccupationFromBE,
  ObjectWithTranslationFromBE,
  BillFromBE,
  PayfortBill,
} from 'common/types/transformedRespTypes'
import { ENABLE_NEW_PROFILE_DESIGN, X_API_KEY } from 'common/envConstants'

import { callAPI, evisaAPIWithCredentials, evisaAPI } from '../axios'

export type CheckEligibilityResponse = {
  occupationsList: Array<OccupationFromBE>
  getMinExpectedBankBalance: number
  getMinExpectedSalaryRange: number
  unallowedNationalities: Array<number>
  isFinanciallyCapable: boolean
  numberOfRemainingVisa: number
  hasRefundableBill: boolean
  totalNumberOfVisa: number
  isSaudi: boolean
  vipUser: boolean
}

export type CheckEligibilityVisaParams = {
  issueType?: string
}

export type CheckEligibilityFailResponse = {
  error: {
    code: number
    message: UserEligibilityErrorMessages
  }
}

const checkEligibility = (params: CheckEligibilityVisaParams | undefined, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CheckEligibilityResponse>(
    '/app/visas/issue_requests/check_availability',
    {
      issue_type: params?.issueType,
    },
    config,
  )

export type CheckEligibilityForDisabilityVisaResponse = {
  occupationsList: Array<OccupationFromBE>
  isFinanciallyCapable: boolean
  numberOfRemainingVisa: number
  hasRefundableBill: boolean
  totalNumberOfVisa: number
}

export type CheckEligibilityForDisabilityVisaFailResponse = {
  error: {
    code: number
    message: DisabilityVisaEligibilityErrorMessages
  }
}

const checkEligibilityForDisabilityVisa = (body = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CheckEligibilityResponse>(
    '/app/visas/mosa_issue_requests/check_availability',
    body,
    config,
  )

export type HasVisasParams = {
  nationality: number
  occupation: number
}

export type HasVisasResponse = {
  has_visa: boolean
  visa_follow: number
  visa_origin?: ObjectWithTranslationFromBE
}

const hasVisas = (params: HasVisasParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<HasVisasResponse>('/app/unified_journey/has_visa', { params, ...config })

export type UploadVisasBody = {
  file: File
}

export type UploadVisasResponse = {
  userUpload: {
    file_path: string
    mime_type: string
    original_file_name: string
    created: string
    id: number
  }
}

export type UploadVisasFailResponse = {
  error?: {
    code: number
    message: UploadFinancialDocumentErrorMessage
  }
}

const uploadVisasFile = (body: UploadVisasBody, config: AxiosRequestConfig) => {
  const data = new FormData()
  data.append('file', body.file)
  return evisaAPIWithCredentials.post<UploadVisasResponse>('/app/user/uploads/upload', data, config)
}

const uploadVisasFileForOCR = (body: UploadVisasBody, config: AxiosRequestConfig) => {
  const data = new FormData()
  data.append('file', body.file)
  return evisaAPIWithCredentials.post<UploadVisasResponse>('/app/user/uploads/upload_financial_document', data, config)
}

export type EContractBodyUserParams = {
  email: string
  familySize: string
  houseTypeId: string
  domesticLaborers: string
  averageIncome: string
  coordinates: string
  insuranceEnabled: boolean
}

export type EContractBodyPreferences = {
  requestType: ServiceTypes.RECRUITMENT
  religion: ReligionTypes
  workExperience: number
  ageRange: number
  extraPreferences: string
  arrivalCityId: number
  offices: Array<number>
  workType: string
}

export type EContractBodyPreselected = {
  requestType: ServiceTypes.PRESELECTED
  workerName: string
  workerNumber: string
  workerPassportNumber: string
  workerPassportExpiryDate: string
  workerPassportImage: File
  arrivalCityId: number
  offices: Array<number>
  workType: string
  ageRange: number
}

type SubmitVisaRelatedParams = {
  nationality: number
  occupation: number
  documents: Array<number>
}

export type SubmitVisaWithContractRequestBodyWithUserParams = SubmitVisaRelatedParams & {
  eContractBody: (EContractBodyPreferences | EContractBodyPreselected) & EContractBodyUserParams
}

export type SubmitVisaWithContractRequestResponse = {}

export type SubmitVisaWithContractRequestFailResponse = {
  error?: {
    message: SubmitVisaWithContractRequestError
  }
  worker_passport?: Array<string>
  worker_passport_image?: Array<string>
}

const consvertBodyToActualParams = ({
  occupation,
  nationality,
  documents,
  eContractBody,
}: SubmitVisaWithContractRequestBodyWithUserParams) => {
  const form = new FormData()
  form.set('occupation', occupation?.toString())
  form.set('nationality', nationality?.toString())
  documents?.forEach((documentId) => {
    form.append('documents[]', documentId.toString())
  })
  form.set(
    'request_type',
    eContractBody.requestType === ServiceTypes.RECRUITMENT
      ? 'PREFERENCES' /* ServiceTypes.RECRUITMENT */
      : eContractBody.requestType,
  )
  form.set('city_id', eContractBody.arrivalCityId?.toString())
  form.set('delivery_city_id', eContractBody.arrivalCityId?.toString())
  eContractBody.offices.forEach((officeId) => {
    form.append('services[]', officeId.toString())
  })
  form.set('work_type', eContractBody.workType)
  if (eContractBody.requestType === ServiceTypes.RECRUITMENT) {
    form.set('religion_id', eContractBody.religion?.toString())
    form.set('work_experience', eContractBody.workExperience?.toString())
    form.set('age_range_id', eContractBody.ageRange?.toString())
    form.set('preferences', eContractBody.extraPreferences)
  }
  if (eContractBody.requestType === ServiceTypes.PRESELECTED) {
    eContractBody.insuranceEnabled && form.set('age_range_id', eContractBody.ageRange?.toString())
    form.set('worker_name', eContractBody.workerName)
    form.set('worker_mobile', eContractBody.workerNumber)
    form.set('worker_passport', eContractBody.workerPassportNumber)
    form.set('worker_passport_image', eContractBody.workerPassportImage)
    form.set('worker_passport_expiry_date', eContractBody.workerPassportExpiryDate)
  }
  // user related params
  form.set('email', eContractBody.email)
  form.set('family_size', eContractBody.familySize)
  form.set('house_type_id', eContractBody.houseTypeId)
  form.set('domestic_laborers', eContractBody.domesticLaborers)
  form.set('average_income', eContractBody.averageIncome)
  !ENABLE_NEW_PROFILE_DESIGN && form.set('lat_lng', eContractBody.coordinates)

  form.set('platform', Platform.WEB)

  return form
}

const submitVisaWithContractRequest = (
  body: SubmitVisaWithContractRequestBodyWithUserParams,
  config: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.post<SubmitVisaWithContractRequestResponse>(
    '/app/unified_journey/submit',
    consvertBodyToActualParams(body),
    config,
  )

export type GetVisaInfoParams = {
  visaId: number
  unissued?: number
  delegated?: number
}

export type VisaInfoFromBE = {
  nationality: NationalityFromBE
  occupation: OccupationFromBE
  followup_number: number
  followup_date: string
  border_number: number
  visaIssuePlace: ObjectWithTranslationFromBE
  id: number
  status: VisaStatus
  cancellationRequest: {
    created_at: string
    id: number
    reason: string
    status: number
    user_upload_id: number | null
    visa_id: string
  } | null
  issueRequest: VisaIssueRequestFromBE
  owner?: {
    name: string
    id_number: number
  }
  created_at?: string
  pdf_id: string | null
}

export type GetVisaInfoResponse = {
  visa: VisaInfoFromBE
}

const getVisaInfo = (params: GetVisaInfoParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetVisaInfoResponse>(`/app/visas/${params.visaId}`, {
    params: { unissued: params.unissued, delegated: params.delegated },
    ...config,
  })

export type GetVisasListParams = {
  page?: number | ''
  unissued?: number
  delegated?: number
  perPage?: number
}

export type GetVisasListResponse = {
  paginator: PaginatorBE<GetVisaInfoResponse['visa']>
}

const getAllVisasList = (
  { page = '', unissued, delegated, perPage }: GetVisasListParams,
  config?: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetVisasListResponse>('/app/visas', {
    params: { page, unissued, delegated, per_page: perPage },
    ...config,
  })

export type GetVisaIssueRequestsListParams = {
  page?: number
  perPage?: number
  requestType?: IssueRequestTypes | ''
  status?: VisaIssueRequestStatus
}

export type VisaIssueRequestFromBE = {
  id: number
  bill: {
    bill_number: string
    id: string
    created_at: string
    paid_at: string
    vat: string
    amount: string
    status: BillStatus
    expiry_date: string
    payment_method?: string
    tbs_id?: number | null
  } | null
  created_at: string
  status: VisaIssueRequestStatus
  occupation: OccupationFromBE
  nationality: NationalityFromBE
  visaIssuePlace: ObjectWithTranslationFromBE
  is_disable: number
  visa_replacement_id: string | null
  rejectionReasons: ObjectWithTranslationFromBE[]
  pending_unified_eligiblity_issue: PendingUnifiedEligibilityIssue | null // the typo is on the backend
  user: {
    email: string
  }
  visa?: VisaInfoFromBE
}

export type GetVisaIssueRequestsListResponse = {
  paginator: PaginatorBE<VisaIssueRequestFromBE>
}

export type GetLastFourVisasResponse = Array<GetVisaInfoResponse['visa']>

const getLastFourVisas = (params: {}, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetLastFourVisasResponse>('/app/user/latest_visas', {
    ...config,
  })

const getVisaIssueRequestsList = (
  { page = 0, requestType, status, perPage = LIST_ITEMS_PER_PAGE }: GetVisaIssueRequestsListParams = {},
  config?: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetVisaIssueRequestsListResponse>('app/visas/issue_requests', {
    params: { page: page + 1, status, request_type: requestType, per_page: perPage },
    ...config,
  })

export type GetVisaIssueRequestInfoParams = {
  issueRequestId: number | string
  unissued?: boolean
}

export type GetVisaIssueRequestInfoResponse = {
  issueRequest: VisaIssueRequestFromBE
  has_refundable_bill?: boolean
}

const getVisaIssueRequestInfo = (
  { issueRequestId, unissued }: GetVisaIssueRequestInfoParams,
  config?: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetVisaIssueRequestInfoResponse>(
    `app/visas/issue_requests/${issueRequestId}?unissued=${unissued || false}`,
    config,
  )

export type GetVipVisaListParams = {
  perPage?: number
  page?: number
  status: VisaIssueRequestStatus
  requestType: VisaTypes | string
}

export type VipVisaFromBE = {
  created_at: string
  id: string
  nationality_abbreviation: string
  nationality_label: string
  nationality_label_en: string
  occupation_label: string
  occupation_label_en: string
  status: string
  user_id: string
  visa_type: VisaTypes
}

export type GetVipVisaListResponse = {
  paginator: {
    last_page: number
    data: Array<VipVisaFromBE>
  }
}

const getVipVisaList = (
  { page = 1, perPage = LIST_ITEMS_PER_PAGE, status, requestType }: GetVipVisaListParams,
  config: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetVipVisaListResponse>('app/premium/visas/issue_requests', {
    params: {
      page: page + 1,
      per_page: perPage,
      status,
      visa_type: requestType,
    },
    ...config,
  })

export type GetAvailableVisasResponse = {
  visas: {
    [key: number]: GetVisaInfoResponse['visa']
  }
}

const getAvailableVisas = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetAvailableVisasResponse>('/app/visas/available', config)

export type IssueNewVisaRequestBody = {
  employmentStatus: EmploymentStatus
  nationality: number | string
  occupation: number | string
  visaIssuePlace: number | string
  documents?: { [key: string]: '1' }
}

export type IssueNewVisaRequestResponse = {
  request: {
    id: number
    bill?: BillFromBE
  }
}
export type IssueNewVisaRequestFailResponse = {
  error?: {
    message: IssueNewVisaRequestStatusError
  }
}

const issueNewVisaRequest = (body: IssueNewVisaRequestBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<IssueNewVisaRequestResponse>(
    '/app/visas/issue_requests',
    {
      employmentStatus: body.employmentStatus,
      ...(body.documents ? { documents: body.documents } : {}),
      nationality: body.nationality,
      occupation: body.occupation,
      visa_issue_place: body.visaIssuePlace,
    },
    config,
  )

export type IssueNewDisabilityVisaBody = {
  nationality: number | string
  occupation: number | string
  visaIssuePlace: number | string
  documents?: { [key: string]: '1' }
}

export type IssueNewDisabilityVisaResponse = { request: { id: number } }
export type IssueNewDisabilityVisaFailResponse = {
  error?: {
    message: DisabilityVisaEligibilityErrorMessages
  }
}

const issueNewDisabilityVisa = (body: IssueNewVisaRequestBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<IssueNewDisabilityVisaResponse>(
    '/app/visas/mosa_issue_requests',
    {
      employmentStatus: body.employmentStatus,
      ...(body.documents ? { documents: body.documents } : { isFinanciallyCapable: true }),
      nationality: body.nationality,
      occupation: body.occupation,
      visa_issue_place: body.visaIssuePlace,
    },
    config,
  )

export type CheckEligibilityForAlternativeVisaResponse = {
  eligible: boolean
}

export type CheckEligibilityForAlternativGeneralFailResponse = {
  error: {
    code: number
    message: UserEligibilityErrorMessages
  }
}

// To check if user is eligible or not we actualy need to call several requests,
// so this response combination of this request and 'userEligibilityResponse' and 'eligibleWorkers'
export type CheckEligibilityForAlternativeVisaFailCombinedResponse = {
  noUsers: boolean
  messageTKey: UserEligibilityErrorMessages | string
}

const checkEligibilityForAlternativeVisa = (params = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<CheckEligibilityForAlternativeVisaResponse>('app/v2/user/check-eligibility', config)

export type ReplaceVisasBody = {
  documents: { [key: string]: '2' }
  nationality: number | string
  occupation: number | string
  visaIssuePlace: number | string
  workerId: number | string
  visaNo: number | string
}

export type ReplaceVisasResponse = {
  request: {
    id: number
    bill: BillFromBE
  }
}

export type ReplaceVisasFailResponse = {
  error?: {
    message: UserEligibilityErrorMessages
  }
}

const replaceVisas = (body: ReplaceVisasBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<ReplaceVisasResponse>(
    '/app/replaced_visas',
    {
      documents: body.documents,
      nationality: body.nationality,
      occupation: body.occupation,
      visa_issue_place: body.visaIssuePlace,
      worker_id: body.workerId,
      visa_no: body.visaNo,
    },
    config,
  )

export type OnlinePaymentVisaBody = {
  issueRequestId: number
  cardType: PaymentMethods
}

export type OnlinePaymentVisaResponse = {
  status: boolean
  bill: PayfortBill
}

export type OnlinePaymentVisaFailResponse = {
  error?: {
    message: UserEligibilityErrorMessages | string
    code: number
  }
  status?: boolean
  data: PayfortBill
}

const onlinePaymentVisa = (body: OnlinePaymentVisaBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<OnlinePaymentVisaResponse>(
    `/app/visas/issue_requests/${body.issueRequestId}/payfort_bill/${body.cardType}`,
    {},
    config,
  )

export type VisaInquiryParams = {
  day?: number | string
  month?: number | string
  year?: number | string
  idNumber?: number | string
  issueRequestId?: number | string
}

export type VisaInquiryResponse = {
  request: {
    visa: VisaInfoFromBE | null
    nationality: NationalityFromBE
    occupation: OccupationFromBE
    visaIssuePlace: ObjectWithTranslationFromBE
    id: number
    created_at: string
    status: VisaIssueRequestStatus
    rejectionReasons: Array<ObjectWithTranslationFromBE>
    pending_unified_eligiblity_issue: PendingUnifiedEligibilityIssue | null // the typo is on the backend
  }
  nicInfo: {
    first_name: string
    last_name: string
    marital_status: string
    gender: string
    status: string
  }
}

const visaInquiry = (params: VisaInquiryParams, config: AxiosRequestConfig) =>
  evisaAPI.get<VisaInquiryResponse>('/inquiry/results', {
    params: {
      day: params.day,
      month: params.month,
      year: params.year,
      id_number: params.idNumber,
      issue_request_id: params.issueRequestId,
    },
    ...{ headers: { 'x-api-key': X_API_KEY || '', ...config.headers }, config },
  })

export type getVisaPdfLinkParams = {
  visaId: number
}

export type getVisaPdfLinkResponse = {
  url: string | null
}

export type getVisaPdfLinkFailResponse = {
  error?: {
    message: string
  }
}

const getVisaPdfLink = ({ visaId }: getVisaPdfLinkParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<getVisaPdfLinkResponse>(`app/visas/${visaId}/pdf_link`, config)

export const visasEvisaAPI = {
  checkEligibility: callAPI<typeof checkEligibility, CheckEligibilityResponse, CheckEligibilityFailResponse>(
    checkEligibility,
  ),
  checkEligibilityForDisabilityVisa: callAPI<
    typeof checkEligibilityForDisabilityVisa,
    CheckEligibilityForDisabilityVisaResponse,
    CheckEligibilityForDisabilityVisaFailResponse
  >(checkEligibilityForDisabilityVisa),
  hasVisas: callAPI<typeof hasVisas, HasVisasResponse>(hasVisas),
  uploadVisasFile: callAPI<typeof uploadVisasFile, UploadVisasResponse>(uploadVisasFile),
  uploadVisasFileForOCR: callAPI<typeof uploadVisasFileForOCR, UploadVisasResponse>(uploadVisasFileForOCR),
  submitVisaWithContractRequest: callAPI<
    typeof submitVisaWithContractRequest,
    SubmitVisaWithContractRequestResponse,
    SubmitVisaWithContractRequestFailResponse
  >(submitVisaWithContractRequest),
  getVisaInfo: callAPI<typeof getVisaInfo, GetVisaInfoResponse>(getVisaInfo),
  getAllVisasList: callAPI<typeof getAllVisasList, GetVisasListResponse>(getAllVisasList),
  getVisaIssueRequestsList: callAPI<typeof getVisaIssueRequestsList, GetVisaIssueRequestsListResponse>(
    getVisaIssueRequestsList,
  ),
  getAvailableVisas: callAPI<typeof getAvailableVisas, GetAvailableVisasResponse>(getAvailableVisas),
  issueNewVisaRequest: callAPI<
    typeof issueNewVisaRequest,
    IssueNewVisaRequestResponse,
    IssueNewVisaRequestFailResponse
  >(issueNewVisaRequest),
  issueNewDisabilityVisa: callAPI<
    typeof issueNewDisabilityVisa,
    IssueNewDisabilityVisaResponse,
    IssueNewDisabilityVisaFailResponse
  >(issueNewDisabilityVisa),
  checkEligibilityForAlternativeVisa: callAPI<
    typeof checkEligibilityForAlternativeVisa,
    CheckEligibilityForAlternativeVisaResponse,
    CheckEligibilityForAlternativGeneralFailResponse
  >(checkEligibilityForAlternativeVisa),
  getVisaIssueRequestInfo: callAPI<typeof getVisaIssueRequestInfo, GetVisaIssueRequestInfoResponse>(
    getVisaIssueRequestInfo,
  ),
  replaceVisas: callAPI<typeof replaceVisas, ReplaceVisasResponse, ReplaceVisasFailResponse>(replaceVisas),
  onlinePaymentVisa: callAPI<typeof onlinePaymentVisa, OnlinePaymentVisaResponse>(onlinePaymentVisa),
  visaInquiry: callAPI<typeof visaInquiry, VisaInquiryResponse>(visaInquiry),
  getLastFourVisas: callAPI<typeof getLastFourVisas, GetLastFourVisasResponse>(getLastFourVisas),
  getVisaPdfLink: callAPI<typeof getVisaPdfLink, getVisaPdfLinkResponse, getVisaPdfLinkFailResponse>(getVisaPdfLink),
  getVipVisaList: callAPI<typeof getVipVisaList, GetVipVisaListResponse>(getVipVisaList),
}

export const rawVisasEvisaAPI = {
  getAllVisasList,
  getVisaIssueRequestsList,
  getVisaIssueRequestInfo,
}
