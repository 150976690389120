import { PreselectedInfoState } from 'redux/reducers/vipPreselectedInfo'
import { GetVipMarketOffersParams } from 'redux/reducers/vipRecruitmentFilter'

import Actions from '../../actions'

export function setVipRecruitmentFilter(filter: Partial<GetVipMarketOffersParams>) {
  return {
    type: Actions.SET_VIP_RECRUITMENT_FILTER,
    payload: {
      filter: {
        page: 0,
        clearPagesCount: true,
        ...filter,
      },
    },
  }
}

export const setVipPreselectedInfo = (data: Partial<PreselectedInfoState>) => ({
  type: Actions.SET_VIP_PRESELECTED_INFO,
  payload: { data },
})
