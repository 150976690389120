import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { AcceptIToIOfferResponse } from 'api/newDLTransferAPI'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_I_TO_I_OFFER_ASYNC,
    Actions.ACCEPT_I_TO_I_OFFER_SUCCESS,
    Actions.ACCEPT_I_TO_I_OFFER_FAIL,
    AcceptIToIOfferResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_I_TO_I_OFFER_ASYNC:
    case Actions.ACCEPT_I_TO_I_OFFER_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.ACCEPT_I_TO_I_OFFER_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    }

    default:
      return state
  }
}
