import React, { memo, VoidFunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootState } from 'redux/rootReducer'
import { logOut } from 'redux/actionCreators/evisaAPI/auth'
import ModalMessage from 'components/Messages/ModalMessage/ModalMessage'
import ButtonWithLoading from 'ui-components/buttons/ButtonWithLoading/ButtonWithLoading'
import { TranslationNamespace } from 'common/utils/translation'
import authManager from 'common/utils/auth/authManager'
import { laborerRefreshToken, updateLaborerAuthData } from 'redux/actionCreators/laborerPortalAPI/auth'
import { resetReducerState } from 'redux/actionCreators/resetReducerState'

const CHECK_INTERVAL_LEN = 7 * 1000 // every 7 seconds

const KeepAliveLaborerModal: VoidFunctionComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(TranslationNamespace.keepAliveModal)
  const { logOutWarning, token, expiresAt, laborerInfo, ...status } = useSelector<
    RootState,
    RootState['laborerRefreshToken']
  >((state) => state.laborerRefreshToken)

  useEffect(() => {
    const interval = setInterval(() => {
      if (authManager.isReadyForAutoLaborerLogOut()) {
        dispatch(updateLaborerAuthData())
      }
      if (authManager.getContractAuthToken() && !authManager.isLaborerAuthenticated()) {
        dispatch(logOut(true, true))
      }
    }, CHECK_INTERVAL_LEN)
    return () => clearInterval(interval)
  }, [dispatch, logOutWarning])

  useEffect(() => {
    if (status.success) dispatch(resetReducerState(['laborerRefreshToken']))
  }, [dispatch, status.success])

  useEffect(() => {
    if (token) {
      authManager.setAuthData(
        {
          contractAuthToken: token,
          laborerTokenExpiresDate: expiresAt,
          laborerInfo,
        },
        false,
      )
    }
  }, [token, expiresAt, laborerInfo])

  return (
    <ModalMessage
      open={logOutWarning}
      title={t('warning')}
      description={t('description')}
      type='warn'
      actions={
        <ButtonWithLoading
          isLoading={status.isLoading}
          dataTestid='continue-usage-button'
          onClick={() => dispatch(laborerRefreshToken())}
        >
          {t('continue')}
        </ButtonWithLoading>
      }
    />
  )
}

export default memo(KeepAliveLaborerModal)
