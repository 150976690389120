import { GetAcceptanceRateResponse } from 'api/eContractingAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  rates: Array<{
    proId: number
    rate: number
  }>
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  rates: [],
}

const convertPayloadToState = (value: { pro_id: number; rate: number }) => ({
  proId: value.pro_id,
  rate: Math.round(value.rate),
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ACCEPTANCE_RATE_ASYNC,
    Actions.GET_ACCEPTANCE_RATE_SUCCESS,
    Actions.GET_ACCEPTANCE_RATE_FAIL,
    GetAcceptanceRateResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_ACCEPTANCE_RATE_SUCCESS:
      return {
        ...state,
        ...action.status,
        rates: action.payload.map(convertPayloadToState),
      }
    case Actions.GET_ACCEPTANCE_RATE_ASYNC:
    case Actions.GET_ACCEPTANCE_RATE_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
