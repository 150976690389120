import { GetMarketOffersParams } from 'api/prosAPI'

import Actions from '../actions'

type FilterValues = Partial<GetMarketOffersParams>

export function setRecruitmentFilter(filter: FilterValues) {
  return {
    type: Actions.SET_RECRUITMENT_FILTER,
    payload: {
      filter: {
        page: 0,
        clearPagesCount: true,
        ...filter,
      },
    },
  }
}
