import { GetItoERequestDetailsResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { Nationality, ObjectWithTranslation, Occupation } from 'common/types/transformedRespTypes'
import {
  formatNationalityFromBE,
  formatObjectWithTranslationFromBE,
  formatOccupationsFromBE,
} from 'common/utils/objectWithTranslation'
import { ItoERequestsStatus } from 'common/types/commonTypes'

type State = {
  id: number
  requestNumber: string
  establishmentName: string
  laborerId?: number
  laborerName: string
  occupation?: Occupation
  nationality?: Nationality
  laborOffice?: ObjectWithTranslation
  statusDescription?: ObjectWithTranslation
  requestDate: string
  status: string | ItoERequestsStatus
} & Status

const initialState: State = {
  id: 0,
  laborerName: '',
  establishmentName: '',
  requestNumber: '',
  requestDate: '',
  status: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_E_REQUEST_DETAILS_ASYNC,
    Actions.GET_I_TO_E_REQUEST_DETAILS_SUCCESS,
    Actions.GET_I_TO_E_REQUEST_DETAILS_FAIL,
    GetItoERequestDetailsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_E_REQUEST_DETAILS_SUCCESS: {
      const { requestDetails } = action.payload
      return {
        ...state,
        ...action.status,
        laborerId: requestDetails.laborer_id,
        requestNumber: requestDetails.request_number,
        establishmentName: requestDetails.establishment_name,
        laborerName: requestDetails.laborer_name,
        requestDate: requestDetails.request_date,
        statusDescription: {
          id: requestDetails.laborer_id,
          label: requestDetails.status_description_ar,
          labelEn: requestDetails.status_description_en,
        },
        laborOffice: formatObjectWithTranslationFromBE(requestDetails.labor_office),
        occupation: formatOccupationsFromBE(requestDetails.occupation),
        nationality: formatNationalityFromBE(requestDetails.nationality),
        status: requestDetails.status,
        id: requestDetails.id,
      }
    }
    case Actions.GET_I_TO_E_REQUEST_DETAILS_ASYNC:
    case Actions.GET_I_TO_E_REQUEST_DETAILS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
