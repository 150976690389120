import { GetAuthContractListResponse } from 'api/laborerPortalAPI/authContract'
import { CallAPIAction, Status } from 'api/types'
import {
  LaborerAuthRequest,
  convertAuthRequestFromResponse,
} from 'common/utils/convertResponse/convertLaborerAuthResponse'
import { getAuthContractList } from 'redux/actionCreators/laborerPortalAPI/authContract'
import Actions from 'redux/actions'

type State = {
  params: {
    page: number
    status: string
    lastPage: number
  }
  requests: Array<LaborerAuthRequest>
} & Status

const initialState: State = {
  params: {
    page: 0,
    status: '',
    lastPage: 0,
  },
  requests: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_AUTH_REQUEST_LIST_ASYNC,
    Actions.GET_LABORER_AUTH_REQUEST_LIST_SUCCESS,
    Actions.GET_LABORER_AUTH_REQUEST_LIST_FAIL,
    GetAuthContractListResponse,
    {},
    {},
    ReturnType<typeof getAuthContractList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_AUTH_REQUEST_LIST_ASYNC: {
      const {
        params: { status = state.params.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        params: {
          page,
          status,
          lastPage: status !== state.params.status ? 0 : state.params.lastPage,
        },
      }
    }
    case Actions.GET_LABORER_AUTH_REQUEST_LIST_SUCCESS: {
      const {
        params: { status = state.params.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        params: {
          page,
          status,
          lastPage: action.payload.meta.last_page,
        },
        requests: action.payload.data.map(convertAuthRequestFromResponse),
      }
    }
    case Actions.GET_LABORER_AUTH_REQUEST_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
