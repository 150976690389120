import { call, put, takeLatest, takeLeading, all } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  login as loginAction,
  loginNafath as loginNafathAction,
  checkNafath as checkNafathAction,
  verify as verifyAction,
  resend as resendAction,
  checkMobile as checkMobileAction,
  verifyMobile as verifyMobileAction,
  regVerify as regVerifyAction,
  checkId as checkIdAction,
  requestReset as requestResetAction,
  verifyReset as verifyResetAction,
  resetPassword as resetPasswordAction,
  getAuthData as getAuthDataAction,
  keepAlive as keepAliveAction,
  checkCaptcha as checkCaptchaAction,
  getUserData as getUserDataAction,
  actAsPremium as actAsPremiumAction,
  actAsPremiumManager as actAsPremiumManagerAction,
} from 'redux/actionCreators/evisaAPI/auth'
import authEvisaAPI, {
  CheckNafathResponse,
  GetJiraTokenResponse,
  LoginResponse,
  RegVerifyResponse,
} from 'api/evisaAPI/auth'
import evisaAPI, { GetUserProfileResponse } from 'api/evisaAPI/evisaAPI'
import { Await, GeneralFailResponse } from 'common/types/commonTypes'
import authManager from 'common/utils/auth/authManager'
import { resetReducerState } from 'redux/actionCreators/resetReducerState'
import { CallApiFnResponse } from 'api/axios'
import convertUserProfileResponse from 'common/utils/convertResponse/convertUserProfileResponse'

import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from '../../utils'

function* login(action: ReturnType<typeof loginAction>) {
  const loginRequest = getSimpleRequestHandler<typeof authEvisaAPI.login, ReturnType<typeof loginAction>>({
    actionLoading: Actions.LOGIN_ASYNC,
    actionSuccess: Actions.LOGIN_SUCCESS,
    actionFail: Actions.LOGIN_FAIL,
    callApiFn: authEvisaAPI.login,
  })
  const { response, status } = yield call(loginRequest, action)
  if (status.success) {
    authManager.setAuthData({ mobileVerified: (response as LoginResponse).mobile_active })
  }
}

const loginNafath = getSimpleRequestHandler<typeof authEvisaAPI.loginNafath, ReturnType<typeof loginNafathAction>>({
  actionLoading: Actions.LOGIN_NAFATH_ASYNC,
  actionSuccess: Actions.LOGIN_NAFATH_SUCCESS,
  actionFail: Actions.LOGIN_NAFATH_FAIL,
  callApiFn: authEvisaAPI.loginNafath,
})

function* checkNafath(action: ReturnType<typeof checkNafathAction>) {
  const checkNafathRequest = getSimpleRequestHandler<
    typeof authEvisaAPI.checkNafath,
    ReturnType<typeof checkNafathAction>
  >({
    actionLoading: Actions.CHECK_NAFATH_ASYNC,
    actionSuccess: Actions.CHECK_NAFATH_SUCCESS,
    actionFail: Actions.CHECK_NAFATH_FAIL,
    callApiFn: authEvisaAPI.checkNafath,
  })
  const { response, status } = yield call(checkNafathRequest, action)
  if (status.success && (response as CheckNafathResponse).authorized) {
    authManager.setAuthData({ mobileVerified: (response as CheckNafathResponse)?.mobile_active })
  }
}

const actAsPremium = getSimpleRequestHandler<typeof authEvisaAPI.actAsPremium, ReturnType<typeof actAsPremiumAction>>({
  actionLoading: Actions.ACT_AS_PREMIUM_ASYNC,
  actionSuccess: Actions.ACT_AS_PREMIUM_SUCCESS,
  actionFail: Actions.ACT_AS_PREMIUM_FAIL,
  callApiFn: authEvisaAPI.actAsPremium,
})

const actAsPremiumManager = getSimpleRequestHandler<
  typeof authEvisaAPI.actAsPremiumManager,
  ReturnType<typeof actAsPremiumManagerAction>
>({
  actionLoading: Actions.ACT_AS_PREMIUM_MANAGER_ASYNC,
  actionSuccess: Actions.ACT_AS_PREMIUM_MANAGER_SUCCESS,
  actionFail: Actions.ACT_AS_PREMIUM_MANAGER_FAIL,
  callApiFn: authEvisaAPI.actAsPremiumManager,
})

const verify = getSimpleRequestHandler<typeof authEvisaAPI.verify, ReturnType<typeof verifyAction>>({
  actionLoading: Actions.VERIFY_ASYNC,
  actionSuccess: Actions.VERIFY_SUCCESS,
  actionFail: Actions.VERIFY_FAIL,
  callApiFn: authEvisaAPI.verify,
})

const resend = getSimpleRequestHandler<typeof authEvisaAPI.resend, ReturnType<typeof resendAction>>({
  actionLoading: Actions.RESEND_ASYNC,
  actionSuccess: Actions.RESEND_SUCCESS,
  actionFail: Actions.RESEND_FAIL,
  callApiFn: authEvisaAPI.resend,
})

const mobileCheck = getSimpleRequestHandler<typeof authEvisaAPI.checkMobile, ReturnType<typeof checkMobileAction>>({
  actionLoading: Actions.MOBILE_CHECK_ASYNC,
  actionSuccess: Actions.MOBILE_CHECK_SUCCESS,
  actionFail: Actions.MOBILE_CHECK_FAIL,
  callApiFn: authEvisaAPI.checkMobile,
})

function* mobileVerify(action: ReturnType<typeof verifyMobileAction>) {
  const verifyRequest = getSimpleRequestHandler<
    typeof authEvisaAPI.verifyMobile,
    ReturnType<typeof verifyMobileAction>
  >({
    actionLoading: Actions.MOBILE_VERIFY_ASYNC,
    actionSuccess: Actions.MOBILE_VERIFY_SUCCESS,
    actionFail: Actions.MOBILE_VERIFY_FAIL,
    callApiFn: authEvisaAPI.verifyMobile,
  })
  const { status } = yield call(verifyRequest, action)
  if (status.success) {
    try {
      const userProfileResponse: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call(
        evisaAPI.getUserProfile,
        {},
        {},
        { silenceError: false },
      )
      authManager.setAuthData({
        userData: convertUserProfileResponse(userProfileResponse.response as GetUserProfileResponse),
      })
    } catch (e) {
      console.warn(`set user profile error: ${e}`)
    }
  }
}

function* regVerify(action: ReturnType<typeof regVerifyAction>) {
  const verifyRequest = getSimpleRequestHandler<typeof authEvisaAPI.regVerify, ReturnType<typeof regVerifyAction>>({
    actionLoading: Actions.REG_VERIFY_ASYNC,
    actionSuccess: Actions.REG_VERIFY_SUCCESS,
    actionFail: Actions.REG_VERIFY_FAIL,
    callApiFn: authEvisaAPI.regVerify,
  })
  const { response, status } = yield call(verifyRequest, action)
  if (status.success) {
    authManager.setAuthData({ mobileVerified: (response as RegVerifyResponse).mobile_active })
  }
}

const checkId = getSimpleRequestHandler<typeof authEvisaAPI.checkId, ReturnType<typeof checkIdAction>>({
  actionLoading: Actions.CHECK_ID_ASYNC,
  actionSuccess: Actions.CHECK_ID_SUCCESS,
  actionFail: Actions.CHECK_ID_FAIL,
  callApiFn: authEvisaAPI.checkId,
})

const requestReset = getSimpleRequestHandler<typeof authEvisaAPI.requestReset, ReturnType<typeof requestResetAction>>({
  actionLoading: Actions.REQUEST_RESET_ASYNC,
  actionSuccess: Actions.REQUEST_RESET_SUCCESS,
  actionFail: Actions.REQUEST_RESET_FAIL,
  callApiFn: authEvisaAPI.requestReset,
})

const verifyReset = getSimpleRequestHandler<typeof authEvisaAPI.verifyReset, ReturnType<typeof verifyResetAction>>({
  actionLoading: Actions.VERIFY_RESET_ASYNC,
  actionSuccess: Actions.VERIFY_RESET_SUCCESS,
  actionFail: Actions.VERIFY_RESET_FAIL,
  callApiFn: authEvisaAPI.verifyReset,
})

const resetPassword = getSimpleRequestHandler<
  typeof authEvisaAPI.resetPassword,
  ReturnType<typeof resetPasswordAction>
>({
  actionLoading: Actions.RESET_PASSWORD_ASYNC,
  actionSuccess: Actions.RESET_PASSWORD_SUCCESS,
  actionFail: Actions.RESET_PASSWORD_FAIL,
  callApiFn: authEvisaAPI.resetPassword,
})

export type AuthDataResponseFail = {
  errorMessage: string
}

function* getUserData(action: ReturnType<typeof getUserDataAction>) {
  try {
    yield putLoading({ type: Actions.GET_USER_DATA_ASYNC })
    const userProfileInfo: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call<
      typeof evisaAPI.getUserProfile
    >(evisaAPI.getUserProfile, {}, {}, { silenceError: false })
    const userProfileResponse = userProfileInfo.response as GetUserProfileResponse
    authManager.setAuthData({
      userData: convertUserProfileResponse(userProfileResponse),
    })
    yield putSuccess({
      type: Actions.GET_USER_DATA_SUCCESS,
      payload: {},
    })
  } catch (error) {
    const response = (error as Await<CallApiFnResponse>).response as GeneralFailResponse | undefined
    yield putFail({
      type: Actions.GET_USER_DATA_FAIL,
      payload: {
        errorMessage: response?.error?.message,
      },
    })
  }
}

export function* getAuthData(action: ReturnType<typeof getAuthDataAction>) {
  try {
    yield putLoading({ type: Actions.GET_AUTH_DATA_ASYNC })
    const response: [
      Await<ReturnType<typeof authEvisaAPI.getJiraToken>>,
      Await<ReturnType<typeof evisaAPI.getUserProfile>>,
    ] = yield all([
      call(authEvisaAPI.getJiraToken, {}, {}, { silenceError: false }),
      call(evisaAPI.getUserProfile, {}, {}, { silenceError: false }),
    ])
    const jiraTokenResponse = response[0].response as GetJiraTokenResponse
    const userProfileResponse = response[1].response as GetUserProfileResponse
    authManager.setAuthData({
      jiraToken: jiraTokenResponse.token,
      userData: convertUserProfileResponse(userProfileResponse),
    })
    yield putSuccess({
      type: Actions.GET_AUTH_DATA_SUCCESS,
      payload: {},
    })
  } catch (error) {
    const response = (error as Await<CallApiFnResponse>).response as GeneralFailResponse | undefined
    yield putFail({
      type: Actions.GET_AUTH_DATA_FAIL,
      payload: {
        errorMessage: response?.error?.message || 'AUTH_DATA_ERROR',
      },
    })
  }
}

function* keepAlive(action: ReturnType<typeof keepAliveAction>) {
  try {
    yield putLoading({ type: Actions.KEEP_ALIVE_ASYNC })
    yield call<typeof evisaAPI.keepAliveEvisaToken>(evisaAPI.keepAliveEvisaToken, {}, {}, { silenceError: false })
    const jiraTokenInfo: Await<ReturnType<typeof authEvisaAPI.getJiraToken>> = yield call<
      typeof authEvisaAPI.getJiraToken
    >(authEvisaAPI.getJiraToken, {}, {}, { silenceError: false })
    const jiraTokenResponse = jiraTokenInfo.response as GetJiraTokenResponse
    authManager.setAuthData({
      jiraToken: jiraTokenResponse.token,
    })
  } catch {
    yield putFail({ type: Actions.KEEP_ALIVE_FAIL })
    authManager.logOut(true)
  } finally {
    yield put(resetReducerState(['auth']))
  }
}

const checkCaptcha = getSimpleRequestHandler<typeof authEvisaAPI.checkCaptcha, ReturnType<typeof checkCaptchaAction>>({
  actionLoading: Actions.CHECK_CAPTCHA_ASYNC,
  actionSuccess: Actions.CHECK_CAPTCHA_SUCCESS,
  actionFail: Actions.CHECK_CAPTCHA_FAIL,
  callApiFn: authEvisaAPI.checkCaptcha,
})

export default [
  takeLatest(Actions.LOGIN, login),
  takeLatest(Actions.LOGIN_NAFATH, loginNafath),
  takeLatest(Actions.CHECK_NAFATH, checkNafath),
  takeLatest(Actions.ACT_AS_PREMIUM, actAsPremium),
  takeLatest(Actions.ACT_AS_PREMIUM_MANAGER, actAsPremiumManager),
  takeLatest(Actions.VERIFY, verify),
  takeLatest(Actions.RESEND, resend),
  takeLatest(Actions.MOBILE_CHECK, mobileCheck),
  takeLatest(Actions.MOBILE_VERIFY, mobileVerify),
  takeLatest(Actions.REG_VERIFY, regVerify),
  takeLatest(Actions.CHECK_ID, checkId),
  takeLatest(Actions.REQUEST_RESET, requestReset),
  takeLatest(Actions.VERIFY_RESET, verifyReset),
  takeLatest(Actions.RESET_PASSWORD, resetPassword),
  takeLatest(Actions.GET_AUTH_DATA, getAuthData),
  takeLeading(Actions.KEEP_ALIVE, keepAlive),
  takeLeading(Actions.CHECK_CAPTCHA, checkCaptcha),
  takeLeading(Actions.GET_USER_DATA, getUserData),
]
