import { ManagerAuthStatusTypes } from 'common/types/commonTypes'
import { CallAPIAction, Status } from 'api/types'
import { GetManagerInfoResponse } from 'api/premiumUserAPI/relationshipManagerAPI'
import Actions from 'redux/actions'

type State = {
  authStatus: ManagerAuthStatusTypes
} & Status

const initialState: State = {
  authStatus: ManagerAuthStatusTypes.REJECTED,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_MANAGER_INFO_ASYNC,
    Actions.GET_MANAGER_INFO_SUCCESS,
    Actions.GET_MANAGER_INFO_FAIL,
    GetManagerInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_MANAGER_INFO_ASYNC:
    case Actions.GET_MANAGER_INFO_FAIL:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_MANAGER_INFO_SUCCESS:
      return {
        ...state,
        ...action.status,
        authStatus: action.payload.authorization,
      }
    default:
      return state
  }
}
