import React, { FunctionComponent, ReactNode } from 'react'
import clsx from 'clsx'

import { ENABLE_NEW_DESIGN_FLOW } from 'common/envConstants'

import MessageContent, { MessageContentProps } from '../../MessageContent/MessageContent'

import styles from './ModalMessageContent.module.scss'

export type ModalMessageContentProps = { actions?: ReactNode; className?: string; icon?: ReactNode } & Pick<
  MessageContentProps,
  'title' | 'type' | 'description' | 'otherContent' | 'classNameDescription'
>

const ModalMessageContent: FunctionComponent<ModalMessageContentProps> = ({
  title,
  description,
  type,
  actions,
  className,
  icon,
  classNameDescription,
}) => (
  <div
    className={clsx(!ENABLE_NEW_DESIGN_FLOW && styles.rootExtended, ENABLE_NEW_DESIGN_FLOW && styles.root, className)}
  >
    <MessageContent
      icon={icon}
      title={title}
      type={type}
      description={description}
      otherContent={actions}
      classNameRoot={styles.container}
      classNameIcon={styles.icon}
      classNameTitle={clsx(
        styles.title,
        type === 'success' && styles.success,
        (type === 'info' || ENABLE_NEW_DESIGN_FLOW) && styles.info,
      )}
      classNameDescription={clsx(
        styles.description,
        ENABLE_NEW_DESIGN_FLOW && styles.descriptionNew,
        classNameDescription,
      )}
      classNameOtherContent={styles.actionsContainer}
    />
  </div>
)

export default ModalMessageContent
