import { CallAPIAction, Status } from 'api/types'
import { GetVisaInfoResponse } from 'api/evisaAPI/visas'
import Actions from 'redux/actions'
import { Visa } from 'common/types/transformedRespTypes'
import { convertVisaResponseToState } from 'common/utils/convertResponse/convertVisaResponseToState'

type State = {
  visa: Visa | undefined
} & Status

const initialState = {
  visa: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VISA_INFO_ASYNC,
    Actions.GET_VISA_INFO_SUCCESS,
    Actions.GET_VISA_INFO_FAIL,
    GetVisaInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VISA_INFO_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VISA_INFO_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        visa: convertVisaResponseToState(payload),
      }
    }
    case Actions.GET_VISA_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
