import { CallAPIAction, Status } from 'api/types'
import { BillStatus, FarmerEligibilityErrorMessages } from 'common/types/commonTypes'
import { Nationality, Occupation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import {
  IssueNewFarmerTransferRequestResponse,
  IssueNewFarmerFarmerRequestFailResponse,
} from 'api/evisaAPI/farmerTransfer'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'

type State = {
  errorMessage: FarmerEligibilityErrorMessages | string
  farmerRequest?: {
    id: number
    nationality: Nationality
    occupation: Occupation
    bill: {
      status: BillStatus
    } | null
  }
} & Status

const initialState = {
  errorMessage: '',
  farmerRequest: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ISSUE_NEW_FARMER_TRANSFER_ASYNC,
    Actions.ISSUE_NEW_FARMER_TRANSFER_SUCCESS,
    Actions.ISSUE_NEW_FARMER_TRANSFER_FAIL,
    IssueNewFarmerTransferRequestResponse,
    IssueNewFarmerFarmerRequestFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.ISSUE_NEW_FARMER_TRANSFER_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.ISSUE_NEW_FARMER_TRANSFER_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        farmerRequest: {
          id: payload.farmerTransferRequest.id,
          nationality: formatNationalityFromBE(payload.farmerTransferRequest.nationality),
          occupation: formatOccupationsFromBE(payload.farmerTransferRequest.occupation),
          bill: payload.farmerTransferRequest.bill
            ? {
                status: payload.farmerTransferRequest.bill.status,
              }
            : null,
        },
      }
    }
    case Actions.ISSUE_NEW_FARMER_TRANSFER_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
