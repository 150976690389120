import { Status, CallAPIAction } from 'api/types'
import type { GetArrivalCitiesResponse } from 'api/evisaAPI/evisaAPI'
import { ArrivalCity } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

const convertResponseToState = (resp: GetArrivalCitiesResponse) =>
  resp.cities.reduce(
    (acc, cityFromBE) => ({
      ...acc,
      [cityFromBE.id]: {
        id: parseInt(cityFromBE.id, 10),
        label: cityFromBE.label_ar,
        labelEn: cityFromBE.label_en,
      },
    }),
    {},
  )

type State = {
  arrivalCities: StringRecord<ArrivalCity>
} & Status

const initialState = {
  arrivalCities: {},
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ARRIVAL_CITIES_ASYNC,
    Actions.GET_ARRIVAL_CITIES_SUCCESS,
    Actions.GET_ARRIVAL_CITIES_FAIL,
    GetArrivalCitiesResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_ARRIVAL_CITIES_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_ARRIVAL_CITIES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        arrivalCities: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_ARRIVAL_CITIES_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
