import { CreateComplaintTicketResponse, CreateComplaintTicketResponseFail } from 'api/jiraAPI'
import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  issueKey: string
  errorMessage: string
  fullError: string
} & Status

const initialState = {
  issueKey: '',
  errorMessage: '',
  fullError: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_COMPLAINT_TICKET_ASYNC,
    Actions.CREATE_COMPLAINT_TICKET_SUCCESS,
    Actions.CREATE_COMPLAINT_TICKET_FAIL,
    CreateComplaintTicketResponse,
    CreateComplaintTicketResponseFail
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_COMPLAINT_TICKET_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CREATE_COMPLAINT_TICKET_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        issueKey: payload.issueKey,
        errorMessage: '',
      }
    }
    case Actions.CREATE_COMPLAINT_TICKET_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.message || '',
        fullError: JSON.stringify(payload),
        issueKey: '',
      }
    }
    default:
      return state
  }
}
