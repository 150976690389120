import { FailResponse, GetLaborerInfoResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type LaborerInfo = {
  occupation: {
    code: string
    name: string
  }
  nationality: {
    code: string
    name: string
  }
  establishmentBasicInfo: {
    establishmentId: number
    establishmentName: string
    laborOfficeId: string
    sequanceNumber: string
    subEconomicActivityId: string
  }
  gender: string
  id: string
  name: string
  token: string
}

type State = {
  info?: LaborerInfo
  error?: {
    message: string
    code: number
  }
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = ({ laborerInfo, token }: GetLaborerInfoResponse) => ({
  occupation: {
    code: laborerInfo.Job.Code,
    name: laborerInfo.Job.Name,
  },
  nationality: {
    code: laborerInfo.Nationality.Code,
    name: laborerInfo.Nationality.Name,
  },
  establishmentBasicInfo: {
    establishmentId: laborerInfo.establishmentBasicInfo.EstablishmentId,
    establishmentName: laborerInfo.establishmentBasicInfo.EstablishmentName,
    laborOfficeId: laborerInfo.establishmentBasicInfo.LaborOfficeId,
    sequanceNumber: laborerInfo.establishmentBasicInfo.SequanceNumber,
    subEconomicActivityId: laborerInfo.establishmentBasicInfo.SubEconomicActivityId,
  },
  gender: laborerInfo.gender,
  id: laborerInfo.id,
  name: laborerInfo.name,
  token,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_INFO_ASYNC,
    Actions.GET_LABORER_INFO_SUCCESS,
    Actions.GET_LABORER_INFO_FAIL,
    GetLaborerInfoResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_INFO_SUCCESS: {
      return {
        ...state,
        ...action.status,
        info: convertResponseToState(action.payload),
      }
    }
    case Actions.GET_LABORER_INFO_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_LABORER_INFO_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }

    default:
      return state
  }
}
