import { closeMaarofaBar } from 'redux/actionCreators/closeMaarofaBar'
import Actions from 'redux/actions'

type State = {
  isVisible: boolean
}

const initialState: State = {
  isVisible: true,
}

export default (state: State = initialState, action: ReturnType<typeof closeMaarofaBar>): State => {
  switch (action.type) {
    case Actions.CLOSE_MAAROFA_BAR: {
      return {
        isVisible: false,
      }
    }
    default:
      return state
  }
}
