import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'
import Actions from 'redux/actions'
import { setVipPreselectedInfo } from 'redux/actionCreators/premiumUserAPI/recruitmentFilter'

export type PreselectedInfoState = {
  name: string
  ageRange: number
  passportNumber: string
  passportExpiry: string
  workerSalary: string
  phone: { value: string; location?: PhoneData }
  passportImage?: File
}

const initialState: PreselectedInfoState = {
  name: '',
  ageRange: 0,
  passportNumber: '',
  passportExpiry: '',
  phone: { value: '', location: undefined },
  passportImage: undefined,
  workerSalary: '',
}

export default (state: PreselectedInfoState = initialState, action: ReturnType<typeof setVipPreselectedInfo>) => {
  switch (action.type) {
    case Actions.SET_VIP_PRESELECTED_INFO:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
