import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<Actions.EXTEND_CONTRACT_ASYNC, Actions.EXTEND_CONTRACT_SUCCESS, Actions.EXTEND_CONTRACT_FAIL>,
): State => {
  switch (action.type) {
    case Actions.EXTEND_CONTRACT_ASYNC:
    case Actions.EXTEND_CONTRACT_SUCCESS:
    case Actions.EXTEND_CONTRACT_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
