import { CallAPIAction, Status } from 'api/types'
import { GetVipVisaListResponse, VipVisaFromBE } from 'api/evisaAPI/visas'
import { Nationality, Occupation } from 'common/types/transformedRespTypes'
import { VisaTypes, VisaIssueRequestStatus } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type VisaRequest = {
  id: string
  createdAt: string
  nationality: Nationality
  occupation: Occupation
  visaType: VisaTypes
  status: VisaIssueRequestStatus
}

type State = {
  data: Array<VisaRequest>
  lastPage: number
} & Status

const initialState = {
  data: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: VipVisaFromBE[]) =>
  resp.map((item) => ({
    id: item.id,
    createdAt: item.created_at,
    nationality: {
      id: 0,
      label: item.nationality_label,
      labelEn: item.nationality_label_en,
      abbreviation: item.nationality_abbreviation,
      code: '0',
      isOnlineContractingAllowed: false,
    },
    occupation: {
      id: 0,
      label: item.occupation_label,
      labelEn: item.occupation_label_en,
    },
    visaType: item.visa_type,
    status: parseInt(item.status) as VisaIssueRequestStatus,
  }))

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VIP_VISA_LIST_ASYNC,
    Actions.GET_VIP_VISA_LIST_SUCCESS,
    Actions.GET_VIP_VISA_LIST_FAIL,
    GetVipVisaListResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VIP_VISA_LIST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VIP_VISA_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        data: convertResponseToState(payload.paginator.data),
        lastPage: payload.paginator.last_page || 0,
      }
    }
    case Actions.GET_VIP_VISA_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
