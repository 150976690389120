import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { GeneralFailResponse } from 'common/types/commonTypes'
import { UpdateLaborsResponse } from 'api/evisaAPI/evisaAPI'
import { ObjectWithTranslation, UpdateLaborsActionTypes } from 'common/types/transformedRespTypes'

type State = {
  errorMessage: string
  labors: Record<
    string,
    {
      description: ObjectWithTranslation
      action: UpdateLaborsActionTypes
    }
  > | null
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
  labors: null,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.UPDATE_LABORS_ASYNC,
    Actions.UPDATE_LABORS_SUCCESS,
    Actions.UPDATE_LABORS_FAIL,
    UpdateLaborsResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.UPDATE_LABORS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.UPDATE_LABORS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        labors: action.payload.reduce(
          (labors, labor, index) => ({
            ...labors,
            [labor.LaborId]: {
              action: labor.Action,
              description: {
                label: labor.ArabicDescription,
                labelEn: labor.EnglishDescription,
                id: index,
              },
            },
          }),
          {},
        ),
      }
    }
    case Actions.UPDATE_LABORS_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
