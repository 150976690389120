import type { AxiosRequestConfig } from 'axios'

import type { EToILegacyRequestsStatus, EmploymentStatus, ItoERequestsStatus } from 'common/types/commonTypes'
import {
  NationalityFromBE,
  ObjectWithTranslationFromBE,
  ObjectWithTranslationFromBEWithoutId,
  OccupationFromBE,
} from 'common/types/transformedRespTypes'
import { callAPI, evisaAPIWithCredentials } from 'api/axios'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'

export type GetEtoILegacyRequestsParams = {
  perPage?: number
  page?: number
  status?: string | EToILegacyRequestsStatus
}

export type EToILegacyRequestFormBE = {
  id: number
  laborer_iqama: string
  user: {
    id: number
    name: string
    name_en: string
  }
  current_sponsor: string
  created_at: string
  status: EToILegacyRequestsStatus
  rejectionReasons: Array<ObjectWithTranslationFromBE>
}

export type GetEtoILegacyRequestsResponse = {
  paginator: {
    data: Array<EToILegacyRequestFormBE>
    last_page: number
    current_page: number
  }
}

const getEToILegacyRequests = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetEtoILegacyRequestsParams,
  config?: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetEtoILegacyRequestsResponse>('/app/dl/requests', {
    params: {
      per_page: perPage,
      page,
      status,
    },
    ...config,
  })

export type IndividualToEntityListParams = {
  perPage?: number
  page?: number
  status?: string | ItoERequestsStatus
}

export type ItoERequestsFormBE = {
  id: number
  request_date: string
  laborer_name: string
  establishment_name: string
  status: number
}

export type IndividualToEntityListResponse = {
  paginator: {
    data: Array<ItoERequestsFormBE>
    last_page: number
  }
}

const getIndividualToEntityList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: IndividualToEntityListParams,
  config?: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<IndividualToEntityListResponse>('/app/ind_to_est_transfer_requests', {
    params: {
      per_page: perPage,
      page: page + 1,
      status,
    },
    ...config,
  })

export type GetLaborerInfoParams = {
  occupation: number
  nationality: number
  numberId: string
}

export type GetLaborerInfoResponse = {
  laborerInfo: {
    Job: {
      Code: string
      Name: string
    }
    Nationality: {
      Code: string // Name
      Name: string
    }
    establishmentBasicInfo: {
      EstablishmentId: number
      EstablishmentName: string
      LaborOfficeId: string
      SequanceNumber: string
      SubEconomicActivityId: string
    }
    gender: string
    id: string
    name: string
  }
  token: string
}
export type FailResponse = {
  error: {
    message: string
    code: number
  }
}

const getLaborerInfo = (params: GetLaborerInfoParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<GetLaborerInfoResponse>(
    '/app/dl/getLaborerInfo',
    {
      id_number: params.numberId,
      nationality: params.nationality,
      occupation: params.occupation,
    },
    config,
  )

export type CreateDomesticTransferRequestParams = {
  documents: Array<string>
  employmentStatus: EmploymentStatus
  nationality: string
  occupation: number
  token: string
}

export type CreateDomesticTransferRequestResponse = {}

const createDomesticTransferRequest = (params: CreateDomesticTransferRequestParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CreateDomesticTransferRequestResponse>(
    '/app/dl/request',
    {
      documents: params.documents,
      employment_status: params.employmentStatus,
      nationality: params.nationality,
      occupation: params.occupation,
      token: params.token,
    },
    config,
  )

export type GetItoERequestDetailsResponse = {
  requestDetails: {
    request_date: string
    laborer_id: number
    laborer_name: string
    establishment_name: string
    occupation: OccupationFromBE
    nationality: NationalityFromBE
    status: ItoERequestsStatus
    labor_office: ObjectWithTranslationFromBEWithoutId
    status_description_en: string
    status_description_ar: string
    request_number: string
    id: number
    user: {
      email: string
      mobile: string
    }
  }
}

export type GetItoERequestDetailsParams = {
  id: string
}

const getItoERequestDetails = ({ id }: GetItoERequestDetailsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetItoERequestDetailsResponse>(`app/ind_to_est_transfer_requests/show/${id}`, config)

export type ItoERequestActionParams = {
  id: number
  type: 'accept' | 'reject'
}

const iToERequestActions = ({ id, type }: ItoERequestActionParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post(`app/ind_to_est_transfer_requests/${type}/${id}`, config)

export const dlAPI = {
  getEToILegacyRequests: callAPI<typeof getEToILegacyRequests, GetEtoILegacyRequestsResponse>(getEToILegacyRequests),
  getLaborerInfo: callAPI<typeof getLaborerInfo, GetLaborerInfoResponse, FailResponse>(getLaborerInfo),
  iToERequestActions: callAPI<typeof iToERequestActions, {}, FailResponse>(iToERequestActions),
  getItoERequestDetails: callAPI<typeof getItoERequestDetails, GetItoERequestDetailsResponse, FailResponse>(
    getItoERequestDetails,
  ),
  createDomesticTransferRequest: callAPI<
    typeof createDomesticTransferRequest,
    CreateDomesticTransferRequestResponse,
    FailResponse
  >(createDomesticTransferRequest),
  getIndividualToEntityList: callAPI<typeof getIndividualToEntityList, IndividualToEntityListResponse, FailResponse>(
    getIndividualToEntityList,
  ),
}

export const rawDlAPI = {
  getEToILegacyRequests,
}
