import { all, spawn } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'

import authManager from 'common/utils/auth/authManager'

import sagas from './sagas'

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* sagaSpawn() {
        while (true) {
          try {
            yield (function* wrapper() {
              yield saga
            })()
            break
          } catch (e) {
            console.error(e)
            Sentry.withScope((scope) => {
              scope.setUser({ id: authManager.getUserNameFromEvisaToken() })
              scope.setLevel(Sentry.Severity.Debug)
              scope.setTransactionName('ReduxSagaException')
              Sentry.captureException(e)
            })
          }
        }
      }),
    ),
  )
}
