import { CallAPIAction, Status } from 'api/types'
import { GetPaymentInfoResponse } from 'api/eContractingAPI'
import Actions from 'redux/actions'

type State = {
  subtotal: number
  vat: number
  domesticFlight: number
  total: number
  sadadBillNumber: string
  vatPercentage: number
  paidDate: string
} & Status

const initialState = {
  subtotal: 0,
  vat: 0,
  domesticFlight: 0,
  total: 0,
  sadadBillNumber: '',
  vatPercentage: 0,
  isLoading: false,
  success: false,
  fail: false,
  paidDate: '',
}

const convertResponseToState = (resp: GetPaymentInfoResponse) => ({
  subtotal: resp.contract.offer.price,
  vat: parseFloat(resp.contract.vat.toFixed(2)),
  domesticFlight: resp.contract.offer.delivery_price,
  total: resp.contract.bill.amount,
  sadadBillNumber: resp.contract.bill.sadad_bill_number || '',
  vatPercentage: parseFloat(resp.contract.bill.vat.value),
  paidDate: resp.contract.bill.paid_date || '',
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PAYMENT_INFO_ASYNC,
    Actions.GET_PAYMENT_INFO_SUCCESS,
    Actions.GET_PAYMENT_INFO_FAIL,
    GetPaymentInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PAYMENT_INFO_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PAYMENT_INFO_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_PAYMENT_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
