import { Lang } from '../../common/types/commonTypes'
import Actions from '../actions'

interface Payload {
  lang: Lang
}

export type SetLanguageAction = {
  type: Actions.SET_LANGUAGE
  payload: Payload
}

export const setLanguage = (lang: Lang): SetLanguageAction => ({
  type: Actions.SET_LANGUAGE,
  payload: {
    lang,
  },
})
