import { takeLatest } from 'redux-saga/effects'

import {
  getLaborerTransfersList as getLaborerTransfersListAction,
  getLaborerTransferDetails as getLaborerTransferDetailsAction,
  sendLaborerRelativeInfo as sendLaborerRelativeInfoAction,
  rejectLaborerTransferRequest as rejectLaborerTransferRequestAction,
  acceptLaborerTransferRequest as acceptLaborerTransferRequestAction,
} from 'redux/actionCreators/laborerPortalAPI/serviceTransfer'
import laborerPortalAPI from 'api/laborerPortalAPI/serviceTransfer'
import Actions from 'redux/actions'
import { getSimpleRequestHandler } from 'saga/utils'

const getLaborerTransfersList = getSimpleRequestHandler<
  typeof laborerPortalAPI.getLaborerTransfersList,
  ReturnType<typeof getLaborerTransfersListAction>
>({
  actionLoading: Actions.GET_LABORER_TRANSFERS_LIST_ASYNC,
  actionSuccess: Actions.GET_LABORER_TRANSFERS_LIST_SUCCESS,
  actionFail: Actions.GET_LABORER_TRANSFERS_LIST_FAIL,
  callApiFn: laborerPortalAPI.getLaborerTransfersList,
})

const getLaborerTransferDetails = getSimpleRequestHandler<
  typeof laborerPortalAPI.getLaborerTransferDetails,
  ReturnType<typeof getLaborerTransferDetailsAction>
>({
  actionLoading: Actions.GET_LABORER_TRANSFER_DETAILS_ASYNC,
  actionSuccess: Actions.GET_LABORER_TRANSFER_DETAILS_SUCCESS,
  actionFail: Actions.GET_LABORER_TRANSFER_DETAILS_FAIL,
  callApiFn: laborerPortalAPI.getLaborerTransferDetails,
})

const sendLaborerRelativeInfo = getSimpleRequestHandler<
  typeof laborerPortalAPI.sendLaborerRelativeInfo,
  ReturnType<typeof sendLaborerRelativeInfoAction>
>({
  actionLoading: Actions.SEND_LABORER_RELATIVE_INFO_ASYNC,
  actionSuccess: Actions.SEND_LABORER_RELATIVE_INFO_SUCCESS,
  actionFail: Actions.SEND_LABORER_RELATIVE_INFO_FAIL,
  callApiFn: laborerPortalAPI.sendLaborerRelativeInfo,
})

const rejectLaborerTransferRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectLaborerTransferRequest,
  ReturnType<typeof rejectLaborerTransferRequestAction>
>({
  actionLoading: Actions.REJECT_LABORER_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.REJECT_LABORER_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.REJECT_LABORER_TRANSFER_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.rejectLaborerTransferRequest,
})

const acceptLaborerTransferRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.acceptLaborerTransferRequest,
  ReturnType<typeof acceptLaborerTransferRequestAction>
>({
  actionLoading: Actions.ACCEPT_LABORER_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.ACCEPT_LABORER_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.ACCEPT_LABORER_TRANSFER_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.acceptLaborerTransferRequest,
})

export default [
  takeLatest(Actions.GET_LABORER_TRANSFERS_LIST, getLaborerTransfersList),
  takeLatest(Actions.GET_LABORER_TRANSFER_DETAILS, getLaborerTransferDetails),
  takeLatest(Actions.SEND_LABORER_RELATIVE_INFO, sendLaborerRelativeInfo),
  takeLatest(Actions.REJECT_LABORER_TRANSFER_REQUEST, rejectLaborerTransferRequest),
  takeLatest(Actions.ACCEPT_LABORER_TRANSFER_REQUEST, acceptLaborerTransferRequest),
]
