import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type GET_CONTRACT_INFO_RESPONSE = {
  contract: {
    offer: {
      contract_request: {
        id: number
      }
    }
    offer_id: number
  }
}

type State = {
  offerId: number
  requestId: number
} & Status

const initialState: State = {
  offerId: 0,
  requestId: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_INFO_ASYNC,
    Actions.GET_CONTRACT_INFO_SUCCESS,
    Actions.GET_CONTRACT_INFO_FAIL,
    GET_CONTRACT_INFO_RESPONSE
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_INFO_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_CONTRACT_INFO_SUCCESS: {
      return {
        ...state,
        ...action.status,
        offerId: action.payload.contract.offer_id,
        requestId: action.payload.contract.offer.contract_request.id,
      }
    }
    case Actions.GET_CONTRACT_INFO_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
