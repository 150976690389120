import { CallAPIAction, Status } from 'api/types'
import type { GetComplaintRatingsResponse } from 'api/jiraAPI'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  ratings: StringRecord<string>
} & Status

const initialState = {
  ratings: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: GetComplaintRatingsResponse) =>
  resp.query_results.row_positions.reduce(
    (acc, [{ name }], index) => ({
      ...acc,
      [name]: `${parseInt(resp.query_results.formatted_values[index][0], 10)}%`,
    }),
    {},
  )

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_COMPLAINT_RATINGS_ASYNC,
    Actions.GET_COMPLAINT_RATINGS_SUCCESS,
    Actions.GET_COMPLAINT_RATINGS_FAIL,
    GetComplaintRatingsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_COMPLAINT_RATINGS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ratings: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_COMPLAINT_RATINGS_ASYNC:
    case Actions.GET_COMPLAINT_RATINGS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
