import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import type { GetTicketResponse } from 'api/jiraAPI'
import type { Ticket } from 'common/utils/convertResponse/convertComplaintResponse'
import { convertComplaintTicket } from 'common/utils/convertResponse/convertComplaintResponse'

type State = {
  ticket: Ticket | null
} & Status

const initialState: State = {
  ticket: null,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_TICKET_ASYNC,
    Actions.GET_TICKET_SUCCESS,
    Actions.GET_TICKET_FAIL,
    GetTicketResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_TICKET_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ticket: convertComplaintTicket(payload),
        ...action.status,
      }
    }
    case Actions.GET_TICKET_ASYNC:
    case Actions.GET_TICKET_FAIL:
      return {
        ...state,
        ticket: null,
        ...action.status,
      }
    default:
      return state
  }
}
