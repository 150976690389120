import { CallAPIAction, Status } from 'api/types'
import { GetVisaIssueRequestInfoResponse } from 'api/evisaAPI/visas'
import { VisaIssueRequest } from 'common/types/transformedRespTypes'
import { formatVisaIssueRequestFromBE } from 'common/utils/convertResponse/convertVisaResponseToState'
import Actions from 'redux/actions'

type State = {
  issueRequest?: VisaIssueRequest
  hasRefundableBill?: boolean
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  issueRequest: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VISA_ISSUE_REQUEST_INFO_ASYNC,
    Actions.GET_VISA_ISSUE_REQUEST_INFO_SUCCESS,
    Actions.GET_VISA_ISSUE_REQUEST_INFO_FAIL,
    GetVisaIssueRequestInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VISA_ISSUE_REQUEST_INFO_SUCCESS: {
      return {
        ...state,
        ...action.status,
        issueRequest: formatVisaIssueRequestFromBE(action.payload.issueRequest),
        hasRefundableBill: action.payload.has_refundable_bill,
      }
    }
    case Actions.GET_VISA_ISSUE_REQUEST_INFO_ASYNC:
    case Actions.GET_VISA_ISSUE_REQUEST_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
