import { GetSupportTicketsResponse, SupportTicketFromBE } from 'api/laborerPortalAPI/supportTicket'
import { CallAPIAction, Status } from 'api/types'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { getLaborerSupportTickets } from 'redux/actionCreators/laborerPortalAPI/support'
import Actions from 'redux/actions'

type SupportTicket = {
  id: string
  key: string
  category: string
  status: string
  createdAt: string
  description: string
  laborerMobile: string | null
  finalDecision: string | null
  attachments: Array<{
    filename: string
    id: string
  }> | null
}

type State = {
  params: {
    currentPage: number
    pages: number
  }
  requests: Array<SupportTicket>
} & Status

const initialState: State = {
  params: {
    currentPage: 0,
    pages: 0,
  },
  requests: [],
  isLoading: false,
  success: false,
  fail: false,
}

const convertSupportTicketsFromBE = (item: SupportTicketFromBE): SupportTicket => ({
  createdAt: item.fields.created,
  key: item.key,
  status: item.fields.status.name,
  category: item.fields.category ? item.fields.category.id : '',
  id: item.id,
  description: item.fields.description,
  laborerMobile: item.fields.laborer_mobile_number,
  finalDecision: item.fields.final_decision,
  attachments: item.fields.attachment.length
    ? item.fields.attachment.map((att) => ({
        filename: att.filename,
        id: att.id,
      }))
    : null,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_SUPPORT_TICKETS_ASYNC,
    Actions.GET_LABORER_SUPPORT_TICKETS_SUCCESS,
    Actions.GET_LABORER_SUPPORT_TICKETS_FAIL,
    GetSupportTicketsResponse,
    {},
    {},
    ReturnType<typeof getLaborerSupportTickets>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_SUPPORT_TICKETS_ASYNC: {
      return {
        ...state,
        ...action.status,
        params: {
          currentPage: action.callAPIActionPayload.params.page || 0,
          pages: state.params.pages,
        },
      }
    }
    case Actions.GET_LABORER_SUPPORT_TICKETS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        params: {
          currentPage: Math.trunc(action.payload.startAt / LIST_ITEMS_PER_PAGE),
          pages: action.payload.pages,
        },
        requests: action.payload.issues.map(convertSupportTicketsFromBE),
      }
    }
    case Actions.GET_LABORER_SUPPORT_TICKETS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
