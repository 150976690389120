import { GetContractAuthDetailedRequestResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import {
  ContractAuthAppendixRequest,
  ContractAuthDetailedRequest,
  convertContractAuthAppendixRequestFromResponse,
  convertContractAuthDetailedRequestFromResponse,
} from 'common/utils/convertResponse/convertAuthApiResponse'
import Actions from 'redux/actions'

type State = {
  detailedAuthContract: ContractAuthDetailedRequest | undefined
  editedAuthContractAppendix: ContractAuthAppendixRequest | undefined
} & Status

const initialState = {
  detailedAuthContract: undefined,
  isLoading: false,
  success: false,
  fail: false,
  editedAuthContractAppendix: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC,
    Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS,
    Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL,
    GetContractAuthDetailedRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC:
    case Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        detailedAuthContract: undefined,
        editedAuthContractAppendix: undefined,
      }
    }
    case Actions.GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        detailedAuthContract: convertContractAuthDetailedRequestFromResponse(payload),
        editedAuthContractAppendix: convertContractAuthAppendixRequestFromResponse(payload),
      }
    }
    default:
      return state
  }
}
