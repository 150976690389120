import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getTicketsList as getTicketsListAction,
  getTicket as getTicketAction,
  getTicketAndCorrespondingOfficeInfo as getTicketAndCorrespondingOfficeInfoAction,
  respondToComplaintSolution as respondToComplaintSolutionAction,
  respondToOfficeComplaint as respondToOfficeComplaintAction,
  getSupportTicketsList as getSupportTicketsListAction,
  createSupportTicket as createSupportTicketAction,
  createSupportTicketGuest as createSupportTicketGuestAction,
  createComplaintTicket as createComplaintTicketAction,
  getSupportTicketDetails as getSupportTicketDetailsAction,
  updateSupportTicketDetails as updateSupportTicketDetailsAction,
  getComplaintRatings as getComplaintRatingsAction,
  getComplaintRatingsV2 as getComplaintRatingsV2Action,
  addMissingAttachment as addMissingAttachmentAction,
  complaintInquiry as complaintInquiryAction,
  addFeedback as addFeedbackAction,
  getSurveyQuestions as getSurveyQuestionsAction,
  postSurveyQuestions as postSurveyQuestionsAction,
  getProPerformance as getProPerformanceAction,
  getProPerformanceOverall as getProPerformanceOverallAction,
} from 'redux/actionCreators/jiraAPI'
import { getOfficeInfo as getOfficeInfoActtion } from 'redux/actionCreators/prosAPI'
import { getPaymentInfo as getPaymentInfoActtion } from 'redux/actionCreators/eContractingAPI'
import { jiraAPI } from 'api/jiraAPI'
import type { RootState } from 'redux/rootReducer'

import { getSimpleRequestHandler, putLoading } from '../utils'

import { getOfficeInfo } from './prosAPISagas'
import { getPaymentInfo } from './eContractingAPISaga'

const getProOfficeId = (state: RootState) => state.ticket.ticket?.fields.proId || ''
const getContractId = (state: RootState) => state.ticket.ticket?.fields.contractId || ''

const getTicketsList = getSimpleRequestHandler<typeof jiraAPI.getTicketsList, ReturnType<typeof getTicketsListAction>>(
  {
    actionLoading: Actions.GET_TICKETS_LIST_ASYNC,
    actionSuccess: Actions.GET_TICKETS_LIST_SUCCESS,
    actionFail: Actions.GET_TICKETS_LIST_FAIL,
    callApiFn: jiraAPI.getTicketsList,
  },
)

const getTicket = getSimpleRequestHandler<typeof jiraAPI.getTicket, ReturnType<typeof getTicketAction>>({
  actionLoading: Actions.GET_TICKET_ASYNC,
  actionSuccess: Actions.GET_TICKET_SUCCESS,
  actionFail: Actions.GET_TICKET_FAIL,
  callApiFn: jiraAPI.getTicket,
})

function* getTicketAndCorrespondingOfficeInfo({
  payload,
}: ReturnType<typeof getTicketAndCorrespondingOfficeInfoAction>) {
  yield putLoading({ type: Actions.GET_OFFICE_INFO_ASYNC })
  yield call(getTicket, getTicketAction(payload.params))
  const proOfficeId: string = yield select(getProOfficeId)
  const contractId: string = yield select(getContractId)
  yield all([
    call(getOfficeInfo, getOfficeInfoActtion({ proOfficeId })),
    call(getPaymentInfo, getPaymentInfoActtion({ contractId })),
  ])
}

function* respondToComplaintSolution(action: ReturnType<typeof respondToComplaintSolutionAction>) {
  const { ticketKey } = action.payload.params
  const respondToComplaint = getSimpleRequestHandler<
    typeof jiraAPI.respondToComplaintSolution,
    ReturnType<typeof respondToComplaintSolutionAction>
  >({
    actionLoading: Actions.RESPOND_TO_COMPLAINT_SOLUTION_ASYNC,
    actionSuccess: Actions.RESPOND_TO_COMPLAINT_SOLUTION_SUCCESS,
    actionFail: Actions.RESPOND_TO_COMPLAINT_SOLUTION_FAIL,
    callApiFn: jiraAPI.respondToComplaintSolution,
  })
  const { status } = yield call(respondToComplaint, action)
  if (status.success) {
    yield put(getTicketAction({ ticketKey }))
  }
}

function* respondToOfficeComplaint(action: ReturnType<typeof respondToOfficeComplaintAction>) {
  const { ticketKey } = action.payload.params
  const respondToOfficeComplaintSolution = getSimpleRequestHandler<
    typeof jiraAPI.respondToOfficeComplaint,
    ReturnType<typeof respondToOfficeComplaintAction>
  >({
    actionLoading: Actions.RESPOND_TO_OFFICE_COMPLAINT_ASYNC,
    actionSuccess: Actions.RESPOND_TO_OFFICE_COMPLAINT_SUCCESS,
    actionFail: Actions.RESPOND_TO_OFFICE_COMPLAINT_FAIL,
    callApiFn: jiraAPI.respondToOfficeComplaint,
  })
  const { status } = yield call(respondToOfficeComplaintSolution, action)
  if (status.success) {
    yield put(getTicketAction({ ticketKey }))
  }
}

const getSupportTicketsList = getSimpleRequestHandler<
  typeof jiraAPI.getSupportTicketsList,
  ReturnType<typeof getSupportTicketsListAction>
>({
  actionLoading: Actions.GET_SUPPORT_TICKETS_LIST_ASYNC,
  actionSuccess: Actions.GET_SUPPORT_TICKETS_LIST_SUCCESS,
  actionFail: Actions.GET_SUPPORT_TICKETS_LIST_FAIL,
  callApiFn: jiraAPI.getSupportTicketsList,
})

const createSupportTicket = getSimpleRequestHandler<
  typeof jiraAPI.createSupportTicket,
  ReturnType<typeof createSupportTicketAction>
>({
  actionLoading: Actions.CREATE_SUPPORT_TICKET_ASYNC,
  actionSuccess: Actions.CREATE_SUPPORT_TICKET_SUCCESS,
  actionFail: Actions.CREATE_SUPPORT_TICKET_FAIL,
  callApiFn: jiraAPI.createSupportTicket,
})

const createSupportTicketGuest = getSimpleRequestHandler<
  typeof jiraAPI.createSupportTicketGuest,
  ReturnType<typeof createSupportTicketGuestAction>
>({
  actionLoading: Actions.CREATE_SUPPORT_TICKET_GUEST_ASYNC,
  actionSuccess: Actions.CREATE_SUPPORT_TICKET_GUEST_SUCCESS,
  actionFail: Actions.CREATE_SUPPORT_TICKET_GUEST_FAIL,
  callApiFn: jiraAPI.createSupportTicketGuest,
})

const getSupportTicketDetails = getSimpleRequestHandler<
  typeof jiraAPI.getSupportTicketDetails,
  ReturnType<typeof getSupportTicketDetailsAction>
>({
  actionLoading: Actions.GET_SUPPORT_TICKET_DETAILS_ASYNC,
  actionSuccess: Actions.GET_SUPPORT_TICKET_DETAILS_SUCCESS,
  actionFail: Actions.GET_SUPPORT_TICKET_DETAILS_FAIL,
  callApiFn: jiraAPI.getSupportTicketDetails,
})

const updateSupportTicketDetails = getSimpleRequestHandler<
  typeof jiraAPI.updateSupportTicketDetails,
  ReturnType<typeof updateSupportTicketDetailsAction>
>({
  actionLoading: Actions.UPDATE_SUPPORT_TICKET_DETAILS_ASYNC,
  actionSuccess: Actions.UPDATE_SUPPORT_TICKET_DETAILS_SUCCESS,
  actionFail: Actions.UPDATE_SUPPORT_TICKET_DETAILS_FAIL,
  callApiFn: jiraAPI.updateSupportTicketDetails,
})

const createComplaintTicket = getSimpleRequestHandler<
  typeof jiraAPI.createComplaintTicket,
  ReturnType<typeof createComplaintTicketAction>
>({
  actionLoading: Actions.CREATE_COMPLAINT_TICKET_ASYNC,
  actionSuccess: Actions.CREATE_COMPLAINT_TICKET_SUCCESS,
  actionFail: Actions.CREATE_COMPLAINT_TICKET_FAIL,
  callApiFn: jiraAPI.createComplaintTicket,
})

const getComplaintRatings = getSimpleRequestHandler<
  typeof jiraAPI.getComplaintRatings,
  ReturnType<typeof getComplaintRatingsAction>
>({
  actionLoading: Actions.GET_COMPLAINT_RATINGS_ASYNC,
  actionSuccess: Actions.GET_COMPLAINT_RATINGS_SUCCESS,
  actionFail: Actions.GET_COMPLAINT_RATINGS_FAIL,
  callApiFn: jiraAPI.getComplaintRatings,
})

const getComplaintRatingsV2 = getSimpleRequestHandler<
  typeof jiraAPI.getComplaintRatingsV2,
  ReturnType<typeof getComplaintRatingsV2Action>
>({
  actionLoading: Actions.GET_COMPLAINT_RATINGS_V2_ASYNC,
  actionSuccess: Actions.GET_COMPLAINT_RATINGS_V2_SUCCESS,
  actionFail: Actions.GET_COMPLAINT_RATINGS_V2_FAIL,
  callApiFn: jiraAPI.getComplaintRatingsV2,
})

const getSurveyQuestions = getSimpleRequestHandler<
  typeof jiraAPI.getSurveyQuestions,
  ReturnType<typeof getSurveyQuestionsAction>
>({
  actionLoading: Actions.GET_SURVEY_QUESTIONS_ASYNC,
  actionSuccess: Actions.GET_SURVEY_QUESTIONS_SUCCESS,
  actionFail: Actions.GET_SURVEY_QUESTIONS_FAIL,
  callApiFn: jiraAPI.getSurveyQuestions,
})

const postSurveyQuestions = getSimpleRequestHandler<
  typeof jiraAPI.postSurveyQuestions,
  ReturnType<typeof postSurveyQuestionsAction>
>({
  actionLoading: Actions.POST_SURVEY_QUESTIONS_ASYNC,
  actionSuccess: Actions.POST_SURVEY_QUESTIONS_SUCCESS,
  actionFail: Actions.POST_SURVEY_QUESTIONS_FAIL,
  callApiFn: jiraAPI.postSurveyQuestions,
})

function* addMissingAttachment(action: ReturnType<typeof addMissingAttachmentAction>) {
  const { ticketID } = action.payload.params
  const addAttachment = getSimpleRequestHandler<
    typeof jiraAPI.addMissingAttachment,
    ReturnType<typeof addMissingAttachmentAction>
  >({
    actionLoading: Actions.ADD_COMPLAINT_MISSING_ATTACHMENT_ASYNC,
    actionSuccess: Actions.ADD_COMPLAINT_MISSING_ATTACHMENT_SUCCESS,
    actionFail: Actions.ADD_COMPLAINT_MISSING_ATTACHMENT_FAIL,
    callApiFn: jiraAPI.addMissingAttachment,
  })
  const { status } = yield call(addAttachment, action)
  if (status.success) {
    yield put(getTicketAction({ ticketKey: ticketID }))
  }
}

const complaintInquiry = getSimpleRequestHandler<
  typeof jiraAPI.complaintInquiry,
  ReturnType<typeof complaintInquiryAction>
>({
  actionLoading: Actions.COMPLAINT_INQUIRY_ASYNC,
  actionSuccess: Actions.COMPLAINT_INQUIRY_SUCCESS,
  actionFail: Actions.COMPLAINT_INQUIRY_FAIL,
  callApiFn: jiraAPI.complaintInquiry,
})

const addFeedback = getSimpleRequestHandler<typeof jiraAPI.addFeedback, ReturnType<typeof addFeedbackAction>>({
  actionLoading: Actions.ADD_FEEDBACK_ASYNC,
  actionSuccess: Actions.ADD_FEEDBACK_SUCCESS,
  actionFail: Actions.ADD_FEEDBACK_FAIL,
  callApiFn: jiraAPI.addFeedback,
})

const getProPerformance = getSimpleRequestHandler<
  typeof jiraAPI.getProPerformance,
  ReturnType<typeof getProPerformanceAction>
>({
  actionLoading: Actions.GET_PRO_PERFORMANCE_ASYNC,
  actionSuccess: Actions.GET_PRO_PERFORMANCE_SUCCESS,
  actionFail: Actions.GET_PRO_PERFORMANCE_FAIL,
  callApiFn: jiraAPI.getProPerformance,
})

const getProPerformanceOverall = getSimpleRequestHandler<
  typeof jiraAPI.getProPerformanceOverall,
  ReturnType<typeof getProPerformanceOverallAction>
>({
  actionLoading: Actions.GET_PRO_PERFORMANCE_OVERALL_ASYNC,
  actionSuccess: Actions.GET_PRO_PERFORMANCE_OVERALL_SUCCESS,
  actionFail: Actions.GET_PRO_PERFORMANCE_OVERALL_FAIL,
  callApiFn: jiraAPI.getProPerformanceOverall,
})

export default [
  takeLatest(Actions.GET_TICKETS_LIST, getTicketsList),
  takeLatest(Actions.GET_TICKET, getTicket),
  takeLatest(Actions.GET_TICKET_AND_CORRESPONDING_OFFICE_INFO, getTicketAndCorrespondingOfficeInfo),
  takeLatest(Actions.RESPOND_TO_COMPLAINT_SOLUTION, respondToComplaintSolution),
  takeLatest(Actions.RESPOND_TO_OFFICE_COMPLAINT, respondToOfficeComplaint),
  takeLatest(Actions.GET_SUPPORT_TICKETS_LIST, getSupportTicketsList),
  takeLatest(Actions.CREATE_SUPPORT_TICKET, createSupportTicket),
  takeLatest(Actions.CREATE_SUPPORT_TICKET_GUEST, createSupportTicketGuest),
  takeLatest(Actions.CREATE_COMPLAINT_TICKET, createComplaintTicket),
  takeLatest(Actions.GET_SUPPORT_TICKET_DETAILS, getSupportTicketDetails),
  takeLatest(Actions.UPDATE_SUPPORT_TICKET_DETAILS, updateSupportTicketDetails),
  takeLatest(Actions.GET_COMPLAINT_RATINGS, getComplaintRatings),
  takeLatest(Actions.GET_SURVEY_QUESTIONS, getSurveyQuestions),
  takeLatest(Actions.POST_SURVEY_QUESTIONS, postSurveyQuestions),
  takeLatest(Actions.GET_COMPLAINT_RATINGS_V2, getComplaintRatingsV2),
  takeLatest(Actions.ADD_COMPLAINT_MISSING_ATTACHMENT, addMissingAttachment),
  takeLatest(Actions.COMPLAINT_INQUIRY, complaintInquiry),
  takeLatest(Actions.ADD_FEEDBACK, addFeedback),
  takeLatest(Actions.GET_PRO_PERFORMANCE, getProPerformance),
  takeLatest(Actions.GET_PRO_PERFORMANCE_OVERALL, getProPerformanceOverall),
]
