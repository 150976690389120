import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getNoticesList as getNoticesListAction,
  getBanksList as getBanksListAction,
  checkNoticeAvailability as checkNoticeAvailabilityAction,
  createNotice as createNoticeAction,
  getNotice as getNoticeAction,
  payNotice as payNoticeAction,
} from 'redux/actionCreators/evisaAPI/noticesAPI'
import { noticesAPI } from 'api/evisaAPI/noticesAPI'

import { getSimpleRequestHandler } from '../../utils'

export const getNoticesList = getSimpleRequestHandler<
  typeof noticesAPI.getNoticesList,
  ReturnType<typeof getNoticesListAction>
>({
  actionLoading: Actions.GET_NOTICES_LIST_ASYNC,
  actionSuccess: Actions.GET_NOTICES_LIST_SUCCESS,
  actionFail: Actions.GET_NOTICES_LIST_FAIL,
  callApiFn: noticesAPI.getNoticesList,
})

export const createNotice = getSimpleRequestHandler<
  typeof noticesAPI.createNotice,
  ReturnType<typeof createNoticeAction>
>({
  actionLoading: Actions.CREATE_NOTICE_ASYNC,
  actionSuccess: Actions.CREATE_NOTICE_SUCCESS,
  actionFail: Actions.CREATE_NOTICE_FAIL,
  callApiFn: noticesAPI.createNotice,
})

export const getBanksList = getSimpleRequestHandler<
  typeof noticesAPI.getBanksList,
  ReturnType<typeof getBanksListAction>
>({
  actionLoading: Actions.GET_BANKS_LIST_ASYNC,
  actionSuccess: Actions.GET_BANKS_LIST_SUCCESS,
  actionFail: Actions.GET_BANKS_LIST_FAIL,
  callApiFn: noticesAPI.getBanksList,
})

export const getAvailableOccupationsList = getSimpleRequestHandler<
  typeof noticesAPI.getAvailableOccupationsList,
  ReturnType<typeof checkNoticeAvailabilityAction>
>({
  actionLoading: Actions.CHECK_NOTICE_AVAILABILITY_ASYNC,
  actionSuccess: Actions.CHECK_NOTICE_AVAILABILITY_SUCCESS,
  actionFail: Actions.CHECK_NOTICE_AVAILABILITY_FAIL,
  callApiFn: noticesAPI.getAvailableOccupationsList,
})

export const getNotice = getSimpleRequestHandler<typeof noticesAPI.getNotice, ReturnType<typeof getNoticeAction>>({
  actionLoading: Actions.GET_NOTICE_ASYNC,
  actionSuccess: Actions.GET_NOTICE_SUCCESS,
  actionFail: Actions.GET_NOTICE_FAIL,
  callApiFn: noticesAPI.getNotice,
})

export const payNotice = getSimpleRequestHandler<typeof noticesAPI.payNotice, ReturnType<typeof payNoticeAction>>({
  actionLoading: Actions.PAY_NOTICE_ASYNC,
  actionSuccess: Actions.PAY_NOTICE_SUCCESS,
  actionFail: Actions.PAY_NOTICE_FAIL,
  callApiFn: noticesAPI.payNotice,
})

export default [
  takeLatest(Actions.GET_NOTICES_LIST, getNoticesList),
  takeLatest(Actions.CREATE_NOTICE, createNotice),
  takeLatest(Actions.GET_BANKS_LIST, getBanksList),
  takeLatest(Actions.CHECK_NOTICE_AVAILABILITY, getAvailableOccupationsList),
  takeLatest(Actions.GET_NOTICE, getNotice),
  takeLatest(Actions.PAY_NOTICE, payNotice),
]
