import { Status, CallAPIAction } from 'api/types'
import type { GetNationalitiesResponse } from 'api/evisaAPI/evisaAPI'
import type { Nationality } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import { formatNationalityFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

const convertResponseToState = (resp: GetNationalitiesResponse) =>
  resp.nationalities.reduce(
    (acc, nationalityFromBE) => ({
      ...acc,
      [nationalityFromBE.id]: formatNationalityFromBE(nationalityFromBE),
    }),
    {},
  )

type State = {
  nationalities: StringRecord<Nationality>
} & Status

const initialState = {
  nationalities: {},
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_NATIONALITIES_ASYNC,
    Actions.GET_NATIONALITIES_SUCCESS,
    Actions.GET_NATIONALITIES_FAIL,
    GetNationalitiesResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_NATIONALITIES_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_NATIONALITIES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        nationalities: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_NATIONALITIES_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
