import type { CallAPIAction, Status } from 'api/types'
import { GetContractRequestsListResponse } from 'api/eContractingAPI'
import type { ContractRequest } from 'common/types/transformedRespTypes'
import { getContractRequestsList } from 'redux/actionCreators/eContractingAPI'
import Actions from 'redux/actions'
import { ContractRequestStatus } from 'common/types/commonTypes'

import { convertResponseToState as convertToContract } from './contractRequestInfo'

type State = {
  contractRequestsParams: {
    page: number
    status: ContractRequestStatus | string
  }
  data: Array<ContractRequest>
  lastPage: number
} & Status

const convertResponseToState = (resp: GetContractRequestsListResponse) => ({
  data: resp.data.map((contractRequests) => convertToContract({ contractRequests })),
  lastPage: resp.last_page,
})

const initialState: State = {
  data: [],
  contractRequestsParams: {
    page: 0,
    status: '',
  },
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_REQUESTS_LIST_ASYNC,
    Actions.GET_CONTRACT_REQUESTS_LIST_SUCCESS,
    Actions.GET_CONTRACT_REQUESTS_LIST_FAIL,
    GetContractRequestsListResponse,
    {},
    {},
    ReturnType<typeof getContractRequestsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_REQUESTS_LIST_ASYNC: {
      const {
        params: { status = state.contractRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        contractRequestsParams: {
          page: page,
          status: status,
        },
        lastPage: status !== state.contractRequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_CONTRACT_REQUESTS_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_CONTRACT_REQUESTS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
