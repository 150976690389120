import type { CancelContractResponseFail, ContractCancelationReasons } from 'api/eContractingAPI'
import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'

type State = {
  errorMessage?: Array<ContractCancelationReasons>
} & Status

const initialState: State = {
  errorMessage: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CANCEL_CONTRACT_ASYNC,
    Actions.CANCEL_CONTRACT_SUCCESS,
    Actions.CANCEL_CONTRACT_FAIL,
    {},
    CancelContractResponseFail
  >,
): State => {
  switch (action.type) {
    case Actions.CANCEL_CONTRACT_ASYNC:
    case Actions.CANCEL_CONTRACT_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CANCEL_CONTRACT_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: [
          action.payload?.error && [action.payload?.error],
          action.payload?.reason,
          action.payload?.contract && action.payload?.contract.length ? action.payload?.contract : undefined,
        ].find((el) => el),
      }
    }
    default:
      return state
  }
}
