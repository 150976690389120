import { takeLatest } from 'redux-saga/effects'

import {
  getAuthContractList as getAuthContractListAction,
  getAuthRequestDetails as getAuthRequestDetailsAction,
  getAuthContractText as getAuthContractTextAction,
  acceptAuthRequest as acceptAuthRequestAction,
  rejectAuthRequest as rejectAuthRequestAction,
  getRejectionReasonList as getRejectionReasonListAction,
  getAuthAppendixList as getAuthAppendixListAction,
  getAuthAppendixDetails as getAuthAppendixDetailsAction,
  approveAuthAppendix as approveAuthAppendixAction,
  rejectAuthAppendix as rejectAuthAppendixAction,
  getAuthContractPdf as getAuthContractPdfAction,
  getPendingAppendix as getPendingAppendixAction,
} from 'redux/actionCreators/laborerPortalAPI/authContract'
import laborerPortalAPI from 'api/laborerPortalAPI/authContract'
import Actions from 'redux/actions'

import { getSimpleRequestHandler } from '../../utils'

const getAuthContractList = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthContractList,
  ReturnType<typeof getAuthContractListAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_REQUEST_LIST_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_REQUEST_LIST_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_REQUEST_LIST_FAIL,
  callApiFn: laborerPortalAPI.getAuthContractList,
})

const getAuthRequestDetails = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthRequestDetails,
  ReturnType<typeof getAuthRequestDetailsAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_REQUEST_DETAILS_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_REQUEST_DETAILS_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_REQUEST_DETAILS_FAIL,
  callApiFn: laborerPortalAPI.getAuthRequestDetails,
})

const getAuthContractText = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthContractText,
  ReturnType<typeof getAuthContractTextAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_CONTRACT_TEXT_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_CONTRACT_TEXT_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_CONTRACT_TEXT_FAIL,
  callApiFn: laborerPortalAPI.getAuthContractText,
})

const acceptAuthRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.acceptAuthRequest,
  ReturnType<typeof acceptAuthRequestAction>
>({
  actionLoading: Actions.ACCEPT_LABORER_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.ACCEPT_LABORER_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.ACCEPT_LABORER_AUTH_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.acceptAuthRequest,
})

const rejectAuthRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectAuthRequest,
  ReturnType<typeof rejectAuthRequestAction>
>({
  actionLoading: Actions.REJECT_LABORER_AUTH_REQUEST_ASYNC,
  actionSuccess: Actions.REJECT_LABORER_AUTH_REQUEST_SUCCESS,
  actionFail: Actions.REJECT_LABORER_AUTH_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.rejectAuthRequest,
})

const getRejectionReasonList = getSimpleRequestHandler<
  typeof laborerPortalAPI.getRejectionReasonList,
  ReturnType<typeof getRejectionReasonListAction>
>({
  actionLoading: Actions.GET_AUTH_REJECTION_REASONS_LIST_ASYNC,
  actionSuccess: Actions.GET_AUTH_REJECTION_REASONS_LIST_SUCCESS,
  actionFail: Actions.GET_AUTH_REJECTION_REASONS_LIST_FAIL,
  callApiFn: laborerPortalAPI.getRejectionReasonList,
})

const getAuthAppendixList = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthAppendixList,
  ReturnType<typeof getAuthAppendixListAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_APPENDIX_LIST_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_APPENDIX_LIST_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_APPENDIX_LIST_FAIL,
  callApiFn: laborerPortalAPI.getAuthAppendixList,
})

const getAuthAppendixDetails = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthAppendixDetails,
  ReturnType<typeof getAuthAppendixDetailsAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_FAIL,
  callApiFn: laborerPortalAPI.getAuthAppendixDetails,
})

const approveAuthAppendix = getSimpleRequestHandler<
  typeof laborerPortalAPI.approveAuthAppendix,
  ReturnType<typeof approveAuthAppendixAction>
>({
  actionLoading: Actions.APPROVE_LABORER_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.APPROVE_LABORER_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.APPROVE_LABORER_AUTH_APPENDIX_FAIL,
  callApiFn: laborerPortalAPI.approveAuthAppendix,
})

const rejectAuthAppendix = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectAuthAppendix,
  ReturnType<typeof rejectAuthAppendixAction>
>({
  actionLoading: Actions.REJECT_LABORER_AUTH_APPENDIX_ASYNC,
  actionSuccess: Actions.REJECT_LABORER_AUTH_APPENDIX_SUCCESS,
  actionFail: Actions.REJECT_LABORER_AUTH_APPENDIX_FAIL,
  callApiFn: laborerPortalAPI.rejectAuthAppendix,
})

const getAuthContractPdf = getSimpleRequestHandler<
  typeof laborerPortalAPI.getAuthContractPdf,
  ReturnType<typeof getAuthContractPdfAction>
>({
  actionLoading: Actions.GET_LABORER_AUTH_CONTRACT_PDF_ASYNC,
  actionSuccess: Actions.GET_LABORER_AUTH_CONTRACT_PDF_SUCCESS,
  actionFail: Actions.GET_LABORER_AUTH_CONTRACT_PDF_FAIL,
  callApiFn: laborerPortalAPI.getAuthContractPdf,
})

const getPendingAppendix = getSimpleRequestHandler<
  typeof laborerPortalAPI.getPendingAppendix,
  ReturnType<typeof getPendingAppendixAction>
>({
  actionLoading: Actions.GET_PENDING_LABORER_APPENDIX_ASYNC,
  actionSuccess: Actions.GET_PENDING_LABORER_APPENDIX_SUCCESS,
  actionFail: Actions.GET_PENDING_LABORER_APPENDIX_FAIL,
  callApiFn: laborerPortalAPI.getPendingAppendix,
})

export default [
  takeLatest(Actions.GET_LABORER_AUTH_REQUEST_LIST, getAuthContractList),
  takeLatest(Actions.GET_LABORER_AUTH_REQUEST_DETAILS, getAuthRequestDetails),
  takeLatest(Actions.GET_LABORER_AUTH_CONTRACT_TEXT, getAuthContractText),
  takeLatest(Actions.ACCEPT_LABORER_AUTH_REQUEST, acceptAuthRequest),
  takeLatest(Actions.REJECT_LABORER_AUTH_REQUEST, rejectAuthRequest),
  takeLatest(Actions.GET_AUTH_REJECTION_REASONS_LIST, getRejectionReasonList),
  takeLatest(Actions.GET_LABORER_AUTH_APPENDIX_LIST, getAuthAppendixList),
  takeLatest(Actions.GET_LABORER_AUTH_APPENDIX_DETAILS, getAuthAppendixDetails),
  takeLatest(Actions.APPROVE_LABORER_AUTH_APPENDIX, approveAuthAppendix),
  takeLatest(Actions.REJECT_LABORER_AUTH_APPENDIX, rejectAuthAppendix),
  takeLatest(Actions.GET_LABORER_AUTH_CONTRACT_PDF, getAuthContractPdf),
  takeLatest(Actions.GET_PENDING_LABORER_APPENDIX, getPendingAppendix),
]
