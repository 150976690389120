import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { AcceptOfferRequestResponse } from 'api/eContractingAPI'

type State = {
  contractId: number
} & Status

const initialState = {
  contractId: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_OFFER_REQUEST_ASYNC,
    Actions.ACCEPT_OFFER_REQUEST_SUCCESS,
    Actions.ACCEPT_OFFER_REQUEST_FAIL,
    AcceptOfferRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_OFFER_REQUEST_ASYNC:
    case Actions.ACCEPT_OFFER_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        contractId: 0,
      }
    }
    case Actions.ACCEPT_OFFER_REQUEST_SUCCESS: {
      return {
        ...state,
        contractId: action.payload?.offer.contract?.id || 0,
        ...action.status,
      }
    }
    default:
      return state
  }
}
