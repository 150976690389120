import type { CallAPIAction, Status } from 'api/types'
import type { GetMarketOffersResponse } from 'api/prosAPI'
import { getMarketOffers } from 'redux/actionCreators/prosAPI'
import type { Offer, OfferFromBE } from 'common/types/transformedRespTypes'
import { convertRating } from 'common/utils/common'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

import { convertServiceAddonsResponse } from './proServiceAddons'

type State = {
  offers: StringRecord<Offer>
  allOffersIds: Array<number>
  lastPage: number
  offersCount: number
} & Status

const convertResponseToState = (resp: GetMarketOffersResponse) => {
  const [offers, allOffersIds] = resp.data.reduce<[StringRecord<Offer>, Array<number>]>(
    ([offersAcc, idsAcc], offerFromBE) => [
      {
        ...offersAcc,
        [offerFromBE.id]: {
          id: offerFromBE.id,
          cities: [
            ...(offerFromBE.pro.transport_services.findIndex(
              (city: OfferFromBE['pro']['transport_services'][0]) => city.id === offerFromBE.pro.address.city_id,
            ) !== -1
              ? []
              : [
                  {
                    id: null,
                    proId: offerFromBE.pro.id,
                    cityId: offerFromBE.pro.address.city_id,
                    transportationCost: 0,
                  },
                ]),
            ...offerFromBE.pro.transport_services.map((city) => ({
              id: city.id,
              proId: city.pro_id,
              cityId: city.city_id,
              transportationCost: city.transportation_cost,
            })),
          ],
          fullName: offerFromBE.pro.name,
          nationalityId: offerFromBE.religion_id,
          occupationId: offerFromBE.occupation_id,
          religionId: offerFromBE.religion_id,
          price: offerFromBE.price,
          acceptanceRate: Math.round(offerFromBE.pro.acceptance_rate),
          duration: offerFromBE.recruitment_period,
          rating: convertRating(offerFromBE.pro.rating),
          logoUrl: offerFromBE.pro.address.logo_url,
          phoneNumber: offerFromBE.pro.address.phone_number,
          mobileNumber: offerFromBE.pro.address.mobile_number,
          email: offerFromBE.pro.address.email,
          location: offerFromBE.pro.address.location,
          address: offerFromBE.pro.address.address,
          proId: offerFromBE.pro.id,
          serviceAddons: offerFromBE.service_addons
            ? convertServiceAddonsResponse(offerFromBE.service_addons).serviceAddons
            : null,
        },
      },
      idsAcc.concat(offerFromBE.id),
    ],
    [{}, []],
  )
  return {
    offers,
    allOffersIds,
    lastPage: resp.meta.last_page,
    offersCount: resp.meta.total,
  }
}

const initialState = {
  offers: {},
  allOffersIds: [],
  lastPage: 0,
  offersCount: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_MARKET_OFFERS_ASYNC,
    Actions.GET_MARKET_OFFERS_SUCCESS,
    Actions.GET_MARKET_OFFERS_FAIL,
    GetMarketOffersResponse,
    {},
    {},
    ReturnType<typeof getMarketOffers>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_MARKET_OFFERS_ASYNC: {
      const { params } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        lastPage: params.clearPagesCount ? 0 : state.lastPage,
      }
    }
    case Actions.GET_MARKET_OFFERS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_MARKET_OFFERS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
