import { BillFromBE, GetBillsResponse } from 'api/evisaAPI/evisaAPI'
import { CallAPIAction, Status } from 'api/types'
import { BillRequestType, PaymentMethods } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type Bill = {
  accountNumber: string
  amount: string
  billNumber: string
  createdAt: string
  expiryDate: string
  id: string
  paymentMethod: PaymentMethods
  requestType: BillRequestType
}

type State = {
  bills: Array<Bill>
} & Status

const initialState: State = {
  bills: [],
  isLoading: false,
  success: false,
  fail: false,
}

const convertToState = (item: BillFromBE): Bill => ({
  id: item.id,
  billNumber: item.bill_number,
  createdAt: item.created_at,
  expiryDate: item.expiry_date,
  accountNumber: item.account_number,
  amount: item.amount,
  // TODO later should be renamed on the backend
  paymentMethod: (item.payment_method === 'SADAD' ? PaymentMethods.SADADBILL : item.payment_method) as PaymentMethods,
  requestType: item.request_type,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_REFUNDABLE_BILLS_ASYNC,
    Actions.GET_REFUNDABLE_BILLS_SUCCESS,
    Actions.GET_REFUNDABLE_BILLS_FAIL,
    GetBillsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_REFUNDABLE_BILLS_ASYNC:
    case Actions.GET_REFUNDABLE_BILLS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_REFUNDABLE_BILLS_SUCCESS:
      return {
        bills: action.payload.map(convertToState),
        ...action.status,
      }
    default:
      return state
  }
}
