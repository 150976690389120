import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { SubmitAgriculturalFileResponse } from 'api/evisaAPI/farmerVisa'
import { GeneralFailResponse, FarmerAgriculturalRequestErrorMessages } from 'common/types/commonTypes'

type State = {
  fileNumber?: string
  errorMessage: FarmerAgriculturalRequestErrorMessages | string
} & Status

const initialState = {
  fileNumber: undefined,
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_AGRICULTURAL_FILE_ASYNC,
    Actions.SUBMIT_AGRICULTURAL_FILE_SUCCESS,
    Actions.SUBMIT_AGRICULTURAL_FILE_FAIL,
    SubmitAgriculturalFileResponse,
    GeneralFailResponse<FarmerAgriculturalRequestErrorMessages>
  >,
): State => {
  switch (action.type) {
    case Actions.SUBMIT_AGRICULTURAL_FILE_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.SUBMIT_AGRICULTURAL_FILE_SUCCESS: {
      return {
        ...state,
        ...action.status,
        fileNumber: action.payload.file_number,
      }
    }
    case Actions.SUBMIT_AGRICULTURAL_FILE_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || FarmerAgriculturalRequestErrorMessages.GENERAL_ERROR,
      }
    }
    default:
      return state
  }
}
