import { FailResponse } from 'api/evisaAPI/dlAPI'
import { VerifyAgentResponse } from 'api/evisaAPI/evisaAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = { errorMessage: string } & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_AGENT_ASYNC,
    Actions.CREATE_AGENT_SUCCESS,
    Actions.CREATE_AGENT_FAIL,
    VerifyAgentResponse,
    FailResponse
  >,
) => {
  switch (action.type) {
    case Actions.CREATE_AGENT_ASYNC:
    case Actions.CREATE_AGENT_SUCCESS:
      return {
        ...state,
        ...action.status,
      }
    case Actions.CREATE_AGENT_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    default:
      return state
  }
}
