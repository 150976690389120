import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { StringRecord } from 'common/types/commonTypes'
import { AuthContractLaborer } from 'common/utils/convertResponse/convertAuthApiResponse'

type State = {
  laborById: StringRecord<AuthContractLaborer>
  errorMessage: string
} & Status

const initialState = {
  laborById: {},
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC,
    Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS,
    Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL,
    {
      laborById: StringRecord<AuthContractLaborer>
    },
    {
      errorMessage: string
    }
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC: {
      return {
        ...state,
        laborById: {},
        errorMessage: '',
        ...action.status,
      }
    }
    case Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS: {
      return {
        ...state,
        laborById: action.payload.laborById,
        errorMessage: '',
        ...action.status,
      }
    }
    case Actions.GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL: {
      return {
        ...state,
        laborById: {},
        errorMessage: action.payload?.errorMessage || '',
        ...action.status,
      }
    }
    default:
      return state
  }
}
