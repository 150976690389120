import { setDisabilityVisaFlowData } from 'redux/actionCreators/disabilityVisaFlow'
import Actions from 'redux/actions'

export type State = {
  acknowledge: boolean
  gender: string
  occupationId: number
  nationalityId: number
  visaIssuePlaceId: number
}

const initialState: State = {
  acknowledge: false,
  gender: '',
  occupationId: 0,
  nationalityId: 0,
  visaIssuePlaceId: 0,
}

export default (state: State = initialState, action: ReturnType<typeof setDisabilityVisaFlowData>) => {
  switch (action.type) {
    case Actions.SET_DISABILITY_VISA_FLOW_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
