import { i18n as i18nType } from 'i18next'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { Lang } from 'common/types/commonTypes'
import { setLanguage } from 'redux/actionCreators/language'
import { RootState } from 'redux/rootReducer'

export const useLanguageDetector = (i18n: i18nType) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector<RootState, RootState['auth']>((state) => state.auth)
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const dirtyLang = urlParams.get('lang') || localStorage.getItem('language') || ''
    const currLang = dirtyLang in Lang ? (dirtyLang as Lang) : Lang.ar
    urlParams.delete('lang')
    history.replace({
      search: urlParams.toString(),
    })
    dispatch(setLanguage(currLang))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loggedIn])

  const lang = useSelector<RootState, RootState['lang']>((state) => state.lang)

  useEffect(() => {
    if (lang === Lang.cimode) {
      return
    }
    localStorage.setItem('language', lang)
    i18n.changeLanguage(lang)
    document.documentElement.setAttribute('lang', lang)
    document.dir = i18n.dir(lang)
    if (lang === Lang.en || lang === Lang.ar) {
      localStorage.setItem('prevLang', lang)
    }
  }, [i18n, lang])
}
