import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { ECONTRACTING_API_URL } from 'common/envConstants'
import {
  ServiceTypes,
  UserStatus,
  ProsStatus,
  ContractRequestStatus,
  ContractStatus,
  BillStatusKey,
  PaymentMethods,
  InstallmentKeysType,
  FeesType,
  RateType,
  Platform,
  InsuranceStatuses,
  ContractCancelRequest,
} from 'common/types/commonTypes'
import { PayfortBill } from 'common/types/transformedRespTypes'
import authManager from 'common/utils/auth/authManager'
import { TicketAttachment } from 'common/utils/convertResponse/convertComplaintResponse'
import { ENABLE_NEW_PROFILE_DESIGN } from 'common/envConstants'

import {
  callAPI,
  eContractingAPIWithCredentials,
  eContractingAPI as eContractingPublicAPI,
  refundAPIWithCredentials,
} from './axios'

const INSURANCE_EXPAND = 'offers.contract.insurance'
const CONTRACT_EXTENSION_EXPAND = 'offers.contract.extensionRequest'
const BILL_EXPAND = 'offers.contract.bill.settings'
const ZATCA_BILL_EXPAND = 'offers.contract.bill.ZATCAInvoices'
const BILL_SETTINGS_EXPAND = 'offers.contract.bill.vatSetting'
const CONTRACT_CANCELLATION_EXPAND = 'offers.contract.contractCancellationRequests'
const EXTRA_ADDONS_EXPAND = 'offers.extraAddons'

export type MaarofaSubmitGeneralParams = {
  requestType: ServiceTypes.DIRECT_CONTRACT
  workerName: string
  workerNumber: string
  workerPassportNumber: string
  workerPassportExpiryDate: string
  workerPassportImage: File
  arrivalCityId: number
  offices?: Array<number>
  visaFollow: number
  visaId: number
  borderNumber: number
  ageRange: number
  workerSalary: string
}

export type MaarofaSubmitUserInfoParams = {
  familySize: string
  houseTypeId: string
  domesticLaborers: string
  averageIncome: string
  coordinates: string
  username: string
  address: string
  job: string
  insuranceEnabled: boolean
}

export type MaarofaSubmitParams = MaarofaSubmitGeneralParams & MaarofaSubmitUserInfoParams

const toFormData = (body: MaarofaSubmitParams): FormData => {
  const form = new FormData()
  form.append('request_type', body.requestType)
  form.append('followup_number', body.visaFollow.toString())
  form.append('visa', body.visaId.toString())
  form.append('border_number', body.borderNumber.toString())
  form.append('family_size', body.familySize)
  form.append('house_type_id', body.houseTypeId)
  form.append('domestic_laborers', body.domesticLaborers)
  form.append('average_income', body.averageIncome)
  !ENABLE_NEW_PROFILE_DESIGN && form.append('lat_lng', body.coordinates)
  form.append('city_id', body.arrivalCityId.toString())
  form.append('delivery_city_id', body.arrivalCityId.toString())
  body.offices?.forEach((office) => form.append('office[]', office.toString()))
  form.append('worker_name', body.workerName)
  form.append('worker_number', body.workerNumber)
  form.append('worker_passport', body.workerPassportNumber)
  form.append('worker_passport_image', body.workerPassportImage)
  form.append('worker_passport_expiry_date', body.workerPassportExpiryDate)
  form.append('id_number', body.username)
  form.append('address', body.address)
  form.append('work_type', body.job)
  body.insuranceEnabled && form.append('worker_salary', body.workerSalary)
  body.insuranceEnabled && form.append('age_range', body.ageRange.toString())
  // we should always send this parametr
  form.append('is_unified', '1')

  form.set('platform', Platform.WEB)
  return form
}

export type MaarofaSubmitResponse = {
  id: number
}

export type MaarofaPresubmitFailResponse = {
  visa?: Array<string>
  worker_passport?: Array<string>
}

export type MaarofaSubmitFailResponse = MaarofaPresubmitFailResponse

const maarofaSubmit = (body: MaarofaSubmitParams, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post<MaarofaSubmitResponse>('/api/requests', toFormData(body), config)

export type MaarofaPresubmitParams = MaarofaSubmitParams
export type MaarofaPresubmitResponse = {}

const maarofaPresubmit = (body: MaarofaSubmitParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post<MaarofaPresubmitResponse>('/api/requests/presubmit', toFormData(body), config)

export type GetContractRequestsInfoParams = {
  requestId: number
}

export type ContractCancellationRequestsFromBE = Array<{
  id: number
  contract_id: number
  status: ContractCancelRequest
  reason: string
  created_at: string
}>

export type ContractInsuranceFromBE = {
  amount: number
  insurance_company_id: number
  insurance_company_name_ar: string
  insurance_company_name_en: string
  policy_issue_date: string
  policy_effective_date: string
  status: InsuranceStatuses
  service_provider: number
  insurance_type: number
  policy_number: number
  policy_expiry_date: string
}

export type GetContractRequestsInfoResponse = {
  contractRequests: {
    visa_id: number
    request_type: ServiceTypes
    age_range_id: string | null
    religion_id: string
    city_id: number
    work_experience: string
    preferences: string | null
    worker_name: string | null
    worker_mobile: string | null
    worker_passport: string | null
    worker_passport_expiry_date: string | null
    id: number
    followup_number: number
    created_at: string
    cancellation_date: string | null
    new_status: ContractRequestStatus
    is_unified_with_initial_visa: boolean
    border_number: number
    offers: Array<{
      id: number
      duration: number
      is_cancelled: number
      cvs?: Array<{
        id: number
        order: number
        candidate_name: string | null
        cv_path: string
        offer_id: string
        portfolio_path: string
        salary: number
      }>
      contract: {
        id: number
        created_at: string
        new_status: ContractStatus
        id_number: number
        visa_id: number
        canceled_by: string | null
        cancelled_by_id: number | null
        is_direct_contract: number
        sent_to_sabb: number
        pro_id: number
        settings: { VAT?: string } | null
        bill: {
          z_a_t_c_a_invoices?: Array<{ id: number; supplier_type: string }>
          id: number
          created_at: string
          status: BillStatusKey
          expiry_date: string
          paid_date: string | null
          amount: string
          sadad_bill_number: string | null
          payment_method: PaymentMethods
          installment_plan_code: string | null
          vat_setting: {
            value: string
          }
          settings: Array<{
            id: number
            key: InstallmentKeysType | string
            value: string
          }>
        } | null
        contract_cancellation_requests?: ContractCancellationRequestsFromBE | null
        extension_request?: {
          // won't be available inside contract request list endpoint
          created_at: string
          is_accepted: number | null
        } | null
        insurance?: ContractInsuranceFromBE[] | ContractInsuranceFromBE | null
      } | null
      contract_text: {
        id: number
        offer_id: number
        text: string
      } | null
      user_status: UserStatus | null
      pro_status: ProsStatus | null
      pro_response_time: string | null
      pro?: {
        id: number
        name: string
        reviews_count: number
        rating: string
        acceptance_rate: string
        address: {
          city_id: number
          mobile_number: string
          phone_number: string
          email: string
          address: string
          logo_url: string
          location: string
        }
        type: ServiceTypes
        latest_license: {
          license_number: string
        }
      }
      worker_salary: number | null
      price: number | null
      delivery_price: number | null
      service_id: number
      extra_addons?:
        | [
            {
              id: number
              offer_request_id: number
              recruited_addon_category_id: number
              note: string
              cost: number
              is_user_accepted: number
              created_at: string
              updated_at: string
              service_provider: string
              duration: number
            },
          ]
        | []
    }>
  }
}

export type GetPaymentInfoResponse = {
  contract: {
    bill: {
      amount: number
      sadad_bill_number: string | null
      paid_date: string | null
      vat: {
        value: string
      }
    }
    vat: number
    offer: {
      price: number
      delivery_price: number
    }
  }
}

const getContractRequestsInfo = (params: GetContractRequestsInfoParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetContractRequestsInfoResponse>(`/api/requests/${params.requestId}`, {
    params: {
      expand: [
        INSURANCE_EXPAND,
        CONTRACT_EXTENSION_EXPAND,
        BILL_SETTINGS_EXPAND,
        CONTRACT_CANCELLATION_EXPAND,
        EXTRA_ADDONS_EXPAND,
      ],
    },
    ...config,
  })

export type SendCVReportBody = {
  cvId: number
  offerId: number
  reasonId: string
}

const sendCvReport = (body: SendCVReportBody, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(
    `/api/offers/${body.offerId}/cvs/report`,
    {
      offer_id: body.offerId,
      cv_id: body.cvId,
      reason_id: body.reasonId,
    },
    config,
  )

export type SendPaymentSurveyParams = {
  surveyAnswer: 'A1' | 'A2' | 'A3'
  requestId: string
}

const sendPaymentSurvey = (body: SendPaymentSurveyParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(
    'api/survey/answer',
    {
      survey_answer: body.surveyAnswer,
      request_id: body.requestId,
    },
    config,
  )

export type GetPaymentInfoParams = {
  contractId: number | string
}

const getPaymentInfo = (params: GetPaymentInfoParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetPaymentInfoResponse>(`/api/contracts/${params.contractId}`, config)

export type OpenPaymentChannelParams = {
  contractId: number
  channel: string
}

const openPaymentChannel = (body: OpenPaymentChannelParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(
    `/api/contracts/${body.contractId}/payment-channel`,
    { contract_id: body.contractId, channel: body.channel },
    config,
  )

export type GetContractAndPreparePaymentParams = {
  requestId: number
  offerId: number
}

export type PayForEContractParams = {
  contractId: number
  issuerCode?: string
  planCode?: string
  installmentsFeesKey?: string
  installmentsFeesValue?: number
}

export type PayForEContractResponse = PayfortBill

const payForEContract = (params: PayForEContractParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get(`api/payfort/${params.contractId}/getPaymentPage/cc`, {
    params: {
      issuer_code: params.issuerCode,
      plan_code: params.planCode,
      installmentsFees_key: params.installmentsFeesKey,
      installmentsFees_value: params.installmentsFeesValue,
    },
    ...config,
  })

const getCVImage = (id: string) => `${ECONTRACTING_API_URL}/api/cv/${id}/download?token=${authManager.getEvisaToken()}`

export type AcceptOfferRequestParams = {
  offerId: number
  requestId: number
  cvId?: string
  cvs: Array<[string, number]>
  insuranceEnabled?: boolean
  language?: number
  previousPageURL?: string
  acceptedAddons?: Array<number>
}

export type AcceptOfferRequestResponse = {
  offer: GetContractRequestsInfoResponse['contractRequests']['offers'][0]
}

const acceptOfferRequest = (
  { requestId, offerId, cvs, acceptedAddons }: AcceptOfferRequestParams,
  config: AxiosRequestConfig,
) =>
  eContractingAPIWithCredentials.put<{}>(
    `/api/requests/${requestId}/offers/${offerId}/accept`,
    {
      offerId,
      requestId,
      cvs,
      ...(acceptedAddons && {
        accepted_addons: acceptedAddons,
      }),
    },
    config,
  )

export type GetContractRequestsListResponse = {
  data: Array<GetContractRequestsInfoResponse['contractRequests']>
  last_page: number
}

export type GetContractRequestsListParams = {
  page?: number
  status?: string
  perPage?: number
  delegated?: number
}

const getContractRequestsList = (
  { perPage = LIST_ITEMS_PER_PAGE, status, page = 0, delegated = 1 }: GetContractRequestsListParams = {},
  config?: AxiosRequestConfig,
) =>
  eContractingAPIWithCredentials.get<GetContractRequestsListResponse>('/api/requests', {
    params: {
      per_page: perPage,
      page: page + 1,
      status,
      delegated,
      expand: [BILL_EXPAND, ZATCA_BILL_EXPAND, BILL_SETTINGS_EXPAND, INSURANCE_EXPAND, CONTRACT_CANCELLATION_EXPAND],
    },
    ...config,
  })

export type CancelContractParams = {
  contractRequestId: number | string
  reason?: string
}

export enum ContractCancelationReasons {
  WORKER_VISA_IS_NOT_ISSUED = 'worker_visa_is_not_issued',
  ETAWTHEEQ_EMPLOYMENT_REQUEST_NOT_CANCELLABLE = 'etawtheeq_employment_request_not_cancellable',
  PAYMENT_UNDER_PROGRESS = 'PAYMENT_UNDER_PROGRESS',
}

export type CancelContractResponseFail = {
  reason: Array<ContractCancelationReasons>
  contract?: Array<ContractCancelationReasons>
  ['cancelRequest.contract_id']?: Array<ContractCancelationReasons>
  error?: ContractCancelationReasons
}

const cancelContract = ({ contractRequestId, reason }: CancelContractParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post<GetContractRequestsListResponse>(
    `/api/contracts/${contractRequestId}/cancel`,
    { reason },
    {
      params: { token: authManager.getEvisaToken() },
      ...config,
    },
  )

export type RejectOfferParams = {
  requestId: number | string
  offerId: number | string
}

const rejectOffer = ({ requestId, offerId }: RejectOfferParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.put(`/api/requests/${requestId}/offers/${offerId}/reject`, {}, config)

export type CancelRequestParams = {
  requestId: number | string
}

const cancelRequest = ({ requestId }: CancelRequestParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.put<{}>(`/api/requests/${requestId}/cancel`, {}, config)

export type AcceptRejectContractParams = {
  requestId: string
}

const acceptContract = (body: AcceptRejectContractParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(
    '/api/contractcancelrequests/accept',
    {
      request_id: body.requestId,
    },
    config,
  )

const rejectContract = (body: AcceptRejectContractParams, config: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(
    '/api/contractcancelrequests/reject',
    {
      request_id: body.requestId,
    },
    config,
  )

export type GetVATValueResponse = {
  value: string
}

const getVATValue = (params = {}, config: AxiosRequestConfig) =>
  eContractingPublicAPI.get<GetVATValueResponse>('/api/system/settings/VAT', config)

export type GetBanksInfoResponse = {
  installmentsFees: Array<{
    id: number
    key: InstallmentKeysType
    value: string
  }>
  installmentsPlans: {
    issuer_detail: Array<{
      bins: Array<{ bin: string }>
      formula: string
      plan_details: Array<{
        minimum_amount: number
        maximum_amount: number
        number_of_installment: number
        plan_code: string
        fees_amount: number
        fee_display_value: number
        fees_type: FeesType
        rate_type: RateType
      }>
      processing_fees_message_ar: string
      processing_fees_message_en: string
      issuer_code: string
      issuer_logo_ar: string
      issuer_logo_en: string
      issuer_name_ar: string
      issuer_name_en: string
      terms_and_condition_ar: string
      terms_and_condition_en: string
    }>
  }
}

const getBanksInfo = (params = {}, config?: AxiosRequestConfig) =>
  eContractingPublicAPI.get<GetBanksInfoResponse>('/api/payfort/getBanks', config)

export type ExtendContractBody = {
  action: 'accept' | 'reject'
  contractId?: number
}

const extendContract = ({ contractId, action }: ExtendContractBody, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.post(`/api/contracts/${contractId}/extend`, { action }, config)

export type GetRequestIdForContractIdParams = {
  contractId: string | number
}

export type GetRequestIdForContractIdResponse = {
  contract_request_id: number
}

const getRequestIdForContractId = ({ contractId }: GetRequestIdForContractIdParams, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetRequestIdForContractIdResponse>(
    `api/contracts/${contractId}/contract_request`,
    config,
  )

export type GetRefundDetailsParams = {
  contractId: string | number
}

export type RefundItemFomBE = {
  contract_id: string
  created_at: string
  employer_name: string
  type_of_proof: 'OTP' | 'Attachment' | null
  id: string
  pro_name: string
  refund_amount: string | null
  refund_required_contract_amount: string | null
  remaining_period: number
  status: string
  attachments: Array<TicketAttachment>
}

export type GetRefundDetailsResponse = {
  data: Array<RefundItemFomBE>
}

const getRefundDetails = ({ contractId }: GetRefundDetailsParams, config?: AxiosRequestConfig) =>
  refundAPIWithCredentials.get<GetRefundDetailsResponse>(`/api/v1/employer/refunds/${contractId}`, config)

const getRefunds = (config?: AxiosRequestConfig) =>
  refundAPIWithCredentials.get<{
    data: Array<{
      status: string
      id: string
    }>
  }>('api/v1/employer/refunds', config)

export type GetContractInfoParams = {
  contractId: string | number
}

const createRefundTicket = ({ contractId, email }: CreateRefundTicketBody, config?: AxiosRequestConfig) =>
  refundAPIWithCredentials.post<CreateRefundTicketResponse>(
    '/api/v1/employer/refunds',
    { contract_id: contractId, email },
    config,
  )

export type CreateRefundTicketBody = {
  contractId: string | number
  email: string
}

export type CreateRefundTicketResponse = {
  id: string
}

export type GetContractInfoResponse = {
  contract: {
    id: number
    new_status: string
  }
}
// Used for Labiba
const getContractInfo = ({ contractId }: GetContractInfoParams, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetContractInfoResponse>(`api/contracts/${contractId}`, config)

export type GetETawtheeqContractParams = {
  contractId: string | number
  idNumber: string | number
  borderNumber: string | number
}

export type GetETawtheeqContractResponse = string

const getETawtheeqContract = (
  { contractId, borderNumber, idNumber }: GetETawtheeqContractParams,
  config?: AxiosRequestConfig,
) =>
  eContractingAPIWithCredentials.get('/api/etawtheeq/contract_details', {
    params: {
      border_number: borderNumber,
      contract_id: contractId,
      id_number: idNumber,
    },
    ...config,
  })

export type GetNajmRedirectionUrlParams = {
  contractId: number
  language: number
  previousPageURL: string
}

export type GetNajmRedirectionUrlResponse = {
  redirection_url: string
}

const getNajmRedirectionUrl = (
  { contractId, language, previousPageURL }: GetNajmRedirectionUrlParams,
  config?: AxiosRequestConfig,
) =>
  eContractingAPIWithCredentials.get(`/api/contracts/${contractId}/najm/redirect`, {
    params: {
      redirect_to: previousPageURL,
      language: language,
    },
    ...config,
  })

export type GetInvoiceFileParams = {
  invoiceId: string | number
}

export type GetInvoiceFileResponse = {
  bytes: string
}

const getContractInvoiceFile = ({ invoiceId }: GetInvoiceFileParams, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetInvoiceFileResponse>('/api/zatca/get_invoice_details', {
    params: {
      invoiceId,
      type: '',
    },
    ...config,
  })

export type GetAcceptanceRateResponse = Array<{
  pro_id: number
  rate: number
}>

const getAcceptanceRate = (params: {}, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetAcceptanceRateResponse>(
    '/api/offices/acceptance_rate?pro_status=ACCEPT',
    config,
  )

export type GetTameeniRedirectionUrlParams = {
  contractId: number
  language: string
  previousPageURL: string
  insuranceTypeId: number
  redirectionUrl: string
}

export type GetTameeniRedirectionUrlResponse = {
  redirection_url: string
}

const getTameeniRedirectionUrl = (
  { contractId, language, previousPageURL, insuranceTypeId, redirectionUrl }: GetTameeniRedirectionUrlParams,
  config?: AxiosRequestConfig,
) =>
  eContractingAPIWithCredentials.get(`/api/contracts/${contractId}/tameeni/redirect`, {
    params: {
      redirect_to: redirectionUrl,
      previous_url: previousPageURL,
      insurance_type_id: insuranceTypeId,
      language: language,
    },
    ...config,
  })

export type GetDelayedContractCountsResponse = {
  delayed: { [key: string]: number }
}

const getDelayedContractCounts = (params: {}, config?: AxiosRequestConfig) =>
  eContractingAPIWithCredentials.get<GetDelayedContractCountsResponse>('/api/contracts/get_delayed_counts', config)

export const eContractingAPI = {
  maarofaSubmit: callAPI<typeof maarofaSubmit, MaarofaSubmitResponse, MaarofaSubmitFailResponse>(maarofaSubmit),
  maarofaPresubmit: callAPI<typeof maarofaPresubmit, MaarofaPresubmitResponse>(maarofaPresubmit),
  getPaymentInfo: callAPI<typeof getPaymentInfo, GetPaymentInfoResponse>(getPaymentInfo),
  openPaymentChannel: callAPI<typeof openPaymentChannel, {}>(openPaymentChannel),
  payForEContract: callAPI<typeof payForEContract, PayForEContractResponse>(payForEContract),
  getContractRequestsInfo: callAPI<typeof getContractRequestsInfo, GetContractRequestsInfoResponse>(
    getContractRequestsInfo,
  ),
  getContractRequestsList: callAPI<typeof getContractRequestsList, GetContractRequestsListResponse>(
    getContractRequestsList,
  ),
  sendCvReport: callAPI<typeof sendCvReport, {}>(sendCvReport),
  acceptOfferRequest: callAPI<typeof acceptOfferRequest, {}>(acceptOfferRequest),
  cancelContract: callAPI<typeof cancelContract, {}>(cancelContract),
  rejectOffer: callAPI<typeof rejectOffer, {}>(rejectOffer),
  cancelRequest: callAPI<typeof cancelRequest, {}>(cancelRequest),
  acceptContract: callAPI<typeof acceptContract, {}>(acceptContract),
  rejectContract: callAPI<typeof rejectContract, {}>(rejectContract),
  getVATValue: callAPI<typeof getVATValue, GetVATValueResponse>(getVATValue),
  sendPaymentSurvey: callAPI<typeof sendPaymentSurvey, {}>(sendPaymentSurvey),
  getBanksInfo: callAPI<typeof getBanksInfo, GetBanksInfoResponse>(getBanksInfo),
  extendContract: callAPI<typeof extendContract, {}>(extendContract),
  getRequestIdForContractId: callAPI<typeof getRequestIdForContractId, GetRequestIdForContractIdResponse>(
    getRequestIdForContractId,
  ),
  getETawtheeqContract: callAPI<typeof getETawtheeqContract, GetETawtheeqContractResponse>(getETawtheeqContract),
  getNajmRedirectionUrl: callAPI<typeof getNajmRedirectionUrl, GetNajmRedirectionUrlResponse>(getNajmRedirectionUrl),
  getRefundDetails: callAPI<typeof getRefundDetails, GetRefundDetailsResponse>(getRefundDetails),
  createRefundTicket: callAPI<typeof createRefundTicket, CreateRefundTicketResponse>(createRefundTicket),
  getContractInfo: callAPI<typeof getContractInfo, GetContractInfoResponse>(getContractInfo),
  getContractInvoiceFile: callAPI<typeof getContractInvoiceFile, GetInvoiceFileResponse>(getContractInvoiceFile),
  getAcceptanceRate: callAPI<typeof getAcceptanceRate, GetAcceptanceRateResponse>(getAcceptanceRate),
  getTameeniRedirectionUrl: callAPI<typeof getTameeniRedirectionUrl, GetTameeniRedirectionUrlResponse>(
    getTameeniRedirectionUrl,
  ),
  getDelayedContractCounts: callAPI<typeof getDelayedContractCounts, GetDelayedContractCountsResponse>(
    getDelayedContractCounts,
  ),
}

export const rawEContractingAPI = {
  getContractInfo,
  getContractRequestsList,
  getCVImage,
  getRefunds,
  getRefundDetails,
}
