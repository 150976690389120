import { GetAuthContractResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  legalTextAr: string
  legalTextEn: string
} & Status

const initialState: State = {
  legalTextAr: '',
  legalTextEn: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_AUTH_CONTRACT_ASYNC,
    Actions.GET_AUTH_CONTRACT_SUCCESS,
    Actions.GET_AUTH_CONTRACT_FAIL,
    GetAuthContractResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_AUTH_CONTRACT_SUCCESS: {
      return {
        ...state,
        ...action.status,
        legalTextAr: action.payload.data.label_ar,
        legalTextEn: action.payload.data.label_en,
      }
    }
    case Actions.GET_AUTH_CONTRACT_FAIL:
    case Actions.GET_AUTH_CONTRACT_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
