import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { SubmitIToIRequestResponse } from 'api/newDLTransferAPI'

type State = {
  error?: string
} & Status

const initialState = {
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_ASYNC,
    Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_SUCCESS,
    Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_FAIL,
    {},
    SubmitIToIRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_ASYNC:
    case Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.SUBMIT_I_TO_I_TRANSFER_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error || action.payload?.message,
      }
    }
    default:
      return state
  }
}
