import type { AxiosRequestConfig } from 'axios'

import { callAPI, evisaAPIWithCredentials } from 'api/axios'
import {
  BillStatus,
  GeneralFailResponse,
  FarmerTransferabilityErrorMessages,
  FarmerTransferRequestsStatus,
  PaymentMethods,
  UserEligibilityErrorMessages,
} from 'common/types/commonTypes'
import {
  BillFromBE,
  NationalityFromBE,
  ObjectWithTranslationFromBE,
  OccupationFromBE,
  PayfortBill,
} from 'common/types/transformedRespTypes'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'

export type СheckFarmerTransferEligibilityResponse = {
  error?: {
    code: number
    message: UserEligibilityErrorMessages
  }
}

export type FarmerTransferabilityFailResponse = {
  error?: {
    code: number
    message: FarmerTransferabilityErrorMessages
  }
}

export type CheckFarmerTransferabilityBody = {
  iqamaNumber: number
}

export type CheckFarmerTransferabilityResponse = {
  establishmentName: string
  iqamaNumber: string
  laborerName: string
  nationality: NationalityFromBE
  occupation: OccupationFromBE
}

const checkFarmerTransferEligibility = (body = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>('/app/farmerVisaTransferRequest/check_eligibility', config)

const checkFarmerTransferability = (body: CheckFarmerTransferabilityBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(
    '/app/farmerVisaTransferRequest/check_transferability',
    { iqama_number: body.iqamaNumber },
    config,
  )

export type GetFarmerTransferRequestsListParams = {
  perPage?: number
  page?: number
  status?: string | FarmerTransferRequestsStatus
}

export type FarmerTransferRequestFormBE = {
  id: number
  laborer_id: number
  sponsor: { name: string }
  user: {
    id: number
    id_number: number
    name: string
    name_en: string
  }
  created_at: string
  status: FarmerTransferRequestsStatus
  bill: BillFromBE
  rejection_reasons?: Array<ObjectWithTranslationFromBE>
}

export type GetFarmerTransferRequestsListResponse = {
  paginator: {
    data: Array<FarmerTransferRequestFormBE>
    last_page: number
    current_page: number
  }
}

const getFarmerTransferRequestsList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetFarmerTransferRequestsListParams,
  config: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<{}>('/app/farmerVisaTransferRequest', {
    params: {
      per_page: perPage,
      page: page + 1,
      status,
    },
    ...config,
  })

export type FarmerTransferRequestParams = {
  requestId: number
}

const cancelFarmerTransferRequest = (params: FarmerTransferRequestParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(`/app/farmerVisaTransferRequest/cancel/${params.requestId}`, {}, config)

const acceptFarmerTransferRequest = (params: FarmerTransferRequestParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(`/app/farmerVisaTransferRequest/accept/${params.requestId}`, {}, config)

const rejectFarmerTransferRequest = (params: FarmerTransferRequestParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<{}>(`/app/farmerVisaTransferRequest/reject/${params.requestId}`, {}, config)

export type IssueNewFarmerTransferRequestBody = {
  iqamaNumber: string
}

export type IssueNewFarmerTransferRequestResponse = {
  farmerTransferRequest: {
    bill: {
      status: BillStatus
    } | null
    id: number
    nationality: NationalityFromBE
    occupation: OccupationFromBE
  }
}

export type IssueNewFarmerFarmerRequestFailResponse = GeneralFailResponse

const issueNewFarmerTransferRequest = (body: IssueNewFarmerTransferRequestBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<IssueNewFarmerTransferRequestResponse>(
    'app/farmerVisaTransferRequest',
    { iqama_number: body.iqamaNumber },
    config,
  )

export type PayForFarmerTransferBody = {
  requestId: string
  paymentMethod: PaymentMethods
}

export type PayForFarmerTransferResponse = {
  status: boolean
  bill: PayfortBill
}

const payForFarmerTransfer = (body: PayForFarmerTransferBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<PayForFarmerTransferResponse>(
    `app/farmerVisaTransferRequest/${body.requestId}/payfort_bill/${body.paymentMethod}`,
    {},
    config,
  )

export default {
  checkFarmerTransferEligibility: callAPI<
    typeof checkFarmerTransferEligibility,
    {},
    СheckFarmerTransferEligibilityResponse
  >(checkFarmerTransferEligibility),
  checkFarmerTransferability: callAPI<typeof checkFarmerTransferability, {}, CheckFarmerTransferabilityResponse>(
    checkFarmerTransferability,
  ),
  getFarmerTransferRequestsList: callAPI<
    typeof getFarmerTransferRequestsList,
    {},
    GetFarmerTransferRequestsListResponse
  >(getFarmerTransferRequestsList),
  cancelFarmerTransferRequest: callAPI<typeof cancelFarmerTransferRequest, {}, {}>(cancelFarmerTransferRequest),
  acceptFarmerTransferRequest: callAPI<typeof acceptFarmerTransferRequest, {}, {}>(acceptFarmerTransferRequest),
  rejectFarmerTransferRequest: callAPI<typeof rejectFarmerTransferRequest, {}, {}>(rejectFarmerTransferRequest),
  payForFarmerTransfer: callAPI<typeof payForFarmerTransfer, PayForFarmerTransferResponse>(payForFarmerTransfer),
  issueNewFarmerTransferRequest: callAPI<
    typeof issueNewFarmerTransferRequest,
    IssueNewFarmerTransferRequestResponse,
    IssueNewFarmerFarmerRequestFailResponse
  >(issueNewFarmerTransferRequest),
}
