import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { TransferWorkerResponse } from 'api/newDLTransferAPI'

type State = {
  errorMessage?: string
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.E_TO_I_TRANSFER_WORKER_ASYNC,
    Actions.E_TO_I_TRANSFER_WORKER_SUCCESS,
    Actions.E_TO_I_TRANSFER_WORKER_FAIL,
    TransferWorkerResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.E_TO_I_TRANSFER_WORKER_ASYNC:
    case Actions.E_TO_I_TRANSFER_WORKER_SUCCESS: {
      return {
        ...state,
        errorMessage: '',
        ...action.status,
      }
    }
    case Actions.E_TO_I_TRANSFER_WORKER_FAIL: {
      return {
        ...state,
        errorMessage: action.payload?.error.message,
        ...action.status,
      }
    }

    default:
      return state
  }
}
