import {
  convertIToIBillFromResponse,
  getEmptyLaborerIToIBill,
  Bill,
} from 'common/utils/convertResponse/convertIToIResponse'
import { GetIToIBillResponse } from 'api/newDLTransferAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { FailResponse } from 'api/evisaAPI/dlAPI'

type State = {
  bill: Bill
} & Status

const initialState = {
  bill: getEmptyLaborerIToIBill(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_BILL_ASYNC,
    Actions.GET_I_TO_I_BILL_SUCCESS,
    Actions.GET_I_TO_I_BILL_FAIL,
    GetIToIBillResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_I_BILL_SUCCESS: {
      return {
        ...state,
        ...action.status,
        bill: convertIToIBillFromResponse(action.payload.data),
      }
    }
    case Actions.GET_I_TO_I_BILL_ASYNC:
    case Actions.GET_I_TO_I_BILL_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
