import authManager from '../auth/authManager'

import apiForLabiba, { APIForLabiba } from './apiForLabiba'

declare global {
  interface Window {
    musaned: {
      isAuthenticated: () => boolean
      api: APIForLabiba
    }
  }
}

class APIExposer {
  private api: APIForLabiba
  constructor() {
    this.api = apiForLabiba
  }
  private isAuthenticated() {
    return authManager.isAuthenticated()
  }
  public exposeAPI() {
    window.musaned = {
      isAuthenticated: this.isAuthenticated,
      api: this.api,
    }
  }
}

const apiExposer = new APIExposer()

export default apiExposer
