import Actions from 'redux/actions'

type State = { open: boolean }

const initialState = {
  open: false,
}

export default (
  state: State = initialState,
  action: { type: Actions.CLOSE_AUTH_BANNER | Actions.OPEN_AUTH_BANNER },
): State => {
  switch (action.type) {
    case Actions.OPEN_AUTH_BANNER: {
      return {
        ...state,
        open: true,
      }
    }
    case Actions.CLOSE_AUTH_BANNER: {
      return {
        ...state,
        open: false,
      }
    }
    default:
      return state
  }
}
