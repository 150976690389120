import React, { VoidFunctionComponent } from 'react'
import Lottie from 'react-lottie'

type LottieAnimationProps = {
  animationData: object
  height?: number
  width?: number
}

const LottieAnimation: VoidFunctionComponent<LottieAnimationProps> = ({ animationData, height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }

  return <Lottie options={defaultOptions} height={height} width={width} />
}

export default LottieAnimation
