import { SubmitSurveyResponse } from 'api/evisaAPI/evisaAPI'
import type { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  error?: {
    message: string
    code: number
  }
  successMsg: string
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  successMsg: '',
  error: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_WATANI_SURVEY_ASYNC,
    Actions.SUBMIT_WATANI_SURVEY_SUCCESS,
    Actions.SUBMIT_WATANI_SURVEY_FAIL,
    SubmitSurveyResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.SUBMIT_WATANI_SURVEY_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.SUBMIT_WATANI_SURVEY_SUCCESS: {
      return {
        ...state,
        ...action.status,
        successMsg: action.payload.message,
      }
    }
    case Actions.SUBMIT_WATANI_SURVEY_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    }
    default:
      return state
  }
}
