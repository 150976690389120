import type {
  GetContractRequestsInfoParams,
  SendCVReportBody,
  GetPaymentInfoParams,
  GetContractAndPreparePaymentParams,
  OpenPaymentChannelParams,
  AcceptOfferRequestParams,
  GetContractRequestsListParams,
  CancelContractParams,
  RejectOfferParams,
  CancelRequestParams,
  MaarofaSubmitParams,
  SendPaymentSurveyParams,
  PayForEContractParams,
  ExtendContractBody,
  GetRequestIdForContractIdParams,
  GetETawtheeqContractParams,
  GetRefundDetailsParams,
  GetContractInfoParams,
  CreateRefundTicketBody,
  GetInvoiceFileParams,
} from 'api/eContractingAPI'
import { Routes, routeWithParams } from 'containers/Routes/routePaths'

import Actions from '../actions'

export const maarofaPresubmit = (body: MaarofaSubmitParams) => ({
  type: Actions.MAAROFA_PRESUBMIT,
  payload: { params: body },
})

export const maarofaSubmit = (body: MaarofaSubmitParams) => ({
  type: Actions.MAAROFA_SUBMIT,
  payload: { params: body },
})

export const getContractRequestsInfo = (params: GetContractRequestsInfoParams) => ({
  type: Actions.GET_CONTRACT_REQUEST_INFO,
  payload: { params },
})

export const getContractRequestsAndVisaInfo = (params: GetContractRequestsInfoParams) => ({
  type: Actions.GET_CONTRACT_REQUEST_AND_VISA_INFO,
  payload: { params },
})

export const sendCvReport = (body: SendCVReportBody) => ({
  type: Actions.SEND_CV_REPORT,
  payload: { params: body },
})

export const acceptOfferRequest = (params: AcceptOfferRequestParams) => ({
  type: Actions.ACCEPT_OFFER_REQUEST,
  payload: { params },
})

export const vipAcceptOfferRequest = (params: AcceptOfferRequestParams) => ({
  type: Actions.VIP_ACCEPT_OFFER_REQUEST,
  payload: { params },
})

export const getContractRequestsList = (params: GetContractRequestsListParams = {}) => ({
  type: Actions.GET_CONTRACT_REQUESTS_LIST,
  payload: { params },
})

export const getPaymentInfo = (params: GetPaymentInfoParams) => ({
  type: Actions.GET_PAYMENT_INFO,
  payload: { params },
})

export const payForEContract = (params: PayForEContractParams) => ({
  type: Actions.PAY_FOR_ECONTRACT,
  payload: { params },
})

export const openPaymentChannel = (params: OpenPaymentChannelParams) => ({
  type: Actions.OPEN_PAYMENT_CHANNEL,
  payload: { params },
})

export const getContractAndPreparePayment = (params: GetContractAndPreparePaymentParams) => ({
  type: Actions.GET_CONTRACT_AND_PREPARE_PAYMENT,
  payload: { params },
})

export const cancelContract = (params: CancelContractParams) => ({
  type: Actions.CANCEL_CONTRACT,
  payload: { params },
})

export const rejectOffer = (params: RejectOfferParams) => ({
  type: Actions.REJECT_OFFER,
  payload: { params },
})

export const cancelRequest = (params: CancelRequestParams) => ({
  type: Actions.CANCEL_REQUEST,
  payload: { params },
})

export const getVATValue = () => ({
  type: Actions.GET_VAT_VALUE,
})

export const sendPaymentSurvey = (params: SendPaymentSurveyParams) => ({
  type: Actions.SEND_PAYMENT_SURVEY,
  payload: { params },
})

export const getBanksInfo = () => ({ type: Actions.GET_BANKS_INFO })

export const extendContract = (params: ExtendContractBody) => ({ type: Actions.EXTEND_CONTRACT, payload: { params } })

export const getRequestIdForContractId = (params: GetRequestIdForContractIdParams) => ({
  type: Actions.GET_REQUEST_ID_FOR_CONTRACT_ID,
  payload: { params },
})

export const getRefundDetails = (params: GetRefundDetailsParams) => ({
  type: Actions.GET_REFUND_DETAILS,
  payload: { params },
})

export const createRefundTicket = (params: CreateRefundTicketBody) => ({
  type: Actions.CREATE_REFUND_TICKET,
  payload: { params },
})

export const getETawtheeqContract = (
  params: GetETawtheeqContractParams & {
    requestId: number
    offerId: number
  },
) => ({
  type: Actions.GET_ETAWTHEQ_CONTRACT,
  payload: { params },
})

export const getNajmRedirectionUrl = (params: {
  requestId: number | string
  offerId: number | string
  contractId: number
  language: number
  previousPageURL: string
}) => ({
  type: Actions.GET_NAJM_REDIRECTION_URL,
  payload: {
    params: {
      callBackLink: routeWithParams(Routes.CONTRACT_DETAILS, {
        requestId: params.requestId,
        offerId: params.offerId,
      }),
      contractId: params.contractId,
      language: params.language,
      previousPageURL: params.previousPageURL,
    },
  },
})

export const getVipNajmRedirectionUrl = (params: {
  requestId: number | string
  offerId: number | string
  contractId: number
  language: number
  previousPageURL: string
}) => ({
  type: Actions.GET_NAJM_REDIRECTION_URL,
  payload: {
    params: {
      callBackLink: routeWithParams(Routes.VIP_CONTRACT_DETAILS, {
        requestId: params.requestId,
        offerId: params.offerId,
      }),
      contractId: params.contractId,
      language: params.language,
      previousPageURL: params.previousPageURL,
    },
  },
})

export const rejectContract = (params: CancelRequestParams) => ({
  type: Actions.REJECT_CONTRACT,
  payload: { params },
})

export const acceptContract = (params: CancelRequestParams) => ({
  type: Actions.ACCEPT_CONTRACT,
  payload: { params },
})

export const getContractInfo = (params: GetContractInfoParams) => ({
  type: Actions.GET_CONTRACT_INFO,
  payload: { params },
})

export const getContractInvoiceFile = (params: GetInvoiceFileParams) => ({
  type: Actions.GET_CONTRACT_INVOICE_FILE,
  payload: { params },
})

export const getAcceptanceRate = () => ({
  type: Actions.GET_ACCEPTANCE_RATE,
})

export const getTameeniRedirectionUrl = (params: {
  requestId: number | string
  offerId: number | string
  contractId: number
  language: string
  previousPageURL: string
  insuranceTypeId: number
  redirectionUrl: string
}) => ({
  type: Actions.GET_TAMEENI_REDIRECTION_URL,
  payload: {
    params: {
      callBackLink: routeWithParams(Routes.CONTRACT_DETAILS, {
        requestId: params.requestId,
        offerId: params.offerId,
      }),
      contractId: params.contractId,
      language: params.language,
      previousPageURL: params.previousPageURL,
      insuranceTypeId: params.insuranceTypeId,
      redirectionUrl: params.redirectionUrl,
    },
  },
})

export const getDelayedContractCounts = () => ({
  type: Actions.GET_DELAYED_CONTRACTS_COUNTS,
})
