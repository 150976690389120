import { useEffect } from 'react'
import { useLocation } from 'react-router'

// added document title based on a route we just use top route for the page
// TODO Later specify this titile for every route
export const useTitle = () => {
  const location = useLocation()
  useEffect(() => {
    const appTitle = location.pathname
      .split('/')
      .filter((value) => !!value && value !== 'app' && value !== 'auth')
      .shift()

    const title = (location.pathname.startsWith('/vip/') ? 'Musaned Private Service' : appTitle) || 'musaned'
    document.title = title.charAt(0).toUpperCase() + title.slice(1)
  }, [location])
}
