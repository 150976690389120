import Actions from 'redux/actions'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { setVipContractRequest } from 'redux/actionCreators/premiumUserAPI/contractRequest'

export type CV = {
  proName?: string
  duration: number
  price: number
  city?: ObjectWithTranslation
  id: number
  candidateName: string | null
  cvPath: string
  offerId: string
  order: string
  portfolioPath: string
  salary: number
}

export type VipContractRequest = {
  cvs?: Array<CV>
}

const initialState: VipContractRequest = {
  cvs: [],
}

const vipRecruitmentFilter = (
  state: VipContractRequest = initialState,
  action: ReturnType<typeof setVipContractRequest>,
): VipContractRequest => {
  switch (action.type) {
    case Actions.SET_VIP_CONTRACT_REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default vipRecruitmentFilter
