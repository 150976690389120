import { CreateSupportTicketResponse } from 'api/laborerPortalAPI/supportTicket'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  issueKey: string
} & Status

const initialState: State = {
  issueKey: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_LABORER_SUPPORT_TICKET_ASYNC,
    Actions.CREATE_LABORER_SUPPORT_TICKET_SUCCESS,
    Actions.CREATE_LABORER_SUPPORT_TICKET_FAIL,
    CreateSupportTicketResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_LABORER_SUPPORT_TICKET_SUCCESS: {
      return {
        ...state,
        ...action.status,
        issueKey: action.payload.issue_key,
      }
    }
    case Actions.CREATE_LABORER_SUPPORT_TICKET_ASYNC:
    case Actions.CREATE_LABORER_SUPPORT_TICKET_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
