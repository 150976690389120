import { UpdateNationalAddressResponse } from 'api/evisaAPI/evisaAPI'
import { Status, CallAPIAction } from 'api/types'
import { NationalAddressType } from 'common/utils/auth/authManager'
import { nationalAddressConvert } from 'common/utils/convertResponse/convertUserProfileResponse'
import Actions from 'redux/actions'

type State = {
  addresses: Array<NationalAddressType>
} & Status

const initialState = {
  addresses: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.UPDATE_NATIONAL_ADDRESS_ASYNC,
    Actions.UPDATE_NATIONAL_ADDRESS_SUCCESS,
    Actions.UPDATE_NATIONAL_ADDRESS_FAIL,
    UpdateNationalAddressResponse
  >,
): State => {
  switch (action.type) {
    case Actions.UPDATE_NATIONAL_ADDRESS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        addresses: action.payload.addresses.map(nationalAddressConvert),
      }
    }

    case Actions.UPDATE_NATIONAL_ADDRESS_ASYNC:
    case Actions.UPDATE_NATIONAL_ADDRESS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
