import { Status, CallAPIAction } from 'api/types'
import type { GetVisaIssuePlacesResponse } from 'api/evisaAPI/evisaAPI'
import type { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

const convertResponseToState = (resp: GetVisaIssuePlacesResponse) =>
  resp.visaIssuePlaces.reduce(
    (acc, visaIssuePlaceFromBE) => ({
      ...acc,
      [visaIssuePlaceFromBE.id]: {
        id: visaIssuePlaceFromBE.id,
        label: visaIssuePlaceFromBE.label,
        labelEn: visaIssuePlaceFromBE.label_en,
      },
    }),
    {},
  )

type State = {
  visaIssuePlaces: StringRecord<ObjectWithTranslation>
} & Status

const initialState = {
  visaIssuePlaces: {},
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VISA_ISSUE_PLACES_ASYNC,
    Actions.GET_VISA_ISSUE_PLACES_SUCCESS,
    Actions.GET_VISA_ISSUE_PLACES_FAIL,
    GetVisaIssuePlacesResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VISA_ISSUE_PLACES_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VISA_ISSUE_PLACES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        visaIssuePlaces: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_VISA_ISSUE_PLACES_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
