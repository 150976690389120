import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'
import Actions from 'redux/actions'
import { setPreselectedFormData } from 'redux/actionCreators/preselectedForm'

export type PreselectedFormState = {
  name: string
  ageRange?: number
  passportNumber: string
  passportExpiry: string
  phone: { value: string; location?: PhoneData }
  passportImage?: File
}

const initialState: PreselectedFormState = {
  name: '',
  ageRange: undefined,
  passportNumber: '',
  passportExpiry: '',
  phone: { value: '', location: undefined },
  passportImage: undefined,
}

export default (state: PreselectedFormState = initialState, action: ReturnType<typeof setPreselectedFormData>) => {
  switch (action.type) {
    case Actions.SET_PRESELECTED_FORM_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
