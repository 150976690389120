import { AxiosRequestConfig } from 'axios'

import { TAKAMOL_BASE_URL } from 'common/envConstants'
import { GeneralFailResponse, ManagerAuthStatusTypes } from 'common/types/commonTypes'

import { callAPI, evisaAPIWithCredentials } from '../axios'

export type GetManagerInfoParams = {}

export type GetManagerInfoResponse = {
  authorization: ManagerAuthStatusTypes
}

const getManagerInfo = (params: GetManagerInfoParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetManagerInfoResponse>(`${TAKAMOL_BASE_URL}/api/individuals/premium-users/info`, config)

export type UpdateManagerAuthStatusParams = {
  authorized: boolean
}

export type UpdateManagerAuthStatusResponse = {}

const updateManagerAuthStatus = ({ authorized }: UpdateManagerAuthStatusParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<UpdateManagerAuthStatusResponse>(
    `${TAKAMOL_BASE_URL}/api/individuals/premium-users/authorize`,
    {
      authorized,
    },
    config,
  )

export default {
  getManagerInfo: callAPI<typeof getManagerInfo, GetManagerInfoResponse, GeneralFailResponse>(getManagerInfo),
  updateManagerAuthStatus: callAPI<
    typeof updateManagerAuthStatus,
    UpdateManagerAuthStatusResponse,
    GeneralFailResponse
  >(updateManagerAuthStatus),
}
