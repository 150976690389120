import { setSupportTicketData } from 'redux/actionCreators/supportTicket'

import Actions from '../actions'

export type State = {
  selectedId?: number
  isRequest?: boolean
}

const initialState: State = {
  selectedId: undefined,
  isRequest: true,
}

export default (state: State = initialState, action: ReturnType<typeof setSupportTicketData>) => {
  switch (action.type) {
    case Actions.SET_SUPPORT_TICKET_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
