import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { respondToOfficeComplaint } from 'redux/actionCreators/jiraAPI'
import type { RespondToOfficeComplaintResponse } from 'api/jiraAPI'

type State = {
  isAccept?: boolean
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  isAccept: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.RESPOND_TO_OFFICE_COMPLAINT_ASYNC,
    Actions.RESPOND_TO_OFFICE_COMPLAINT_SUCCESS,
    Actions.RESPOND_TO_OFFICE_COMPLAINT_FAIL,
    RespondToOfficeComplaintResponse,
    {},
    {},
    ReturnType<typeof respondToOfficeComplaint>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.RESPOND_TO_OFFICE_COMPLAINT_ASYNC: {
      const { callAPIActionPayload } = action
      return {
        ...state,
        ...action.status,
        isAccept: callAPIActionPayload.params.accept,
      }
    }
    case Actions.RESPOND_TO_OFFICE_COMPLAINT_SUCCESS:
    case Actions.RESPOND_TO_OFFICE_COMPLAINT_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
