import { CheckIToIElegibilityResponse } from 'api/newDLTransferAPI'
import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  error?: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_ASYNC,
    Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_SUCCESS,
    Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_FAIL,
    {},
    CheckIToIElegibilityResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_ASYNC:
    case Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_I_TO_I_TRANSFER_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    }
    default:
      return state
  }
}
