import { Status, CallAPIAction } from 'api/types'
import type { CheckEligibilityForAlternativeVisaFailCombinedResponse } from 'api/evisaAPI/visas'
import Actions from 'redux/actions'

type State = CheckEligibilityForAlternativeVisaFailCombinedResponse & Status

const initialState = {
  noUsers: false,
  messageTKey: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_ASYNC,
    Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_SUCCESS,
    Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_FAIL,
    {},
    CheckEligibilityForAlternativeVisaFailCombinedResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_ASYNC:
    case Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_FAIL: {
      return {
        ...state,
        ...action.status,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
