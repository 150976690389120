import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import { getProsStatistic as getProStatisticAction } from 'redux/actionCreators/surveyAPI'
import surveyAPI from 'api/surveyAPI'

import { getSimpleRequestHandler } from '../utils'

const getProsStatistic = getSimpleRequestHandler<
  typeof surveyAPI.getProsStatistic,
  ReturnType<typeof getProStatisticAction>
>({
  actionLoading: Actions.GET_PRO_STATISTIC_ASYNC,
  actionSuccess: Actions.GET_PRO_STATISTIC_SUCCESS,
  actionFail: Actions.GET_PRO_STATISTIC_FAIL,
  callApiFn: surveyAPI.getProsStatistic,
})

export default [takeLatest(Actions.GET_PRO_STATISTIC, getProsStatistic)]
