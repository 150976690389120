import Actions from 'redux/actions'
import { setLaborerComplaintDetails } from 'redux/actionCreators/laborerPortalAPI/support'
import { FileStatus } from 'ui-components/inputs/DropArea/types'

export type LaborerComplaintDetailsState = {
  type: string
  details: string
  files: Map<string, FileStatus>
  mobileNumber: string
}

const initialState: LaborerComplaintDetailsState = {
  type: '',
  details: '',
  files: new Map(),
  mobileNumber: '',
}

export default (
  state: LaborerComplaintDetailsState = initialState,
  action: ReturnType<typeof setLaborerComplaintDetails>,
) => {
  switch (action.type) {
    case Actions.SET_LABORER_COMPLAINT_DETAILS:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
