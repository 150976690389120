import {
  ContractAuthRequestFromBE,
  GetEmployerLaborersResponse,
  GetContractAuthDetailedRequestResponse,
  CheckLaborerPhoneForContractAuthResponse,
} from 'api/contractAuthAPI'
import {
  AuthContractLaborerStatus,
  ContractAuthRequestStatus,
  StringRecord,
  PlaceOfResidencyType,
  ResidencyDescriptionType,
  RelativeRelationType,
  ContractAuthAppendixStatus,
} from 'common/types/commonTypes'
import { Nationality, ObjectWithTranslation, Occupation } from 'common/types/transformedRespTypes'

import { formatDateForUI } from '../common'
import {
  formatNationalityFromBE,
  formatObjectWithTranslationFromBE,
  formatOccupationsFromBE,
} from '../objectWithTranslation'

export type Commitment = 'committed' | 'uncommitted' | ''

export type MobileNumberChangeNotice = {
  id: number
  changedBy: string
  createdAt: string
  deletedAt: string | null
  laborId: number
  newMobileNumber: string
  oldMobileNumber: string
  status: ContractAuthAppendixStatus
  updatedAt: string
}

export type Appendix = {
  id: number
  status: ContractAuthAppendixStatus
  statusValueKind: string
  updatedAt: string
  approvedAt: string
  authenticatedContractId: number
  createdAt: string
  deletedAt: string | null
  durationInMonths: number | null
  salary: string
  startDateDetails: {
    day: ObjectWithTranslation
    gregorian: string
    hijri: string
  } | null
  uuid: string
  laborDetails: LaborDetails | null
  residencyDetails: ResidencyDetails | null
}

export type PendingAppendix = {
  id: number
  status: ContractAuthAppendixStatus
  statusValueKind: string
  updatedAt: string
  approvedAt: string
  authenticatedContractId: number
  createdAt: string
  deletedAt: string | null
  durationInMonths: number | null
  salary: string
  startDateDetails: {
    day: ObjectWithTranslation
    gregorian: string
    hijri: string
  } | null
  uuid: string
}

export type LaborDetails = {
  nationality: Nationality
  occupation: Occupation
  religion: string
  passportExpiryDate: string
  passportNumber: string
  relativeName: string
  relativeMobileNumber: string
  relativeRelation: RelativeRelationType | string
  martialStatus: string
  iqamaExpiryDate: string
  mobileNumber: string
}

export type ResidencyDetails = {
  place: PlaceOfResidencyType
  address: string
  description: ResidencyDescriptionType | string
  residentsNumber: number | null
  additionalNumber: string | null
  buildingNumber: string | null
  city: string | null
  district: string | null
  postalCode: string | null
  region: string | null
  streetName: string | null
}

export type ContractAuthRequest = {
  id: number
  status: ContractAuthRequestStatus
  statusValueKind: string
  iqamaNumber: string
  name: ObjectWithTranslation
  nationality: ObjectWithTranslation
  occupation: ObjectWithTranslation
  createdAt: string
  startDate: string
  rejectionReasons: Array<ObjectWithTranslation>
  lastAppendixAt: string | null
  appendixes: Array<Appendix> | null
  lastAppendixStatus: ContractAuthAppendixStatus | null
  lastStatusValueKind: string | null
  mobileNumberChangeNotice: MobileNumberChangeNotice | null
  uuid: string | null
  pendingAppendix: PendingAppendix | null
}

export type ContractAuthDetailedRequest = {
  durationInMonth: number
  salary: string
  status: ContractAuthRequestStatus
  employerDetails: {
    name: string
    nationality: string
    idNumber: string
    childrenUnderTwelveCount: string
    address: string
    mobileNumber: string
    extraMobileNumber: string
    email: string
    job: string
    familySize: string
  }
  labor: {
    name: ObjectWithTranslation
    birthDate: string
    iqamaNumber: string
    mobileNumber: string
  } | null
  laborDetails: LaborDetails
  residencyDetails: ResidencyDetails
  occupationDescriptions: Array<ObjectWithTranslation>
  startDateDetails: {
    day: ObjectWithTranslation
    gregorian: string
    hijri: string
  }
  approvedAppendixes: Array<Appendix>
  uuid: string
  createdAt: string
  pendingAppendix: PendingAppendix | null
  legalText: {
    pdfIdAr: string
    pdfIdEn: string
  } | null
}

export type ContractAuthAppendixRequest = {
  salary: string
  durationInMonth: number
  passportNumber: string
  passportExpiryDate: string
  place: string
  description: string
  region: string | null
  city: string | null
  district: string | null
  streetName: string | null
  buildingNumber: string | null
  additionalNumber: string | null
  postalCode: string | null
}

const getAuthRequestStatusValueKind = (status: ContractAuthRequestStatus) => {
  switch (status) {
    case ContractAuthRequestStatus.CANCELED:
    case ContractAuthRequestStatus.REJECTED:
      return 'danger'
    case ContractAuthRequestStatus.VALID:
      return 'success'
    case ContractAuthRequestStatus.WAITING_LABOR_RESPONSE:
      return 'warning'
    default:
      return ''
  }
}

const getAuthAppendixStatusValueKind = (status: ContractAuthAppendixStatus) => {
  switch (status) {
    case ContractAuthAppendixStatus.REJECTED:
    case ContractAuthAppendixStatus.CANCELED:
      return 'danger'
    case ContractAuthAppendixStatus.APPROVED:
      return 'success'
    case ContractAuthAppendixStatus.PENDING:
    case ContractAuthAppendixStatus.EXPIRED:
      return 'warning'
    default:
      return ''
  }
}

export const convertContractAuthRequestFromResponse = (request: ContractAuthRequestFromBE): ContractAuthRequest => ({
  id: request.id,
  status: request.status,
  statusValueKind: getAuthRequestStatusValueKind(request.status),
  iqamaNumber: request.iqama_number,
  rejectionReasons: request.rejectionReasons.map(formatObjectWithTranslationFromBE),
  name: {
    label: request.name,
    labelEn: request.name_en,
    id: 0,
  },
  nationality: formatObjectWithTranslationFromBE(request.nationality),
  occupation: formatObjectWithTranslationFromBE(request.occupation),
  createdAt: formatDateForUI(request.created_at),
  startDate: formatDateForUI(request.start_date),
  lastAppendixAt: request.last_appendix_at,
  appendixes:
    request.appendixes?.map((appendix) => ({
      id: appendix.id,
      status: appendix.status,
      statusValueKind: getAuthAppendixStatusValueKind(appendix.status),
      updatedAt: formatDateForUI(appendix.updated_at),
      approvedAt: formatDateForUI(appendix.approved_at),
      authenticatedContractId: appendix.authenticated_contract_id,
      createdAt: formatDateForUI(appendix.created_at),
      deletedAt: formatDateForUI(appendix.deleted_at),
      durationInMonths: appendix.duration_in_months,
      salary: appendix.status,
      startDateDetails: appendix.start_date_details
        ? {
            day: {
              id: 0,
              label: appendix.start_date_details?.day || '',
              labelEn: appendix.start_date_details?.day_en || '',
            },
            gregorian: appendix.start_date_details?.gregorian,
            hijri: appendix.start_date_details?.hijri,
          }
        : null,
      uuid: appendix.uuid,
      laborDetails: appendix.labor_details
        ? {
            nationality: formatNationalityFromBE(appendix.labor_details.nationality),
            occupation: formatOccupationsFromBE(appendix.labor_details.occupation),
            religion: appendix.labor_details.religion,
            passportExpiryDate: appendix.labor_details.passport_expiry_date,
            passportNumber: appendix.labor_details.passport_number,
            relativeName: appendix.labor_details.relative_name,
            relativeMobileNumber: appendix.labor_details.relative_mobile_number,
            relativeRelation: appendix.labor_details.relative_relation,
            martialStatus: appendix.labor_details.martial_status,
            iqamaExpiryDate: appendix.labor_details.iqama_expiry_date,
            mobileNumber: appendix.labor_details.mobile_number,
          }
        : null,
      residencyDetails: appendix.residency_details
        ? {
            place: appendix.residency_details.place,
            address: appendix.residency_details.address,
            description: appendix.residency_details.description,
            residentsNumber: appendix.residency_details.residents_number,
            additionalNumber: appendix.residency_details.additional_number,
            buildingNumber: appendix.residency_details.building_number,
            city: appendix.residency_details.city,
            district: appendix.residency_details.district,
            postalCode: appendix.residency_details.postal_code,
            region: appendix.residency_details.region,
            streetName: appendix.residency_details.street_name,
          }
        : null,
    })) || null,
  lastAppendixStatus: request.last_appendix_status,
  lastStatusValueKind: request.last_appendix_status && getAuthAppendixStatusValueKind(request.last_appendix_status),
  mobileNumberChangeNotice: request.mobile_number_change_notice
    ? {
        id: request.mobile_number_change_notice?.id,
        changedBy: request.mobile_number_change_notice?.changed_by,
        createdAt: request.mobile_number_change_notice?.created_at,
        deletedAt: request.mobile_number_change_notice?.deleted_at,
        laborId: request.mobile_number_change_notice?.labor_id,
        newMobileNumber: request.mobile_number_change_notice?.new_mobile_number,
        oldMobileNumber: request.mobile_number_change_notice?.old_mobile_number,
        status: request.mobile_number_change_notice?.status,
        updatedAt: request.mobile_number_change_notice?.updated_at,
      }
    : null,
  uuid: request.uuid || null,
  pendingAppendix: request.pending_appendix
    ? {
        id: request.pending_appendix.id,
        status: request.pending_appendix.status,
        statusValueKind: getAuthAppendixStatusValueKind(request.pending_appendix.status),
        updatedAt: formatDateForUI(request.pending_appendix.updated_at),
        approvedAt: formatDateForUI(request.pending_appendix.approved_at),
        authenticatedContractId: request.pending_appendix.authenticated_contract_id,
        createdAt: formatDateForUI(request.pending_appendix.created_at),
        deletedAt: formatDateForUI(request.pending_appendix.deleted_at),
        durationInMonths: request.pending_appendix.duration_in_months,
        salary: request.pending_appendix.salary,
        startDateDetails: request.pending_appendix.start_date_details
          ? {
              day: {
                id: 0,
                label: request.pending_appendix.start_date_details?.day || '',
                labelEn: request.pending_appendix.start_date_details?.day_en || '',
              },
              gregorian: request.pending_appendix.start_date_details?.gregorian,
              hijri: request.pending_appendix.start_date_details?.hijri,
            }
          : null,
        uuid: request.pending_appendix.uuid,
      }
    : null,
})

export const convertContractAuthDetailedRequestFromResponse = (
  request: GetContractAuthDetailedRequestResponse,
): ContractAuthDetailedRequest => ({
  durationInMonth: request.authenticated_contract.duration_in_months,
  salary: request.authenticated_contract.new_salary,
  status: request.authenticated_contract.status,
  employerDetails: {
    name: request.authenticated_contract.employer_details.name,
    nationality: request.authenticated_contract.employer_details.nationality,
    idNumber: request.authenticated_contract.employer_details.id_number,
    childrenUnderTwelveCount: request.authenticated_contract.employer_details.children_under_twelve_count,
    address: request.authenticated_contract.employer_details.address,
    mobileNumber: request.authenticated_contract.employer_details.mobile_number,
    extraMobileNumber: request.authenticated_contract.employer_details.extra_mobile_number,
    email: request.authenticated_contract.employer_details.email,
    job: request.authenticated_contract.employer_details.job,
    familySize: request.authenticated_contract.employer_details.family_size,
  },
  labor: request.authenticated_contract?.labor
    ? {
        name: {
          label: request.authenticated_contract.labor?.name || '',
          labelEn: request.authenticated_contract.labor?.name_en || '',
          id: 0,
        },
        birthDate: request.authenticated_contract.labor?.birth_date || '',
        iqamaNumber: request.authenticated_contract.labor?.iqama_number || '',
        mobileNumber: request.authenticated_contract.labor?.mobile_number || '',
      }
    : null,
  laborDetails: {
    nationality: formatNationalityFromBE(request.authenticated_contract.labor_details.nationality),
    occupation: formatOccupationsFromBE(request.authenticated_contract.labor_details.occupation),
    religion: request.authenticated_contract.labor_details.religion,
    passportExpiryDate: request.authenticated_contract.labor_details.passport_expiry_date,
    passportNumber: request.authenticated_contract.labor_details.passport_number,
    relativeName: request.authenticated_contract.labor_details.relative_name,
    relativeMobileNumber: request.authenticated_contract.labor_details.relative_mobile_number,
    relativeRelation: request.authenticated_contract.labor_details.relative_relation,
    martialStatus: request.authenticated_contract.labor_details.martial_status,
    iqamaExpiryDate: request.authenticated_contract.labor_details.iqama_expiry_date,
    mobileNumber: request.authenticated_contract.labor_details.mobile_number,
  },
  residencyDetails: {
    place: request.authenticated_contract.residency_details.place,
    address: request.authenticated_contract.residency_details.address,
    description: request.authenticated_contract.residency_details.description,
    residentsNumber: request.authenticated_contract.residency_details.residents_number,
    additionalNumber: request.authenticated_contract.residency_details.additional_number,
    buildingNumber: request.authenticated_contract.residency_details.building_number,
    city: request.authenticated_contract.residency_details.city,
    district: request.authenticated_contract.residency_details.district,
    postalCode: request.authenticated_contract.residency_details.postal_code,
    region: request.authenticated_contract.residency_details.region,
    streetName: request.authenticated_contract.residency_details.street_name,
  },
  occupationDescriptions: request.authenticated_contract.occupation_descriptions.map((desc) => ({
    id: desc.id,
    label: desc.label_ar,
    labelEn: desc.label_en,
  })),
  startDateDetails: {
    day: {
      id: 0,
      label: request.authenticated_contract.start_date_details?.day || '',
      labelEn: request.authenticated_contract.start_date_details?.day_en || '',
    },
    gregorian: request.authenticated_contract.start_date_details?.gregorian || '',
    hijri: request.authenticated_contract.start_date_details?.hijri || '',
  },
  approvedAppendixes: request.authenticated_contract.approved_appendixes
    ? request.authenticated_contract.approved_appendixes?.map((appendix) => ({
        id: appendix.id,
        status: appendix.status,
        statusValueKind: getAuthAppendixStatusValueKind(appendix.status),
        updatedAt: formatDateForUI(appendix.updated_at),
        approvedAt: formatDateForUI(appendix.approved_at),
        authenticatedContractId: appendix.authenticated_contract_id,
        createdAt: formatDateForUI(appendix.created_at),
        deletedAt: formatDateForUI(appendix.deleted_at),
        durationInMonths: appendix.duration_in_months,
        salary: appendix.salary,
        startDateDetails: appendix.start_date_details
          ? {
              day: {
                id: 0,
                label: appendix.start_date_details?.day || '',
                labelEn: appendix.start_date_details?.day_en || '',
              },
              gregorian: appendix.start_date_details?.gregorian,
              hijri: appendix.start_date_details?.hijri,
            }
          : null,
        uuid: appendix.uuid,
        laborDetails: appendix.labor_details
          ? {
              nationality: formatNationalityFromBE(request.authenticated_contract.labor_details.nationality),
              occupation: formatOccupationsFromBE(request.authenticated_contract.labor_details.occupation),
              religion: appendix.labor_details.religion,
              passportExpiryDate: appendix.labor_details.passport_expiry_date,
              passportNumber: appendix.labor_details.passport_number,
              relativeName: appendix.labor_details.relative_name,
              relativeMobileNumber: appendix.labor_details.relative_mobile_number,
              relativeRelation: appendix.labor_details.relative_relation,
              martialStatus: appendix.labor_details.martial_status,
              iqamaExpiryDate: appendix.labor_details.iqama_expiry_date,
              mobileNumber: appendix.labor_details.mobile_number,
            }
          : null,
        residencyDetails: appendix.residency_details
          ? {
              place: appendix.residency_details.place,
              address: appendix.residency_details.address,
              description: appendix.residency_details.description,
              residentsNumber: appendix.residency_details.residents_number,
              additionalNumber: appendix.residency_details.additional_number,
              buildingNumber: appendix.residency_details.building_number,
              city: appendix.residency_details.city,
              district: appendix.residency_details.district,
              postalCode: appendix.residency_details.postal_code,
              region: appendix.residency_details.region,
              streetName: appendix.residency_details.street_name,
            }
          : null,
      }))
    : [],
  uuid: request.authenticated_contract.uuid || '',
  createdAt: request.authenticated_contract.created_at,
  pendingAppendix: request.authenticated_contract.pending_appendix
    ? {
        id: request.authenticated_contract.pending_appendix.id,
        status: request.authenticated_contract.pending_appendix.status,
        statusValueKind: getAuthAppendixStatusValueKind(request.authenticated_contract.pending_appendix.status),
        updatedAt: formatDateForUI(request.authenticated_contract.pending_appendix.updated_at),
        approvedAt: formatDateForUI(request.authenticated_contract.pending_appendix.approved_at),
        authenticatedContractId: request.authenticated_contract.pending_appendix.authenticated_contract_id,
        createdAt: formatDateForUI(request.authenticated_contract.pending_appendix.created_at),
        deletedAt: formatDateForUI(request.authenticated_contract.pending_appendix.deleted_at),
        durationInMonths: request.authenticated_contract.pending_appendix.duration_in_months,
        salary: request.authenticated_contract.pending_appendix.salary,
        startDateDetails: request.authenticated_contract.pending_appendix.start_date_details
          ? {
              day: {
                id: 0,
                label: request.authenticated_contract.pending_appendix.start_date_details?.day || '',
                labelEn: request.authenticated_contract.pending_appendix.start_date_details?.day_en || '',
              },
              gregorian: request.authenticated_contract.pending_appendix.start_date_details?.gregorian,
              hijri: request.authenticated_contract.pending_appendix.start_date_details?.hijri,
            }
          : null,
        uuid: request.authenticated_contract.pending_appendix.uuid,
      }
    : null,
  legalText: request.authenticated_contract.legal_text
    ? {
        pdfIdAr: request.authenticated_contract.legal_text.ar_file_id,
        pdfIdEn: request.authenticated_contract.legal_text.en_file_id,
      }
    : null,
})

export type UncontractableReasons =
  | 'OCCUPATION_IS_NOT_DOMESTIC'
  | 'LABOR_STATUS_IS_NOT_VALID'
  | 'LABOR_IS_OUTSIDE_KSA'
  | 'LABOR_OCCUPATION_OR_NATIONALITY_IS_NOT_ALLOWED'
  | null

export type AuthContractLaborer = {
  id: string
  authenticationStatus: AuthContractLaborerStatus
  authenticationStatusValueKind: string
  nationality: Nationality & {
    isContractAuthenticationAllowed: boolean
  }
  occupation: Occupation
  borderNo: string
  name: string
  personalStatus: string
  idExpiryDate: string
  religion: string
  birthDate: string
  entryDate: string
  payrollAmt: string
  finalExitVisaIssued: boolean
  requestId?: number
  requestIdSource: 'CONTRACTS_AUTHENTICATION' | 'ETAWTHEEQ' | ''
  contractNo: string
  uncontractableReason: UncontractableReasons
}

const getAuthenticationStatusValueKind = (status: AuthContractLaborerStatus) => {
  switch (status) {
    case AuthContractLaborerStatus.UNAUTHENTICATED:
      return 'danger'
    case AuthContractLaborerStatus.AUTHENTICATED:
      return 'success'
    case AuthContractLaborerStatus.UNDER_PROCESS:
      return 'warning'
    default:
      return ''
  }
}

export const convertContractAuthEmloyerLaborers = (
  resp: GetEmployerLaborersResponse,
): StringRecord<AuthContractLaborer> =>
  resp.reduce(
    (acc, laborer) => ({
      ...acc,
      [laborer.id]: {
        id: laborer.id,
        authenticationStatus: laborer.authenticationStatus,
        occupation: formatOccupationsFromBE(
          laborer.occupation || {
            label: laborer.OccupationName || '',
            label_en: laborer.OccupationName || '',
            id: NaN,
          },
        ),
        nationality: {
          ...formatNationalityFromBE(
            laborer.nationality || {
              label: laborer.nationalityName || '',
              label_en: laborer.nationalityName || '',
              id: NaN,
              abbreviation: '',
              code: '',
            },
          ),
          isContractAuthenticationAllowed:
            laborer.nationality?.is_contract_authentication_allowed === '0' ? false : true, // '0' is false '1' or other string is true
        },
        borderNo: laborer.borderNo,
        name: laborer.name,
        authenticationStatusValueKind: getAuthenticationStatusValueKind(laborer.authenticationStatus),
        personalStatus: laborer.personalStatus,
        idExpiryDate: formatDateForUI(laborer.idExpiryDate),
        religion: laborer.religion,
        birthDate: formatDateForUI(laborer.birthDate),
        entryDate: formatDateForUI(laborer.entryDate),
        exitDate: formatDateForUI(laborer.entryDate, '/'),
        finalExitVisaIssued: laborer.finalExitVisaIssued === 'true',
        payrollAmt: laborer.payrollAmt ? laborer.payrollAmt.toString() : '',
        requestId: laborer.requestId,
        requestIdSource: laborer.requestIdSource || '',
        contractNo: laborer.contractNo,
        uncontractableReason: laborer.uncontractableReason,
      },
    }),
    {},
  )

export type LborerMobileCheck = {
  isUsed: boolean
}

export const convertMobileCheckResponse = (resp: CheckLaborerPhoneForContractAuthResponse) => ({
  isUsed: resp.used,
})

export const convertContractAuthAppendixRequestFromResponse = (
  request: GetContractAuthDetailedRequestResponse,
): ContractAuthAppendixRequest => {
  const appendixes = request.authenticated_contract.approved_appendixes || []
  return {
    salary: appendixes[appendixes.length - 1]?.salary || request.authenticated_contract.new_salary,
    durationInMonth:
      appendixes[appendixes.length - 1]?.duration_in_months || request.authenticated_contract.duration_in_months,
    passportNumber:
      appendixes[appendixes.length - 1]?.labor_details?.passport_number ||
      request.authenticated_contract.labor_details.passport_number,
    passportExpiryDate:
      appendixes[appendixes.length - 1]?.labor_details?.passport_expiry_date ||
      request.authenticated_contract.labor_details.passport_expiry_date,
    place:
      appendixes[appendixes.length - 1]?.residency_details?.place ||
      request.authenticated_contract.residency_details.place,
    description:
      appendixes[appendixes.length - 1]?.residency_details?.description ||
      request.authenticated_contract.residency_details.description,
    region:
      appendixes[appendixes.length - 1]?.residency_details?.region ||
      request.authenticated_contract.residency_details.region,
    city:
      appendixes[appendixes.length - 1]?.residency_details?.city ||
      request.authenticated_contract.residency_details.city,
    district:
      appendixes[appendixes.length - 1]?.residency_details?.district ||
      request.authenticated_contract.residency_details.district,
    streetName:
      appendixes[appendixes.length - 1]?.residency_details?.street_name ||
      request.authenticated_contract.residency_details.street_name,
    buildingNumber:
      appendixes[appendixes.length - 1]?.residency_details?.building_number ||
      request.authenticated_contract.residency_details.building_number,
    additionalNumber:
      appendixes[appendixes.length - 1]?.residency_details?.additional_number ||
      request.authenticated_contract.residency_details.additional_number,
    postalCode:
      appendixes[appendixes.length - 1]?.residency_details?.postal_code ||
      request.authenticated_contract.residency_details.postal_code,
  }
}
