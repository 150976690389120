import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { CheckFarmerTransferabilityResponse, FarmerTransferabilityFailResponse } from 'api/evisaAPI/farmerTransfer'
import { FarmerLabor } from 'common/types/transformedRespTypes'
import { FarmerTransferabilityErrorMessages } from 'common/types/commonTypes'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'

type State = {
  labor?: FarmerLabor
  errorMessage?: FarmerTransferabilityErrorMessages
} & Status

const initialState: State = {
  labor: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (res: CheckFarmerTransferabilityResponse): FarmerLabor => ({
  name: res.laborerName,
  occupation: formatOccupationsFromBE(res.occupation),
  nationality: formatNationalityFromBE(res.nationality),
  id: res.iqamaNumber,
  establishmentName: res.establishmentName,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_FARMER_TRANSFERABILITY_ASYNC,
    Actions.CHECK_FARMER_TRANSFERABILITY_SUCCESS,
    Actions.CHECK_FARMER_TRANSFERABILITY_FAIL,
    CheckFarmerTransferabilityResponse,
    FarmerTransferabilityFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_FARMER_TRANSFERABILITY_SUCCESS:
      return {
        ...state,
        ...action.status,
        labor: convertResponseToState(action.payload),
      }
    case Actions.CHECK_FARMER_TRANSFERABILITY_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.CHECK_FARMER_TRANSFERABILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || FarmerTransferabilityErrorMessages.DEFAULT_ERROR,
      }
    }
    default:
      return state
  }
}
