import { LaborerLoginFailResponse } from 'api/laborerPortalAPI/auth'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  error?: string
} & Status

const initialState = {
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.LABORER_LOGIN_ASYNC,
    Actions.LABORER_LOGIN_SUCCESS,
    Actions.LABORER_LOGIN_FAIL,
    {},
    LaborerLoginFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.LABORER_LOGIN_ASYNC:
    case Actions.LABORER_LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.LABORER_LOGIN_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.message || action.payload?.error?.message,
      }
    default:
      return state
  }
}
