import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { RejectAuthRequestResponse } from 'api/laborerPortalAPI/authContract'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.REJECT_LABORER_AUTH_REQUEST_ASYNC,
    Actions.REJECT_LABORER_AUTH_REQUEST_SUCCESS,
    Actions.REJECT_LABORER_AUTH_REQUEST_FAIL,
    RejectAuthRequestResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.REJECT_LABORER_AUTH_REQUEST_ASYNC:
    case Actions.REJECT_LABORER_AUTH_REQUEST_SUCCESS:
    case Actions.REJECT_LABORER_AUTH_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
