import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { CancelLaborerReportFailResponse, CancelLaborerReportResponse } from 'api/contractAuthAPI'

type State = {
  errorMessage?: string
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CANCEL_LABORER_REPORT_ASYNC,
    Actions.CANCEL_LABORER_REPORT_SUCCESS,
    Actions.CANCEL_LABORER_REPORT_FAIL,
    CancelLaborerReportResponse,
    CancelLaborerReportFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CANCEL_LABORER_REPORT_ASYNC:
    case Actions.CANCEL_LABORER_REPORT_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CANCEL_LABORER_REPORT_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message,
      }
    }
    default:
      return state
  }
}
