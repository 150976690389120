import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetCurrentLaborerResponse } from 'api/wpsAPI'
import {
  getEmptyLaborer,
  getEmptySponsor,
  convertLaborerFromResponse,
  Laborer,
  Sponsor,
  convertSponsorFromResponse,
} from 'common/utils/wpsConvertResponse'

type State = {
  laborer: Laborer
  sponsor: Sponsor
} & Status

const initialState = {
  laborer: getEmptyLaborer(),
  sponsor: getEmptySponsor(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WPS_CURRENT_LABORER_ASYNC,
    Actions.GET_WPS_CURRENT_LABORER_SUCCESS,
    Actions.GET_WPS_CURRENT_LABORER_FAIL,
    GetCurrentLaborerResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_WPS_CURRENT_LABORER_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        laborer: convertLaborerFromResponse(payload.LaborInfo),
        sponsor: convertSponsorFromResponse(payload.SponsorInfo),
      }
    }
    case Actions.GET_WPS_CURRENT_LABORER_ASYNC:
    case Actions.GET_WPS_CURRENT_LABORER_FAIL: {
      return {
        ...state,
        ...action.status,
        laborer: getEmptyLaborer(),
        sponsor: getEmptySponsor(),
      }
    }
    default:
      return state
  }
}
