import { FailResponse } from 'api/evisaAPI/dlAPI'
import { VerifyAgentResponse } from 'api/evisaAPI/evisaAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  fullName: string
  mobile: string
  errorMessage: string
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  fullName: '',
  mobile: '',
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.VERIFY_AGENT_ASYNC,
    Actions.VERIFY_AGENT_SUCCESS,
    Actions.VERIFY_AGENT_FAIL,
    VerifyAgentResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.VERIFY_AGENT_SUCCESS:
      return {
        ...state,
        ...action.status,
        fullName: action.payload.fullName,
        mobile: action.payload.mobile,
      }
    case Actions.VERIFY_AGENT_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    case Actions.VERIFY_AGENT_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
