import { CallAPIAction, Status } from 'api/types'
import { GetLastFourVisasResponse, VisaInfoFromBE } from 'api/evisaAPI/visas'
import { convertVisaResponseToState } from 'common/utils/convertResponse/convertVisaResponseToState'
import { Visa } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  visas: StringRecord<Visa>
  lastPage: number
} & Status

const initialState = {
  visas: {},
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: VisaInfoFromBE[]): StringRecord<Visa> =>
  resp.reduce(
    (acc, visa) => ({
      ...acc,
      [visa.id]: convertVisaResponseToState({ visa }),
    }),
    {},
  )

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LAST_FOUR_VISAS_ASYNC,
    Actions.GET_LAST_FOUR_VISAS_SUCCESS,
    Actions.GET_LAST_FOUR_VISAS_FAIL,
    GetLastFourVisasResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LAST_FOUR_VISAS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LAST_FOUR_VISAS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        visas: convertResponseToState(payload),
      }
    }
    case Actions.GET_LAST_FOUR_VISAS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
