import { Status, CallAPIAction } from 'api/types'
import type { CheckEligibilityFailResponse } from 'api/evisaAPI/visas'
import { UserEligibilityErrorMessages } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage?: UserEligibilityErrorMessages
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_ASYNC,
    Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_SUCCESS,
    Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_FAIL,
    {},
    CheckEligibilityFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_ASYNC:
    case Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || UserEligibilityErrorMessages.DEFAULT_ERROR,
      }
    }
    default:
      return state
  }
}
