import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { APP_ENV, APP_SENTRY_DSN, IS_LOCAL_DEVELOPMENT_ENV, REACT_APP_GIT_SHA } from 'common/envConstants'
import authManager from 'common/utils/auth/authManager'
import { loadExternalScripts } from 'common/utils/externalScripts'
import { initVhWithoutURLBar } from 'common/utils/viewportCalculate'
import App from 'containers/App'
import ErrorBoundary from 'containers/ErrorBoundary/ErrorBoundary'
import store from 'redux/store'
import { history } from 'redux/browserHistory'
import apiExposer from 'common/utils/labibaChatBot/apiExposer'

import * as serviceWorker from './serviceWorker'

import 'i18n'
import 'styles/index.scss'

// eslint-disable-next-line no-console
console.info(`%cVersion: ${REACT_APP_GIT_SHA || 'develop'}`, 'color: #58abc8;')

Sentry.init({ dsn: APP_SENTRY_DSN, release: REACT_APP_GIT_SHA, environment: APP_ENV })
Sentry.withScope((scope) => {
  scope.setUser({ id: authManager.getUserNameFromEvisaToken() })
  scope.setLevel(Sentry.Severity.Debug)
})

initVhWithoutURLBar()
apiExposer.exposeAPI()

// add external scripts after app script was loaded to improve performance
// should be placed before the app initializing since app used some of the scripts even if they loaded asynchronously
loadExternalScripts()

// Function to render the app with react-axe (for accessibility) in development mode
const renderAppWithAxe = () => {
  import('react-axe').then((axe) => {
    axe.default(React, ReactDOM, 1000)
    renderApp()
  })
}

// Function to render the app without react-axe
const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
  )
}

// Check if we are in development mode and render accordingly
if (IS_LOCAL_DEVELOPMENT_ENV) {
  renderAppWithAxe()
} else {
  renderApp()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
