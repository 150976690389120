import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetPendingAppendixResponse } from 'api/laborerPortalAPI/authContract'
import { GeneralFailResponse } from 'common/types/commonTypes'

type State = {
  legalTextAr: string
  legalTextEn: string
} & Status

const initialState = {
  legalTextAr: '',
  legalTextEn: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PENDING_LABORER_APPENDIX_ASYNC,
    Actions.GET_PENDING_LABORER_APPENDIX_SUCCESS,
    Actions.GET_PENDING_LABORER_APPENDIX_FAIL,
    GetPendingAppendixResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PENDING_LABORER_APPENDIX_SUCCESS: {
      return {
        ...state,
        ...action.status,
        legalTextAr: action.payload.legalTextAr,
        legalTextEn: action.payload.legalTextEn,
      }
    }
    case Actions.GET_PENDING_LABORER_APPENDIX_FAIL:
    case Actions.GET_PENDING_LABORER_APPENDIX_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
