import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'

import { callAPI, wpsAPIWithCredentials } from './axios'

export type SponsorFromBE = {
  SponsorId: string
  SponsorName: string
}

export type LaborerFromBE = {
  LaborName: string
  LaborID: string | null
  LaborBorder: string
  LaborNationality: string
  OccupationName: string
  OccupationNameEn: string
  ContractNo?: string
  commitment_percentage: number | null
  PayrollAmt: string
}

export type TransactionFromBE = {
  id: number
  payment_reference: string
  type: string
  for_month: string | null | [string, string]
  payroll_amount: number
  amount: number
  commitment_percentage: number | null
  payment_timestamp: string
  notes: string | null
  channel: string
  bank_code: string
  deducted_amount: number | null
  percentage_of_deduction: number | null
  violation_type: string | null
  justification_description: string | null
}

export type GetLaborersListParams = {
  birthDate: string
}

export type GetLaborersListResponse = {
  LaborInfoList: Array<LaborerFromBE>
}

const getLaborersList = ({ birthDate, ...params }: GetLaborersListParams, config?: AxiosRequestConfig) =>
  wpsAPIWithCredentials.get<GetLaborersListResponse>(`api/v1.0/musaned/employer/${birthDate}/laborers`, {
    params,
    ...config,
  })

export type GetCurrentLaborerParams = {
  laborerId: string | number
  birthDate: string
}

export type GetCurrentLaborerResponse = {
  LaborInfo: LaborerFromBE
  SponsorInfo: SponsorFromBE
}

const getCurrentLabor = ({ laborerId, birthDate, ...params }: GetCurrentLaborerParams, config?: AxiosRequestConfig) =>
  wpsAPIWithCredentials.get<GetCurrentLaborerResponse>(
    `api/v1.0/musaned/employer/${birthDate}/laborers/${laborerId}`,
    {
      params,
      ...config,
    },
  )

export type GetTransactionsParams = {
  laborerId: string | number
  perPage?: number
  page?: number
}

export type GetTransactionsResponse = {
  data: Array<TransactionFromBE>
  last_page: number
}

const getTransactions = (
  { laborerId, perPage = LIST_ITEMS_PER_PAGE, page = 0 }: GetTransactionsParams,
  config?: AxiosRequestConfig,
) =>
  wpsAPIWithCredentials.get<GetTransactionsResponse>(`api/v1.0/musaned/laborers/${laborerId}/transactions`, {
    params: {
      per_page: perPage,
      page: page + 1,
    },
    ...config,
  })

export type GetCurrentTransactionParams = {
  laborerId: string
  transactionId: string
}

export type GetCurrentTransactionResponse = TransactionFromBE

const getCurrentTransaction = (
  { laborerId, transactionId }: GetCurrentTransactionParams,
  config?: AxiosRequestConfig,
) =>
  wpsAPIWithCredentials.get<GetCurrentTransactionResponse>(
    `api/v1.0/musaned/laborers/${laborerId}/transactions/${transactionId}`,
    config,
  )

export type GetCommitmentsStatisticParams = {
  year?: string | number
  interval?: 'monthly' | 'quarterly'
}

export type GetCommitmentsStatisticResponse = Array<{
  value: number
  interval: number
}>

const getCommitmentsStatistic = (params: GetCommitmentsStatisticParams, config?: AxiosRequestConfig) =>
  wpsAPIWithCredentials.get<GetCommitmentsStatisticResponse>('api/v1.0/musaned/employer/commitments', {
    params,
    ...config,
  })

export default {
  getCommitmentsStatistic: callAPI<typeof getCommitmentsStatistic, GetCommitmentsStatisticResponse>(
    getCommitmentsStatistic,
  ),
  getLaborersList: callAPI<typeof getLaborersList, GetLaborersListResponse>(getLaborersList),
  getCurrentLabor: callAPI<typeof getCurrentLabor, GetCurrentLaborerResponse>(getCurrentLabor),
  getTransactions: callAPI<typeof getTransactions, GetTransactionsResponse>(getTransactions),
  getCurrentTransaction: callAPI<typeof getCurrentTransaction, GetCurrentTransactionResponse>(getCurrentTransaction),
}
