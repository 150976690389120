import type { CallAPIAction, Status } from 'api/types'
import { GetProServiceDetailsResponse } from 'api/prosAPI'
import Actions from 'redux/actions'

type State = {
  details: {
    serviceTemplate?: {
      bankFees: number
    }
  }
} & Status

const convertResponseToState = (resp: GetProServiceDetailsResponse) => ({
  details: {
    serviceTemplate: resp.service_template
      ? {
          bankFees: resp.service_template.bank_fees,
        }
      : undefined,
  },
})

const initialState: State = {
  details: {
    serviceTemplate: undefined,
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_SERVICE_DETAILS_ASYNC,
    Actions.GET_PRO_SERVICE_DETAILS_SUCCESS,
    Actions.GET_PRO_SERVICE_DETAILS_FAIL,
    GetProServiceDetailsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_SERVICE_DETAILS_ASYNC:
    case Actions.GET_PRO_SERVICE_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PRO_SERVICE_DETAILS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    default:
      return state
  }
}
