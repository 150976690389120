import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  laborerLogin as laborerLoginAction,
  laborerOtpVerify as laborerOtpVerifyAction,
  laborerRefreshToken as laborerRefreshTokenAction,
} from 'redux/actionCreators/laborerPortalAPI/auth'
import authAPI from 'api/laborerPortalAPI/auth'
import { getSimpleRequestHandler } from 'saga/utils'

const laborerLogin = getSimpleRequestHandler<typeof authAPI.laborerLogin, ReturnType<typeof laborerLoginAction>>({
  actionLoading: Actions.LABORER_LOGIN_ASYNC,
  actionSuccess: Actions.LABORER_LOGIN_SUCCESS,
  actionFail: Actions.LABORER_LOGIN_FAIL,
  callApiFn: authAPI.laborerLogin,
})

const laborerOtpVerify = getSimpleRequestHandler<
  typeof authAPI.laborerOtpVerify,
  ReturnType<typeof laborerOtpVerifyAction>
>({
  actionLoading: Actions.LABORER_OTP_VERIFY_ASYNC,
  actionSuccess: Actions.LABORER_OTP_VERIFY_SUCCESS,
  actionFail: Actions.LABORER_OTP_VERIFY_FAIL,
  callApiFn: authAPI.laborerOtpVerify,
})

const laborerRefreshToken = getSimpleRequestHandler<
  typeof authAPI.refreshLaborerToken,
  ReturnType<typeof laborerRefreshTokenAction>
>({
  actionLoading: Actions.REFRESH_LABORER_TOKEN_ASYNC,
  actionSuccess: Actions.REFRESH_LABORER_TOKEN_SUCCESS,
  actionFail: Actions.REFRESH_LABORER_TOKEN_FAIL,
  callApiFn: authAPI.refreshLaborerToken,
})

export default [
  takeLatest(Actions.LABORER_LOGIN, laborerLogin),
  takeLatest(Actions.LABORER_OTP_VERIFY, laborerOtpVerify),
  takeLatest(Actions.REFRESH_LABORER_TOKEN, laborerRefreshToken),
]
