import type { CallAPIAction, Status } from 'api/types'
import { getETawtheeqContract } from 'redux/actionCreators/eContractingAPI'
import Actions from 'redux/actions'

type State = {
  request: {
    requestId: number
    offerId: number
  }
  eTawseqContractUrl: string
} & Status

const initialState = {
  request: {
    requestId: 0,
    offerId: 0,
    contractId: 0,
  },
  eTawseqContractUrl: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ETAWTHEQ_CONTRACT_ASYNC,
    Actions.GET_ETAWTHEQ_CONTRACT_SUCCESS,
    Actions.GET_ETAWTHEQ_CONTRACT_FAIL,
    {
      eTawseqContractUrl: string
    },
    {},
    {},
    ReturnType<typeof getETawtheeqContract>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_ETAWTHEQ_CONTRACT_ASYNC: {
      return {
        ...state,
        ...action.status,
        request: {
          requestId: action.callAPIActionPayload.params.requestId,
          offerId: action.callAPIActionPayload.params.offerId,
        },
      }
    }
    case Actions.GET_ETAWTHEQ_CONTRACT_SUCCESS: {
      return {
        ...state,
        ...action.status,
        eTawseqContractUrl: action.payload.eTawseqContractUrl,
      }
    }
    case Actions.GET_ETAWTHEQ_CONTRACT_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
