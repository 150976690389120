import { CallAPIAction, Status } from 'api/types'
import type { GetComplaintRatingsV2Response } from 'api/jiraAPI'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  ratings: StringRecord<string>
} & Status

const initialState = {
  ratings: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: GetComplaintRatingsV2Response) =>
  resp.row_positions.reduce(
    (acc, item, index) => ({
      ...acc,
      [item]: `${parseInt((resp.values[index] * 100).toString(), 10)}%`,
    }),
    {},
  )

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_COMPLAINT_RATINGS_V2_ASYNC,
    Actions.GET_COMPLAINT_RATINGS_V2_SUCCESS,
    Actions.GET_COMPLAINT_RATINGS_V2_FAIL,
    GetComplaintRatingsV2Response
  >,
): State => {
  switch (action.type) {
    case Actions.GET_COMPLAINT_RATINGS_V2_SUCCESS: {
      return {
        ...state,
        ratings: convertResponseToState(action.payload),
        ...action.status,
      }
    }
    case Actions.GET_COMPLAINT_RATINGS_V2_ASYNC:
    case Actions.GET_COMPLAINT_RATINGS_V2_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
