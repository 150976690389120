import { AddFinancialDocumentsResponse } from 'api/newDLTransferAPI'
import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_SUCCESS,
    Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_ASYNC,
    Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_FAIL,
    AddFinancialDocumentsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_SUCCESS:
    case Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_ASYNC:
    case Actions.ADD_E_TO_I_FINANCIAL_DOCUMENTS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
