import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { CheckAgriculturalFileResponse } from 'api/evisaAPI/farmerVisa'
import { GeneralFailResponse } from 'common/types/commonTypes'
import { FarmerType, RegistrationFile } from 'common/types/transformedRespTypes'

type State = {
  farmerTypes?: Array<FarmerType>
  registrationFile?: RegistrationFile
  token: string
  errorMessage: string
} & Status

const initialState = {
  farmerTypes: [],
  registrationFile: undefined,
  token: '',
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: CheckAgriculturalFileResponse) => {
  const { registrationFile, token, farm_types } = resp
  return {
    farmerTypes: farm_types?.map((item) => ({
      id: parseInt(item.id),
      label: item.label,
    })),
    registrationFile: {
      registrationNumber: registrationFile.RegistrationNumber,
      startDate: registrationFile.StartDate.replaceAll('=>', ':'),
      endDatetDate: registrationFile.EndDatetDate.replaceAll('=>', ':'),
      region: registrationFile.Region,
      city: registrationFile.City,
      village: registrationFile.Village,
      farmType: registrationFile.FarmType,
      farmName: registrationFile.FarmName,
      location: {
        longitude: registrationFile.Location.Longitude,
        latitude: registrationFile.Location.Latitude,
      },
      owner: {
        idNumber: registrationFile.Owner.IdNumber,
        fullName: `مزرعة ${registrationFile?.Owner.FullName}`,
      },
    },
    token,
  }
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_AGRICULTURAL_FILE_ASYNC,
    Actions.CHECK_AGRICULTURAL_FILE_SUCCESS,
    Actions.CHECK_AGRICULTURAL_FILE_FAIL,
    CheckAgriculturalFileResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_AGRICULTURAL_FILE_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_AGRICULTURAL_FILE_SUCCESS: {
      return {
        ...state,
        ...convertResponseToState(action.payload),
        ...action.status,
      }
    }
    case Actions.CHECK_AGRICULTURAL_FILE_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
