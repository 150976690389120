import { ContractAuthAppendixRequest } from 'common/utils/convertResponse/convertAuthApiResponse'
import { setAuthContractAppendixData } from 'redux/actionCreators/contractAuthAPI'

import Actions from '../actions'

const initialState: ContractAuthAppendixRequest = {
  salary: '',
  durationInMonth: 0,
  passportNumber: '',
  passportExpiryDate: '',
  place: '',
  description: '',
  region: '',
  city: '',
  district: '',
  streetName: '',
  buildingNumber: '',
  additionalNumber: '',
  postalCode: '',
}

export default (
  state: ContractAuthAppendixRequest = initialState,
  action: ReturnType<typeof setAuthContractAppendixData>,
) => {
  switch (action.type) {
    case Actions.SET_AUTH_CONTRACT_APPENDIX_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
