import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetCommitmentsStatisticResponse } from 'api/wpsAPI'
import type { DataGrid } from 'containers/WageProtectionPages/WageProtectionListPage/components/Commitment/LineGraph/LineGraph'

type State = {
  statistic: DataGrid
} & Status

const initialState = {
  statistic: [],
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: GetCommitmentsStatisticResponse) =>
  resp.map((val) => ({
    position: {
      x: val.interval,
      y: val.value,
    },
    label: `${Math.round(val.value)}%`,
  }))

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WPS_COMMITMENTS_STATISTIC_ASYNC,
    Actions.GET_WPS_COMMITMENTS_STATISTIC_SUCCESS,
    Actions.GET_WPS_COMMITMENTS_STATISTIC_FAIL,
    GetCommitmentsStatisticResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_WPS_COMMITMENTS_STATISTIC_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        statistic: convertResponseToState(payload),
      }
    }
    case Actions.GET_WPS_COMMITMENTS_STATISTIC_ASYNC:
    case Actions.GET_WPS_COMMITMENTS_STATISTIC_FAIL: {
      return {
        ...state,
        ...action.status,
        statistic: [],
      }
    }
    default:
      return state
  }
}
