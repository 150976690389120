import type {
  GetMarketOffersParams,
  GetMaarofaOfficesParams,
  GetOfficeInfoParams,
  GetProServiceDetailsParams,
  GetProServiceAddonsParams,
  GetVipProsParams,
  SelectVipProsParams,
} from 'api/prosAPI'

import Actions from '../actions'

export const getRecruitmentDurationRange = () => ({
  type: Actions.GET_DURATION_RANGE,
})

export const getMarketOffers = (params: GetMarketOffersParams) => ({
  type: Actions.GET_MARKET_OFFERS,
  payload: { params },
})

export const getAllMarketOffers = (params: GetMarketOffersParams) => ({
  type: Actions.GET_ALL_MARKET_OFFERS,
  payload: { params },
})

export const getOfficeInfo = (params: GetOfficeInfoParams) => ({
  type: Actions.GET_OFFICE_INFO,
  payload: { params },
})

export const getMaarofaServiceTemplate = () => ({
  type: Actions.GET_MAAROFA_SERVICE_TEMPLATE,
})

export const getMaarofaOffices = (params: GetMaarofaOfficesParams) => ({
  type: Actions.GET_MAAROFA_OFFICES,
  payload: { params },
})

export const getPriceRange = () => ({
  type: Actions.GET_PRICE_RANGE,
})

export const getProServiceDetails = (params: GetProServiceDetailsParams) => ({
  type: Actions.GET_PRO_SERVICE_DETAILS,
  payload: { params },
})

export const getProServiceAddons = (params: GetProServiceAddonsParams) => ({
  type: Actions.GET_PRO_SERVICE_ADDONS,
  payload: { params },
})

export const getProServiceAddonsAll = (params: GetProServiceAddonsParams) => ({
  type: Actions.GET_PRO_SERVICE_ADDONS_ALL,
  payload: { params },
})

export const getProRecruitedServiceAddons = () => ({
  type: Actions.GET_PRO_RECRUITED_SERVICE_ADDONS,
})

export const getVipPros = (params: GetVipProsParams) => ({
  type: Actions.GET_VIP_PROS,
  payload: { params },
})

export const selectVipPros = (params: SelectVipProsParams) => ({
  type: Actions.SELECT_VIP_PRO,
  payload: { params },
})
