import { Status, CallAPIAction } from 'api/types'
import type { GetOccupationsResponse } from 'api/evisaAPI/evisaAPI'
import type { Occupation } from 'common/types/transformedRespTypes'
import { formatOccupationsFromBE } from 'common/utils/objectWithTranslation'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

const convertResponseToState = (resp: GetOccupationsResponse) =>
  resp.occupations.reduce(
    (acc, occupationFromBE) => ({
      ...acc,
      [occupationFromBE.id]: {
        ...formatOccupationsFromBE(occupationFromBE),
      },
    }),
    {},
  )

type State = {
  occupations: StringRecord<Occupation>
} & Status

const initialState = {
  occupations: {},
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_OCCUPATIONS_ASYNC,
    Actions.GET_OCCUPATIONS_SUCCESS,
    Actions.GET_OCCUPATIONS_FAIL,
    GetOccupationsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_OCCUPATIONS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_OCCUPATIONS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        occupations: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_OCCUPATIONS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
