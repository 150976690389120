import { all, call, select, takeLatest } from 'redux-saga/effects'

import { RootState } from 'redux/rootReducer'
import { Await } from 'common/types/commonTypes'

import Actions from '../../redux/actions'
import {
  getPriceRange as getPriceRangeAction,
  getRecruitmentDurationRange as getRecruitmentDurationRangeAction,
  getMarketOffers as getMarketOffersAction,
  getAllMarketOffers as getAllMarketOffersAction,
  getMaarofaOffices as getMaarofaOfficesAction,
  getOfficeInfo as getOfficeInfoACtion,
  getMaarofaServiceTemplate as getMaarofaServiceTemplateAction,
  getProServiceDetails as getProServiceDetailsAction,
  getProServiceAddons as getProServiceAddonsAction,
  getProRecruitedServiceAddons as getProRecruitedServiceAddonsAction,
  getVipPros as getVipProsAction,
  selectVipPros as selectVipProsAction,
} from '../../redux/actionCreators/prosAPI'
import prosAPI, { GetProServiceAddonsResponse } from '../../api/prosAPI'
import { getSimpleRequestHandler, putLoading, putSuccess } from '../utils'

const getPriceRange = getSimpleRequestHandler<typeof prosAPI.getPriceRange, ReturnType<typeof getPriceRangeAction>>({
  actionLoading: Actions.GET_PRICE_RANGE_ASYNC,
  actionSuccess: Actions.GET_PRICE_RANGE_SUCCESS,
  actionFail: Actions.GET_PRICE_RANGE_FAIL,
  callApiFn: prosAPI.getPriceRange,
})

const getRecruitmentDurationRange = getSimpleRequestHandler<
  typeof prosAPI.getRecruitmentDurationRange,
  ReturnType<typeof getRecruitmentDurationRangeAction>
>({
  actionLoading: Actions.GET_DURATION_RANGE_ASYNC,
  actionSuccess: Actions.GET_DURATION_RANGE_SUCCESS,
  actionFail: Actions.GET_DURATION_RANGE_FAIL,
  callApiFn: prosAPI.getRecruitmentDurationRange,
})

const getMarketOffers = getSimpleRequestHandler<
  typeof prosAPI.getMarketOffers,
  ReturnType<typeof getMarketOffersAction>
>({
  actionLoading: Actions.GET_MARKET_OFFERS_ASYNC,
  actionSuccess: Actions.GET_MARKET_OFFERS_SUCCESS,
  actionFail: Actions.GET_MARKET_OFFERS_FAIL,
  callApiFn: prosAPI.getMarketOffers,
})

const getAllMarketOffers = getSimpleRequestHandler<
  typeof prosAPI.getAllMarketOffers,
  ReturnType<typeof getAllMarketOffersAction>
>({
  actionLoading: Actions.GET_ALL_MARKET_OFFERS_ASYNC,
  actionSuccess: Actions.GET_ALL_MARKET_OFFERS_SUCCESS,
  actionFail: Actions.GET_ALL_MARKET_OFFERS_FAIL,
  callApiFn: prosAPI.getAllMarketOffers,
})

const getMaarofaOffices = getSimpleRequestHandler<
  typeof prosAPI.getMaarofaOffices,
  ReturnType<typeof getMaarofaOfficesAction>
>({
  actionLoading: Actions.GET_MAAROFA_OFFICES_ASYNC,
  actionSuccess: Actions.GET_MAAROFA_OFFICES_SUCCESS,
  actionFail: Actions.GET_MAAROFA_OFFICES_FAIL,
  callApiFn: prosAPI.getMaarofaOffices,
})

export const getOfficeInfo = getSimpleRequestHandler<
  typeof prosAPI.getOfficeInfo,
  ReturnType<typeof getOfficeInfoACtion>
>({
  actionLoading: Actions.GET_OFFICE_INFO_ASYNC,
  actionSuccess: Actions.GET_OFFICE_INFO_SUCCESS,
  actionFail: Actions.GET_OFFICE_INFO_FAIL,
  callApiFn: prosAPI.getOfficeInfo,
})

export const getMaarofaServiceTemplate = getSimpleRequestHandler<
  typeof prosAPI.getMaarofaServiceTemplate,
  ReturnType<typeof getMaarofaServiceTemplateAction>
>({
  actionLoading: Actions.GET_MAAROFA_SERVICE_TEMPLATE_ASYNC,
  actionSuccess: Actions.GET_MAAROFA_SERVICE_TEMPLATE_SUCCESS,
  actionFail: Actions.GET_MAAROFA_SERVICE_TEMPLATE_FAIL,
  callApiFn: prosAPI.getMaarofaServiceTemplate,
})

export const getProServiceDetails = getSimpleRequestHandler<
  typeof prosAPI.getProServiceDetails,
  ReturnType<typeof getProServiceDetailsAction>
>({
  actionLoading: Actions.GET_PRO_SERVICE_DETAILS_ASYNC,
  actionSuccess: Actions.GET_PRO_SERVICE_DETAILS_SUCCESS,
  actionFail: Actions.GET_PRO_SERVICE_DETAILS_FAIL,
  callApiFn: prosAPI.getProServiceDetails,
})

export const getProServiceAddons = getSimpleRequestHandler<
  typeof prosAPI.getProServiceAddons,
  ReturnType<typeof getProServiceAddonsAction>
>({
  actionLoading: Actions.GET_PRO_SERVICE_ADDONS_ASYNC,
  actionSuccess: Actions.GET_PRO_SERVICE_ADDONS_SUCCESS,
  actionFail: Actions.GET_PRO_SERVICE_ADDONS_FAIL,
  callApiFn: prosAPI.getProServiceAddons,
})

function* getProServiceAddonsAll(action: ReturnType<typeof getProServiceAddonsAction>) {
  const contractRequestInfo: RootState['contractRequestInfo'] = yield select(
    (state: RootState) => state.contractRequestInfo,
  )
  const offers = [...Object.values(contractRequestInfo.contractRequests.offers).map((item) => item?.serviceId || 0)]

  yield putLoading({ type: Actions.GET_PRO_SERVICE_ADDONS_ALL_ASYNC })

  const response: Array<Await<ReturnType<typeof prosAPI.getProServiceAddons>>> = yield all([
    ...offers.map((skip, index) =>
      call(getProServiceAddons, {
        ...action,
        payload: {
          params: {
            serviceId: offers[index] || 0,
          },
        },
      }),
    ),
  ])

  yield putSuccess({
    type: Actions.GET_PRO_SERVICE_ADDONS_ALL_SUCCESS,
    payload: response.map((item) => item?.response) as Array<GetProServiceAddonsResponse>,
  })
}

export const getProRecruitedServiceAddons = getSimpleRequestHandler<
  typeof prosAPI.getProRecruitedServiceAddons,
  ReturnType<typeof getProRecruitedServiceAddonsAction>
>({
  actionLoading: Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_ASYNC,
  actionSuccess: Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_SUCCESS,
  actionFail: Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_FAIL,
  callApiFn: prosAPI.getProRecruitedServiceAddons,
})

export const getVipPros = getSimpleRequestHandler<typeof prosAPI.getVipPros, ReturnType<typeof getVipProsAction>>({
  actionLoading: Actions.GET_VIP_PROS_ASYNC,
  actionSuccess: Actions.GET_VIP_PROS_SUCCESS,
  actionFail: Actions.GET_VIP_PROS_FAIL,
  callApiFn: prosAPI.getVipPros,
})

const selectVipPros = getSimpleRequestHandler<typeof prosAPI.selectVipPros, ReturnType<typeof selectVipProsAction>>({
  actionLoading: Actions.SELECT_VIP_PRO_ASYNC,
  actionSuccess: Actions.SELECT_VIP_PRO_SUCCESS,
  actionFail: Actions.SELECT_VIP_PRO_FAIL,
  callApiFn: prosAPI.selectVipPros,
})

export default [
  takeLatest(Actions.GET_PRO_SERVICE_DETAILS, getProServiceDetails),
  takeLatest(Actions.GET_MAAROFA_SERVICE_TEMPLATE, getMaarofaServiceTemplate),
  takeLatest(Actions.GET_PRICE_RANGE, getPriceRange),
  takeLatest(Actions.GET_DURATION_RANGE, getRecruitmentDurationRange),
  takeLatest(Actions.GET_MARKET_OFFERS, getMarketOffers),
  takeLatest(Actions.GET_ALL_MARKET_OFFERS, getAllMarketOffers),
  takeLatest(Actions.GET_MAAROFA_OFFICES, getMaarofaOffices),
  takeLatest(Actions.GET_OFFICE_INFO, getOfficeInfo),
  takeLatest(Actions.GET_PRO_SERVICE_ADDONS, getProServiceAddons),
  takeLatest(Actions.GET_PRO_SERVICE_ADDONS_ALL, getProServiceAddonsAll),
  takeLatest(Actions.GET_PRO_RECRUITED_SERVICE_ADDONS, getProRecruitedServiceAddons),
  takeLatest(Actions.GET_VIP_PROS, getVipPros),
  takeLatest(Actions.SELECT_VIP_PRO, selectVipPros),
]
