import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { GetSurveyQuestionsResponse } from 'api/jiraAPI'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

type State = {
  questions: Array<ObjectWithTranslation>
} & Status

const initialState = {
  questions: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_SURVEY_QUESTIONS_ASYNC,
    Actions.GET_SURVEY_QUESTIONS_SUCCESS,
    Actions.GET_SURVEY_QUESTIONS_FAIL,
    GetSurveyQuestionsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_SURVEY_QUESTIONS_FAIL:
    case Actions.GET_SURVEY_QUESTIONS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_SURVEY_QUESTIONS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        questions: [
          {
            label: action.payload.question_one_ar,
            labelEn: action.payload.question_one_en,
            id: 1,
          },
          {
            label: action.payload.question_two_ar,
            labelEn: action.payload.question_two_en,
            id: 2,
          },
        ],
      }
    }
    default:
      return state
  }
}
