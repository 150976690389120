import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetLaborersListResponse } from 'api/wpsAPI'
import { convertLaborerFromResponse, Laborer } from 'common/utils/wpsConvertResponse'

type State = {
  data: Array<Laborer>
} & Status

const convertResponseToState = (response: GetLaborersListResponse) =>
  response.LaborInfoList.map(convertLaborerFromResponse)

const initialState = {
  data: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WPS_LABORERS_LIST_ASYNC,
    Actions.GET_WPS_LABORERS_LIST_SUCCESS,
    Actions.GET_WPS_LABORERS_LIST_FAIL,
    GetLaborersListResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_WPS_LABORERS_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        data: convertResponseToState(payload),
      }
    }
    case Actions.GET_WPS_LABORERS_LIST_ASYNC:
    case Actions.GET_WPS_LABORERS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
        data: [],
      }
    }
    default:
      return state
  }
}
