import { AxiosRequestConfig } from 'axios'

import { callAPI, evisaAPI, evisaAPIWithCredentials } from '../axios'

export type AuthHeader = {
  'x-auth-token': string
}

export type LoginBody = {
  username: string
  password: string
  token?: string
}
export type LoginFailResponse = {
  error: {
    code: number
    message: string
  }
  errors?: {
    email?: Array<string>
  }
  message: string
}

export type MobileCheckFailResponse = {
  mobile?: Array<string>
}

export type LoginResponse = {
  verified: boolean
  mobile_active: boolean
  terms_accepted: boolean
  token?: string
  is_agent?: boolean
  for?: {
    name: string
    id_number: number
  }
}
const login = (body: LoginBody, config: AxiosRequestConfig) =>
  evisaAPI.post<LoginResponse>('/app/auth/login', body, config)

export type LoginNafathBody = {
  username: string
}
export type LoginNafathResponse = {
  random: number
}
const loginNafath = (body: LoginNafathBody, config: AxiosRequestConfig) =>
  evisaAPI.post<LoginNafathResponse>('/app/auth/login_nafath', { id_number: body.username }, config)

export type CheckNafathBody = {
  username: string
}
export type CheckNafathResponse = {
  authorized: boolean
  username: string
  mobile_active: boolean
  terms_accepted: boolean
  is_agent: boolean
  for: {
    name: string
    email: string
    id_number: number
    mobile: string
  } | null
}
const checkNafath = (body: CheckNafathBody, config: AxiosRequestConfig) =>
  evisaAPI.post<CheckNafathResponse>('/app/auth/login_nafath_check', { id_number: body.username }, config)

export type ActAsPremiumResponse = {
  token: string
}

export type ActAsPremiumBody = {
  username: string
}

const actAsPremium = (body: ActAsPremiumBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<ActAsPremiumResponse>(
    '/app/premium/agent/act_as_premium',
    { id_number: body.username },
    config,
  )

const actAsPremiumManager = (body: ActAsPremiumBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<ActAsPremiumResponse>('/app/premium/relationship-manager/act_as_premium', config)

export type VerifyBody = {
  code: string
  payload?: string
}
export type VerifyResponse = LoginResponse
export type VerifyFailResponse = LoginFailResponse
const verify = (body: VerifyBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<VerifyResponse>('/app/auth/verify', body, config)

export type ResendBody = {
  username: string | number
  digits?: number
}
export type ResendResponse = {}
const resend = (body: ResendBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<ResendResponse>('app/auth/resend', body, config)

export type MobileCheckBody = {
  mobile: string
  resend?: boolean
}
export type MobileCheckResponse = { payload: string }
const checkMobile = (body: MobileCheckBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<MobileCheckResponse>('app/user/details/check_mobile', body, config)

export type MobileVerifyBody = {
  code: string
  payload: string
}
export type MobileVerifyResponse = LoginResponse
export type MobileVerifyFailResponse = LoginFailResponse
const verifyMobile = (body: MobileVerifyBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<MobileVerifyResponse>('app/user/details/verify_mobile', body, config)

export type RegVerifyBody = VerifyBody
export type RegVerifyResponse = {
  payload: string
  mobile_active: boolean
}
export type RegVerifyFailResponse = LoginFailResponse
const regVerify = (body: RegVerifyBody, config: AxiosRequestConfig) =>
  evisaAPI.post<RegVerifyResponse>('/app/user/register/verify', body, config)

export type CheckIdBody = {
  id_number: number
  check_date: string
  email: string
  password: string
  city?: string
  region?: string
  district?: string
}
export type CheckIdResponse = RegVerifyResponse
export type CheckIdFailResponse = LoginFailResponse
const checkId = (body: CheckIdBody, config: AxiosRequestConfig) =>
  evisaAPI.post<CheckIdResponse>(
    'app/user/register/check',
    {
      ...body,
      city: 'dummy',
      region: 'dummy',
      district: 'dummy',
      register_source: 'online',
    },
    config,
  )

export type CompeteSignUpBody = {
  payload: string
  email: string
  password: string
}

export type RequestResetBody = {
  id_number: string
  date: string
  resend?: boolean
}
export type RequestResetResponse = LoginResponse
export type RequestResetFailResponse = LoginFailResponse
const requestReset = (body: RequestResetBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<RequestResetResponse>('app/password/request', body, config)

export type VerifyResetBody = {
  id_number: string
  code: string
  token?: string
}
export type VerifyResetResponse = LoginResponse
export type VerifyResetFailResponse = LoginFailResponse

const verifyReset = (body: VerifyResetBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<VerifyResetResponse>('app/password/verify', body, config)

export type ResetPasswordBody = {
  id_number: string
  new_password: string
  token: string
}

export type ResetPasswordResponse = LoginResponse
export type ResetPasswordFailResponse = LoginFailResponse
const resetPassword = (body: ResetPasswordBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<ResetPasswordResponse>('app/password/reset', body, config)

export type GetJiraTokenParams = {}

export type GetJiraTokenResponse = {
  token: string
}

const getJiraToken = (params: GetJiraTokenParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get('/app/user/jira_token', { params, ...config })

export type CheckCaptchaResponse = {
  max_attempt: boolean
}

const checkCaptcha = (params: {}, config?: AxiosRequestConfig) => evisaAPI.get('/app/auth/should_show_captcha', config)

export default {
  login: callAPI<typeof login, LoginResponse, LoginFailResponse>(login),
  loginNafath: callAPI<typeof loginNafath, LoginNafathResponse, LoginFailResponse>(loginNafath),
  checkNafath: callAPI<typeof checkNafath, CheckNafathResponse, LoginFailResponse>(checkNafath),
  actAsPremium: callAPI<typeof actAsPremium, ActAsPremiumResponse, LoginFailResponse>(actAsPremium),
  actAsPremiumManager: callAPI<typeof actAsPremiumManager, ActAsPremiumResponse, LoginFailResponse>(
    actAsPremiumManager,
  ),
  verify: callAPI<typeof verify, VerifyResponse, VerifyFailResponse>(verify),
  resend: callAPI<typeof resend, ResendResponse>(resend),
  checkMobile: callAPI<typeof checkMobile, MobileCheckResponse>(checkMobile),
  verifyMobile: callAPI<typeof verifyMobile, MobileVerifyResponse, MobileVerifyFailResponse>(verifyMobile),
  regVerify: callAPI<typeof regVerify, RegVerifyResponse>(regVerify),
  checkId: callAPI<typeof checkId, CheckIdResponse, CheckIdFailResponse>(checkId),
  requestReset: callAPI<typeof requestReset, RequestResetResponse, RequestResetFailResponse>(requestReset),
  verifyReset: callAPI<typeof verifyReset, VerifyResetResponse, VerifyResetFailResponse>(verifyReset),
  resetPassword: callAPI<typeof resetPassword, ResetPasswordResponse, ResetPasswordFailResponse>(resetPassword),
  getJiraToken: callAPI<typeof getJiraToken, GetJiraTokenResponse>(getJiraToken),
  checkCaptcha: callAPI<typeof checkCaptcha, CheckCaptchaResponse>(checkCaptcha),
}
