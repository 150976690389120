import { CallAPIAction, Status } from 'api/types'
import { VisaInquiryResponse } from 'api/evisaAPI/visas'
import { Nationality, ObjectWithTranslation, Occupation, Visa } from 'common/types/transformedRespTypes'
import { convertVisaResponseToState } from 'common/utils/convertResponse/convertVisaResponseToState'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import { VisaIssueRequestServiceFields, VisaIssueRequestStatus } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'

type State = {
  request?: {
    visa?: Visa
    nationality: Nationality
    occupation: Occupation
    visaIssuePlace: ObjectWithTranslation
    id: number
    createdAt: string
    status: VisaIssueRequestStatus
    rejectionReasons: Array<ObjectWithTranslation>
  } & VisaIssueRequestServiceFields
  nicInfo?: {
    firstName: string
    lastName: string
    maritalStatus: string
    gender: string
    status: string
  }
} & Status

const initialState = {
  nicInfo: undefined,
  requiest: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = ({ request, nicInfo }: VisaInquiryResponse) => ({
  nicInfo: {
    gender: nicInfo.gender,
    maritalStatus: nicInfo.marital_status,
    firstName: nicInfo.first_name,
    lastName: nicInfo.last_name,
    status: nicInfo.status,
  },
  request: {
    visa: request.visa ? convertVisaResponseToState({ visa: request.visa! }) : undefined,
    nationality: formatNationalityFromBE(request.nationality),
    occupation: formatOccupationsFromBE(request.occupation),
    visaIssuePlace: formatObjectWithTranslationFromBE(request.visaIssuePlace),
    id: request.id,
    createdAt: request.created_at,
    status: request.status,
    rejectionReasons: request.rejectionReasons.map((reason) => formatObjectWithTranslationFromBE(reason)),
    pendingUnifiedEligibilityIssue: request.pending_unified_eligiblity_issue,
  },
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.VISA_INQUIRY_ASYNC,
    Actions.VISA_INQUIRY_SUCCESS,
    Actions.VISA_INQUIRY_FAIL,
    VisaInquiryResponse
  >,
): State => {
  switch (action.type) {
    case Actions.VISA_INQUIRY_ASYNC:
    case Actions.VISA_INQUIRY_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.VISA_INQUIRY_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    default:
      return state
  }
}
