import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetAuthContractDraftResponse } from 'api/contractAuthAPI'
import { GeneralFailResponse } from 'common/types/commonTypes'

type State = {
  legalDraftTextAr: string
  legalDraftTextEn: string
} & Status

const initialState = {
  legalDraftTextAr: '',
  legalDraftTextEn: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_AUTH_DRAFT_ASYNC,
    Actions.GET_CONTRACT_AUTH_DRAFT_SUCCESS,
    Actions.GET_CONTRACT_AUTH_DRAFT_FAIL,
    GetAuthContractDraftResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_AUTH_DRAFT_SUCCESS: {
      return {
        ...state,
        ...action.status,
        legalDraftTextAr: action.payload.legal_text_ar,
        legalDraftTextEn: action.payload.legal_text_en,
      }
    }
    case Actions.GET_CONTRACT_AUTH_DRAFT_FAIL:
    case Actions.GET_CONTRACT_AUTH_DRAFT_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
