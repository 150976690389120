import { DownloadHistoryDetailsResponse } from 'api/evisaAPI/evisaAPI'
import { CallAPIAction, Status } from 'api/types'
import { StringRecord } from 'common/types/commonTypes'
import type { SelectPreviouslyUploadedAction } from 'redux/actionCreators/previouslyUploaded'
import Actions from 'redux/actions'

export type RecentlyUploadedVisaFileData = {
  id: string
  originalFileName: string
  mimeType: string
  createdAt: string
  isSelected: boolean
}

type State = {
  files: StringRecord<RecentlyUploadedVisaFileData>
} & Status

const initialState: State = {
  files: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: DownloadHistoryDetailsResponse): StringRecord<RecentlyUploadedVisaFileData> =>
  resp.reduce(
    (acc, item) => ({
      ...acc,
      [item.original_file_name]: {
        id: item.id,
        originalFileName: item.original_file_name,
        createdAt: item.created_at,
        mimeType: item.mime_type,
        isSelected: false,
      },
    }),
    {},
  )

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.GET_DOWNLOAD_HISTORY_DETAILS_ASYNC,
        Actions.GET_DOWNLOAD_HISTORY_DETAILS_SUCCESS,
        Actions.GET_DOWNLOAD_HISTORY_DETAILS_FAIL,
        DownloadHistoryDetailsResponse
      >
    | SelectPreviouslyUploadedAction,
): State => {
  switch (action.type) {
    case Actions.GET_DOWNLOAD_HISTORY_DETAILS_ASYNC:
    case Actions.GET_DOWNLOAD_HISTORY_DETAILS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_DOWNLOAD_HISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        files: convertResponseToState(action.payload),
        ...action.status,
      }
    case Actions.SELECT_PREVIOUSLY_UPLOADED:
      return {
        ...state,
        files: action.payload.files,
      }
    default:
      return state
  }
}
