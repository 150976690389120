import { CallAPIAction, Status } from 'api/types'
import { HasVisasResponse } from 'api/evisaAPI/visas'
import Actions from 'redux/actions'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

type State = {
  hasVisa?: boolean
  visaFollowId?: number
  visaOrigin?: ObjectWithTranslation
} & Status

const initialState = {
  hasVisa: undefined,
  visaFollowId: undefined,
  visaOrigin: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<Actions.HAS_VISAS_ASYNC, Actions.HAS_VISAS_SUCCESS, Actions.HAS_VISAS_FAIL, HasVisasResponse>,
): State => {
  switch (action.type) {
    case Actions.HAS_VISAS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.HAS_VISAS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        hasVisa: payload.has_visa,
        visaFollowId: payload.visa_follow,
        visaOrigin: payload.visa_origin
          ? {
              id: payload.visa_origin.id,
              label: payload.visa_origin.label,
              labelEn: payload.visa_origin.label_en,
            }
          : undefined,
        ...action.status,
      }
    }
    case Actions.HAS_VISAS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
