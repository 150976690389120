import { ProPerformance } from 'common/types/commonTypes'
import { GetProPerformanceResponse } from 'api/jiraAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { convertResponseToState } from 'common/utils/convertResponse/convertPerformanceResponse'

type State = {
  performance: Array<ProPerformance>
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  performance: [],
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_PERFORMANCE_OVERALL_ASYNC,
    Actions.GET_PRO_PERFORMANCE_OVERALL_SUCCESS,
    Actions.GET_PRO_PERFORMANCE_OVERALL_FAIL,
    GetProPerformanceResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_PERFORMANCE_OVERALL_SUCCESS: {
      return {
        ...state,
        ...action.status,
        performance: action.payload.map(convertResponseToState),
      }
    }
    case Actions.GET_PRO_PERFORMANCE_OVERALL_ASYNC:
    case Actions.GET_PRO_PERFORMANCE_OVERALL_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
