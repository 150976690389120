import { GetSimpleAuthContractInfoResponse } from 'api/contractAuthAPI'
import { Status, CallAPIAction } from 'api/types'
import { ContractAuthAppendixStatus, ContractAuthRequestStatus } from 'common/types/commonTypes'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = {
  data: {
    id: number
    status: ContractAuthRequestStatus
    duration: number
    laborerDetails: {
      name: string
      mobileNumber: string
      nationality: ObjectWithTranslation
    }
    employerDetails: {
      name: string
      email: string
      mobileNumber: string
    }
    startDateDetails: {
      hijri: string
      gregorian: string
    }
    lastAppendix?: {
      duration: number
      status: ContractAuthAppendixStatus
    }
  }
} & Status

const initialState = {
  data: {
    id: 0,
    status: ContractAuthRequestStatus.REQUEST_EXPIRED,
    duration: 0,
    laborerDetails: {
      name: '',
      mobileNumber: '',
      nationality: {
        id: 0,
        label: '',
        labelEn: '',
      },
    },
    employerDetails: {
      name: '',
      email: '',
      mobileNumber: '',
    },
    startDateDetails: {
      hijri: '',
      gregorian: '',
    },
    lastAppendix: {
      duration: 0,
      status: ContractAuthAppendixStatus.EXPIRED,
    },
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC,
    Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS,
    Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL,
    GetSimpleAuthContractInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC:
    case Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        ...action.status,
        data: {
          id: data.id,
          status: data.status,
          duration: data.duration_in_months,
          laborerDetails: {
            name: data.laborer_details.name,
            mobileNumber: data.laborer_details.mobile_number,
            nationality: {
              id: 0,
              label: data.laborer_details.nationality.label,
              labelEn: data.laborer_details.nationality.label_en,
            },
          },
          employerDetails: {
            name: data.employer_details.name,
            email: data.employer_details.email,
            mobileNumber: data.employer_details.mobile_number,
          },
          startDateDetails: {
            hijri: data.start_date_details.hijri,
            gregorian: data.start_date_details.gregorian,
          },
          lastAppendix: data.last_appendix && {
            duration: data.last_appendix?.duration_in_months,
            status: data.last_appendix?.status,
          },
        },
      }
    }

    default:
      return state
  }
}
