import { ServiceTypes } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { setVipRecruitmentFilter } from 'redux/actionCreators/premiumUserAPI/recruitmentFilter'
import { SORT_ASC, SORT_DESC } from 'common/constants'
import { NodeSelectOption } from 'ui-components/inputs/NodeSelect/NodeSelect'
import { Nationality, Occupation } from 'common/types/transformedRespTypes'

export type GetVipMarketOffersParams = {
  occupation?: number
  typeOfRecruitment: ServiceTypes
  nationality?: number
  nationalityInfo?: Nationality
  occupationInfo?: Occupation
  priceSort: string
  ratingSort: string
  officeNameSearch: string
  workerSalary?: string
  costRange: {
    min?: number
    max?: number
  }
  durationRange: {
    min?: number
    max?: number
  }
  arrivalCityId?: number
  religion?: number
  starsRating: number
  acceptanceRateRange: {
    min?: number
    max?: number
  }
  page: number
  clearPagesCount: boolean
  gender?: string
  isUserInfo?: boolean
  ageRange?: number
  workExperience?: number
  extraPreferences?: NodeSelectOption[]
  premium?: number
}

const initialState: GetVipMarketOffersParams = {
  typeOfRecruitment: ServiceTypes.NONE,
  gender: undefined,
  occupation: undefined,
  nationality: undefined,

  priceSort: SORT_ASC,
  ratingSort: SORT_DESC,
  officeNameSearch: '',
  costRange: {
    min: undefined,
    max: undefined,
  },
  durationRange: {
    min: undefined,
    max: undefined,
  },
  arrivalCityId: undefined,
  religion: undefined,
  starsRating: 0,
  acceptanceRateRange: {
    min: undefined,
    max: undefined,
  },
  page: 0,
  clearPagesCount: false,
  extraPreferences: [],
  premium: 1,
}

const vipRecruitmentFilter = (
  state: GetVipMarketOffersParams = initialState,
  action: ReturnType<typeof setVipRecruitmentFilter>,
): GetVipMarketOffersParams => {
  switch (action.type) {
    case Actions.SET_VIP_RECRUITMENT_FILTER:
      return {
        ...state,
        ...action.payload.filter,
      }
    default:
      return state
  }
}

export default vipRecruitmentFilter
