import { select, takeLatest, takeLeading } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getFarmerVisaRequests as getFarmerVisaRequestsAction,
  checkEligibilityForFarmerVisa as checkEligibilityForFarmerVisaAction,
  issueNewFarmerVisa as issueNewFarmerVisaAction,
  getInfoAboutFarmerRequest as getInfoAboutFarmerRequestAction,
  payForFarmerVisa as payForFarmerVisaAction,
  cancelFarmerVisa as cancelFarmerVisaAction,
  checkAgriculturalFile as checkAgriculturalFileAction,
  submitAgriculturalFile as submitAgriculturalFileAction,
} from 'redux/actionCreators/evisaAPI/farmerVisa'
import farmerVisasAPI from 'api/evisaAPI/farmerVisa'
import { getSimpleRequestHandler } from 'saga/utils'
import { RootState } from 'redux/rootReducer'
import { history } from 'redux/browserHistory'
import { Routes } from 'containers/Routes/routePaths'

export const getFarmerVisaRequests = getSimpleRequestHandler<
  typeof farmerVisasAPI.getFarmerVisaRequests,
  ReturnType<typeof getFarmerVisaRequestsAction>
>({
  actionLoading: Actions.GET_FARMER_VISA_REQUESTS_ASYNC,
  actionSuccess: Actions.GET_FARMER_VISA_REQUESTS_SUCCESS,
  actionFail: Actions.GET_FARMER_VISA_REQUESTS_FAIL,
  callApiFn: farmerVisasAPI.getFarmerVisaRequests,
})

const checkEligibilityForFarmerVisa = getSimpleRequestHandler<
  typeof farmerVisasAPI.checkEligibilityForFarmerVisa,
  ReturnType<typeof checkEligibilityForFarmerVisaAction>
>({
  actionLoading: Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_ASYNC,
  actionSuccess: Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_SUCCESS,
  actionFail: Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_FAIL,
  callApiFn: farmerVisasAPI.checkEligibilityForFarmerVisa,
})

const issueNewFarmerVisaRequest = getSimpleRequestHandler<
  typeof farmerVisasAPI.issueNewFarmerVisaRequest,
  ReturnType<typeof issueNewFarmerVisaAction>
>({
  actionLoading: Actions.ISSUE_NEW_FARMER_VISA_ASYNC,
  actionSuccess: Actions.ISSUE_NEW_FARMER_VISA_SUCCESS,
  actionFail: Actions.ISSUE_NEW_FARMER_VISA_FAIL,
  callApiFn: farmerVisasAPI.issueNewFarmerVisaRequest,
})

const getInfoAboutFarmerRequest = getSimpleRequestHandler<
  typeof farmerVisasAPI.getInfoAboutFarmerRequest,
  ReturnType<typeof getInfoAboutFarmerRequestAction>
>({
  actionLoading: Actions.GET_INFO_ABOUT_FARMER_REQUEST_ASYNC,
  actionSuccess: Actions.GET_INFO_ABOUT_FARMER_REQUEST_SUCCESS,
  actionFail: Actions.GET_INFO_ABOUT_FARMER_REQUEST_FAIL,
  callApiFn: farmerVisasAPI.getInfoAboutFarmerRequest,
})

const payForFarmerVisa = getSimpleRequestHandler<
  typeof farmerVisasAPI.payForFarmerVisa,
  ReturnType<typeof payForFarmerVisaAction>
>({
  actionLoading: Actions.PAY_FOR_FARMER_VISA_ASYNC,
  actionSuccess: Actions.PAY_FOR_FARMER_VISA_SUCCESS,
  actionFail: Actions.PAY_FOR_FARMER_VISA_FAIL,
  callApiFn: farmerVisasAPI.payForFarmerVisa,
})

const cancelFarmerVisa = getSimpleRequestHandler<
  typeof farmerVisasAPI.cancelFarmerVisa,
  ReturnType<typeof cancelFarmerVisaAction>
>({
  actionLoading: Actions.CANCEL_FARMER_VISA_ASYNC,
  actionSuccess: Actions.CANCEL_FARMER_VISA_SUCCESS,
  actionFail: Actions.CANCEL_FARMER_VISA_FAIL,
  callApiFn: farmerVisasAPI.cancelFarmerVisa,
})

function* checkAgricultruralFileSaga(action: ReturnType<typeof checkAgriculturalFileAction>) {
  const checkAgricultruralFile = getSimpleRequestHandler<
    typeof farmerVisasAPI.checkAgriculturalFile,
    ReturnType<typeof checkAgriculturalFileAction>
  >({
    actionLoading: Actions.CHECK_AGRICULTURAL_FILE_ASYNC,
    actionSuccess: Actions.CHECK_AGRICULTURAL_FILE_SUCCESS,
    actionFail: Actions.CHECK_AGRICULTURAL_FILE_FAIL,
    callApiFn: farmerVisasAPI.checkAgriculturalFile,
  })

  yield checkAgricultruralFile(action)
  const checkAgricultruralState: RootState['agriculturalRegisterationFile'] = yield select(
    (state: RootState) => state.agriculturalRegisterationFile,
  )
  if (checkAgricultruralState.success) {
    history.push(
      action.payload.params.isVip
        ? Routes.VIP_FARMER_VISA_ESTABLISHMENT_CONFIRMATION
        : Routes.FARMER_VISA_ESTABLISHMENT_CONFIRMATION,
    )
  }
}

const submitAgricultruralFile = getSimpleRequestHandler<
  typeof farmerVisasAPI.submitAgriculturalFile,
  ReturnType<typeof submitAgriculturalFileAction>
>({
  actionLoading: Actions.SUBMIT_AGRICULTURAL_FILE_ASYNC,
  actionSuccess: Actions.SUBMIT_AGRICULTURAL_FILE_SUCCESS,
  actionFail: Actions.SUBMIT_AGRICULTURAL_FILE_FAIL,
  callApiFn: farmerVisasAPI.submitAgriculturalFile,
})

export default [
  takeLatest(Actions.GET_FARMER_VISA_REQUESTS, getFarmerVisaRequests),
  takeLeading(Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA, checkEligibilityForFarmerVisa),
  takeLeading(Actions.ISSUE_NEW_FARMER_VISA, issueNewFarmerVisaRequest),
  takeLatest(Actions.GET_INFO_ABOUT_FARMER_REQUEST, getInfoAboutFarmerRequest),
  takeLeading(Actions.PAY_FOR_FARMER_VISA, payForFarmerVisa),
  takeLeading(Actions.CANCEL_FARMER_VISA, cancelFarmerVisa),
  takeLeading(Actions.CHECK_AGRICULTURAL_FILE, checkAgricultruralFileSaga),
  takeLeading(Actions.SUBMIT_AGRICULTURAL_FILE, submitAgricultruralFile),
]
