import { useLocation } from 'react-router'

import { Routes } from 'containers/Routes/routePaths'
import authManager from 'common/utils/auth/authManager'

export const useVipRoute = () => {
  const location = useLocation()
  const isVip = location.pathname.startsWith('/vip/')
  const getRoute = (route: Routes) =>
    authManager.isVipUser() ? (route.replace('/app/', '/vip/') as Routes) : (route as Routes)

  return { isVip, getRoute }
}
