import moment from 'moment'

import { CallAPIAction, Status } from 'api/types'
import { PayfortBill } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import { PayForIToIRequestResponse, PayForIToIRequestFailResponse } from 'api/newDLTransferAPI'

type State = {
  error?: string
  status?: boolean
  bill?: PayfortBill
  breakdownBill?: any
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  breakdownBill: {
    transferRequestId: 0,
    payBeforedate: '',
    totalAmount: 0,
    breakdown: {
      operationalFees: 0,
      vat: 0,
      serviceTotal: 0,
      servicePrice: 0,
    },
  },
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.PAY_FOR_I_TO_I_REQUEST_ASYNC,
    Actions.PAY_FOR_I_TO_I_REQUEST_SUCCESS,
    Actions.PAY_FOR_I_TO_I_REQUEST_FAIL,
    PayForIToIRequestResponse,
    PayForIToIRequestFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.PAY_FOR_I_TO_I_REQUEST_SUCCESS: {
      const { bill, tokenization_data } = action.payload.data
      return {
        ...state,
        ...action.status,
        status: action.payload.status,
        bill: {
          params: tokenization_data.params,
          url: tokenization_data.url,
        },
        breakdownBill: {
          payBeforedate: moment(bill.bill_issue_date).add(3, 'days').format('YYYY-MM-DD HH:mm:ss'),
          totalAmount: bill.total_amount,
          transferRequestId: bill.transfer_request_id,
          breakdown: {
            operationalFees: bill.breakdown.operational_fees_without_vat,
            vat: bill.breakdown.vat,
            serviceTotal: bill.breakdown.service_total,
            servicePrice: bill.breakdown.service_price,
          },
        },
      }
    }
    case Actions.PAY_FOR_I_TO_I_REQUEST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.PAY_FOR_I_TO_I_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    }
    default:
      return state
  }
}
