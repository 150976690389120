import { CallAPIAction, Status } from 'api/types'
import type { IssueNewFarmerVisaRequestResponse, IssueNewFarmerVisaRequestFailResponse } from 'api/evisaAPI/farmerVisa'
import { BillStatus, FarmerEligibilityErrorMessages } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage: FarmerEligibilityErrorMessages | string
  farmerRequest?: {
    id: number
    bill: {
      status: BillStatus
    } | null
  }
} & Status

const initialState = {
  errorMessage: '',
  farmerRequest: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ISSUE_NEW_FARMER_VISA_ASYNC,
    Actions.ISSUE_NEW_FARMER_VISA_SUCCESS,
    Actions.ISSUE_NEW_FARMER_VISA_FAIL,
    IssueNewFarmerVisaRequestResponse,
    IssueNewFarmerVisaRequestFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.ISSUE_NEW_FARMER_VISA_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.ISSUE_NEW_FARMER_VISA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        farmerRequest: {
          id: payload.farmerVisaRequest.id,
          bill: payload.farmerVisaRequest.bill
            ? {
                status: payload.farmerVisaRequest.bill.status,
              }
            : null,
        },
      }
    }
    case Actions.ISSUE_NEW_FARMER_VISA_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
