import { CallAPIAction, Status } from 'api/types'
import { LaborerOtpVerifyResponse } from 'api/laborerPortalAPI/auth'
import Actions from 'redux/actions'
import authManager from 'common/utils/auth/authManager'

type State = {
  laborerInfo: ReturnType<typeof authManager.getLaborerProfileData>
  token: string
  expiresAt: string
  error?: string
} & Status

const getInitialState = (): State => ({
  token: authManager.getContractAuthToken(),
  expiresAt: '',
  laborerInfo: authManager.getLaborerProfileData(),
  isLoading: false,
  success: false,
  fail: false,
})

export default (
  state: State = getInitialState(),
  action: CallAPIAction<
    Actions.LABORER_OTP_VERIFY_ASYNC,
    Actions.LABORER_OTP_VERIFY_SUCCESS,
    Actions.LABORER_OTP_VERIFY_FAIL,
    LaborerOtpVerifyResponse,
    { error?: { message: string }; message: string }
  >,
): State => {
  switch (action.type) {
    case Actions.LABORER_OTP_VERIFY_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.LABORER_OTP_VERIFY_SUCCESS: {
      const { labor, token, expires_at } = action.payload
      return {
        ...state,
        ...action.status,
        token,
        expiresAt: expires_at,
        laborerInfo: {
          iqamaNumber: labor.iqama_number,
          birthDate: labor.birth_date,
          nationality: {
            id: labor.nationality.id,
            label: labor.nationality.label,
            labelEn: labor.nationality.label_en,
            abbreviation: labor.nationality.abbreviation,
          },
          occupation: {
            id: labor.occupation.id,
            label: labor.occupation.label,
            labelEn: labor.occupation.label_en,
          },
          mobileNumber: labor.mobile_number,
          religion: labor.religion,
        },
      }
    }
    case Actions.LABORER_OTP_VERIFY_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.error?.message || action.payload?.message,
      }
    default:
      return state
  }
}
