import { CallAPIAction, Status } from 'api/types'
import { FarmerVisaIssueRequest } from 'common/types/transformedRespTypes'
import { formatFarmerVisaRequestFromBE } from 'common/utils/convertResponse/convertFarmerRequest'
import { GetInfoAboutFarmerRequestResponse } from 'api/evisaAPI/farmerVisa'
import Actions from 'redux/actions'

type State = {
  request?: FarmerVisaIssueRequest
  hasRefundableBill?: boolean
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  request: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_INFO_ABOUT_FARMER_REQUEST_ASYNC,
    Actions.GET_INFO_ABOUT_FARMER_REQUEST_SUCCESS,
    Actions.GET_INFO_ABOUT_FARMER_REQUEST_FAIL,
    GetInfoAboutFarmerRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_INFO_ABOUT_FARMER_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        request: formatFarmerVisaRequestFromBE(action.payload.farmerVisaRequest),
        hasRefundableBill: action.payload.has_refundable_bill,
      }
    }
    case Actions.GET_INFO_ABOUT_FARMER_REQUEST_ASYNC:
    case Actions.GET_INFO_ABOUT_FARMER_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
