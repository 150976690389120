import { AuthHeader, CheckNafathResponse, LoginFailResponse } from 'api/evisaAPI/auth'
import { CallAPIAction, Status } from 'api/types'
import { EVISA_AUTH_HEADER } from 'common/constants'
import Actions from 'redux/actions'

type State = {
  token?: string
  isAgent?: boolean
  agentFor?: string
  authorized?: boolean
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  isAgent: false,
  authorized: false,
  agentFor: '',
  token: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_NAFATH_ASYNC,
    Actions.CHECK_NAFATH_SUCCESS,
    Actions.LOGIN_NAFATH_FAIL,
    CheckNafathResponse,
    LoginFailResponse,
    AuthHeader
  >,
) => {
  switch (action.type) {
    case Actions.CHECK_NAFATH_SUCCESS: {
      const token = action.headers[EVISA_AUTH_HEADER]

      return {
        ...state,
        ...action.status,
        isAgent: action.payload.is_agent,
        agentFor: action.payload.for?.name,
        authorized: action.payload.authorized,
        token,
      }
    }
    case Actions.CHECK_NAFATH_ASYNC:
    case Actions.LOGIN_NAFATH_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
