import { takeLatest, takeEvery, all, call, put, select, takeLeading } from 'redux-saga/effects'

import Actions from 'redux/actions'
import type { Await } from 'common/types/commonTypes'
import {
  checkEligibility as checkEligibilityAction,
  checkEligibilityForDisabilityVisa as checkEligibilityForDisabilityVisaAction,
  hasVisas as hasVisasAction,
  uploadVisasFile as uploadVisasFileAction,
  submitVisaWithContractRequest as submitVisaWithContractRequestAction,
  getVisaInfo as getVisaInfoAction,
  getAllVisasList as getAllVisasListAction,
  getVisaIssueRequestsList as getVisaIssueRequestsListAction,
  getAvailableVisas as getAvailableVisasAction,
  issueNewVisaRequest as issueNewVisaRequestAction,
  issueNewDisabilityVisa as issueNewDisabilityVisaAction,
  checkEligibilityForAlternativeVisa as checkEligibilityForAlternativeVisaAction,
  replaceVisas as replaceVisasAction,
  getVisaIssueRequestInfo as getVisaIssueRequestInfoAction,
  onlinePaymentVisa as onlinePaymentVisaAction,
  visaInquiry as visaInquiryAction,
  getLastFourVisas as getLastFourVisasAction,
  getVisaPdfLink as getVisaPdfLinkAction,
  getVipVisaList as getVipVisaListAction,
} from 'redux/actionCreators/evisaAPI/visas'
import {
  visasEvisaAPI,
  CheckEligibilityFailResponse,
  CheckEligibilityForAlternativeVisaResponse,
  CheckEligibilityForAlternativGeneralFailResponse,
} from 'api/evisaAPI/visas'
import evisaAPI, { GetEligibleWorkersResponse } from 'api/evisaAPI/evisaAPI'
import { FAIL, SUCCESS } from 'common/constants'
import { RootState } from 'redux/rootReducer'
import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from 'saga/utils'
import { IS_DEV } from 'common/envConstants'

const getFailCount = ({ uploadVisasFilesStatus }: RootState) => uploadVisasFilesStatus.failCount

const checkEligibility = getSimpleRequestHandler<
  typeof visasEvisaAPI.checkEligibility,
  ReturnType<typeof checkEligibilityAction>
>({
  actionLoading: Actions.CHECK_ELIGIBILITY_ASYNC,
  actionSuccess: Actions.CHECK_ELIGIBILITY_SUCCESS,
  actionFail: Actions.CHECK_ELIGIBILITY_FAIL,
  callApiFn: visasEvisaAPI.checkEligibility,
})

const checkEligibilityForDisabilityVisa = getSimpleRequestHandler<
  typeof visasEvisaAPI.checkEligibilityForDisabilityVisa,
  ReturnType<typeof checkEligibilityForDisabilityVisaAction>
>({
  actionLoading: Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_ASYNC,
  actionSuccess: Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_SUCCESS,
  actionFail: Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_FAIL,
  callApiFn: visasEvisaAPI.checkEligibilityForDisabilityVisa,
})

const hasVisas = getSimpleRequestHandler<typeof visasEvisaAPI.hasVisas, ReturnType<typeof hasVisasAction>>({
  actionLoading: Actions.HAS_VISAS_ASYNC,
  actionSuccess: Actions.HAS_VISAS_SUCCESS,
  actionFail: Actions.HAS_VISAS_FAIL,
  callApiFn: visasEvisaAPI.hasVisas,
})

function* uploadVisasFile(action: ReturnType<ReturnType<typeof uploadVisasFileAction>>) {
  yield putLoading({ type: Actions.UPLOAD_VISAS_FILES_ASYNC, callAPIActionPayload: action.payload })
  const failCount: number = yield select(getFailCount)
  const callApiFn =
    action.payload.useOCR && failCount < 3 && IS_DEV
      ? visasEvisaAPI.uploadVisasFileForOCR
      : visasEvisaAPI.uploadVisasFile
  const { response, headers, status }: Await<ReturnType<typeof visasEvisaAPI.uploadVisasFile>> = yield call<
    typeof visasEvisaAPI.uploadVisasFile
  >(callApiFn, action?.payload?.params, action.payload.config)
  if (status.fail) {
    yield putFail({
      type: Actions.UPLOAD_VISAS_FILES_FAIL,
      callAPIActionPayload: action.payload,
      payload: response,
      headers,
    })
    return
  }
  yield putSuccess({
    type: Actions.UPLOAD_VISAS_FILES_SUCCESS,
    callAPIActionPayload: action.payload,
    payload: response,
    headers,
  })
}

const submitVisaWithContractRequest = getSimpleRequestHandler<
  typeof visasEvisaAPI.submitVisaWithContractRequest,
  ReturnType<typeof submitVisaWithContractRequestAction>
>({
  actionLoading: Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_ASYNC,
  actionSuccess: Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_SUCCESS,
  actionFail: Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_FAIL,
  callApiFn: visasEvisaAPI.submitVisaWithContractRequest,
})

export const getVisaInfo = getSimpleRequestHandler<
  typeof visasEvisaAPI.getVisaInfo,
  ReturnType<typeof getVisaInfoAction>
>({
  actionLoading: Actions.GET_VISA_INFO_ASYNC,
  actionSuccess: Actions.GET_VISA_INFO_SUCCESS,
  actionFail: Actions.GET_VISA_INFO_FAIL,
  callApiFn: visasEvisaAPI.getVisaInfo,
})

export const getAllVisasList = getSimpleRequestHandler<
  typeof visasEvisaAPI.getAllVisasList,
  ReturnType<typeof getAllVisasListAction>
>({
  actionLoading: Actions.GET_VISAS_LIST_ASYNC,
  actionSuccess: Actions.GET_VISAS_LIST_SUCCESS,
  actionFail: Actions.GET_VISAS_LIST_FAIL,
  callApiFn: visasEvisaAPI.getAllVisasList,
})

export const getVisaIssueRequestsList = getSimpleRequestHandler<
  typeof visasEvisaAPI.getVisaIssueRequestsList,
  ReturnType<typeof getVisaIssueRequestsListAction>
>({
  actionLoading: Actions.GET_VISA_ISSUE_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_VISA_ISSUE_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_VISA_ISSUE_REQUESTS_LIST_FAIL,
  callApiFn: visasEvisaAPI.getVisaIssueRequestsList,
})

export const getAvailableVisas = getSimpleRequestHandler<
  typeof visasEvisaAPI.getAvailableVisas,
  ReturnType<typeof getAvailableVisasAction>
>({
  actionLoading: Actions.GET_AVAILABLE_VISAS_ASYNC,
  actionSuccess: Actions.GET_AVAILABLE_VISAS_SUCCESS,
  actionFail: Actions.GET_AVAILABLE_VISAS_FAIL,
  callApiFn: visasEvisaAPI.getAvailableVisas,
})

export const issueNewVisaRequest = getSimpleRequestHandler<
  typeof visasEvisaAPI.issueNewVisaRequest,
  ReturnType<typeof issueNewVisaRequestAction>
>({
  actionLoading: Actions.ISSUE_NEW_VISA_REQUEST_ASYNC,
  actionSuccess: Actions.ISSUE_NEW_VISA_REQUEST_SUCCESS,
  actionFail: Actions.ISSUE_NEW_VISA_REQUEST_FAIL,
  callApiFn: visasEvisaAPI.issueNewVisaRequest,
})

export const issueNewDisabilityVisa = getSimpleRequestHandler<
  typeof visasEvisaAPI.issueNewDisabilityVisa,
  ReturnType<typeof issueNewDisabilityVisaAction>
>({
  actionLoading: Actions.ISSUE_NEW_DISABILITY_VISA_ASYNC,
  actionSuccess: Actions.ISSUE_NEW_DISABILITY_VISA_SUCCESS,
  actionFail: Actions.ISSUE_NEW_DISABILITY_VISA_FAIL,
  callApiFn: visasEvisaAPI.issueNewDisabilityVisa,
})

function* checkEligibilityForAlternativeVisa(action: ReturnType<typeof checkEligibilityForAlternativeVisaAction>) {
  yield putLoading({ type: Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_ASYNC, callAPIActionPayload: {} })
  const [userEligibility, alternativeVisaEligibility, workers]: [
    Await<ReturnType<typeof visasEvisaAPI.checkEligibility>>,
    Await<ReturnType<typeof visasEvisaAPI.checkEligibilityForAlternativeVisa>>,
    Await<ReturnType<typeof evisaAPI.getEligibleWorkers>>,
  ] = yield all([
    call<typeof visasEvisaAPI.checkEligibility>(visasEvisaAPI.checkEligibility, action?.payload?.params, {}),
    call<typeof visasEvisaAPI.checkEligibilityForAlternativeVisa>(
      visasEvisaAPI.checkEligibilityForAlternativeVisa,
      {},
      {},
    ),
    call<typeof evisaAPI.getEligibleWorkers>(evisaAPI.getEligibleWorkers, {}, {}),
  ])
  const workersResponse = workers.response as GetEligibleWorkersResponse
  if (
    userEligibility.status.success &&
    alternativeVisaEligibility.status.success &&
    (alternativeVisaEligibility.response as CheckEligibilityForAlternativeVisaResponse).eligible &&
    workers.status.success &&
    workersResponse.length
  ) {
    yield put({
      type: Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_SUCCESS,
      callAPIActionPayload: {},
      payload: {},
      headers: {},
      status: SUCCESS,
    })
  } else {
    const userElegibilityResponse = userEligibility.response as CheckEligibilityFailResponse
    // eslint-disable-next-line max-len
    const alternativeVisaEligibilityResponse =
      alternativeVisaEligibility.response as CheckEligibilityForAlternativGeneralFailResponse
    const messageTKey =
      userElegibilityResponse?.error?.message || alternativeVisaEligibilityResponse?.error?.message || ''
    yield put({
      type: Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_FAIL,
      callAPIActionPayload: {},
      payload: {
        messageTKey,
        noUsers: workersResponse.length === 0,
      },
      headers: {},
      status: FAIL,
    })
  }
}

const replaceVisas = getSimpleRequestHandler<typeof visasEvisaAPI.replaceVisas, ReturnType<typeof replaceVisasAction>>(
  {
    actionLoading: Actions.REPLACE_VISAS_ASYNC,
    actionSuccess: Actions.REPLACE_VISAS_SUCCESS,
    actionFail: Actions.REPLACE_VISAS_FAIL,
    callApiFn: visasEvisaAPI.replaceVisas,
  },
)

const getVisaIssueRequestInfo = getSimpleRequestHandler<
  typeof visasEvisaAPI.getVisaIssueRequestInfo,
  ReturnType<typeof getVisaIssueRequestInfoAction>
>({
  actionLoading: Actions.GET_VISA_ISSUE_REQUEST_INFO_ASYNC,
  actionSuccess: Actions.GET_VISA_ISSUE_REQUEST_INFO_SUCCESS,
  actionFail: Actions.GET_VISA_ISSUE_REQUEST_INFO_FAIL,
  callApiFn: visasEvisaAPI.getVisaIssueRequestInfo,
})

const onlinePaymentVisa = getSimpleRequestHandler<
  typeof visasEvisaAPI.onlinePaymentVisa,
  ReturnType<typeof onlinePaymentVisaAction>
>({
  actionLoading: Actions.ONLINE_PAYMENT_VISA_ASYNC,
  actionSuccess: Actions.ONLINE_PAYMENT_VISA_SUCCESS,
  actionFail: Actions.ONLINE_PAYMENT_VISA_FAIL,
  callApiFn: visasEvisaAPI.onlinePaymentVisa,
})

const getLastFourVisas = getSimpleRequestHandler<
  typeof visasEvisaAPI.getLastFourVisas,
  ReturnType<typeof getLastFourVisasAction>
>({
  actionLoading: Actions.GET_LAST_FOUR_VISAS_ASYNC,
  actionSuccess: Actions.GET_LAST_FOUR_VISAS_SUCCESS,
  actionFail: Actions.GET_LAST_FOUR_VISAS_FAIL,
  callApiFn: visasEvisaAPI.getLastFourVisas,
})

const visaInquiry = getSimpleRequestHandler<typeof visasEvisaAPI.visaInquiry, ReturnType<typeof visaInquiryAction>>({
  actionLoading: Actions.VISA_INQUIRY_ASYNC,
  actionSuccess: Actions.VISA_INQUIRY_SUCCESS,
  actionFail: Actions.VISA_INQUIRY_FAIL,
  callApiFn: visasEvisaAPI.visaInquiry,
})

const getVisaPdfLink = getSimpleRequestHandler<
  typeof visasEvisaAPI.getVisaPdfLink,
  ReturnType<typeof getVisaPdfLinkAction>
>({
  actionLoading: Actions.GET_VISA_PDF_URL_ASYNC,
  actionSuccess: Actions.GET_VISA_PDF_URL_SUCCESS,
  actionFail: Actions.GET_VISA_PDF_URL_FAIL,
  callApiFn: visasEvisaAPI.getVisaPdfLink,
})

const getVipVisaList = getSimpleRequestHandler<
  typeof visasEvisaAPI.getVipVisaList,
  ReturnType<typeof getVipVisaListAction>
>({
  actionLoading: Actions.GET_VIP_VISA_LIST_ASYNC,
  actionSuccess: Actions.GET_VIP_VISA_LIST_SUCCESS,
  actionFail: Actions.GET_VIP_VISA_LIST_FAIL,
  callApiFn: visasEvisaAPI.getVipVisaList,
})

export default [
  takeLatest(Actions.CHECK_ELIGIBILITY, checkEligibility),
  takeLatest(Actions.HAS_VISAS, hasVisas),
  takeEvery(Actions.UPLOAD_VISAS_FILES, uploadVisasFile),
  takeLatest(Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST, submitVisaWithContractRequest),
  takeLatest(Actions.GET_VISA_INFO, getVisaInfo),
  takeLatest(Actions.GET_VISAS_LIST, getAllVisasList),
  takeLatest(Actions.GET_VISA_ISSUE_REQUESTS_LIST, getVisaIssueRequestsList),
  takeLatest(Actions.GET_AVAILABLE_VISAS, getAvailableVisas),
  takeLatest(Actions.ISSUE_NEW_VISA_REQUEST, issueNewVisaRequest),
  takeLatest(Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA, checkEligibilityForDisabilityVisa),
  takeLatest(Actions.ISSUE_NEW_DISABILITY_VISA, issueNewDisabilityVisa),
  takeLatest(Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA, checkEligibilityForAlternativeVisa),
  takeLatest(Actions.REPLACE_VISAS, replaceVisas),
  takeLatest(Actions.GET_VISA_ISSUE_REQUEST_INFO, getVisaIssueRequestInfo),
  takeLatest(Actions.ONLINE_PAYMENT_VISA, onlinePaymentVisa),
  takeLatest(Actions.GET_VISA_PDF_URL, getVisaPdfLink),
  takeLeading(Actions.VISA_INQUIRY, visaInquiry),
  takeLeading(Actions.GET_LAST_FOUR_VISAS, getLastFourVisas),
  takeLeading(Actions.GET_VIP_VISA_LIST, getVipVisaList),
]
