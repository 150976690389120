import {
  CreateComplaintTicketBody,
  GetTicketListParams,
  GetTicketParams,
  RespondToComplaintSolutionParams,
  RespondToOfficeComplaintParams,
  GetSupportTicketsListParams,
  CreateSupportTicketParams,
  GetSupportTicketParams,
  UpdateSupportTicketDetailsParams,
  AddMissingAttachmentBody,
  CreateSupportTicketGuestParams,
  ComplaintInquiryParams,
  AddFeedbackParams,
  GetSurveyQuestionsParams,
  PostSurveyQuestionsParams,
  GetProPerformanceParams,
} from 'api/jiraAPI'

import Actions from '../actions'

export const getTicketsList = (params: GetTicketListParams, clearPageCount = false) => ({
  type: Actions.GET_TICKETS_LIST,
  payload: {
    params,
    clearPageCount,
  },
})

export const getTicket = (params: GetTicketParams) => ({
  type: Actions.GET_TICKET,
  payload: {
    params,
  },
})

export const addFeedback = (body: AddFeedbackParams) => ({
  type: Actions.ADD_FEEDBACK,
  payload: { params: body },
})

export const getTicketAndCorrespondingOfficeInfo = (params: GetTicketParams) => ({
  type: Actions.GET_TICKET_AND_CORRESPONDING_OFFICE_INFO,
  payload: {
    params,
  },
})

export const respondToComplaintSolution = (params: RespondToComplaintSolutionParams & GetTicketParams) => ({
  type: Actions.RESPOND_TO_COMPLAINT_SOLUTION,
  payload: {
    params,
  },
})

export const respondToOfficeComplaint = (params: RespondToOfficeComplaintParams & GetTicketParams) => ({
  type: Actions.RESPOND_TO_OFFICE_COMPLAINT,
  payload: {
    params,
  },
})

export const getSupportTicketsList = (params: GetSupportTicketsListParams) => ({
  type: Actions.GET_SUPPORT_TICKETS_LIST,
  payload: {
    params,
  },
})

export const createSupportTicket = (params: CreateSupportTicketParams) => ({
  type: Actions.CREATE_SUPPORT_TICKET,
  payload: {
    params,
  },
})

export const createSupportTicketGuest = (params: CreateSupportTicketGuestParams) => ({
  type: Actions.CREATE_SUPPORT_TICKET_GUEST,
  payload: {
    params,
  },
})

export const updateSupportTicketDetails = (params: UpdateSupportTicketDetailsParams) => ({
  type: Actions.UPDATE_SUPPORT_TICKET_DETAILS,
  payload: {
    params,
  },
})

export const getSupportTicketDetails = (params: GetSupportTicketParams) => ({
  type: Actions.GET_SUPPORT_TICKET_DETAILS,
  payload: {
    params,
  },
})

export const getSurveyQuestions = (params: GetSurveyQuestionsParams) => ({
  type: Actions.GET_SURVEY_QUESTIONS,
  payload: {
    params,
  },
})

export const postSurveyQuestions = (params: PostSurveyQuestionsParams) => ({
  type: Actions.POST_SURVEY_QUESTIONS,
  payload: {
    params,
  },
})

export const createComplaintTicket = (params: CreateComplaintTicketBody) => ({
  type: Actions.CREATE_COMPLAINT_TICKET,
  payload: {
    params,
  },
})

export const getComplaintRatings = () => ({
  type: Actions.GET_COMPLAINT_RATINGS,
})

export const getComplaintRatingsV2 = () => ({
  type: Actions.GET_COMPLAINT_RATINGS_V2,
})

export const addMissingAttachment = (params: AddMissingAttachmentBody) => ({
  type: Actions.ADD_COMPLAINT_MISSING_ATTACHMENT,
  payload: {
    params,
  },
})

export const complaintInquiry = (params: ComplaintInquiryParams) => ({
  type: Actions.COMPLAINT_INQUIRY,
  payload: { params },
})

export const showComplaintsSurvey = () => ({
  type: Actions.SHOW_COMPLAINTS_SURVEY,
})

export const getProPerformance = (params: GetProPerformanceParams) => ({
  type: Actions.GET_PRO_PERFORMANCE,
  payload: { params },
})

export const setProPerformance = (params: GetProPerformanceParams) => ({
  type: Actions.SET_PRO_PERFORMANCE,
  payload: { params },
})

export const getProPerformanceOverall = () => ({
  type: Actions.GET_PRO_PERFORMANCE_OVERALL,
})
