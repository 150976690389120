import { Lang } from 'common/types/commonTypes'
import { SetLanguageAction } from 'redux/actionCreators/language'
import Actions from 'redux/actions'

const lang = (state: Lang = Lang.cimode, action: SetLanguageAction): Lang => {
  switch (action.type) {
    case Actions.SET_LANGUAGE:
      return action.payload.lang
    default:
      return state
  }
}

export default lang
