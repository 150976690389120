import type { CallAPIAction, Status } from 'api/types'
import { GetVipProsResponse } from 'api/prosAPI'
import Actions from 'redux/actions'
import { VipPro } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'

type State = {
  pros: StringRecord<VipPro>
  allProIds: Array<number>
} & Status

const convertResponseToState = (resp: GetVipProsResponse) => {
  const [pros, allProIds] = resp.data.reduce<[StringRecord<VipPro>, Array<number>]>(
    ([prossAcc, idsAcc], proFromBE) => [
      {
        ...prossAcc,
        [proFromBE.id]: {
          id: proFromBE.id,
          occupation: proFromBE.occupation,
          nationality: proFromBE.nationality,
          type: proFromBE.type,
          pro: {
            address: proFromBE.pro.address,
            email: proFromBE.pro.email,
            externalProId: proFromBE.pro.external_pro_id,
            iban: proFromBE.pro.iban,
            ibanBeneficiary: proFromBE.pro.iban_beneficiary,
            id: proFromBE.pro.id,
            laborerOfficeId: proFromBE.pro.laborer_office_id,
            logoUrl: proFromBE.pro.logo_url,
            mobileNumber: proFromBE.pro.mobile_number,
            name: proFromBE.pro.name,
            sequenceNumber: proFromBE.pro.sequence_number,
          },
        },
      },
      idsAcc.concat(proFromBE.id),
    ],
    [{}, []],
  )
  return {
    pros,
    allProIds,
  }
}

const initialState: State = {
  pros: {},
  allProIds: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VIP_PROS_ASYNC,
    Actions.GET_VIP_PROS_SUCCESS,
    Actions.GET_VIP_PROS_FAIL,
    GetVipProsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VIP_PROS_ASYNC:
    case Actions.GET_VIP_PROS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VIP_PROS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    default:
      return state
  }
}
