import { Status, CallAPIAction } from 'api/types'
import type { GetVATValueResponse } from 'api/eContractingAPI'
import Actions from 'redux/actions'

type State = {
  value: number
} & Status

const initialState = {
  value: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VAT_VALUE_ASYNC,
    Actions.GET_VAT_VALUE_SUCCESS,
    Actions.GET_VAT_VALUE_FAIL,
    GetVATValueResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VAT_VALUE_ASYNC:
    case Actions.GET_VAT_VALUE_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VAT_VALUE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        value: parseFloat(payload.value),
        ...action.status,
      }
    }
    default:
      return state
  }
}
