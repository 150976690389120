import type { CallAPIAction, Status } from 'api/types'
import { GetProServiceAddonsResponse, ServiceAddonsFromBE } from 'api/prosAPI'
import Actions from 'redux/actions'
import { ProServiceAddons, ServiceAddons } from 'common/types/transformedRespTypes'

type State = {
  serviceAddons: Array<ServiceAddons> | null
  totalRecruitmentFee: number | null
  totalRecruitmentQuantity: number | null
  totalGovernmentFee: number | null
  totalGovernmentQuantity: number | null
} & Status

export const convertServiceAddonsResponse = (resp: Array<ServiceAddonsFromBE> | null): ProServiceAddons => {
  let recruitmentFee = 0
  let recruitmentQuantity = 0
  let governmentFee = 0
  let governmentQuantity = 0

  resp &&
    resp.forEach((service) => {
      if (service.pivot.recruited_addon_category_id === 1 && service.taxable === 0) {
        recruitmentFee += service.pivot.cost
        recruitmentQuantity++
      } else if (service.pivot.recruited_addon_category_id === 2 && service.taxable === 0) {
        governmentFee += service.pivot.cost
        governmentQuantity++
      }
    })

  return {
    serviceAddons: resp
      ? resp.map((service) => ({
          id: service.id,
          name: service.name,
          categoryMaxCount: service.category_max_count,
          categoryMinCount: service.category_min_count,
          description: service.description,
          taxable: service.taxable,
          pivot: {
            cost: service.pivot.cost,
            note: service.pivot.note,
            proServiceId: service.pivot.pro_service_id,
            recruitedAddonCategoryId: service.pivot.recruited_addon_category_id,
          },
        }))
      : null,
    totalRecruitmentFee: recruitmentFee || null,
    totalRecruitmentQuantity: recruitmentQuantity || null,
    totalGovernmentFee: governmentFee || null,
    totalGovernmentQuantity: governmentQuantity || null,
  }
}

const initialState: State = {
  serviceAddons: null,
  totalRecruitmentFee: 0,
  totalRecruitmentQuantity: 0,
  totalGovernmentFee: 0,
  totalGovernmentQuantity: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_SERVICE_ADDONS_ASYNC,
    Actions.GET_PRO_SERVICE_ADDONS_SUCCESS,
    Actions.GET_PRO_SERVICE_ADDONS_FAIL,
    GetProServiceAddonsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_SERVICE_ADDONS_ASYNC:
    case Actions.GET_PRO_SERVICE_ADDONS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PRO_SERVICE_ADDONS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertServiceAddonsResponse(payload.service_addons),
      }
    }
    default:
      return state
  }
}
