import { GetAuthAppendixListResponse } from 'api/laborerPortalAPI/authContract'
import { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse } from 'common/types/commonTypes'
import {
  LaborerAuthAppendix,
  convertAuthAppendixFromResponse,
} from 'common/utils/convertResponse/convertLaborerAuthResponse'
import Actions from 'redux/actions'

type State = {
  appendixes: Array<LaborerAuthAppendix>
} & Status

const initialState: State = {
  appendixes: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_AUTH_APPENDIX_LIST_ASYNC,
    Actions.GET_LABORER_AUTH_APPENDIX_LIST_SUCCESS,
    Actions.GET_LABORER_AUTH_APPENDIX_LIST_FAIL,
    GetAuthAppendixListResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_AUTH_APPENDIX_LIST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        appendixes: action.payload.data.map(convertAuthAppendixFromResponse),
      }
    }
    case Actions.GET_LABORER_AUTH_APPENDIX_LIST_FAIL:
    case Actions.GET_LABORER_AUTH_APPENDIX_LIST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
