import { CallAPIAction, Status } from 'api/types'
import { getVisaPdfLinkFailResponse, getVisaPdfLinkResponse } from 'api/evisaAPI/visas'
import Actions from 'redux/actions'

type State = {
  url: string | null
  errorMessage: string
} & Status

const initialState = {
  url: '',
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VISA_PDF_URL_ASYNC,
    Actions.GET_VISA_PDF_URL_SUCCESS,
    Actions.GET_VISA_PDF_URL_FAIL,
    getVisaPdfLinkResponse,
    getVisaPdfLinkFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VISA_PDF_URL_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_VISA_PDF_URL_SUCCESS: {
      return {
        ...state,
        ...action.status,
        url: action.payload.url,
      }
    }
    case Actions.GET_VISA_PDF_URL_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
