import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { CheckFeesExemptionResponse } from 'api/evisaAPI/evisaAPI'

type State = { visasCount: number; laborersCount: number } & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  visasCount: 0,
  laborersCount: 0,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_FEES_EXEMPTION_ASYNC,
    Actions.CHECK_FEES_EXEMPTION_SUCCESS,
    Actions.CHECK_FEES_EXEMPTION_FAIL,
    CheckFeesExemptionResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_FEES_EXEMPTION_SUCCESS: {
      return {
        ...state,
        ...action.status,
        visasCount: action.payload.visas_count,
        laborersCount: action.payload.laborers_count,
      }
    }
    case Actions.CHECK_FEES_EXEMPTION_ASYNC:
    case Actions.CHECK_FEES_EXEMPTION_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
