import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<Actions.REMOVE_AGENT_ASYNC, Actions.REMOVE_AGENT_SUCCESS, Actions.REMOVE_AGENT_FAIL>,
): State => {
  switch (action.type) {
    case Actions.REMOVE_AGENT_ASYNC:
    case Actions.REMOVE_AGENT_FAIL:
    case Actions.REMOVE_AGENT_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
