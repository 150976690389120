import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { CreateRefundTicketResponse } from 'api/eContractingAPI'

type State = { errorMessage: string; ticketId: string } & Status

const initialState = {
  errorMessage: '',
  ticketId: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_REFUND_TICKET_ASYNC,
    Actions.CREATE_REFUND_TICKET_SUCCESS,
    Actions.CREATE_REFUND_TICKET_FAIL,
    CreateRefundTicketResponse,
    { id: string; message: string }
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_REFUND_TICKET_ASYNC:
    case Actions.CREATE_REFUND_TICKET_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CREATE_REFUND_TICKET_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message || '',
        ticketId: action.payload?.id || '',
      }
    }

    default:
      return state
  }
}
