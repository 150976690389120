import React, { FunctionComponent } from 'react'
import { Trans, TransProps } from 'react-i18next'

import { useVipRoute } from 'common/hooks/useVipRoute'

import styles from './TransUtil.module.scss'

type TransUtilProps = {
  tKey: string
  withWrapper?: boolean
  className?: string
} & Omit<TransProps<string>, 'children'>

const TransUtil: FunctionComponent<TransUtilProps> = ({
  tKey,
  components,
  withWrapper = false,
  className,
  ...rest
}) => {
  const { isVip } = useVipRoute()

  const component = (
    <Trans
      {...rest}
      components={{
        bold: <b />,
        small: <small />,
        highlight: <span className={isVip ? styles.highlightTextVip : styles.highlightText}> </span>,
        ...components,
      }}
    >
      {tKey}
    </Trans>
  )
  return withWrapper ? <div className={className}> {component} </div> : component
}

export default TransUtil
