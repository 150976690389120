import { applyMiddleware, createStore, compose, StoreEnhancerStoreCreator } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '../saga/rootSaga'

import rootReducer from './rootReducer'

const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const middleware = [thunkMiddleware, sagaMiddleware]
const enhancers = [devToolsEnhancer({})]
const composeEnhancers = compose<StoreEnhancerStoreCreator<{}, {}>>(applyMiddleware(...middleware), ...enhancers)

export default createStore(rootReducer(), initialState, composeEnhancers)

sagaMiddleware.run(rootSaga)
