import { CallAPIAction, Status } from 'api/types'
import { PayForEContractResponse } from 'api/eContractingAPI'
import { PayfortBill } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = PayfortBill & Status

const initialState = {
  params: {},
  url: '',
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: PayForEContractResponse) => ({
  params: resp.params,
  url: resp.url,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.PAY_FOR_ECONTRACT_ASYNC,
    Actions.PAY_FOR_ECONTRACT_SUCCESS,
    Actions.PAY_FOR_ECONTRACT_FAIL,
    PayForEContractResponse
  >,
): State => {
  switch (action.type) {
    case Actions.PAY_FOR_ECONTRACT_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.PAY_FOR_ECONTRACT_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.PAY_FOR_ECONTRACT_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
