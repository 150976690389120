import { GetWorkersListResponse, WorkerInfoFromBE, VisaInfoFromBE } from 'api/evisaAPI/evisaAPI'
import { Status, CallAPIAction } from 'api/types'
import type { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

type WorkerVisa = {
  visaNumber: string
  occupation: ObjectWithTranslation
  nationality: ObjectWithTranslation
  borderNo: string
  visaIssueDate: string
  visaStatus: string
}

type Worker = {
  id: string
  name: string
  occupation: ObjectWithTranslation
  nationality: ObjectWithTranslation & { abbreviation: string }
}

export const createProperWorker = (acc: Array<Worker>, worker: WorkerInfoFromBE): Array<Worker> => {
  if (!worker) return acc
  return [
    ...acc,
    {
      id: worker.id,
      name: worker.name,
      nationality: {
        ...formatObjectWithTranslationFromBE(worker.nationality),
        abbreviation: worker.nationality.abbreviation,
      },
      occupation: formatObjectWithTranslationFromBE(worker.occupation),
    },
  ]
}

const transformWorkerVisa = (visa: VisaInfoFromBE): WorkerVisa => ({
  borderNo: visa.BorderNo,
  visaIssueDate: visa.VisaIssueDate,
  visaStatus: String(visa.VisaStatus),
  visaNumber: visa.VisaNo,
  nationality: formatObjectWithTranslationFromBE(visa.nationality),
  occupation: formatObjectWithTranslationFromBE(visa.occupation),
})

type State = {
  workers: Array<Worker>
  farmerWorkers: Array<Worker>
  visas: Array<WorkerVisa>
} & Status

const initialState: State = {
  workers: [],
  farmerWorkers: [],
  visas: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WORKERS_ASYNC,
    Actions.GET_WORKERS_SUCCESS,
    Actions.GET_WORKERS_FAIL,
    GetWorkersListResponse
  >,
): State => {
  const { status } = action
  switch (action.type) {
    case Actions.GET_WORKERS_SUCCESS: {
      return {
        ...state,
        workers: action.payload.workers.reduce(createProperWorker, []),
        farmerWorkers: action.payload.farmerWorkers.reduce(createProperWorker, []),
        visas: action.payload.visas.map(transformWorkerVisa),
        ...status,
      }
    }
    case Actions.GET_WORKERS_ASYNC:
    case Actions.GET_WORKERS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default: {
      return state
    }
  }
}
