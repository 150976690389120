import { CallAPIAction, Status } from 'api/types'
import { PayfortBill } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import { PayForEToIRequestResponse } from 'api/newDLTransferAPI'

type State = {
  status?: boolean
  bill?: PayfortBill
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.PAY_FOR_E_TO_I_REQUEST_ASYNC,
    Actions.PAY_FOR_E_TO_I_REQUEST_SUCCESS,
    Actions.PAY_FOR_E_TO_I_REQUEST_FAIL,
    PayForEToIRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.PAY_FOR_E_TO_I_REQUEST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        status: payload.status,
        bill: {
          params: payload.data.params,
          url: payload.data.url,
        },
      }
    }
    case Actions.PAY_FOR_E_TO_I_REQUEST_ASYNC:
    case Actions.PAY_FOR_E_TO_I_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
