import { ObjectWithTranslation, Occupation } from 'common/types/transformedRespTypes'
import { ContractAuthAppendixStatus, ContractAuthRequestStatus } from 'common/types/commonTypes'

export type LaborerAuthRequestFromBE = {
  id: number
  created_at: string
  employer: {
    id_number: string
    name: string
    nationality: string
    job: string
    mobile_number: string
  }
  iqama_number: string
  name: string
  name_en: string
  new_salary: string
  duration_in_months: number
  nationality?: {
    label: string
    label_en: string
  } | null
  occupation: {
    label: string
    label_en: string
  }
  start_date: string
  status: ContractAuthRequestStatus
  passport_number: string
  residency: {
    description: string | null
  }
  legalText: {
    ar_file_id: string
    en_file_id: string
  } | null
}

export type LaborerAuthRequest = {
  id: number
  createdAt: string
  employer: {
    idNumber: string
    name: string
    nationality: string
    job: string
    mobileNumber: string
  }
  iqamaNumber: string
  duration: number
  name: ObjectWithTranslation
  newSalary: string
  nationality?: ObjectWithTranslation
  occupation: Occupation
  startDate: string
  status: ContractAuthRequestStatus
  passportNumber?: string
  residency?: string
  legalText: {
    pdfIdAr: string
    pdfIdEn: string
  } | null
}

export const getEmptyLaborerAuthRequest = (): LaborerAuthRequest => ({
  id: 0,
  createdAt: '',
  employer: {
    idNumber: '',
    name: '',
    nationality: '',
    job: '',
    mobileNumber: '',
  },
  iqamaNumber: '',
  duration: 0,
  newSalary: '',
  name: {
    id: 0,
    label: '',
    labelEn: '',
  },
  nationality: {
    id: 0,
    label: '',
    labelEn: '',
  },
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  startDate: '',
  status: ContractAuthRequestStatus.REQUEST_EXPIRED,
  passportNumber: '',
  residency: '',
  legalText: null,
})

export const convertAuthRequestFromResponse = (request: LaborerAuthRequestFromBE): LaborerAuthRequest => ({
  id: request.id,
  createdAt: request.created_at,
  employer: {
    idNumber: request.employer.id_number,
    name: request.employer.name,
    nationality: request.employer.nationality,
    job: request.employer.job,
    mobileNumber: request.employer.mobile_number,
  },
  iqamaNumber: request.iqama_number,
  duration: request.duration_in_months,
  name: {
    id: 0,
    label: request.name,
    labelEn: request.name_en,
  },
  newSalary: request.new_salary,
  nationality: request.nationality
    ? {
        id: 0,
        label: request.nationality.label,
        labelEn: request.nationality.label_en,
      }
    : undefined,
  occupation: {
    id: 0,
    label: request.occupation.label,
    labelEn: request.occupation.label_en,
  },
  startDate: request.start_date,
  status: request.status,
  passportNumber: request.passport_number || '',
  residency: request.residency?.description ? request.residency.description : '',
  legalText: request.legalText
    ? {
        pdfIdAr: request.legalText.ar_file_id,
        pdfIdEn: request.legalText.en_file_id,
      }
    : null,
})

export type LaborerAuthAppendixFromBE = {
  id: number
  authenticated_contract_id: number
  created_at: string
  status: ContractAuthAppendixStatus
  salary: string
  approved_at: string | null
  duration_in_months: number
  passport_number: string
  residency: {
    description: string | null
  }
}

export type LaborerAuthAppendix = {
  id: number
  contractId: number
  createdAt: string
  status: ContractAuthAppendixStatus
  salary: string
  approvedAt: string
  duration: number
  passportNumber?: string
  residency?: string
}

export const getEmptyLaborerAuthAppendix = (): LaborerAuthAppendix => ({
  id: 0,
  contractId: 0,
  createdAt: '',
  status: ContractAuthAppendixStatus.EXPIRED,
  salary: '',
  approvedAt: '',
  duration: 0,
  passportNumber: '',
  residency: '',
})

export const convertAuthAppendixFromResponse = (appendix: LaborerAuthAppendixFromBE): LaborerAuthAppendix => ({
  id: appendix.id,
  contractId: appendix.authenticated_contract_id,
  createdAt: appendix.created_at,
  status: appendix.status,
  salary: appendix.salary,
  approvedAt: appendix.approved_at || '',
  duration: appendix.duration_in_months,
  passportNumber: appendix.passport_number || '',
  residency: appendix.residency?.description ? appendix.residency.description : '',
})
