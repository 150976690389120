import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { ComplaintInquiryResponse } from 'api/jiraAPI'

type State = {
  issue: {
    id: string
    status: string
    reporterName: string
    createdAt: string
  }
} & Status

const initialState = {
  issue: {
    id: '',
    status: '',
    reporterName: '',
    createdAt: '',
  },
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = ({ issue }: ComplaintInquiryResponse) => ({
  id: issue.id,
  status: issue.status,
  reporterName: issue.reporter_name,
  createdAt: issue.created,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.COMPLAINT_INQUIRY_ASYNC,
    Actions.COMPLAINT_INQUIRY_SUCCESS,
    Actions.COMPLAINT_INQUIRY_FAIL,
    ComplaintInquiryResponse
  >,
): State => {
  switch (action.type) {
    case Actions.COMPLAINT_INQUIRY_ASYNC:
    case Actions.COMPLAINT_INQUIRY_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.COMPLAINT_INQUIRY_SUCCESS: {
      return {
        ...state,
        ...action.status,
        issue: convertResponseToState(action.payload),
      }
    }
    default:
      return state
  }
}
