import type { AxiosRequestConfig } from 'axios'

import { callAPI, contractAuthAPI, laborerAuthAPIWithCredentials } from 'api/axios'
import { OccupationFromBE, NationalityFromBE, ObjectWithTranslationFromBE } from 'common/types/transformedRespTypes'
import { GeneralFailResponse } from 'common/types/commonTypes'

export type LaborerLoginParams = {
  laborerId: string
}

export type LaborerLoginFailResponse = {
  message?: string
  error?: {
    code: number
    message: string
  }
}

const laborerLogin = ({ laborerId }: LaborerLoginParams, config?: AxiosRequestConfig) =>
  contractAuthAPI.post(`/api/profile/labor/absher/login/${laborerId}`, config)

export type LaborerOtpVerifyParams = {
  laborerId: string
  otp: string
}

export type LaborerOtpVerifyResponse = {
  token: string
  expires_at: string
  labor: {
    iqama_number: string
    birth_date: string
    nationality: NationalityFromBE
    occupation: ObjectWithTranslationFromBE
    mobile_number: string
    religion: string
  }
}

const laborerOtpVerify = ({ laborerId, otp }: LaborerOtpVerifyParams, config?: AxiosRequestConfig) =>
  contractAuthAPI.post<LaborerOtpVerifyResponse>(`/api/musaned/labors/${laborerId}/otp/${otp}`, config)

export type RefreshLaborerTokenParams = {}

export type RefreshLaborerTokenResponse = {
  token: string
  expires_at: string
  labor: {
    iqama_number: string
    birth_date: string
    nationality: NationalityFromBE
    occupation: OccupationFromBE
    mobile_number: string
    religion: string
  }
}

const refreshLaborerToken = (params: RefreshLaborerTokenParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<RefreshLaborerTokenResponse>('/api/profile/labor/refresh', config)

export default {
  laborerLogin: callAPI<typeof laborerLogin>(laborerLogin),
  laborerOtpVerify: callAPI<typeof laborerOtpVerify, LaborerOtpVerifyResponse>(laborerOtpVerify),
  refreshLaborerToken: callAPI<typeof refreshLaborerToken, RefreshLaborerTokenResponse, GeneralFailResponse>(
    refreshLaborerToken,
  ),
}
