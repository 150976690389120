import { GetReplaceableVisaResponse } from 'api/evisaAPI/evisaAPI'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import { Status, CallAPIAction } from 'api/types'
import type { Occupation } from 'common/types/transformedRespTypes'
import { GeneralFailResponse } from 'common/types/commonTypes'
import Actions from 'redux/actions'

export const convertResponseToState = (resp: GetReplaceableVisaResponse) => ({
  visaId: resp.visaId,
  occupation: formatObjectWithTranslationFromBE(resp.occupation),
})

type State = {
  visaId: string
  occupation: Occupation
  errorMessage: string
} & Status

const initialState: State = {
  visaId: '',
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_REPLACEABLE_VISA_ASYNC,
    Actions.GET_REPLACEABLE_VISA_SUCCESS,
    Actions.GET_REPLACEABLE_VISA_FAIL,
    GetReplaceableVisaResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_REPLACEABLE_VISA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_REPLACEABLE_VISA_FAIL: {
      const { payload } = action
      return {
        ...state,
        errorMessage: payload?.error?.message || '',
        ...action.status,
      }
    }
    case Actions.GET_REPLACEABLE_VISA_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
