import { useEffect, VoidFunctionComponent } from 'react'

import authManager from 'common/utils/auth/authManager'

const NewRelic: VoidFunctionComponent = () => {
  const isAuthenticated = authManager.isAuthenticated()
  const username = authManager.getUserNameFromEvisaToken()

  useEffect(() => {
    if (isAuthenticated) {
      try {
        window.newrelic?.setCustomAttribute('username', `${username}`)
      } catch (err) {
        return console.warn(err)
      }
    }
  }, [isAuthenticated, username])

  return null
}

export default NewRelic
