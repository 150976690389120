import type { Offer } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { AddSelectOfferAction, RemoveSelectOfferAction } from 'redux/actionCreators/marketSelectedOffers'

type State = StringRecord<Offer>

const initialState = {}

export default (state: State = initialState, action: AddSelectOfferAction | RemoveSelectOfferAction): State => {
  switch (action.type) {
    case Actions.ADD_SELECTED_OFFER: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    }
    case Actions.REMOVE_SELECTED_OFFER: {
      const filteredState = { ...state }
      delete filteredState[action.payload]
      return filteredState
    }
    default:
      return state
  }
}
