import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetTransactionsResponse } from 'api/wpsAPI'
import { convertTransactionFromResponse, Transaction } from 'common/utils/wpsConvertResponse'

type State = {
  transactions: Array<Transaction>
  lastPage: number
} & Status

const initialState = {
  transactions: [],
  isLoading: false,
  success: false,
  fail: false,
  lastPage: 0,
}

const convertResponseToState = (response: GetTransactionsResponse) => ({
  transactions: response.data.map(convertTransactionFromResponse),
  lastPage: response.last_page,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WPS_LABORER_TRANSACTIONS_ASYNC,
    Actions.GET_WPS_LABORER_TRANSACTIONS_SUCCESS,
    Actions.GET_WPS_LABORER_TRANSACTIONS_FAIL,
    GetTransactionsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_WPS_LABORER_TRANSACTIONS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_WPS_LABORER_TRANSACTIONS_ASYNC:
    case Actions.GET_WPS_LABORER_TRANSACTIONS_FAIL: {
      return {
        ...state,
        ...action.status,
        transactions: [],
      }
    }
    default:
      return state
  }
}
