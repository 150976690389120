import { CallAPIAction, Status } from 'api/types'
import { FarmerVisaIssueRequest } from 'common/types/transformedRespTypes'
import { Paginator } from 'common/types/commonTypes'
import { formatFarmerVisaRequestFromBE } from 'common/utils/convertResponse/convertFarmerRequest'
import { getFarmerVisaRequests } from 'redux/actionCreators/evisaAPI/farmerVisa'
import { GetFarmerVisaRequestsResponse, FarmerVisaIssueRequestFromBE } from 'api/evisaAPI/farmerVisa'
import Actions from 'redux/actions'

type State = Paginator<FarmerVisaIssueRequest> & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  lastPage: 0,
  data: [],
}

const convertResponseToState = (resp: GetFarmerVisaRequestsResponse): Array<FarmerVisaIssueRequest> =>
  resp.paginator.data.map((request: FarmerVisaIssueRequestFromBE) => formatFarmerVisaRequestFromBE(request))

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_FARMER_VISA_REQUESTS_ASYNC,
    Actions.GET_FARMER_VISA_REQUESTS_SUCCESS,
    Actions.GET_FARMER_VISA_REQUESTS_FAIL,
    GetFarmerVisaRequestsResponse,
    {},
    {},
    ReturnType<typeof getFarmerVisaRequests>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_FARMER_VISA_REQUESTS_ASYNC: {
      const { clearPageCount } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        lastPage: clearPageCount ? 0 : state.lastPage || 0,
      }
    }
    case Actions.GET_FARMER_VISA_REQUESTS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        lastPage: payload.paginator.last_page || 0,
        data: convertResponseToState(payload),
      }
    }
    case Actions.GET_FARMER_VISA_REQUESTS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
