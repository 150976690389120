import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetCurrentTransactionResponse } from 'api/wpsAPI'
import { getEmptyTransaction, Transaction, convertTransactionFromResponse } from 'common/utils/wpsConvertResponse'

type State = {
  transaction: Transaction
} & Status

const initialState = {
  transaction: getEmptyTransaction(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_WPS_CURRENT_TRANSACTION_ASYNC,
    Actions.GET_WPS_CURRENT_TRANSACTION_SUCCESS,
    Actions.GET_WPS_CURRENT_TRANSACTION_FAIL,
    GetCurrentTransactionResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_WPS_CURRENT_TRANSACTION_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        transaction: convertTransactionFromResponse(payload),
      }
    }
    case Actions.GET_WPS_CURRENT_TRANSACTION_ASYNC:
    case Actions.GET_WPS_CURRENT_TRANSACTION_FAIL: {
      return {
        ...state,
        ...action.status,
        transaction: getEmptyTransaction(),
      }
    }
    default:
      return state
  }
}
