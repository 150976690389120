import { GetBanksListFailResponse, GetBanksListResponse } from 'api/evisaAPI/noticesAPI'
import { CallAPIAction, Status } from 'api/types'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

type Bank = ObjectWithTranslation

type State = {
  banks: Array<Bank>
  error?: {
    message: string
    code: number
  }
} & Status

const initialState: State = {
  banks: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_BANKS_LIST_ASYNC,
    Actions.GET_BANKS_LIST_SUCCESS,
    Actions.GET_BANKS_LIST_FAIL,
    GetBanksListResponse,
    GetBanksListFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_BANKS_LIST_SUCCESS:
      return {
        ...action.status,
        banks: action.payload.banks.map(formatObjectWithTranslationFromBE),
      }
    case Actions.GET_BANKS_LIST_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_BANKS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    default:
      return state
  }
}
