import { IBAN_CHECKED, VERIFIED } from 'common/constants'
import { ObjectWithTranslation, Occupation } from 'common/types/transformedRespTypes'

import LocalStorageData from '../localStorageData'

import JWTToken from './jwtToken'
declare global {
  interface Window {
    LabibaBot_UserFullName: string
    LabibaBot_UserEmail: string
    LabibaBot_PhoneNumber: string
  }
}

window.LabibaBot_UserFullName = ''
window.LabibaBot_UserEmail = ''
window.LabibaBot_PhoneNumber = ''

type LaborerProfileData = {
  iqamaNumber: string
  birthDate: string
  nationality: ObjectWithTranslation & { abbreviation: string }
  occupation: Occupation
  mobileNumber: string
  religion: string
}

const laborerProfile: LaborerProfileData = {
  iqamaNumber: '',
  birthDate: '',
  nationality: {
    id: 0,
    label: '',
    labelEn: '',
    abbreviation: '',
  },
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  mobileNumber: '',
  religion: '',
}

export type NationalAddressType = {
  id: string
  isPrimary: string
  buildingNumber: string
  streetName: string
  streetNameEn: string
  additionalNumber: string
  districtArea: string
  districtAreaEn: string
  cityName: string
  cityNameEn: string
  zipcode: string
  detailed: string
  canUpdate: boolean
}

type UserProfileData = {
  isProfileFilled: boolean
  insuranceEnabled: boolean
  email: string
  idNumber: number
  mobile: string
  name: string
  nic: {
    nationality: ObjectWithTranslation
    maritalStatus: ObjectWithTranslation
    birthDate: string
    gender: string
    firstName: ObjectWithTranslation
    lastName: ObjectWithTranslation
    fatherName: ObjectWithTranslation
    grandfatherName: ObjectWithTranslation
  }
  personalDetails: {
    familySize: string
    houseTypeId: string
    averageIncome: string
    domesticLaborers: string
    address: string
    coordinates: string
    id: string
    userId: string
    job: string
    childrenUnderTwelve: string
    ibanOwner: string
    iban: string
    route: string
    sublocality: string
    streetNumber: string
    postalCode: string
    postalCodeSuffix: string
    isOutdatedIBAN: boolean
  }
  premiumAgent: {
    idNumber: number
    email: string
    mobile: number
    name: string
  } | null
  relationManager: {
    uuid: string
    landlineNumber: number
    email: string
    mobile: number
    name: string
    isAuthorized: number
  } | null
  secondaryMobile: string
  username?: number
  showCurrentBalanceAndRefundRequest: boolean
  showNoticeBalanceAndRefundRequest: boolean
  nationalAddress: Array<NationalAddressType>
  userTitle: {
    title: ObjectWithTranslation
    description: string
  } | null
}

const userProfile: UserProfileData = {
  isProfileFilled: false,
  insuranceEnabled: false,
  email: '',
  idNumber: 0,
  mobile: '',
  name: '',
  premiumAgent: null,
  relationManager: null,
  nic: {
    nationality: {
      id: 0,
      label: '',
      labelEn: '',
    },
    maritalStatus: {
      id: 0,
      label: '',
      labelEn: '',
    },
    birthDate: '',
    gender: '',
    firstName: {
      id: 0,
      label: '',
      labelEn: '',
    },
    lastName: {
      id: 0,
      label: '',
      labelEn: '',
    },
    fatherName: {
      id: 0,
      label: '',
      labelEn: '',
    },
    grandfatherName: {
      id: 0,
      label: '',
      labelEn: '',
    },
  },
  personalDetails: {
    familySize: '',
    houseTypeId: '',
    averageIncome: '',
    domesticLaborers: '',
    address: '',
    coordinates: '',
    id: '',
    userId: '',
    job: '',
    childrenUnderTwelve: '',
    ibanOwner: '',
    iban: '',
    route: '',
    sublocality: '',
    streetNumber: '',
    postalCode: '',
    postalCodeSuffix: '',
    isOutdatedIBAN: false,
  },
  secondaryMobile: '',
  username: undefined,
  showCurrentBalanceAndRefundRequest: false,
  showNoticeBalanceAndRefundRequest: false,
  nationalAddress: [],
  userTitle: null,
}

export type AuthListener = (event: AuthEvents, params: { autoLoggedOut?: boolean }) => void

type AuthDataValues = {
  evisaToken?: string
  contractAuthToken?: string
  jiraToken?: string
  userData?: UserProfileData
  mobileVerified?: boolean
  laborerTokenExpiresDate?: string
  laborerInfo?: LaborerProfileData
}

type AuthEvents = 'LOG_OUT' | 'DATA_UPDATE'
class AuthManager {
  private static readonly KEEP_ALIVE_EXPIRATION = 5 * 60 * 1000 // 5 minutes
  private static readonly LOG_OUT_EXPIRATION = 1 * 60 * 1000 // 1 minute
  private static readonly KEEP_ALIVE_EXPIRATION_LABORER = AuthManager.LOG_OUT_EXPIRATION // after 14 minutes
  private readonly jiraAPIToken = new JWTToken('jiraToken')
  private readonly evisaAPIToken = new JWTToken('evisaToken')
  private readonly contractAuthToken = new JWTToken('contractAuthToken')
  private readonly userProfile = new LocalStorageData<UserProfileData>('userProfile', userProfile)
  private readonly laborerProfile = new LocalStorageData<LaborerProfileData>('laborerProfile', laborerProfile)
  private readonly mobileVerified = new LocalStorageData<boolean>(VERIFIED, false)
  private readonly ibanChecked = new LocalStorageData<boolean>(IBAN_CHECKED, false)
  private listeners = [] as Array<AuthListener>

  addListener(listener: AuthListener) {
    this.listeners.push(listener)
  }

  removeListener(listenerToRemove: AuthListener) {
    this.listeners = this.listeners.filter((listener) => listener !== listenerToRemove)
  }

  private notifyAboutUpdate(event: AuthEvents, params: { autoLoggedOut?: boolean } = {}) {
    this.listeners.forEach((listener) => listener(event, params))
  }

  private checkTokensExpiration(dateShift: number) {
    const expDate = +new Date() + dateShift
    return !![this.evisaAPIToken, this.jiraAPIToken].every((jwtToken) => jwtToken.getExpirationDate() > expDate)
  }

  private checkLaborerTokensExpiration(expDate: string) {
    return +new Date() > +new Date(expDate)
  }

  private isReadyToShowKeepAliveModal(expDate: string) {
    return +new Date() > +new Date(expDate) - AuthManager.KEEP_ALIVE_EXPIRATION_LABORER
  }

  evisaAuthenticated() {
    return this.evisaAPIToken.getExpirationDate() > +new Date() + AuthManager.LOG_OUT_EXPIRATION
  }

  isAuthenticated() {
    return this.checkTokensExpiration(AuthManager.LOG_OUT_EXPIRATION)
  }

  isLaborerAuthenticated() {
    return !this.checkLaborerTokensExpiration(this.contractAuthToken.laborerTokenExpiresDate)
  }

  isReadyForAutoLogOut() {
    return this.isAuthenticated() && !this.checkTokensExpiration(AuthManager.KEEP_ALIVE_EXPIRATION)
  }

  isVipUser() {
    return this.evisaAPIToken.getUserType() || false
  }

  isAgent() {
    return this.evisaAPIToken.getUserRole() === 'agent' || false
  }

  isRelationManger() {
    return this.evisaAPIToken.getUserRole() === 'relationship manager' || false
  }

  isReadyForAutoLaborerLogOut() {
    return (
      this.isLaborerAuthenticated() && this.isReadyToShowKeepAliveModal(this.contractAuthToken.laborerTokenExpiresDate)
    )
  }

  getEvisaToken() {
    return this.evisaAPIToken.token
  }

  getContractAuthToken() {
    return this.contractAuthToken.token
  }

  getUserNameFromEvisaToken() {
    return this.evisaAPIToken.getUsername() || 'anonymous'
  }

  getJiraToken() {
    return this.jiraAPIToken.token
  }

  getUserProfileData() {
    return this.userProfile.data
  }

  getLaborerProfileData() {
    return this.laborerProfile.data
  }

  getMobileVerified() {
    return this.mobileVerified.data
  }

  setAuthData(
    {
      evisaToken = this.evisaAPIToken.token,
      contractAuthToken = this.contractAuthToken.token,
      laborerTokenExpiresDate = this.contractAuthToken.laborerTokenExpiresDate,
      jiraToken = this.jiraAPIToken.token,
      userData = this.userProfile.data,
      laborerInfo = this.laborerProfile.data,
      mobileVerified = this.mobileVerified.data,
    }: AuthDataValues,
    notify = true,
  ) {
    this.evisaAPIToken.token = evisaToken
    this.contractAuthToken.token = contractAuthToken
    this.contractAuthToken.laborerTokenExpiresDate = laborerTokenExpiresDate
    this.jiraAPIToken.token = jiraToken
    this.userProfile.data = userData
    this.laborerProfile.data = laborerInfo
    this.mobileVerified.data = mobileVerified
    if (notify) {
      this.notifyAboutUpdate('DATA_UPDATE')
    }
    window.LabibaBot_UserFullName = userData.name
    window.LabibaBot_UserEmail = userData.email
    window.LabibaBot_PhoneNumber = userData.mobile
  }

  removeData() {
    this.evisaAPIToken.removeToken()
    this.contractAuthToken.removeToken()
    this.jiraAPIToken.removeToken()
    this.userProfile.removeData()
    this.laborerProfile.removeData()
    this.mobileVerified.removeData()
    this.ibanChecked.removeData()
    this.contractAuthToken.removeToken()
    // legacy values
    localStorage.removeItem('jwt')
    localStorage.removeItem(VERIFIED)
    window.LabibaBot_UserFullName = ''
    window.LabibaBot_UserEmail = ''
    window.LabibaBot_PhoneNumber = ''
  }

  logOut(autoLoggedOut = false) {
    this.removeData()
    this.notifyAboutUpdate('LOG_OUT', { autoLoggedOut })
  }
}

const authManager = new AuthManager()

export default authManager
