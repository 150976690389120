import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { GetRejectionReasonListResponse } from 'api/laborerPortalAPI/authContract'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'

type State = {
  list: Array<ObjectWithTranslation>
} & Status

const initialState = {
  list: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_AUTH_REJECTION_REASONS_LIST_ASYNC,
    Actions.GET_AUTH_REJECTION_REASONS_LIST_SUCCESS,
    Actions.GET_AUTH_REJECTION_REASONS_LIST_FAIL,
    GetRejectionReasonListResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_AUTH_REJECTION_REASONS_LIST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        list: action.payload.map(formatObjectWithTranslationFromBE),
      }
    }
    case Actions.GET_AUTH_REJECTION_REASONS_LIST_ASYNC:
    case Actions.GET_AUTH_REJECTION_REASONS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
