import { CallAPIAction, Status } from 'api/types'
import { GetVisaIssueRequestsListResponse } from 'api/evisaAPI/visas'
import { VisaIssueRequest } from 'common/types/transformedRespTypes'
import { Paginator } from 'common/types/commonTypes'
import { formatVisaIssueRequestFromBE } from 'common/utils/convertResponse/convertVisaResponseToState'
import { getVisaIssueRequestsList } from 'redux/actionCreators/evisaAPI/visas'
import Actions from 'redux/actions'

type State = Paginator<VisaIssueRequest> & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  lastPage: 0,
  data: [],
}

const convertResponseToState = (resp: GetVisaIssueRequestsListResponse): VisaIssueRequest[] =>
  resp.paginator.data.map(formatVisaIssueRequestFromBE)

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_VISA_ISSUE_REQUESTS_LIST_ASYNC,
    Actions.GET_VISA_ISSUE_REQUESTS_LIST_SUCCESS,
    Actions.GET_VISA_ISSUE_REQUESTS_LIST_FAIL,
    GetVisaIssueRequestsListResponse,
    {},
    {},
    ReturnType<typeof getVisaIssueRequestsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_VISA_ISSUE_REQUESTS_LIST_ASYNC: {
      const { clearPageCount } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        lastPage: clearPageCount ? 0 : state.lastPage,
      }
    }
    case Actions.GET_VISA_ISSUE_REQUESTS_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        lastPage: payload.paginator.last_page || 0,
        data: convertResponseToState(payload),
      }
    }
    case Actions.GET_VISA_ISSUE_REQUESTS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
