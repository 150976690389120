import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  getLaborerSupportTickets as getLaborerSupportTicketsAction,
  createLaborerSupportTicket as createSupportTicketAction,
  getLaborerSupportAttachments as getLaborerSupportAttachmentsAction,
} from 'redux/actionCreators/laborerPortalAPI/support'
import supportTicketAPI from 'api/laborerPortalAPI/supportTicket'
import Actions from 'redux/actions'
import { getSimpleRequestHandler, putLoading } from 'saga/utils'
import { FAIL, SUCCESS } from 'common/constants'
import { Status } from 'api/types'

const getLaborerSupportTickets = getSimpleRequestHandler<
  typeof supportTicketAPI.getSupportTickets,
  ReturnType<typeof getLaborerSupportTicketsAction>
>({
  actionLoading: Actions.GET_LABORER_SUPPORT_TICKETS_ASYNC,
  actionSuccess: Actions.GET_LABORER_SUPPORT_TICKETS_SUCCESS,
  actionFail: Actions.GET_LABORER_SUPPORT_TICKETS_FAIL,
  callApiFn: supportTicketAPI.getSupportTickets,
})

const createLaborerSupportTicket = getSimpleRequestHandler<
  typeof supportTicketAPI.createSupportTicket,
  ReturnType<typeof createSupportTicketAction>
>({
  actionLoading: Actions.CREATE_LABORER_SUPPORT_TICKET_ASYNC,
  actionSuccess: Actions.CREATE_LABORER_SUPPORT_TICKET_SUCCESS,
  actionFail: Actions.CREATE_LABORER_SUPPORT_TICKET_FAIL,
  callApiFn: supportTicketAPI.createSupportTicket,
})

type GetSupportAttachmentsResponse = {
  status: Status
  response: {
    attachment: string
    type: string
  }
}

function* getLaborerSupportAttachments(action: ReturnType<typeof getLaborerSupportAttachmentsAction>) {
  yield putLoading({ type: Actions.GET_LABORER_SUPPORT_ATTACHMENTS_ASYNC, callAPIActionPayload: {} })

  const attachments = action.payload.params.attachments

  const apiCalls: Array<GetSupportAttachmentsResponse> = yield all([
    ...attachments.map((item) =>
      call<typeof supportTicketAPI.getSupportAttachments>(supportTicketAPI.getSupportAttachments, { id: item.id }, {}),
    ),
  ])

  if (apiCalls.every((item) => item.status.success)) {
    yield put({
      type: Actions.GET_LABORER_SUPPORT_ATTACHMENTS_SUCCESS,
      callAPIActionPayload: {},
      payload: apiCalls.map(
        (item, index) => ({
          attachment: item.response.attachment,
          type: item.response.type,
          filename: attachments[index].filename,
          id: attachments[index].id,
        }),
        {},
      ),
      headers: {},
      status: SUCCESS,
    })
  } else {
    yield put({
      type: Actions.GET_LABORER_SUPPORT_ATTACHMENTS_FAIL,
      callAPIActionPayload: {},
      payload: {},
      headers: {},
      status: FAIL,
    })
  }
}

export default [
  takeLatest(Actions.GET_LABORER_SUPPORT_TICKETS, getLaborerSupportTickets),
  takeLatest(Actions.CREATE_LABORER_SUPPORT_TICKET, createLaborerSupportTicket),
  takeLatest(Actions.GET_LABORER_SUPPORT_ATTACHMENTS, getLaborerSupportAttachments),
]
