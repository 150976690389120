import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'

type State = {
  errorMessage?: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SEND_OTP_FOR_UPDATE_MOBILE_ASYNC,
    Actions.SEND_OTP_FOR_UPDATE_MOBILE_SUCCESS,
    Actions.SEND_OTP_FOR_UPDATE_MOBILE_FAIL,
    {},
    { error?: { message?: string }; mobile?: string[] }
  >,
): State => {
  switch (action.type) {
    case Actions.SEND_OTP_FOR_UPDATE_MOBILE_ASYNC:
    case Actions.SEND_OTP_FOR_UPDATE_MOBILE_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.SEND_OTP_FOR_UPDATE_MOBILE_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || (action.payload?.mobile || [])[0] || '',
      }
    }
    default:
      return state
  }
}
