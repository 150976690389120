import Actions from 'redux/actions'
import { CallAPIAction, Status } from 'api/types'
import { MaarofaSubmitFailResponse, MaarofaSubmitResponse, MaarofaPresubmitFailResponse } from 'api/eContractingAPI'

type State = {
  requestId?: number
  offerId?: number
  error: string
} & Status

const initialState = {
  requestId: undefined,
  offerId: undefined,
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.MAAROFA_PRESUBMIT_ASYNC,
        Actions.MAAROFA_PRESUBMIT_SUCCESS,
        Actions.MAAROFA_PRESUBMIT_FAIL,
        MaarofaSubmitResponse,
        MaarofaPresubmitFailResponse
      >
    | CallAPIAction<
        Actions.MAAROFA_SUBMIT_ASYNC,
        Actions.MAAROFA_SUBMIT_SUCCESS,
        Actions.MAAROFA_SUBMIT_FAIL,
        {
          offerId: number
          requestId: number
        },
        MaarofaSubmitFailResponse
      >,
): State => {
  switch (action.type) {
    case Actions.MAAROFA_PRESUBMIT_ASYNC:
    case Actions.MAAROFA_SUBMIT_ASYNC:
    case Actions.MAAROFA_PRESUBMIT_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.MAAROFA_SUBMIT_SUCCESS: {
      return {
        ...state,
        ...action.status,
        requestId: action.payload.requestId,
        offerId: action.payload.offerId,
      }
    }
    case Actions.MAAROFA_PRESUBMIT_FAIL:
    case Actions.MAAROFA_SUBMIT_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        error: (payload?.visa || payload?.worker_passport || [])[0] || '',
      }
    }
    default:
      return state
  }
}
