import React, { FunctionComponent, useEffect, useLayoutEffect, memo, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { CSSTransition } from 'react-transition-group'

import { ESCAPE_KEY, TRANSITION_TIMEOUT } from 'common/constants'
import { ENABLE_NEW_DESIGN_FLOW, IS_LOCAL_DEVELOPMENT_ENV } from 'common/envConstants'

import { ReactComponent as CloseIcon } from 'images/close-icon.svg'

import styles from './Modal.module.scss'

const modalRoot = document.getElementById('modalRoot') as HTMLDivElement
if (IS_LOCAL_DEVELOPMENT_ENV) {
  if (!modalRoot) {
    throw new Error('modalRoot should be in DOM')
  }
}

export type ModalProps = {
  open: boolean
  onClose?: () => void
  withCloseBtn?: boolean
  classNames?: {
    rootShowActive?: string
    root?: string
    rootHideActive?: string
    content?: string
  }
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  open,
  onClose = () => null,
  classNames = {},
  withCloseBtn,
}) => {
  const [element] = useState<HTMLDivElement>(document.createElement('div'))
  const [readyToMount, setReadyToMount] = useState<boolean>(false)
  const modal = useRef<null | HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (open) {
      modalRoot.appendChild(element)
    }
    return () => {
      if (!open && !readyToMount && modalRoot.contains(element)) {
        modalRoot.removeChild(element)
      }
    }
  }, [open, readyToMount, element])

  useEffect(() => {
    const handleClickClose = (event: MouseEvent) => {
      if ((event.target as HTMLDivElement) === modal.current && open) onClose()
    }
    const handleKeyClose = (event: KeyboardEvent) => {
      if (event.key === ESCAPE_KEY && open) onClose()
    }
    document.addEventListener('click', handleClickClose)
    document.addEventListener('keydown', handleKeyClose)
    return () => {
      document.removeEventListener('click', handleClickClose)
      document.removeEventListener('keyup', handleKeyClose)
    }
  }, [onClose, open])

  return (
    <CSSTransition
      in={open}
      appear
      timeout={TRANSITION_TIMEOUT}
      mountOnEnter
      unmountOnExit
      onEntered={() => setReadyToMount(true)}
      onExited={() => setReadyToMount(false)}
      classNames={{
        appearActive: clsx(styles.rootShowActive, classNames?.rootShowActive),
        appearDone: clsx(styles.rootShowActive, classNames?.rootShowActive),
        enterActive: clsx(styles.rootShowActive, classNames?.rootShowActive),
        enterDone: clsx(styles.root, ENABLE_NEW_DESIGN_FLOW && styles.rootNew, classNames?.root),
        exitActive: clsx(styles.rootHideActive, classNames.rootHideActive),
      }}
    >
      <>
        {ReactDOM.createPortal(
          <div ref={modal}>
            <div className={clsx(styles.content, ENABLE_NEW_DESIGN_FLOW && styles.contentNew, classNames.content)}>
              {readyToMount && withCloseBtn && (
                <div className={styles.closeBtn} onClick={() => onClose()}>
                  <CloseIcon className={styles.icon} />
                </div>
              )}
              {readyToMount && children}
            </div>
          </div>,
          element,
        )}
      </>
    </CSSTransition>
  )
}

export default memo<typeof Modal>(Modal)
