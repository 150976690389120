import { CallAPIAction, Status } from 'api/types'
import { GetMaarofaOfficesResponse, MaarofaOfficeFromBE } from 'api/prosAPI'
import { convertRating } from 'common/utils/common'
import { MaarofaOffice } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  offices: StringRecord<MaarofaOffice>
} & Status

const initialState = {
  offices: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: Array<MaarofaOfficeFromBE>) =>
  resp.reduce((acc, maarofaOfficeFromBE) => {
    const service = maarofaOfficeFromBE.services[0]
    return {
      ...acc,
      [service.id]: {
        name: maarofaOfficeFromBE.name,
        officeId: service.id,
        rating: convertRating(maarofaOfficeFromBE.rating),
        address: {
          cityId: maarofaOfficeFromBE.address.city_id,
          logoUrl: maarofaOfficeFromBE.address.logo_url,
          location: maarofaOfficeFromBE.address.location,
          mobileNumber: maarofaOfficeFromBE.address.mobile_number,
          phoneNumber: maarofaOfficeFromBE.address.phone_number,
          address: maarofaOfficeFromBE.address.address,
          email: maarofaOfficeFromBE.address.email,
        },
        proId: service.pro_id,
        religionId: service.religion_id,
        price: service.price,
        recruitmentPeriod: service.recruitment_period,
      },
    }
  }, {})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_MAAROFA_OFFICES_ASYNC,
    Actions.GET_MAAROFA_OFFICES_SUCCESS,
    Actions.GET_MAAROFA_OFFICES_FAIL,
    GetMaarofaOfficesResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_MAAROFA_OFFICES_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_MAAROFA_OFFICES_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        offices: convertResponseToState(payload.offices),
      }
    }
    case Actions.GET_MAAROFA_OFFICES_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
