import type { CallAPIAction, Status } from 'api/types'
import { GetProRecruitedServiceAddonsResponse } from 'api/prosAPI'
import Actions from 'redux/actions'

type State = {
  recruitedServiceAddons: Array<{
    id: number
    name: string
    categoryMaxCount: number
    categoryMinCount: number
    description: string
    taxable: number
    isOffered: number
  }> | null
} & Status

export const convertRecruitedServiceAddonsResponse = (resp: GetProRecruitedServiceAddonsResponse) => {
  if (resp.data.length) {
    return resp.data.map((service) => ({
      id: service.id,
      name: service.name,
      categoryMaxCount: service.category_max_count,
      categoryMinCount: service.category_min_count,
      description: service.description,
      taxable: service.taxable,
      isOffered: service.is_offered,
    }))
  }

  return null
}

const initialState: State = {
  recruitedServiceAddons: null,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_ASYNC,
    Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_SUCCESS,
    Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_FAIL,
    GetProRecruitedServiceAddonsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_ASYNC:
    case Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PRO_RECRUITED_SERVICE_ADDONS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        recruitedServiceAddons: convertRecruitedServiceAddonsResponse(payload),
      }
    }
    default:
      return state
  }
}
