import moment from 'moment'

import { GetUserProfileResponse, NationalAddressResponseType } from 'api/evisaAPI/evisaAPI'
import { NATIONALITY_TRANSLATIONS } from 'common/constants'
import { ValidationErrors } from 'common/types/commonTypes'
import { RootState } from 'redux/rootReducer'
import { ENABLE_CHECK_USER_PROFILE } from 'common/envConstants'

import { formatValidationMessages } from '../common'
import { NationalAddressType } from '../auth/authManager'

export const getFilteredIbanValidationMessage = (resp: ValidationErrors['errors'] = {}) => {
  if (
    resp?.iban &&
    resp.iban.includes(
      'عفواً، رقم الآيبان المدخل تم إدخاله من قبل مستخدم آخر، يرجى إدخال رقم الآيبان الصحيح الخاص بالمستخدم',
    ) &&
    resp.iban.includes(
      'عفواً، رقم الآيبان المدخل تم إدخاله من قبل مكتب ، يرجى إدخال رقم الآيبان الصحيح الخاص بالمستخدم',
    )
  ) {
    return 'ibanUsedByAnotherEntity'
  } else if (
    ENABLE_CHECK_USER_PROFILE &&
    (resp?.address ||
      resp?.average_income ||
      resp?.children_under_twelve_count ||
      resp?.coordinates ||
      resp?.domestic_laborers ||
      resp?.family_size ||
      resp?.house_type_id ||
      resp?.job ||
      resp?.locality ||
      resp?.postal_code ||
      resp?.postal_code_suffix ||
      resp?.route ||
      resp?.street_number ||
      resp?.sublocality)
  ) {
    return 'CHECK_USER_PROFILE'
  } else if (resp) {
    return formatValidationMessages(resp)
  }
}

export const convertUserNationality = (nationality: string) => {
  const nationalityTranslationObj = NATIONALITY_TRANSLATIONS.find((item) => item.nationality === nationality)

  if (nationalityTranslationObj) {
    return {
      id: 0,
      label: nationalityTranslationObj.label,
      labelEn: nationalityTranslationObj.labelEn,
    }
  }

  return {
    id: 0,
    label: nationality,
    labelEn: nationality,
  }
}

export const nationalAddressConvert = (address: NationalAddressResponseType): NationalAddressType => ({
  id: address.id,
  isPrimary: address.is_primary,
  buildingNumber: address.buildingNumber,
  streetName: address.streetName,
  streetNameEn: address.streetNameEn,
  additionalNumber: address.additionalNumber,
  districtArea: address.districtArea,
  districtAreaEn: address.districtAreaEn,
  cityName: address.cityName,
  cityNameEn: address.cityNameEn,
  zipcode: address.zipcode,
  detailed: address.detailed,
  canUpdate: moment(Date.now()).diff(address.updated_at, 'hours') >= 24,
})

const convertUserProfileResponse = (resp: GetUserProfileResponse): RootState['auth']['userProfile'] => {
  const userProfile = {
    isProfileFilled: !!(resp.email && resp.personal_details),
    insuranceEnabled: resp.insurance_enabled,
    email: resp.email,
    idNumber: resp.id_number,
    mobile: resp.mobile,
    name: resp.name,
    premiumAgent: resp.premium_agent
      ? {
          idNumber: resp.premium_agent?.id_number,
          email: resp.premium_agent?.email,
          mobile: resp.premium_agent?.mobile,
          name: resp.premium_agent?.name,
        }
      : null,
    relationManager: resp.relation_manager
      ? {
          uuid: resp.relation_manager.uuid,
          landlineNumber: resp.relation_manager?.landline_number,
          email: resp.relation_manager?.email,
          mobile: resp.relation_manager?.mobile_number,
          name: resp.relation_manager?.name,
          isAuthorized: parseInt(resp.relation_manager?.authorized),
        }
      : null,
    nic: {
      nationality: convertUserNationality(resp.nic.nationality),
      maritalStatus: {
        id: 0,
        label: resp.nic.marital_status,
        labelEn: resp.nic.marital_status_en,
      },
      birthDate: resp.nic.birth_date,
      gender: resp.nic.gender,
      firstName: {
        id: 0,
        label: resp.nic.first_name,
        labelEn: resp.nic.first_name_en,
      },
      lastName: {
        id: 0,
        label: resp.nic.last_name,
        labelEn: resp.nic.last_name_en,
      },
      fatherName: {
        id: 0,
        label: resp.nic.father_name,
        labelEn: resp.nic.father_name_en,
      },
      grandfatherName: {
        id: 0,
        label: resp.nic.grandfather_name,
        labelEn: resp.nic.grandfather_name_en,
      },
    },
    personalDetails: {
      familySize: resp.personal_details ? resp.personal_details.family_size.toString() : '',
      houseTypeId: resp.personal_details ? resp.personal_details.house_type_id.toString() : '',
      averageIncome: resp.personal_details ? resp.personal_details.average_income.toString() : '',
      address: resp.personal_details?.address || '',
      coordinates: resp.personal_details?.coordinates || '24.7081983,46.6786661', // default
      domesticLaborers: resp.personal_details ? resp.personal_details.domestic_laborers.toString() : '0',
      id: resp.personal_details ? resp.personal_details.id.toString() : '',
      userId: resp.personal_details ? resp.personal_details.user_id.toString() : '',
      job: resp.personal_details?.job || '',
      childrenUnderTwelve: resp.personal_details ? resp.personal_details.children_under_twelve_count.toString() : '',
      ibanOwner: resp.personal_details?.iban_owner || '',
      iban: resp.personal_details?.iban || '',
      route: resp.personal_details?.route || '',
      sublocality: resp.personal_details?.sublocality || '',
      streetNumber: resp.personal_details?.street_number || '',
      postalCode: resp.personal_details?.postal_code || '',
      postalCodeSuffix: resp.personal_details?.postal_code_suffix || '',
      isOutdatedIBAN: !!Number(resp.personal_details?.is_outdated_iban),
    },
    secondaryMobile: resp.secondary_mobile || '',
    username: resp.username,
    showCurrentBalanceAndRefundRequest: resp.show_current_balance_and_refund_request,
    showNoticeBalanceAndRefundRequest: resp.show_notice_balance_and_refund_request,
    nationalAddress: (resp.national_address || []).map(nationalAddressConvert),
    userTitle: resp.user_title
      ? {
          title: {
            id: 0,
            label: resp.user_title.title.ar,
            labelEn: resp.user_title.title.en,
          },
          description: resp.user_title.description.ar,
        }
      : null,
  }
  return userProfile
}

export default convertUserProfileResponse
