import { ItoERequestsFormBE, IndividualToEntityListResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { getIndividualToEntityList } from 'redux/actionCreators/evisaAPI/dlAPI'
import { ItoERequestsStatus } from 'common/types/commonTypes'

type Request = {
  id: number
  requestDate: string
  laborerName: string
  establishmentName: string
  status: ItoERequestsStatus
}

type State = {
  iToERequestsParams: {
    page: number
    status: string | ItoERequestsStatus
  }
  requests: Array<Request>
  lastPage: number
} & Status

const initialState: State = {
  iToERequestsParams: {
    page: 0,
    status: '',
  },
  requests: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertRequestToState = (resp: ItoERequestsFormBE): Request => ({
  id: resp.id,
  laborerName: resp.laborer_name,
  establishmentName: resp.establishment_name,
  requestDate: resp.request_date,
  status: resp.status,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_E_REQUESTS_ASYNC,
    Actions.GET_I_TO_E_REQUESTS_SUCCESS,
    Actions.GET_I_TO_E_REQUESTS_FAIL,
    IndividualToEntityListResponse,
    {},
    {},
    ReturnType<typeof getIndividualToEntityList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_E_REQUESTS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        requests: action.payload.paginator.data.map(convertRequestToState),
        lastPage: action.payload.paginator.last_page,
      }
    }
    case Actions.GET_I_TO_E_REQUESTS_ASYNC: {
      const {
        params: { status = state.iToERequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        iToERequestsParams: {
          page,
          status,
        },
        lastPage: status !== state.iToERequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_I_TO_E_REQUESTS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
