import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type FailResponse = { errorMessage: string; errors?: Record<string, Array<string>> }

type State = {
  errorMessage: string
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.SAVE_USER_DATA_ASYNC,
        Actions.SAVE_USER_DATA_SUCCESS,
        Actions.SAVE_USER_DATA_FAIL,
        {},
        FailResponse
      >
    | CallAPIAction<
        Actions.UPDATE_PERSONAL_INFO_ASYNC,
        Actions.UPDATE_PERSONAL_INFO_SUCCESS,
        Actions.UPDATE_PERSONAL_INFO_FAIL,
        {},
        FailResponse
      >
    | CallAPIAction<
        Actions.UPDATE_USER_CONTACTS_ASYNC,
        Actions.UPDATE_USER_CONTACTS_SUCCESS,
        Actions.UPDATE_USER_CONTACTS_FAIL,
        {},
        FailResponse
      >,
): State => {
  switch (action.type) {
    case Actions.SAVE_USER_DATA_ASYNC:
    case Actions.UPDATE_PERSONAL_INFO_ASYNC:
    case Actions.UPDATE_USER_CONTACTS_ASYNC:
    case Actions.SAVE_USER_DATA_SUCCESS:
    case Actions.UPDATE_PERSONAL_INFO_SUCCESS:
    case Actions.UPDATE_USER_CONTACTS_SUCCESS:
      return {
        ...state,
        ...action.status,
      }
    case Actions.SAVE_USER_DATA_FAIL:
    case Actions.UPDATE_PERSONAL_INFO_FAIL:
    case Actions.UPDATE_USER_CONTACTS_FAIL: {
      const validationErrors = Object.values(action.payload?.errors || {})
        .reduce((acc, curr) => {
          return [...acc, ...curr]
        }, [])
        .join('\f')
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.errorMessage || validationErrors || '',
      }
    }
    default:
      return state
  }
}
