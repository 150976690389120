/**
 * Use this function to calculate VAT:
 *
 * if you got the VAT value from the total and want to FORMAT!!! the VAT with double precision you need to use
 * formatCurrencyUp for amount
 * formatCurrencyDown for VAT
 * formatCurrency for total amount
 * why? consider this case:
 *
 * amount 199.055
 * VAT 1.015
 * totalAmount = 199.055 + 1.015 === 200.07
 *
 * if we will use Math.round or Math.floor or .toFixed(2) we will get wrong result
 * so in case we have half of cent we always need to round down the VAT and round up the amount
 * in this case we will always get the correct value
 * amout => 199.06
 * vat => 1.01
 * --------------------
 * In other cases just use formatCurrency(calculateVAT)
 */
export const calculateVAT = (values: Array<number>, vatPercentage: number): number =>
  values.reduce((val, acc) => val + acc, 0) * vatPercentage

/**
 * Format currency to double precision
 * 0.005 => 0.01
 */
export const formatCurrency = (val: number, precisionCount = 2) => {
  const helperValue = 10 ** precisionCount
  return Math.round(val * helperValue + Number.EPSILON) / helperValue
}

/**
 * Format currency to double precision if we have any amount after 2 digit we round it up
 * 0.003 => 0.01
 */
export const formatCurrencyUp = (val: number, precisionCount = 2) => {
  const helperValue = 10 ** precisionCount
  const rounded = Math.ceil(val * helperValue - Number.EPSILON) / helperValue
  return parseFloat(rounded.toFixed(precisionCount))
}

/**
 * Format currency to double precision if we have any amount after 2 digit we round it down
 * 0.0199 => 0.01
 */

export const formatCurrencyDown = (val: number, precisionCount = 2) => {
  const helperValue = 10 ** precisionCount
  const rounded = Math.trunc(val * helperValue) / helperValue
  return parseFloat(rounded.toFixed(precisionCount))
}
