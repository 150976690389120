import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { ResendEToIRequestFailResponse, ResendEToIRequestResponse } from 'api/newDLTransferAPI'

type State = {
  error?: {
    message: string
    code: number
  }
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.RESEND_E_TO_I_REQUEST_ASYNC,
    Actions.RESEND_E_TO_I_REQUEST_SUCCESS,
    Actions.RESEND_E_TO_I_REQUEST_FAIL,
    ResendEToIRequestResponse,
    ResendEToIRequestFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.RESEND_E_TO_I_REQUEST_ASYNC:
    case Actions.RESEND_E_TO_I_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.RESEND_E_TO_I_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    }

    default:
      return state
  }
}
