import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { RejectIToIOfferResponse } from 'api/newDLTransferAPI'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.REJECT_I_TO_I_OFFER_ASYNC,
    Actions.REJECT_I_TO_I_OFFER_SUCCESS,
    Actions.REJECT_I_TO_I_OFFER_FAIL,
    RejectIToIOfferResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.REJECT_I_TO_I_OFFER_ASYNC:
    case Actions.REJECT_I_TO_I_OFFER_SUCCESS:
    case Actions.REJECT_I_TO_I_OFFER_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
