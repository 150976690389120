import { CallAPIAction, Status } from 'api/types'
import type { GetPriceRangeResponse } from 'api/prosAPI'
import Actions from 'redux/actions'

type State = {
  min: number
  max: number
} & Status

const initialState = {
  min: 0,
  max: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRICE_RANGE_ASYNC,
    Actions.GET_PRICE_RANGE_SUCCESS,
    Actions.GET_PRICE_RANGE_FAIL,
    GetPriceRangeResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRICE_RANGE_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PRICE_RANGE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        min: payload.min,
        max: payload.max,
      }
    }
    case Actions.GET_PRICE_RANGE_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
