import {
  SendLaborerRelativeInfoFailResponse,
  SendLaborerRelativeInfoResponse,
} from 'api/laborerPortalAPI/serviceTransfer'
import { CallAPIAction, Status } from 'api/types'
import {
  convertIToIRequestFromResponse,
  getEmptyLaborerIToIRequest,
  IToIRequest,
} from 'common/utils/convertResponse/convertIToIResponse'
import Actions from 'redux/actions'

type State = {
  request: IToIRequest
  error?: string
} & Status

const initialState: State = {
  request: getEmptyLaborerIToIRequest(),
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SEND_LABORER_RELATIVE_INFO_ASYNC,
    Actions.SEND_LABORER_RELATIVE_INFO_SUCCESS,
    Actions.SEND_LABORER_RELATIVE_INFO_FAIL,
    SendLaborerRelativeInfoResponse,
    SendLaborerRelativeInfoFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.SEND_LABORER_RELATIVE_INFO_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.SEND_LABORER_RELATIVE_INFO_SUCCESS:
      return {
        ...state,
        ...action.status,
        request: convertIToIRequestFromResponse(action.payload.data),
      }
    case Actions.SEND_LABORER_RELATIVE_INFO_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.error,
      }
    default:
      return state
  }
}
