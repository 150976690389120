import { setAlternativeVisaFlowData } from 'redux/actionCreators/alternativeVisaFlow'
import Actions from 'redux/actions'

export type State = {
  acknowledge: boolean
  workerId: string
  nationalityId: number
  visaIssuePlaceId: number
}

const initialState: State = {
  acknowledge: false,
  workerId: '',
  nationalityId: 0,
  visaIssuePlaceId: 0,
}

export default (state: State = initialState, action: ReturnType<typeof setAlternativeVisaFlowData>) => {
  switch (action.type) {
    case Actions.SET_ALTERNATIVE_VISA_FLOW_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
