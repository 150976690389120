import { UpdateLaborerMobileNumberFailResponse, UpdateLaborerMobileNumberResponse } from 'api/contractAuthAPI'
import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.UPDATE_LABORER_MOBILE_NUMBER_ASYNC,
    Actions.UPDATE_LABORER_MOBILE_NUMBER_SUCCESS,
    Actions.UPDATE_LABORER_MOBILE_NUMBER_FAIL,
    UpdateLaborerMobileNumberResponse,
    UpdateLaborerMobileNumberFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.UPDATE_LABORER_MOBILE_NUMBER_ASYNC:
    case Actions.UPDATE_LABORER_MOBILE_NUMBER_SUCCESS: {
      return {
        ...state,
        ...action.status,
        errorMessage: '',
      }
    }
    case Actions.UPDATE_LABORER_MOBILE_NUMBER_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
