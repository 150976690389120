import React, { FunctionComponent, memo } from 'react'
import clsx from 'clsx'

import { useVipRoute } from 'common/hooks/useVipRoute'

import styles from './CircualrLoading.module.scss'

export type CircularLoadingProps = {
  size?: number
  thickness?: number
  color?: 'primary' | 'white' | 'red' | 'dark' | 'green'
  className?: string
}

const VIEW_BOX_SIZE = 44

const CircularLoading: FunctionComponent<CircularLoadingProps> = ({
  size = 32,
  thickness = 3.6,
  color = 'primary',
  className,
}) => {
  const { isVip } = useVipRoute()
  const colorType = isVip && color === 'primary' ? `vip-${color}` : color

  return (
    <div className={clsx(styles.root, className)} style={{ width: size, height: size }} role='progressbar'>
      <svg viewBox={`${VIEW_BOX_SIZE / 2} ${VIEW_BOX_SIZE / 2} ${VIEW_BOX_SIZE} ${VIEW_BOX_SIZE}`}>
        <circle
          className={clsx(styles.circle, styles[colorType])}
          cx={VIEW_BOX_SIZE}
          cy={VIEW_BOX_SIZE}
          r={(VIEW_BOX_SIZE - thickness) / 2}
          fill='none'
          strokeWidth={thickness}
        />
      </svg>
    </div>
  )
}

export default memo(CircularLoading)
