import { StringRecord, UploadFinancialDocumentErrorMessage } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { Status, CallAPIAction } from 'api/types'
import type { UploadVisasFailResponse, UploadVisasResponse } from 'api/evisaAPI/visas'
import type { UpdateProgressAction, RemoveFileAction } from 'redux/actionCreators/evisaAPI/visas'

export interface UploadFile extends Status {
  id?: number
  file: File
  progress: number
  timeRemaining: number
  errorMessage?: UploadFinancialDocumentErrorMessage
}

type State = {
  files: StringRecord<UploadFile>
  failCount: number
}

const initialState = {
  files: {},
  failCount: 0,
}

type RequestPayload = {
  params: {
    file: File
  }
}

const updateProgress = (state: State, action: UpdateProgressAction): StringRecord<UploadFile> => {
  const { progress, timeLeft } = action.payload
  const files = { ...state.files }
  const file = files[action.payload.fileName]!
  file.progress = progress
  file.timeRemaining = timeLeft
  return files
}

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.UPLOAD_VISAS_FILES_ASYNC,
        Actions.UPLOAD_VISAS_FILES_SUCCESS,
        Actions.UPLOAD_VISAS_FILES_FAIL,
        UploadVisasResponse,
        UploadVisasFailResponse,
        {},
        RequestPayload
      >
    | RemoveFileAction
    | UpdateProgressAction,
): State => {
  switch (action.type) {
    case Actions.UPDATE_PROGRESS: {
      return {
        ...state,
        files: updateProgress(state, action),
      }
    }
    case Actions.REMOVE_VISAS_FILE: {
      const files = { ...state.files }
      delete files[action.payload.fileName]
      return {
        ...state,
        files,
      }
    }
    case Actions.UPLOAD_VISAS_FILES_ASYNC: {
      const { file } = action.callAPIActionPayload.params
      const newFile: UploadFile = {
        ...action.status,
        file,
        progress: 0,
        timeRemaining: 0,
      }
      return {
        ...state,
        files: { ...state.files, [newFile.file.name]: newFile },
      }
    }
    case Actions.UPLOAD_VISAS_FILES_SUCCESS: {
      const uploadData = action.payload.userUpload
      const file = state.files[uploadData.original_file_name]!
      const newFile: UploadFile = {
        ...file,
        ...action.status,
        id: uploadData.id,
        progress: 100,
        timeRemaining: 0,
      }
      return {
        ...state,
        files: { ...state.files, [newFile.file.name]: newFile },
      }
    }
    case Actions.UPLOAD_VISAS_FILES_FAIL: {
      const fileName = action.callAPIActionPayload.params.file.name
      const errorMessage = action.payload?.error?.message || undefined
      const file = state.files[fileName]!
      const newFiles = {
        ...state.files,
        [fileName]: {
          ...file,
          ...action.status,
          errorMessage,
        },
      }
      return {
        ...state,
        files: newFiles,
        failCount: state.failCount + 1,
      }
    }
    default:
      return state
  }
}
