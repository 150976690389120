import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { AcceptAuthRequestResponse } from 'api/laborerPortalAPI/authContract'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_LABORER_AUTH_REQUEST_ASYNC,
    Actions.ACCEPT_LABORER_AUTH_REQUEST_SUCCESS,
    Actions.ACCEPT_LABORER_AUTH_REQUEST_FAIL,
    AcceptAuthRequestResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_LABORER_AUTH_REQUEST_ASYNC:
    case Actions.ACCEPT_LABORER_AUTH_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.ACCEPT_LABORER_AUTH_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    }
    default:
      return state
  }
}
