import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

import MusanedLogo from 'ui-components/icons/MusanedLogo/MusanedLogo'

import styles from './SuspensePreloader.module.scss'

type SuspensePreloaderProps = {
  wholeView?: boolean
  isVip?: boolean
}

const SuspensePreloader: FunctionComponent<SuspensePreloaderProps> = ({ wholeView, isVip }) => (
  <div className={clsx(wholeView ? styles.rootWholeView : styles.root, isVip && styles.isVip)}>
    <div className={styles.container}>
      <MusanedLogo isVip={isVip} className={styles.icon} />
    </div>
  </div>
)

export default SuspensePreloader
