import { Status, CallAPIAction } from 'api/types'
import type {
  CheckEligibilityForDisabilityVisaResponse,
  CheckEligibilityForDisabilityVisaFailResponse,
} from 'api/evisaAPI/visas'
import type { Occupation } from 'common/types/transformedRespTypes'
import { DisabilityVisaEligibilityErrorMessages, StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { formatOccupationsFromBE } from 'common/utils/objectWithTranslation'

const convertResponseToState = (resp: CheckEligibilityForDisabilityVisaResponse) => {
  const occupations = resp.occupationsList.reduce(
    (acc, occupationFromBE) => ({
      ...acc,
      [occupationFromBE.id]: {
        ...formatOccupationsFromBE(occupationFromBE),
        gender: occupationFromBE.gender,
      },
    }),
    {},
  )
  return {
    occupations,
    isFinanciallyCapable: resp.isFinanciallyCapable,
    totalNumberOfVisa: resp.totalNumberOfVisa,
    numberOfRemainingVisa: resp.numberOfRemainingVisa,
  }
}

type State = {
  occupations: StringRecord<Occupation>
  isFinanciallyCapable?: boolean
  totalNumberOfVisa?: number
  numberOfRemainingVisa?: number
  errorMessage?: DisabilityVisaEligibilityErrorMessages
} & Status

const initialState = {
  occupations: {},
  isFinanciallyCapable: undefined,
  totalNumberOfVisa: undefined,
  numberOfRemainingVisa: undefined,
  errorMessage: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_ASYNC,
    Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_SUCCESS,
    Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_FAIL,
    CheckEligibilityForDisabilityVisaResponse,
    CheckEligibilityForDisabilityVisaFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || DisabilityVisaEligibilityErrorMessages.DEFAULT_ERROR,
      }
    }
    default:
      return state
  }
}
