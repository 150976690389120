import { UserEligibilityErrorMessages } from 'common/types/commonTypes'
import { CheckNoticeAvailabilityFailResponse, CheckNoticeAvailabilityResponse } from 'api/evisaAPI/noticesAPI'
import { CallAPIAction, Status } from 'api/types'
import { Occupation } from 'common/types/transformedRespTypes'
import { formatOccupationsFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

type State = {
  yemeniOccupations: Array<Occupation>
  isFinanciallyCapable?: boolean
  totalNumberOfVisa?: number
  numberOfRemainingVisa?: number
  isSaudi?: boolean
  errorMessage?: UserEligibilityErrorMessages
} & Status

const initialState: State = {
  yemeniOccupations: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_NOTICE_AVAILABILITY_ASYNC,
    Actions.CHECK_NOTICE_AVAILABILITY_SUCCESS,
    Actions.CHECK_NOTICE_AVAILABILITY_FAIL,
    CheckNoticeAvailabilityResponse,
    CheckNoticeAvailabilityFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_NOTICE_AVAILABILITY_SUCCESS: {
      const { yemeniOccupationsList, isFinanciallyCapable, isSaudi, numberOfRemainingVisa, totalNumberOfVisa } =
        action.payload
      return {
        ...action.status,
        yemeniOccupations: yemeniOccupationsList.map(formatOccupationsFromBE),
        isFinanciallyCapable,
        isSaudi,
        numberOfRemainingVisa,
        totalNumberOfVisa,
      }
    }
    case Actions.CHECK_NOTICE_AVAILABILITY_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.CHECK_NOTICE_AVAILABILITY_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || UserEligibilityErrorMessages.DEFAULT_ERROR,
      }
    default:
      return state
  }
}
