import { CreateNoticeFailResponse, CreateNoticeResponse } from 'api/evisaAPI/noticesAPI'
import { CallAPIAction, Status } from 'api/types'
import { UserEligibilityErrorMessages } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage: string | UserEligibilityErrorMessages
  noticeId: number
} & Status

const initialState = {
  noticeId: 0,
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state = initialState,
  action: CallAPIAction<
    Actions.CREATE_NOTICE_ASYNC,
    Actions.CREATE_NOTICE_SUCCESS,
    Actions.CREATE_NOTICE_FAIL,
    CreateNoticeResponse,
    CreateNoticeFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_NOTICE_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.CREATE_NOTICE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        noticeId: payload.notice,
        ...action.status,
      }
    }

    case Actions.CREATE_NOTICE_FAIL: {
      const { error, errors = {} } = action.payload || {}
      const msg = Object.values(errors).length ? Object.values(errors)[0][0] : ''
      return {
        ...state,
        errorMessage: error?.message || msg || '',
        ...action.status,
      }
    }

    default:
      return state
  }
}
