import type { AxiosRequestConfig } from 'axios'

import { callAPI, laborerTransferAPIWithCredentials } from 'api/axios'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { IToIRequestFromBE } from 'common/types/transformedRespTypes'
import { GeneralFailResponse } from 'common/types/commonTypes'

export type GetLaborerTransfersListParams = {
  perPage?: number
  page?: number
  status?: string
}

export type GetLaborerTransfersListResponse = {
  data: Array<IToIRequestFromBE>
  last_page: number
  current_page: number
}

const getLaborerTransfersList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetLaborerTransfersListParams,
  config?: AxiosRequestConfig,
) =>
  laborerTransferAPIWithCredentials.get<GetLaborerTransfersListResponse>('api/laborers/transfer_request', {
    params: {
      page: page + 1,
      per_page: perPage,
      status,
    },
    ...config,
  })

export type GetLaborerTransferDetailsParams = {
  requestId: number
}

export type GetLaborerTransferDetailsResponse = {
  data: IToIRequestFromBE
}

export const getLaborerTransferDetails = (
  { requestId }: GetLaborerTransferDetailsParams,
  config?: AxiosRequestConfig,
) => laborerTransferAPIWithCredentials.get<GetLaborerTransferDetailsResponse>(`api/laborers/${requestId}/show`, config)

export type SendLaborerRelativeInfoParams = {
  requestId: string
  relativeInfo: {
    name: string
    relation: {
      textEn: string
      text: string
    }
    mobileNumber: string
  }
}

export type SendLaborerRelativeInfoResponse = {
  data: IToIRequestFromBE
}

export type SendLaborerRelativeInfoFailResponse = {
  error?: string
}

export const sendLaborerRelativeInfo = (
  { requestId, relativeInfo }: SendLaborerRelativeInfoParams,
  config?: AxiosRequestConfig,
) =>
  laborerTransferAPIWithCredentials.post<SendLaborerRelativeInfoResponse>(
    `api/laborers/${requestId}/contract`,
    {
      relative: {
        name: relativeInfo.name,
        relation: {
          text: relativeInfo.relation.text,
          text_en: relativeInfo.relation.textEn,
        },
        mobile_number: `+${relativeInfo.mobileNumber}`,
      },
    },
    config,
  )

export type RejectLaborerTransferParams = {
  requestId: number
  rejectionReasons: {
    label: string
    labelEn: string
  }
}

export type RejectLaborerTransferResponse = {}

const rejectLaborerTransferRequest = (
  { requestId, rejectionReasons }: RejectLaborerTransferParams,
  config?: AxiosRequestConfig,
) =>
  laborerTransferAPIWithCredentials.post<RejectLaborerTransferResponse>(
    `api/laborers/${requestId}/reject`,
    {
      rejection_reasons: [
        {
          label: rejectionReasons.label,
          label_en: rejectionReasons.labelEn,
        },
      ],
    },
    config,
  )

export type AcceptLaborerTransferParams = {
  requestId: number
  relativeInfo: {
    name: string
    relation: {
      textEn: string
      text: string
    }
    mobileNumber: string
  }
}

export type AcceptLaborerTransferResponse = {}

const acceptLaborerTransferRequest = (
  { requestId, relativeInfo }: AcceptLaborerTransferParams,
  config?: AxiosRequestConfig,
) =>
  laborerTransferAPIWithCredentials.post<AcceptLaborerTransferResponse>(
    `api/laborers/${requestId}/accept`,
    {
      relative: {
        name: relativeInfo.name,
        relation: {
          text: relativeInfo.relation.text,
          text_en: relativeInfo.relation.textEn,
        },
        mobile_number: `+${relativeInfo.mobileNumber}`,
      },
    },
    config,
  )

export default {
  getLaborerTransfersList: callAPI<
    typeof getLaborerTransfersList,
    GetLaborerTransfersListResponse,
    GeneralFailResponse
  >(getLaborerTransfersList),
  getLaborerTransferDetails: callAPI<
    typeof getLaborerTransferDetails,
    GetLaborerTransferDetailsResponse,
    GeneralFailResponse
  >(getLaborerTransferDetails),
  sendLaborerRelativeInfo: callAPI<
    typeof sendLaborerRelativeInfo,
    SendLaborerRelativeInfoResponse,
    GeneralFailResponse
  >(sendLaborerRelativeInfo),
  rejectLaborerTransferRequest: callAPI<
    typeof rejectLaborerTransferRequest,
    RejectLaborerTransferResponse,
    GeneralFailResponse
  >(rejectLaborerTransferRequest),
  acceptLaborerTransferRequest: callAPI<
    typeof acceptLaborerTransferRequest,
    AcceptLaborerTransferResponse,
    GeneralFailResponse
  >(acceptLaborerTransferRequest),
}
