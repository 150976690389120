import { CreateDomesticTransferRequestResponse, FailResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  error?: {
    message: string
    code: number
  }
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_ASYNC,
    Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_SUCCESS,
    Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_FAIL,
    CreateDomesticTransferRequestResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_ASYNC:
    case Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.status,
      }
    case Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_FAIL:
      return {
        ...state,
        error: {
          message: action.payload?.error.message || '',
          code: action.payload?.error.code || 0,
        },
        ...action.status,
      }
    default:
      return state
  }
}
