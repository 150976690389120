import { takeLatest } from 'redux-saga/effects'

import { dlAPI } from 'api/evisaAPI/dlAPI'
import {
  createDomesticTransferRequestAction,
  getEToILegacyRequests as getEToILegacyRequestsAction,
  getLaborerInfoAction,
  getIndividualToEntityList as getIndividualToEntityListAction,
  getItoERequestDetails as getItoERequestDetailsAction,
  iToERequestActions as iToERequestActionsAction,
} from 'redux/actionCreators/evisaAPI/dlAPI'
import Actions from 'redux/actions'

import { getSimpleRequestHandler } from '../../utils'

const getEToILegacyRequests = getSimpleRequestHandler<
  typeof dlAPI.getEToILegacyRequests,
  ReturnType<typeof getEToILegacyRequestsAction>
>({
  actionLoading: Actions.GET_E_TO_I_LEGACY_REQUESTS_ASYNC,
  actionSuccess: Actions.GET_E_TO_I_LEGACY_REQUESTS_SUCCESS,
  actionFail: Actions.GET_E_TO_I_LEGACY_REQUESTS_FAIL,
  callApiFn: dlAPI.getEToILegacyRequests,
})

const getIToERequests = getSimpleRequestHandler<
  typeof dlAPI.getIndividualToEntityList,
  ReturnType<typeof getIndividualToEntityListAction>
>({
  actionLoading: Actions.GET_I_TO_E_REQUESTS_ASYNC,
  actionSuccess: Actions.GET_I_TO_E_REQUESTS_SUCCESS,
  actionFail: Actions.GET_I_TO_E_REQUESTS_FAIL,
  callApiFn: dlAPI.getIndividualToEntityList,
})

const getLaborerInfo = getSimpleRequestHandler<typeof dlAPI.getLaborerInfo, ReturnType<typeof getLaborerInfoAction>>({
  actionLoading: Actions.GET_LABORER_INFO_ASYNC,
  actionSuccess: Actions.GET_LABORER_INFO_SUCCESS,
  actionFail: Actions.GET_LABORER_INFO_FAIL,
  callApiFn: dlAPI.getLaborerInfo,
})

const createDomesticTransferRequest = getSimpleRequestHandler<
  typeof dlAPI.createDomesticTransferRequest,
  ReturnType<typeof createDomesticTransferRequestAction>
>({
  actionLoading: Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.CREATE_DOMESTIC_TRANSFER_REQUEST_FAIL,
  callApiFn: dlAPI.createDomesticTransferRequest,
})

const getItoERequestDetails = getSimpleRequestHandler<
  typeof dlAPI.getItoERequestDetails,
  ReturnType<typeof getItoERequestDetailsAction>
>({
  actionLoading: Actions.GET_I_TO_E_REQUEST_DETAILS_ASYNC,
  actionSuccess: Actions.GET_I_TO_E_REQUEST_DETAILS_SUCCESS,
  actionFail: Actions.GET_I_TO_E_REQUEST_DETAILS_FAIL,
  callApiFn: dlAPI.getItoERequestDetails,
})

const acceptItoERequest = getSimpleRequestHandler<
  typeof dlAPI.iToERequestActions,
  ReturnType<typeof iToERequestActionsAction>
>({
  actionLoading: Actions.I_TO_E_REQUEST_ACTIONS_ASYNC,
  actionSuccess: Actions.I_TO_E_REQUEST_ACTIONS_SUCCESS,
  actionFail: Actions.I_TO_E_REQUEST_ACTIONS_FAIL,
  callApiFn: dlAPI.iToERequestActions,
})

export default [
  takeLatest(Actions.GET_E_TO_I_LEGACY_REQUESTS, getEToILegacyRequests),
  takeLatest(Actions.GET_LABORER_INFO, getLaborerInfo),
  takeLatest(Actions.CREATE_DOMESTIC_TRANSFER_REQUEST, createDomesticTransferRequest),
  takeLatest(Actions.GET_I_TO_E_REQUESTS, getIToERequests),
  takeLatest(Actions.GET_I_TO_E_REQUEST_DETAILS, getItoERequestDetails),
  takeLatest(Actions.I_TO_E_REQUEST_ACTIONS, acceptItoERequest),
]
