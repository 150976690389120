import React, { FunctionComponent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { TranslationNamespace } from 'common/utils/translation'
import Button, { ButtonProps } from 'ui-components/buttons/Button/Button'
import CircularLoading, { CircularLoadingProps } from 'ui-components/loading/CircularLoading/CircularLoading'

import styles from './ButtonWithLoading.module.scss'

export type ButtonWithLoadingProps = ButtonProps & {
  isLoading?: boolean
  loadingColor?: CircularLoadingProps['color']
}

type LoadingComponentProps = {
  hasIconEnhancer: boolean
  color: CircularLoadingProps['color']
}

const LoadingComponent: FunctionComponent<LoadingComponentProps> = ({ hasIconEnhancer, color }) =>
  hasIconEnhancer ? (
    <CircularLoading size={15} color={color} />
  ) : (
    <div className={styles.appearAnimation}>
      <CircularLoading size={15} color={color} />
    </div>
  )

const ButtonWithLoading: FunctionComponent<ButtonWithLoadingProps> = ({
  children,
  isLoading,
  iconEnhancer,
  type = 'primary',
  loadingColor = type.includes('tertiary') ? 'primary' : 'white',
  className,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.uiComponents)

  return (
    <Button
      iconEnhancer={
        isLoading ? <LoadingComponent hasIconEnhancer={!!iconEnhancer} color={loadingColor} /> : iconEnhancer
      }
      type={type}
      className={clsx(isLoading && styles.button, className)}
      {...rest}
    >
      {children || t('next')}
    </Button>
  )
}

export default memo<typeof ButtonWithLoading>(ButtonWithLoading)
