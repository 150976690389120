import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import {
  LaborerAuthRequest,
  convertAuthRequestFromResponse,
  getEmptyLaborerAuthRequest,
} from 'common/utils/convertResponse/convertLaborerAuthResponse'
import { GetAuthRequestDetailsResponse } from 'api/laborerPortalAPI/authContract'

type State = {
  request: LaborerAuthRequest
} & Status

const initialState = {
  request: getEmptyLaborerAuthRequest(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_AUTH_REQUEST_DETAILS_ASYNC,
    Actions.GET_LABORER_AUTH_REQUEST_DETAILS_SUCCESS,
    Actions.GET_LABORER_AUTH_REQUEST_DETAILS_FAIL,
    GetAuthRequestDetailsResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_AUTH_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        request: convertAuthRequestFromResponse(action.payload.data),
      }
    }
    case Actions.GET_LABORER_AUTH_REQUEST_DETAILS_ASYNC:
    case Actions.GET_LABORER_AUTH_REQUEST_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
