import { AxiosError } from 'axios'

import { HttpStatusCode } from 'common/types/commonTypes'
import i18n from 'i18n'

import authManager from '../auth/authManager'
import { TranslationNamespace } from '../translation'

export const t = (key: string, options?: Record<string, string | number>) =>
  i18n.t(key, { ns: TranslationNamespace.labibaChatBot, ...options })

export const tCommon = (key: string, options?: Record<string, string | number>) =>
  i18n.t(key, { ns: TranslationNamespace.common, ...options })

type AnyFn = (...args: any) => any

export const withAuthGuard =
  <T extends AnyFn>(fn: T) =>
  async (...params: Parameters<T>) => {
    if (authManager.isAuthenticated()) {
      return fn(...params)
    }
    return t('login')
  }

export const wrongIdGuard =
  (notFountTKey: string) =>
  <T extends AnyFn>(fn: T) =>
  async (...params: Parameters<T>) => {
    try {
      const text = await fn(...params)
      return text
    } catch (error) {
      const id = params[0]
      if ((error as AxiosError)?.response?.status === HttpStatusCode.NOT_FOUND) {
        return t(notFountTKey, { id })
      }
      throw error
    }
  }

export const withFetchGuard =
  <T extends AnyFn>(fn: T) =>
  async (...params: Parameters<T>) => {
    try {
      const text = await fn(...params)
      return text
    } catch {
      return t('fetchError')
    }
  }
