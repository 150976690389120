import { Visa } from 'common/types/transformedRespTypes'
import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'
import { setMaarofaData } from 'redux/actionCreators/maarofaRequest'

import Actions from '../actions'

export type State = {
  fullName: string
  arrivalCity?: number
  passportNumber: string
  passportExpiry: string
  ageRange?: number
  workerSalary: string
  phone: { value: string; location?: PhoneData }
  passportImage?: File
  visa?: Visa
  acknowledge?: boolean
  workerInfoAcknowledge: boolean
  occupationId: number
  nationalityId: number
}

const initialState: State = {
  fullName: '',
  arrivalCity: undefined,
  ageRange: undefined,
  workerSalary: '',
  passportNumber: '',
  passportExpiry: '',
  phone: { value: '', location: undefined },
  visa: undefined,
  passportImage: undefined,
  acknowledge: false,
  occupationId: 0,
  nationalityId: 0,
  workerInfoAcknowledge: false,
}

export default (state: State = initialState, action: ReturnType<typeof setMaarofaData>) => {
  switch (action.type) {
    case Actions.SET_MAAROFA_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
