import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { GetEligibleLaborersResponse } from 'api/evisaAPI/evisaAPI'

type State = { eligibleLaborers: string[] } & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  eligibleLaborers: [],
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_ELIGIBLE_LABORERS_ASYNC,
    Actions.GET_ELIGIBLE_LABORERS_SUCCESS,
    Actions.GET_ELIGIBLE_LABORERS_FAIL,
    GetEligibleLaborersResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_ELIGIBLE_LABORERS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        eligibleLaborers: action.payload.laborers,
      }
    }
    case Actions.GET_ELIGIBLE_LABORERS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_ELIGIBLE_LABORERS_FAIL: {
      return {
        ...state,
        ...action.status,
        eligibleLaborers: [],
      }
    }
    default:
      return state
  }
}
