import { getInvoiceFileResponse } from 'api/newDLTransferAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  file: string
} & Status

const initialState: State = {
  file: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_INVOICE_FILE_ASYNC,
    Actions.GET_I_TO_I_INVOICE_FILE_SUCCESS,
    Actions.GET_I_TO_I_INVOICE_FILE_FAIL,
    getInvoiceFileResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_I_TO_I_INVOICE_FILE_SUCCESS:
      return {
        ...action.status,
        file: action.payload.data,
      }
    case Actions.GET_I_TO_I_INVOICE_FILE_ASYNC:
    case Actions.GET_I_TO_I_INVOICE_FILE_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
