import React, { FunctionComponent, Suspense, useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import KeepAliveModalLaborer from 'components/KeepAliveLaborerModal/KeepAliveLaborerModal'
import SuspensePreloader from 'ui-components/loading/SuspensePreloader/SuspensePreloader'
import { Routes } from 'containers/Routes/routePaths'
import { useTitle } from 'common/hooks/useTitle'
import KeepAliveModal from 'components/KeepAliveModal/KeepAliveModal'
import { useLanguageDetector } from 'common/hooks/useLanguageDetector'
import NewRelic from 'components/NewRelic/NewRelic'
import authManager from 'common/utils/auth/authManager'
import JWTToken from 'common/utils/auth/jwtToken'
import { RootState } from 'redux/rootReducer'
import { useVipRoute } from 'common/hooks/useVipRoute'

import i18n from '../i18n'

const AppLayout = React.lazy(() => import('./AppLayout/AppLayout'))
const VipLayout = React.lazy(() => import('./VipUser/VipLayout/VipLayout'))
const UnifyJourneyLayout = React.lazy(() => import('./UnifyJourneyLayout/UnifyJourneyLayout'))

let interval: NodeJS.Timeout
const App: FunctionComponent = () => {
  useLanguageDetector(i18n)
  useTitle()
  const { pathname, search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const rmToken = urlParams.get('token') || ''
  const auth = useSelector<RootState, RootState['auth']>((state) => state.auth)
  const { isVip: isVipRoute, getRoute } = useVipRoute()
  const token = new JWTToken('rm', rmToken)
  const isVip = token.getUserType()
  const history = useHistory()

  useEffect(() => {
    if (auth.loggedIn) token.removeToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loggedIn])

  useEffect(() => {
    const path = pathname + search
    if (!isVipRoute && authManager.isVipUser() && path.includes('/app/')) history.replace(getRoute(path as Routes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])

  useEffect(() => {
    interval = setInterval(() => {
      const labibaDiv = document.getElementById('labibaWebBotDiv')
      console.warn(new Date().toLocaleDateString())
      if (labibaDiv) {
        labibaDiv.style.display = isVipRoute ? 'none' : 'block'
        clearInterval(interval)
      }
    }, 100)
  }, [isVipRoute])

  return (
    <Suspense fallback={<SuspensePreloader wholeView />}>
      <Switch>
        {authManager.isVipUser() || isVip ? (
          <Route path={Routes.VIP}>
            <VipLayout />
          </Route>
        ) : (
          <Route path={Routes.APP}>
            <AppLayout />
          </Route>
        )}
        <Route path='/'>
          <UnifyJourneyLayout />
        </Route>
      </Switch>
      <KeepAliveModal />
      <KeepAliveModalLaborer />
      <NewRelic />
    </Suspense>
  )
}

export default App
