import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  checkFarmerTransferEligibility as checkFarmerTransferEligibilityAction,
  checkFarmerTransferability as checkFarmerTransferabilityAction,
  getFarmerTransferRequestsList as getFarmerTransferRequestsListAction,
  cancelFarmerTransferRequest as cancelFarmerTransferRequestAction,
  acceptFarmerTransferRequest as acceptFarmerTransferRequestAction,
  rejectFarmerTransferRequest as rejectFarmerTransferRequestAction,
  issueNewFarmerTransfer as issueNewFarmerTransferAction,
  payForFarmerTransfer as payForFarmerTransferAction,
} from 'redux/actionCreators/evisaAPI/farmerTransfer'
import farmerTransfer from 'api/evisaAPI/farmerTransfer'
import { getSimpleRequestHandler } from 'saga/utils'

const checkFarmerTransferEligibility = getSimpleRequestHandler<
  typeof farmerTransfer.checkFarmerTransferEligibility,
  ReturnType<typeof checkFarmerTransferEligibilityAction>
>({
  actionLoading: Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_ASYNC,
  actionSuccess: Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_SUCCESS,
  actionFail: Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY_FAIL,
  callApiFn: farmerTransfer.checkFarmerTransferEligibility,
})

const checkFarmerTransferability = getSimpleRequestHandler<
  typeof farmerTransfer.checkFarmerTransferability,
  ReturnType<typeof checkFarmerTransferabilityAction>
>({
  actionLoading: Actions.CHECK_FARMER_TRANSFERABILITY_ASYNC,
  actionSuccess: Actions.CHECK_FARMER_TRANSFERABILITY_SUCCESS,
  actionFail: Actions.CHECK_FARMER_TRANSFERABILITY_FAIL,
  callApiFn: farmerTransfer.checkFarmerTransferability,
})

const getFarmerTransferRequestsList = getSimpleRequestHandler<
  typeof farmerTransfer.getFarmerTransferRequestsList,
  ReturnType<typeof getFarmerTransferRequestsListAction>
>({
  actionLoading: Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_ASYNC,
  actionSuccess: Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_SUCCESS,
  actionFail: Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_FAIL,
  callApiFn: farmerTransfer.getFarmerTransferRequestsList,
})

const cancelFarmerTransferRequest = getSimpleRequestHandler<
  typeof farmerTransfer.cancelFarmerTransferRequest,
  ReturnType<typeof cancelFarmerTransferRequestAction>
>({
  actionLoading: Actions.CANCEL_FARMER_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.CANCEL_FARMER_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.CANCEL_FARMER_TRANSFER_REQUEST_FAIL,
  callApiFn: farmerTransfer.cancelFarmerTransferRequest,
})

const acceptFarmerTransferRequest = getSimpleRequestHandler<
  typeof farmerTransfer.acceptFarmerTransferRequest,
  ReturnType<typeof acceptFarmerTransferRequestAction>
>({
  actionLoading: Actions.ACCEPT_FARMER_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.ACCEPT_FARMER_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.ACCEPT_FARMER_TRANSFER_REQUEST_FAIL,
  callApiFn: farmerTransfer.acceptFarmerTransferRequest,
})

const rejectFarmerTransferRequest = getSimpleRequestHandler<
  typeof farmerTransfer.rejectFarmerTransferRequest,
  ReturnType<typeof rejectFarmerTransferRequestAction>
>({
  actionLoading: Actions.REJECT_FARMER_TRANSFER_REQUEST_ASYNC,
  actionSuccess: Actions.REJECT_FARMER_TRANSFER_REQUEST_SUCCESS,
  actionFail: Actions.REJECT_FARMER_TRANSFER_REQUEST_FAIL,
  callApiFn: farmerTransfer.rejectFarmerTransferRequest,
})

const issueNewFarmerTransferRequest = getSimpleRequestHandler<
  typeof farmerTransfer.issueNewFarmerTransferRequest,
  ReturnType<typeof issueNewFarmerTransferAction>
>({
  actionLoading: Actions.ISSUE_NEW_FARMER_TRANSFER_ASYNC,
  actionSuccess: Actions.ISSUE_NEW_FARMER_TRANSFER_SUCCESS,
  actionFail: Actions.ISSUE_NEW_FARMER_TRANSFER_FAIL,
  callApiFn: farmerTransfer.issueNewFarmerTransferRequest,
})

const payForFarmerTransfer = getSimpleRequestHandler<
  typeof farmerTransfer.payForFarmerTransfer,
  ReturnType<typeof payForFarmerTransferAction>
>({
  actionLoading: Actions.PAY_FOR_FARMER_TRANSFER_ASYNC,
  actionSuccess: Actions.PAY_FOR_FARMER_TRANSFER_SUCCESS,
  actionFail: Actions.PAY_FOR_FARMER_TRANSFER_FAIL,
  callApiFn: farmerTransfer.payForFarmerTransfer,
})

export default [
  takeLatest(Actions.CHECK_FARMER_TRANSFER_ELIGIBILITY, checkFarmerTransferEligibility),
  takeLatest(Actions.CHECK_FARMER_TRANSFERABILITY, checkFarmerTransferability),
  takeLatest(Actions.GET_FARMER_TRANSFER_REQUESTS_LIST, getFarmerTransferRequestsList),
  takeLatest(Actions.CANCEL_FARMER_TRANSFER_REQUEST, cancelFarmerTransferRequest),
  takeLatest(Actions.ACCEPT_FARMER_TRANSFER_REQUEST, acceptFarmerTransferRequest),
  takeLatest(Actions.REJECT_FARMER_TRANSFER_REQUEST, rejectFarmerTransferRequest),
  takeLatest(Actions.ISSUE_NEW_FARMER_TRANSFER, issueNewFarmerTransferRequest),
  takeLatest(Actions.PAY_FOR_FARMER_TRANSFER, payForFarmerTransfer),
]
