import { CallAPIAction, Status } from 'api/types'
import type {
  SubmitVisaWithContractRequestResponse,
  SubmitVisaWithContractRequestFailResponse,
} from 'api/evisaAPI/visas'
import { SubmitVisaWithContractRequestError } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage: SubmitVisaWithContractRequestError | string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_ASYNC,
    Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_SUCCESS,
    Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_FAIL,
    SubmitVisaWithContractRequestResponse,
    SubmitVisaWithContractRequestFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_ASYNC:
    case Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST_FAIL: {
      const { payload } = action
      return {
        ...state,
        errorMessage:
          payload?.error?.message ||
          (payload?.worker_passport || payload?.worker_passport_image || [])[0] ||
          SubmitVisaWithContractRequestError.ERROR_GENERAL,
        ...action.status,
      }
    }
    default:
      return state
  }
}
