import { GetIToIRequestsListResponse } from 'api/newDLTransferAPI'
import { CallAPIAction, Status } from 'api/types'
import { convertIToIRequestFromResponse, IToIRequest } from 'common/utils/convertResponse/convertIToIResponse'
import { getIToIRequestsList } from 'redux/actionCreators/newDLTransferAPI'
import Actions from 'redux/actions'

type State = {
  iToIRequestsParams: {
    page: number
    status: string
  }
  requests: Array<IToIRequest>
  lastPage: number
} & Status

const initialState: State = {
  iToIRequestsParams: {
    page: 0,
    status: '',
  },
  requests: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_REQUESTS_LIST_ASYNC,
    Actions.GET_I_TO_I_REQUESTS_LIST_SUCCESS,
    Actions.GET_I_TO_I_REQUESTS_LIST_FAIL,
    GetIToIRequestsListResponse,
    {},
    {},
    ReturnType<typeof getIToIRequestsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_I_REQUESTS_LIST_ASYNC: {
      const {
        params: { status = state.iToIRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        iToIRequestsParams: {
          page,
          status,
        },
        lastPage: status !== state.iToIRequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_I_TO_I_REQUESTS_LIST_SUCCESS: {
      const {
        params: { status = state.iToIRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        iToIRequestsParams: {
          page,
          status,
        },
        requests: action.payload.data.map(convertIToIRequestFromResponse),
        lastPage: action.payload.last_page,
      }
    }
    case Actions.GET_I_TO_I_REQUESTS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
