import { AxiosRequestConfig } from 'axios'

import { callAPI, surveyAPI } from './axios'

export type ProStatisticBE = {
  pro_id: string
  arrival_duration_rating: number | null
  arrival_duration_ratings_count: number | null
  recommendability_rating: number | null
  recommendability_ratings_count: number | null
  responsiveness_rating: number | null
  responsiveness_ratings_count: number | null
  conformity_rating: number | null
  conformity_ratings_count: number | null
}

export type GetProStatisticResponse = Array<ProStatisticBE>

const getProsStatistic = (config?: AxiosRequestConfig) =>
  surveyAPI.get<GetProStatisticResponse>('/stats/pro_ratings_v2', config)

export default {
  getProsStatistic: callAPI<typeof getProsStatistic, GetProStatisticResponse>(getProsStatistic),
}
