import { Status, CallAPIAction } from 'api/types'
import { GetMaarofaServiceTemplateResponse } from 'api/prosAPI'
import Actions from 'redux/actions'

type Graph = Record<
  string,
  Record<
    string,
    {
      price: number
      recruitmentPeriod: number
    }
  >
>

type State = {
  graph?: Graph
} & Status

const convertResponseToState = ({ data }: GetMaarofaServiceTemplateResponse) => {
  const graph: Graph = {}
  data.forEach((item) => {
    if (!graph[item.occupation_id]) {
      graph[item.occupation_id] = {}
    }
    graph[item.occupation_id] = {
      [item.nationality_id]: {
        price: item.price,
        recruitmentPeriod: item.recruitment_period,
      },
      ...graph[item.occupation_id],
    }
  })
  return graph
}

const initialState = {
  graph: undefined,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_MAAROFA_SERVICE_TEMPLATE_ASYNC,
    Actions.GET_MAAROFA_SERVICE_TEMPLATE_SUCCESS,
    Actions.GET_MAAROFA_SERVICE_TEMPLATE_FAIL,
    GetMaarofaServiceTemplateResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_MAAROFA_SERVICE_TEMPLATE_ASYNC:
    case Actions.GET_MAAROFA_SERVICE_TEMPLATE_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_MAAROFA_SERVICE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        graph: convertResponseToState(action.payload),
        ...action.status,
      }
    }
    default:
      return state
  }
}
