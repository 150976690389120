import { MakeRefundSADADResponse } from 'api/evisaAPI/evisaAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type Response = {
  showNoticeBalance: boolean
  showNoticeBalanceAndRefundRequest: boolean
}

type State = {
  response?: Response
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.MAKE_REFUND_SADAD_ASYNC,
    Actions.MAKE_REFUND_SADAD_SUCCESS,
    Actions.MAKE_REFUND_SADAD_FAIL,
    MakeRefundSADADResponse
  >,
): State => {
  switch (action.type) {
    case Actions.MAKE_REFUND_SADAD_ASYNC:
    case Actions.MAKE_REFUND_SADAD_FAIL:
      return {
        ...state,
        ...action.status,
      }
    case Actions.MAKE_REFUND_SADAD_SUCCESS:
      return {
        ...action.status,
        response: {
          showNoticeBalance: action.payload.show_notice_balance,
          showNoticeBalanceAndRefundRequest: action.payload.show_notice_balance_and_refund_request,
        },
      }
    default:
      return state
  }
}
