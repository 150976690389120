import { GetNoticesListResponse, NoticeFromBE } from 'api/evisaAPI/noticesAPI'
import { CallAPIAction, Status } from 'api/types'
import { NoticeStatus } from 'common/types/commonTypes'
import { Nationality, NoticeBill, Occupation } from 'common/types/transformedRespTypes'
import { getNoticesList } from 'redux/actionCreators/evisaAPI/noticesAPI'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

import { formatNoticeBillFromBE } from './notice'

export type Notice = {
  id: number
  hash: string
  createdAt: string
  occupation: Occupation
  nationality: Nationality
  status: NoticeStatus
  bill?: NoticeBill
}

type State = {
  notices: Array<Notice>
  lastPage: number
} & Status

const initialState: State = {
  notices: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const mapStateFromBE = (noticeFromBE: NoticeFromBE): Notice => ({
  id: noticeFromBE.id,
  createdAt: noticeFromBE.created_at,
  status: noticeFromBE.status,
  hash: noticeFromBE.hash,
  nationality: formatNationalityFromBE(noticeFromBE.employee.nationality),
  occupation: formatOccupationsFromBE(noticeFromBE.occupation),
  bill: formatNoticeBillFromBE(noticeFromBE.bill),
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_NOTICES_LIST_ASYNC,
    Actions.GET_NOTICES_LIST_SUCCESS,
    Actions.GET_NOTICES_LIST_FAIL,
    GetNoticesListResponse,
    {},
    {},
    ReturnType<typeof getNoticesList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_NOTICES_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        notices: action.payload.paginator.data.map(mapStateFromBE),
        lastPage: action.payload.paginator.last_page,
      }
    case Actions.GET_NOTICES_LIST_ASYNC: {
      const { clearPageCount } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        lastPage: clearPageCount ? 0 : state.lastPage,
      }
    }
    case Actions.GET_NOTICES_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
