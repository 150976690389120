import { CallAPIAction, Status } from 'api/types'
import { UpdateManagerAuthStatusResponse } from 'api/premiumUserAPI/relationshipManagerAPI'
import Actions from 'redux/actions'

type State = {} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.UPDATE_MANAGER_AUTH_STATUS_ASYNC,
    Actions.UPDATE_MANAGER_AUTH_STATUS_SUCCESS,
    Actions.UPDATE_MANAGER_AUTH_STATUS_FAIL,
    UpdateManagerAuthStatusResponse
  >,
): State => {
  switch (action.type) {
    case Actions.UPDATE_MANAGER_AUTH_STATUS_ASYNC:
    case Actions.UPDATE_MANAGER_AUTH_STATUS_FAIL:
    case Actions.UPDATE_MANAGER_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        ...action.status,
      }

    default:
      return state
  }
}
