import { GetAuthAppendixDetailsResponse } from 'api/laborerPortalAPI/authContract'
import { CallAPIAction, Status } from 'api/types'
import { GeneralFailResponse } from 'common/types/commonTypes'
import {
  LaborerAuthAppendix,
  convertAuthAppendixFromResponse,
  getEmptyLaborerAuthAppendix,
} from 'common/utils/convertResponse/convertLaborerAuthResponse'
import Actions from 'redux/actions'

type State = {
  appendix: LaborerAuthAppendix
} & Status

const initialState: State = {
  appendix: getEmptyLaborerAuthAppendix(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_ASYNC,
    Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_SUCCESS,
    Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_FAIL,
    GetAuthAppendixDetailsResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        appendix: convertAuthAppendixFromResponse(action.payload.data),
      }
    }
    case Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_FAIL:
    case Actions.GET_LABORER_AUTH_APPENDIX_DETAILS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
