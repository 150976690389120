import moment, { Moment } from 'moment'

import {
  END_COVID_POLICY_DATE,
  END_PHILIPPINES_CANCEL_EXEMPTION,
  MAX_FILES_FOR_ATTACHMENTS,
  MIN_SALARY,
  PHILIPPINES_ID,
  KENYA_ID,
  START_COVID_POLICY_DATE,
  START_PHILIPPINES_CANCEL_EXEMPTION,
  NEW_GENERAL_PROVISIONS_DATE,
  ADDONS_INVOICE_START_DATE,
  ZATCA_CONTRACT_INVOICE_START_DATE,
} from 'common/constants'
import {
  Labor,
  ValidationErrors,
  ValueLabelOption,
  NationalIdTypes,
  StringRecord,
  ValuesFromRecord,
  RelativeRelationType,
  ResidencyDescriptionType,
  AuthContractLaborerStatus,
  ContractAuthAppendixStatus,
  InputState,
  ContractAuthRequestStatus,
} from 'common/types/commonTypes'
import { useBETranslationLabel } from 'common/hooks/useBETranslationLabel'
import { NEW_BILL_ISSUANCE_START_DATE, NEW_MAAROFA_FLOW_START_DATE } from 'common/envConstants'
import { RecentlyUploadedVisaFileData } from 'redux/reducers/downloadHistory'
import { FileStatus } from 'ui-components/inputs/DropArea/types'
import { GetEmployerLaborersResponse } from 'api/contractAuthAPI'
import { ServiceAddons } from 'common/types/transformedRespTypes'

import { ContractAuthDetailedRequest, ContractAuthRequest } from './convertResponse/convertAuthApiResponse'
import { NationalAddressType } from './auth/authManager'
import { checkValidity } from './validation/validators'

interface ApiDataState {
  isLoading: boolean
  success: boolean
  fail: boolean
}

export const isFirstLoad = (state: ApiDataState) => !state.success && !state.fail && !state.isLoading

export function toValueLabelOption<T>(
  valueObj: any = {},
  valueObjKey: string,
  label: ReturnType<typeof useBETranslationLabel>,
): ValueLabelOption<T> {
  return {
    value: valueObj[valueObjKey],
    labelKey: label(valueObj),
  }
}

export const base64ToBlob = (fileStr: string, type = 'application/octet-stream') => {
  const binStr = window.atob(fileStr)
  const len = binStr.length
  const arr = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i)
  }
  return new Blob([arr], { type: type })
}

export const isDateWithinCovidDisturbancePeriod = (value?: string | Date | Moment) => {
  if (!value) {
    return false
  }
  const date = moment.isMoment(value) ? value : moment(value)
  return date.isBetween(START_COVID_POLICY_DATE, END_COVID_POLICY_DATE, 'days', '[]')
}

export const isPhilippineExemptionPeriod = (paidDate?: string | Moment, nationalityId?: number) => {
  if (!paidDate || !nationalityId) return false
  const date = moment.isMoment(paidDate) ? paidDate : moment(paidDate)
  return (
    nationalityId === PHILIPPINES_ID &&
    date.startOf('day').isBetween(START_PHILIPPINES_CANCEL_EXEMPTION, END_PHILIPPINES_CANCEL_EXEMPTION, 'days', '[]')
  )
}

export const isPhilippineKenyaExemptionPeriod = (nationalityId?: number) => {
  if (!nationalityId) return false
  return nationalityId === PHILIPPINES_ID || nationalityId === KENYA_ID
}

export const formatDateForUI = (value?: string | Date | Moment | null, separator = '-') => {
  if (!value) {
    return '-'
  }

  const momentVal = moment(value)

  if (!momentVal.isValid()) {
    return '-'
  }

  return momentVal.format(`DD${separator}MM${separator}YYYY`)
}

export const formatDateTimeForUI = (value: string | Date | null | undefined) => {
  if (!value) {
    return '-'
  }
  return moment(value).format('DD/MM/YYYY - hh:mm')
}

export const formatDateTimeForUIDashFormat = (value: string | Date = '') => {
  const momentValue = moment(value)
  if (momentValue.isValid()) {
    return momentValue.format('DD-MM-YYYY - hh:mm')
  }
  return ''
}

export const formatTimeForUI = (value: string | Date = '') => {
  const momentValue = moment(value)
  if (momentValue.isValid()) {
    return momentValue.format('hh:mm')
  }
  return ''
}

export const convertRating = (rating: string) => Math.round(parseFloat(rating) * 10) / 10

export const maskValue = (value: string, limit: number, separator: string) => {
  const output = []
  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator)
    }

    output.push(value[i])
  }

  return output.join('')
}

export const unmaskValue = (value: string) => value.replace(/[^\d]/g, '')

export const formatValidationMessages = (validationErrors: ValidationErrors['errors'] = {}) => {
  const errorsDetails = Object.values(validationErrors).flat()
  return errorsDetails.length
    ? errorsDetails.reduce((previousValue, currentValue) => `${previousValue}\n${currentValue}`)
    : ''
}

export const getNationalIdType = (id?: string) => {
  switch (id?.charAt(0)) {
    case '1':
      return NationalIdTypes.NATIONAL_ID
    case '2':
      return NationalIdTypes.IQAMA_ID
    default:
      return NationalIdTypes.UNKNOWN
  }
}

export const isLaborSalaryValid = (labor: Labor) => labor.contractNo || labor.payrollAmt >= MIN_SALARY

export const isNewMaarofaFlow = (date?: string) => moment(date).isAfter(moment(NEW_MAAROFA_FLOW_START_DATE))

export const maskTranslationKey = (tKey?: string | null) => tKey?.replace(/\./g, '_') || ''

export const getCurrentIslamicYear = () =>
  parseInt(
    // Intl return year as islamic year AH | '1441 AH' and then we convert it to number
    new Intl.DateTimeFormat('en-US-u-ca-islamic', { year: 'numeric' }).format(Date.now()).split(' ').shift() as string,
    10,
  )

export const isNewBilLAvailable = (createdAt = '') => moment(createdAt).isAfter(moment(NEW_BILL_ISSUANCE_START_DATE))

const getTLVForValue = (tagNum: number, tagValue: string) => {
  const len = new Blob([tagValue]).size
  return Buffer.concat([Buffer.from([tagNum]), Buffer.from([len]), Buffer.from(tagValue, 'utf8')])
}

export const generateQRCode = (qrCodeFields: Array<string | undefined>) => {
  return Buffer.concat(qrCodeFields.map((field, index) => getTLVForValue(index + 1, field || ''))).toString('base64')
}

const currentYear = () => new Date().getFullYear()

export const getCurrentYear = () => {
  return document.dir === 'rtl' ? currentYear().toLocaleString('ar-Eg').replace(/٬/, '') : currentYear()
}

export const getFullURL = (route: string) => `${window.location.origin}${route}`

export const limitFileAttachments = (
  previouslyUploadedFiles: StringRecord<RecentlyUploadedVisaFileData>,
  currentlyUploadedFiles: StringRecord<FileStatus>,
  fileCounts: number = MAX_FILES_FOR_ATTACHMENTS,
) =>
  (Object.values(previouslyUploadedFiles) as ValuesFromRecord<typeof previouslyUploadedFiles>).filter(
    ({ isSelected }) => isSelected,
  ).length +
    Object.values(currentlyUploadedFiles).length >
  fileCounts

export const checkIsOtherRelativeRelation = (relativeRelation: string) =>
  ([RelativeRelationType.OTHER] as Array<string>).includes(relativeRelation)

export const checkIsOtherResidencyDesc = (residencyDescription: string) =>
  ([ResidencyDescriptionType.OTHER] as Array<string>).includes(residencyDescription)

export const checkIsSpecialResidencyDesc = (residencyDescription: string) =>
  (
    [
      ResidencyDescriptionType.SEPARATE_ROOM_WITH_SHARED_BATHROOM,
      ResidencyDescriptionType.SHARED_ROOM_WITH_SEPARATE_TOILET,
      ResidencyDescriptionType.SHARED_ROOM_WITH_SHARED_BATHROOM,
    ] as Array<string>
  ).includes(residencyDescription)

export function getUpdatedValuesObject<T extends Record<string, any>>(
  original: T,
  updated: Record<string, any>,
): Partial<Record<string, any>> {
  return Object.entries(updated).reduce((result, [key, value]) => {
    if (JSON.stringify(original[key]) !== JSON.stringify(value)) {
      result[key] = value
    }
    return result
  }, {} as Partial<Record<string, any>>)
}

export const checkLaborers = (laborers: GetEmployerLaborersResponse): boolean => {
  if (laborers.length) {
    const filteredLaborers = laborers.filter(
      (laborer) =>
        laborer.authenticationStatus === AuthContractLaborerStatus.UNAUTHENTICATED &&
        laborer.nationality &&
        !!Number(laborer.nationality.is_contract_authentication_allowed) &&
        laborer.finalExitVisaIssued === 'false' &&
        !laborer.uncontractableReason,
    )
    return filteredLaborers.length > 0
  }
  return false
}

export const checkIsContractNotEditedRecently = (request: ContractAuthRequest) =>
  (request.lastAppendixAt && moment(new Date()).diff(moment(request.lastAppendixAt), 'months') >= 3) ||
  !request.lastAppendixAt

export const getPrimaryAddress = (nationalAddress: Array<NationalAddressType>) =>
  nationalAddress.find((item) => !!parseInt(item.isPrimary))

export const checkIsContractEditable = (request: ContractAuthRequest) =>
  request.status === ContractAuthRequestStatus.VALID &&
  (!request.mobileNumberChangeNotice ||
    (request.mobileNumberChangeNotice &&
      request.mobileNumberChangeNotice.status !== ContractAuthAppendixStatus.PENDING))

export const allowEnterOnlyDigits = (value: string) => value.replace(/[^0-9]/g, '')

export const formatMobileNumber = (mobileNumber?: string | number) => {
  const validation = /^05[0-9]{8}|9665[0-9]{8}|\+9665[0-9]{8}$/

  if (!mobileNumber || !validation.test(mobileNumber.toString())) {
    return mobileNumber
  }

  const value = mobileNumber.toString()

  switch (value.length) {
    case 10:
      return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`
    case 12:
      return `${value.slice(0, 3)} ${value.slice(3, 5)} ${value.slice(5, 8)} ${value.slice(8)}`
    case 13:
      return `${value.slice(0, 4)} ${value.slice(4, 6)} ${value.slice(6, 9)} ${value.slice(9)}`
    default:
      return mobileNumber
  }
}

export const onBlurRequiredInput = (value: string, setValue: (value: InputState) => void) => {
  if (!value) {
    const validation = checkValidity(value, {
      required: true,
    })

    setValue({ value, validation })
  }
}

export const isNewGeneralProvisions = (value?: string | Moment) => {
  if (!value) {
    return false
  }
  const date = moment.isMoment(value) ? value : moment(value)
  return date.isAfter(NEW_GENERAL_PROVISIONS_DATE)
}

export const showContractQRCode = (request?: ContractAuthDetailedRequest) =>
  request &&
  (
    [
      ContractAuthRequestStatus.VALID,
      ContractAuthRequestStatus.CONTRACT_EXPIRED,
      ContractAuthRequestStatus.CANCELED,
    ] as Array<string>
  ).includes(request.status) &&
  !!request.uuid

export const checkIsContractCancelable = (request: ContractAuthRequest) =>
  request.status === ContractAuthRequestStatus.VALID &&
  (!request.lastAppendixStatus ||
    (request.lastAppendixStatus && request.lastAppendixStatus !== ContractAuthAppendixStatus.PENDING))

export const checkIsEditingRequestCancelable = (request: ContractAuthRequest) =>
  request.status === ContractAuthRequestStatus.VALID &&
  request.lastAppendixStatus &&
  request.lastAppendixStatus === ContractAuthAppendixStatus.PENDING

export const calculateTotalAddonsFee = (serviceAddons: Array<ServiceAddons>, vat: number): number => {
  let totalAddonsFee = 0

  serviceAddons &&
    serviceAddons.forEach((service) => {
      if (service.taxable === 1) {
        totalAddonsFee += service.pivot.cost * vat + service.pivot.cost
      } else if (service.taxable === 0) {
        totalAddonsFee += service.pivot.cost
      }
    })

  return totalAddonsFee
}

export const calculateTotalAddonsFeeWV = (serviceAddons: Array<ServiceAddons>): number => {
  let totalAddonsFee = 0

  serviceAddons &&
    serviceAddons.forEach((service) => {
      if (service.taxable === 1) {
        totalAddonsFee += service.pivot.cost
      }
    })

  return totalAddonsFee
}

export const calculateTotalTaxableFee = (serviceAddons: Array<ServiceAddons>): number[] => {
  let totalAddonsFee = 0
  let totalAddonsFeeQuantity = 0

  serviceAddons &&
    serviceAddons.forEach((service) => {
      if (service.taxable) {
        totalAddonsFee += service.pivot.cost
        totalAddonsFeeQuantity++
      }
    })

  return [totalAddonsFee, totalAddonsFeeQuantity]
}

export const calculateTotalNonTaxableFee = (serviceAddons: Array<ServiceAddons>): number[] => {
  let totalAddonsFee = 0
  let totalAddonsFeeQuantity = 0

  serviceAddons &&
    serviceAddons.forEach((service) => {
      if (!service.taxable) {
        totalAddonsFee += service.pivot.cost
        totalAddonsFeeQuantity++
      }
    })

  return [totalAddonsFee, totalAddonsFeeQuantity]
}

export const isNewElectronicInvoiceAvailable = (paidDate = '') =>
  moment(paidDate).isAfter(moment(ADDONS_INVOICE_START_DATE))

export const isNewZatcacInvoiceAvailable = (paidDate = '') =>
  moment(paidDate).isAfter(moment(ZATCA_CONTRACT_INVOICE_START_DATE))

export const formatPrice = (price: number | string) =>
  (typeof price === 'number' ? price.toString() : price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
