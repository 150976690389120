import type { IssueNewDisabilityVisaFailResponse, IssueNewDisabilityVisaResponse } from 'api/evisaAPI/visas'
import { CallAPIAction, Status } from 'api/types'
import { DisabilityVisaEligibilityErrorMessages } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  errorMessage: DisabilityVisaEligibilityErrorMessages | string
  requestId: string
} & Status

const initialState = {
  requestId: '',
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ISSUE_NEW_DISABILITY_VISA_ASYNC,
    Actions.ISSUE_NEW_DISABILITY_VISA_SUCCESS,
    Actions.ISSUE_NEW_DISABILITY_VISA_FAIL,
    IssueNewDisabilityVisaResponse,
    IssueNewDisabilityVisaFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ISSUE_NEW_DISABILITY_VISA_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.ISSUE_NEW_DISABILITY_VISA_SUCCESS:
      return {
        ...state,
        ...action.status,
        requestId: action.payload.request.id.toString(),
      }
    case Actions.ISSUE_NEW_DISABILITY_VISA_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
