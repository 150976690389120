import { IToIBill } from 'api/newDLTransferAPI'
import {
  IToIContractType,
  IToIRequestFromBE,
  Nationality,
  ObjectWithTranslation,
} from 'common/types/transformedRespTypes'
import { BillStatus } from 'common/types/commonTypes'

import { formatObjectWithTranslationFromBE } from '../objectWithTranslation'

export type IToIRequest = {
  id: number
  createdAt: string
  source?: 'EMPLOYER' | 'HRSD'
  bill: {
    operationalFees: number
    servicePrice: number
    serviceTotal: number
    vat: number
  }
  currentEmployer: {
    id: number
    idNumber: number
    date: string
    mobileNumber: string
    name: string
  }
  newEmployer: {
    id: number
    idNumber: number
    date: string
    mobileNumber: string
    name: string
  }
  laborer: {
    iqama: number
    name: string
    mobile: string
    occupation: ObjectWithTranslation
    nationality?: Nationality
  }
  laborerSalary: number
  status: string
  contracts: Array<{
    id: number
    legalText: string
    legalTextEn: string
    startedAt: string | null
    type: IToIContractType
  }>
  isFinanciallyCapable: boolean
  shouldViewZatcaBill?: boolean
  financialDetail: {
    minSalary: number
    minBankBalance: number
  }
  rejectionReasons: Array<ObjectWithTranslation>
}

export const getEmptyLaborerIToIRequest = (): IToIRequest => ({
  id: 0,
  createdAt: '',
  bill: {
    operationalFees: 0,
    servicePrice: 0,
    serviceTotal: 0,
    vat: 0,
  },
  currentEmployer: {
    id: 0,
    idNumber: 0,
    date: '',
    mobileNumber: '',
    name: '',
  },
  newEmployer: {
    id: 0,
    idNumber: 0,
    date: '',
    mobileNumber: '',
    name: '',
  },
  laborer: {
    iqama: 0,
    name: '',
    mobile: '',
    occupation: {
      id: 0,
      label: '',
      labelEn: '',
    },
    nationality: {
      id: 0,
      label: '',
      labelEn: '',
      isOnlineContractingAllowed: false,
      code: '',
      abbreviation: '',
    },
  },
  laborerSalary: 0,
  status: '',
  contracts: [],
  isFinanciallyCapable: false,
  financialDetail: {
    minSalary: 0,
    minBankBalance: 0,
  },
  rejectionReasons: [],
  source: undefined,
})

export const convertIToIRequestFromResponse = (request: IToIRequestFromBE): IToIRequest => ({
  id: request.id,
  createdAt: request.created_at,
  source: request.source,
  bill: {
    operationalFees: request.bill?.breakdown.operational_fees_without_vat || 0,
    servicePrice: request.bill?.breakdown.service_price || 0,
    serviceTotal: request.bill?.breakdown.service_total || 0,
    vat: request.bill?.breakdown.vat || 0,
  },
  currentEmployer: {
    id: request.current_employer.id,
    idNumber: request.current_employer.id_number,
    date: request.current_employer.date,
    mobileNumber: request.current_employer.mobile_number,
    name: request.current_employer.name || '-',
  },
  newEmployer: {
    id: request.new_employer.id,
    idNumber: request.new_employer.id_number,
    date: request.new_employer.date,
    mobileNumber: request.new_employer.mobile_number,
    name: request.new_employer.name || '-',
  },
  laborer: {
    iqama: request.laborer.id_number,
    name: request.laborer.name,
    mobile: request.laborer.mobile_number,
    occupation: {
      id: request.laborer.occupation.id || 0,
      label: request.laborer.occupation.label,
      labelEn: request.laborer.occupation.label_en,
    },
    nationality: {
      id: request.laborer.nationality?.id || 0,
      label: request.laborer.nationality?.label || '',
      labelEn: request.laborer.nationality?.label_en || '',
      abbreviation: request.laborer.nationality?.abbreviation || '',
      isOnlineContractingAllowed: !!request.laborer.nationality?.is_online_contracting_allowed || false,
      code: request.laborer.nationality?.code || '',
    },
  },
  laborerSalary: request.laborer_salary,
  status: request.status,
  contracts: request.contracts
    ? request.contracts.map((contract) => ({
        id: contract.id,
        legalText: contract.legal_text,
        legalTextEn: contract.legal_text_en,
        startedAt: contract.started_at,
        type: contract.type,
      }))
    : [],
  isFinanciallyCapable: request.is_financially_capable,
  shouldViewZatcaBill: request.should_view_zatca_bill,
  financialDetail: {
    minSalary: request.financial_details ? request.financial_details.min_salary : 0,
    minBankBalance: request.financial_details ? request.financial_details.min_bank_balance : 0,
  },
  rejectionReasons: request.rejection_reasons?.map(formatObjectWithTranslationFromBE) || [],
})

export type Bill = {
  billNumber: number
  billIssueDate: string
  contractRequestNumber: number
  status: BillStatus
  paidAt: string
  amount: number
  totalAmount: number
  currentEmployer: {
    name: string
    idNumber: number
  }
  newEmployer: {
    name: string
    idNumber: number
  }
  breakdown: {
    servicePrice: number
    bankFees: number
    operationalFees: number
    vat: number
  }
}
export const getEmptyLaborerIToIBill = (): Bill => ({
  billNumber: 0,
  billIssueDate: '',
  contractRequestNumber: 0,
  status: 0,
  paidAt: '',
  amount: 0,
  totalAmount: 0,
  currentEmployer: {
    name: '',
    idNumber: 0,
  },
  newEmployer: {
    name: '',
    idNumber: 0,
  },
  breakdown: {
    servicePrice: 0,
    bankFees: 0,
    operationalFees: 0,
    vat: 0,
  },
})
export const convertIToIBillFromResponse = (bill: IToIBill): Bill => ({
  billNumber: bill.bill_number,
  billIssueDate: bill.bill_issue_date,
  contractRequestNumber: bill.contract_request_number,
  status: bill.status,
  paidAt: bill.paid_at,
  amount: bill.amount,
  totalAmount: bill.total_amount,
  currentEmployer: {
    name: bill.current_employer.name,
    idNumber: bill.current_employer.id_number,
  },
  newEmployer: {
    name: bill.new_employer.name,
    idNumber: bill.new_employer.id_number,
  },
  breakdown: {
    servicePrice: bill.breakdown.service_price,
    bankFees: bill.breakdown.bank_fees_without_vat,
    operationalFees: bill.breakdown.operational_fees_without_vat,
    vat: bill.breakdown.vat,
  },
})
