import type { CallAPIAction, Status } from 'api/types'
import { GetProServiceAddonsResponse } from 'api/prosAPI'
import Actions from 'redux/actions'
import { ServiceAddons } from 'common/types/transformedRespTypes'

type State = {
  serviceAddons: Record<string, Array<ServiceAddons> | null>
} & Status

export const convertServiceAddonsResponse = (resp: GetProServiceAddonsResponse) => {
  const serviceAddonsRecord: Record<string, ServiceAddons[] | null> = {}

  if (resp.service_addons) {
    serviceAddonsRecord[resp.pro_id] = resp.service_addons.map((service) => ({
      id: service.id,
      name: service.name,
      categoryMaxCount: service.category_max_count,
      categoryMinCount: service.category_min_count,
      description: service.description,
      taxable: service.taxable,
      pivot: {
        cost: service.pivot.cost,
        note: service.pivot.note,
        proServiceId: service.pivot.pro_service_id,
        recruitedAddonCategoryId: service.pivot.recruited_addon_category_id,
      },
    }))
  } else {
    serviceAddonsRecord[resp.pro_id] = null
  }

  return serviceAddonsRecord
}

const initialState: State = {
  serviceAddons: { 0: [] },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_SERVICE_ADDONS_ALL_ASYNC,
    Actions.GET_PRO_SERVICE_ADDONS_ALL_SUCCESS,
    Actions.GET_PRO_SERVICE_ADDONS_ALL_FAIL,
    Array<GetProServiceAddonsResponse>
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_SERVICE_ADDONS_ALL_ASYNC:
    case Actions.GET_PRO_SERVICE_ADDONS_ALL_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_PRO_SERVICE_ADDONS_ALL_SUCCESS: {
      const serviceAddons = action.payload.reduce((acc, curr) => {
        return { ...acc, ...convertServiceAddonsResponse(curr) }
      }, {})

      return {
        ...state,
        ...action.status,
        serviceAddons,
      }
    }
    default:
      return state
  }
}
