import type { AxiosRequestConfig } from 'axios'

import type { EToIAppendixStatus, EToIReplacementStatus, EToIRequestStatus } from 'common/types/commonTypes'
import { callAPI, indToIndAPIWithCredentials, newDLTransferAPIWithCredentials } from 'api/axios'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import {
  NationalityFromBE,
  ObjectWithTranslationFromBE,
  OccupationFromBE,
  PayfortBill,
  IToIRequestFromBE,
} from 'common/types/transformedRespTypes'

import { FailResponse } from './evisaAPI/dlAPI'

export type GetEtoIRequestsParams = {
  perPage?: number
  page?: number
  status?: string | EToIRequestStatus
}

export type AppendixFormBE = {
  id_number: number
  id: number
  name: string
  new_occupation: OccupationFromBE
  nationality: NationalityFromBE
  status: EToIAppendixStatus
  started_at: string
  salary: number
  has_replacement_request: boolean
  legal_text: {
    id: number
    legal_text: string
    started_at: string | null
    type: string
  } | null
}

export type EtoIActionRequest = {
  id: number
  status: EToIReplacementStatus
}

export type GetEtoINewRequestResponse = {
  appendixes: Array<AppendixFormBE>
  is_financially_capable: boolean
  is_salary_excluded: number
  probation_days: number | null
  rejection_reasons?: Array<ObjectWithTranslationFromBE>
  bill: {
    id: number
    created_at: string
    paid_at: string | null
    paid_at_day: string | null
    paid_at_hijri: string | null
  } | null
  id: number
  started_at: string | null
  transferred_at: string | null
  will_end_at: string | null
  created_at: string
  status: string
  amount: number
  operational_cost: number
  service_cost: number
  vat: number
  laborer_salary: number
  laborer_salary_for_3_months: number
  laborer_id_number: number
  laborer_name: string
  laborer_new_occupation: OccupationFromBE
  laborer_nationality: NationalityFromBE
  laborer_status: EToIAppendixStatus
  laborer_started_at: string | null
  laborer_has_replacement_request: boolean
  laborer_birth_date: string
  laborer_passport_number: string
  replacement_requests: Array<EtoIActionRequest>
  cancellation_requests: Array<EtoIActionRequest>
  issued_cancellation_notice_at?: string
  is_legacy: boolean
  establishment: {
    hrsdId: string
    email: string
    crNumber: string
    licenceNumber: string
    vatNumber: string
    address: {
      country: string
      city: string
      district: string
      street: string
      postalCode: string
    }
    mobile: string
    city: {
      id: string
      name_ar: string
      name_en: string
    }
    logoUrl: string | null
    name: string
    laborOfficeId: string
    sequenceNumber: string
  }
  employer: {
    id_number: number
    nic: {
      birth_date: string
      nationality: string
      occupation: string
    }
    personal_details: {
      address: string
      family_size: string
      house_type_id: string
      children_under_twelve_count: string
    }
    secondary_mobile: string
    name: string
    mobile: string
    email: string
  }
  financial_details: {
    min_bank_balance: number
    min_salary: number
  } | null
  transfer_request: {
    rejection_reasons: Array<ObjectWithTranslationFromBE>
    status: string
    is_10_days_exceeded: boolean
  } | null
  elapsed_trial_period: number
  updated_at: string
  should_view_zatca_bill: boolean
  legal_text: {
    id: number
    legal_text: string
    started_at: string | null
    type: string
  } | null
}

export type GetEtoILegacyRequestResponse = {
  id: number
  laborer_iqama: string
  user: {
    id: number
    name: string
    name_en: string
  }
  current_sponsor: string
  created_at: string
  status: string
  rejectionReasons: Array<ObjectWithTranslationFromBE>
  is_legacy: boolean
}

export type GetEtoIRequestsResponse = {
  data: Array<GetEtoINewRequestResponse>
  last_page: number
  current_page: number
}

const getEtoIRequests = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetEtoIRequestsParams,
  config?: AxiosRequestConfig,
) =>
  newDLTransferAPIWithCredentials.get<GetEtoIRequestsResponse | GetEtoILegacyRequestResponse>(
    '/api/employer/contracts',
    {
      params: {
        page: page + 1,
        per_page: perPage,
        status,
      },
      ...config,
    },
  )

export type GetEtoIRequestParams = {
  requestId: number | string
}

const getEtoIRequestDetails = ({ requestId }: GetEtoIRequestParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.get<GetEtoINewRequestResponse>(`/api/employer/contracts/${requestId}`, config)

export type AcceptOfferParams = {
  id: number
}

export type AcceptOfferResponse = {
  contract: {
    status: EToIRequestStatus
  }
}

const acceptEToIOffer = ({ id }: AcceptOfferParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<AcceptOfferResponse>(`/api/employer/contracts/${id}/accept`, {}, config)

export type AddFinancialDocumentsParams = {
  id: string
  documents: Array<string>
}

export type AddFinancialDocumentsResponse = {}

const addFinancialDocuments = ({ id, documents }: AddFinancialDocumentsParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<AddFinancialDocumentsResponse>(
    `/api/employer/contracts/${id}/documents`,
    {
      documents,
    },
    config,
  )

export type RejectOfferParams = {
  id: string
}

export type RejectOfferResponse = {}

const rejectEToIOffer = ({ id }: RejectOfferParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<GetEtoINewRequestResponse>(`/api/employer/contracts/${id}/reject`, {}, config)

export type PayForEToIRequestParams = {
  id: string
  redirectUrl: string
}

export type PayForEToIRequestResponse = {
  status: boolean
  data: PayfortBill
}

const payForEToIRequest = ({ id, redirectUrl }: PayForEToIRequestParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.get<PayForEToIRequestResponse>(`/api/employer/payment/${id}/tokenization`, {
    params: {
      redirect_url: redirectUrl,
    },
    ...config,
  })

export type CancelEToIContractParams = {
  id: string
}

export type CancelEToIContractResponse = {}

const cancelEToIContract = ({ id }: CancelEToIContractParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<CancelEToIContractResponse>(
    `/api/employer/contracts/${id}/request_cancellation`,
    {},
    config,
  )

export type TransferWorkerParams = {
  id: string
}

export type TransferWorkerResponse = {}

const transferWorker = ({ id }: TransferWorkerParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<TransferWorkerResponse>(
    '/api/employer/transfer_requests',
    {
      contract_id: id,
    },
    config,
  )

export type ReplaceWorkerParams = {
  requestId: string
  laborerId: string
}

export type ReplaceWorkerResponse = {}

const replaceWorker = ({ requestId, laborerId }: ReplaceWorkerParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<ReplaceWorkerResponse>(
    `/api/employer/contracts/${requestId}/request_replacement`,
    {
      id_number: laborerId,
    },
    config,
  )

export type CancelReplacementResponse = {}

export type CancelReplacementParams = {
  contractId: string
  replacementId: number
}

const cancelReplacement = ({ contractId, replacementId }: CancelReplacementParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.delete<ReplaceWorkerResponse>(
    `/api/employer/contracts/${contractId}/cancel_replacement_request/${replacementId}`,
    config,
  )

export type ResendEToIRequestParams = {
  contractId: number
}

export type ResendEToIRequestResponse = {}

export type ResendEToIRequestFailResponse = {
  error?: {
    message: string
    code: number
  }
}

const resendEToIRequest = ({ contractId }: ResendEToIRequestParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.post<ResendEToIRequestResponse>(
    'api/employer/transfer_requests/resend',
    {
      contract_id: contractId,
    },
    config,
  )

export type GetItoIRequestResponse = IToIRequestFromBE

export type GetIToIRequestsListParams = {
  perPage?: number
  page?: number
  status?: string
}

export type GetIToIRequestsListResponse = {
  data: Array<GetItoIRequestResponse>
  last_page: number
  current_page: number
}

const getIToIRequestsList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetIToIRequestsListParams,
  config?: AxiosRequestConfig,
) =>
  indToIndAPIWithCredentials.get<GetIToIRequestsListResponse>('/api/employer/transfer_request', {
    params: {
      page: page + 1,
      per_page: perPage,
      status,
    },
    ...config,
  })

export type GetIToIRequestDetailsParams = {
  requestId: number
}

export type GetIToIRequestDetailsResponse = {
  data: GetItoIRequestResponse
}

const getIToIRequestDetails = ({ requestId }: GetIToIRequestDetailsParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<GetIToIRequestDetailsResponse>(`/api/employer/transfer_request/${requestId}`, config)

export type IToILaborerListItem = {
  idNumber: number
  name: string
  nationality: {
    code: string
    label: string
    labelEn: string
  }
  occupation: {
    code: string
    label: string
    labelEn: string
  }
  birthDate: string
}

export type IToILaborerListResponse = Array<IToILaborerListItem>

export type IToILaborerListFailResponse = {
  error?: string
}

export type IToILaborerListParams = {
  date: string
}

const getIToILaborerList = ({ date }: IToILaborerListParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<IToILaborerListResponse>(`/api/employer/${date}/laborers`, config)

export type IToILaborerDetailsParams = {
  date: string
  iqamaNumber: number
}

export type IToILaborerDetailsResponse = IToILaborerListItem

const getIToILaborerDetails = ({ date, iqamaNumber }: IToILaborerDetailsParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<IToILaborerDetailsResponse>(`/api/employer/${date}/laborers/${iqamaNumber}`, config)

export type RejectIToIOfferParams = {
  requestId: number | string
}

export type RejectIToIOfferResponse = {}

const rejectIToIOffer = ({ requestId }: RejectIToIOfferParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<GetEtoINewRequestResponse>(
    `/api/employer/transfer_request/${requestId}/reject`,
    {},
    config,
  )

export type AcceptIToIOfferParams = {
  requestId: number
}

export type AcceptIToIOfferResponse = {}

const acceptIToIOffer = ({ requestId }: AcceptIToIOfferParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<AcceptIToIOfferResponse>(
    `/api/employer/transfer_request/${requestId}/accept`,
    {},
    config,
  )

export type CheckIToIElegibilityParams = {
  id: number
  date: string
}

export type CheckIToIElegibilityResponse = {
  error?: string
}

const checkIToIElegibility = ({ id, date }: CheckIToIElegibilityParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<CheckIToIElegibilityResponse>(
    'api/employer/laborers/check_eligibility',
    {
      id_number: id,
      date,
    },
    config,
  )

export type GetItoISuggestedCostParams = {
  date: string
  iqamaNumber: number
}

export type GetItoISuggestedCostResponse = {
  suggested_cost: string
  max_cost: string
  min_cost?: number
}

const getItoISuggestedCost = ({ date, iqamaNumber }: GetItoISuggestedCostParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<GetItoISuggestedCostResponse>(
    `/api/employer/transfer_request/${date}/cost_suggestion/${iqamaNumber}`,
    config,
  )

export type SubmitIToIRequestParams = {
  newEmployerId: string
  date: string
  iqama: number
  salary: string
  phoneNumber: string
  serviceCost: string
  contractDuration: number
}

export type SubmitIToIRequestResponse = {
  error?: string
  message?: string
}

const submitIToITransferRequest = (params: SubmitIToIRequestParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<SubmitIToIRequestResponse>(
    '/api/employer/transfer_request',
    {
      new_employer_id: params.newEmployerId,
      date: params.date,
      iqama_number: params.iqama,
      salary: params.salary,
      mobile_number: params.phoneNumber,
      service_cost: params.serviceCost,
      contract_duration: params.contractDuration,
    },
    config,
  )

export type AddIToIFinancialDocumentsParams = {
  id: string
  documents: Array<string>
}

export type AddIToIFinancialDocumentsResponse = {}

const addIToIFinancialDocuments = ({ id, documents }: AddIToIFinancialDocumentsParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<AddFinancialDocumentsResponse>(
    `api/employer/transfer_request/${id}/documents`,
    {
      documents,
    },
    config,
  )

export type PayForIToIRequestParams = {
  requestId: string | number
  redirectUrl: string
}

export type PayForIToIRequestResponse = {
  error?: string
  status?: boolean
  data: {
    tokenization_data: PayfortBill
    bill: {
      transfer_request_id: number
      bill_issue_date: string
      total_amount: number
      breakdown: {
        operational_fees_without_vat: number
        vat: number
        service_total: number
        service_price: number
      }
    }
  }
}

export type PayForIToIRequestFailResponse = {
  error?: string
  status?: boolean
  data: PayfortBill
}

const payForIToIRequest = ({ requestId, redirectUrl }: PayForIToIRequestParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.post<PayForIToIRequestResponse>(
    `api/employer/transfer_request/${requestId}/tokenization`,
    {
      redirect_url: redirectUrl,
    },
    config,
  )

export type GetIToIBillParams = {
  requestId: number
}

export type IToIBill = {
  id: number
  bill_number: number
  bill_issue_date: string
  contract_request_number: number
  status: number
  paid_at: string
  amount: number
  total_amount: number
  current_employer: {
    name: string
    id_number: number
  }
  new_employer: {
    name: string
    id_number: number
  }
  breakdown: {
    service_price: number
    bank_fees_without_vat: number
    operational_fees_without_vat: number
    vat: number
  }
}

export type GetIToIBillResponse = {
  data: IToIBill
}

const getIToIBill = ({ requestId }: GetIToIBillParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<GetIToIBillResponse>(`/api/employer/transfer_request/${requestId}/bill`, config)

export type getInvoiceFileParams = {
  id: string
}

export type getInvoiceFileResponse = {
  data: string
}

const getItoIInvoiceFile = ({ id }: getInvoiceFileParams, config?: AxiosRequestConfig) =>
  indToIndAPIWithCredentials.get<getInvoiceFileResponse>(`/api/employer/transfer_request/${id}/bill/zatca`, config)

const getEtoIInvoiceFile = ({ id }: getInvoiceFileParams, config?: AxiosRequestConfig) =>
  newDLTransferAPIWithCredentials.get<getInvoiceFileResponse>(`api/employer/contracts/${id}/bill/zatca`, config)

export default {
  getEtoIRequestsList: callAPI<typeof getEtoIRequests, GetEtoIRequestsResponse | GetEtoILegacyRequestResponse>(
    getEtoIRequests,
  ),
  getEtoIRequestDetails: callAPI<typeof getEtoIRequestDetails, GetEtoINewRequestResponse>(getEtoIRequestDetails),
  rejectEToIOffer: callAPI<typeof rejectEToIOffer, RejectOfferResponse, FailResponse>(rejectEToIOffer),
  payForEToIRequest: callAPI<typeof payForEToIRequest, PayForEToIRequestResponse>(payForEToIRequest),
  cancelEToIContract: callAPI<typeof cancelEToIContract, CancelEToIContractResponse, FailResponse>(cancelEToIContract),
  acceptEToIOffer: callAPI<typeof acceptEToIOffer, AcceptOfferResponse, FailResponse>(acceptEToIOffer),
  addFinancialDocuments: callAPI<typeof addFinancialDocuments, AddFinancialDocumentsResponse, FailResponse>(
    addFinancialDocuments,
  ),
  transferWorker: callAPI<typeof transferWorker, TransferWorkerResponse, FailResponse>(transferWorker),
  replaceWorker: callAPI<typeof replaceWorker, ReplaceWorkerResponse, FailResponse>(replaceWorker),
  cancelReplacement: callAPI<typeof cancelReplacement, CancelReplacementResponse, FailResponse>(cancelReplacement),
  resendEToIRequest: callAPI<typeof resendEToIRequest, ResendEToIRequestResponse, FailResponse>(resendEToIRequest),
  getIToIRequestsList: callAPI<typeof getIToIRequestsList, GetIToIRequestsListResponse, FailResponse>(
    getIToIRequestsList,
  ),
  getIToILaborerList: callAPI<typeof getIToILaborerList, IToILaborerListResponse, FailResponse>(getIToILaborerList),
  checkIToIElegibility: callAPI<typeof checkIToIElegibility, CheckIToIElegibilityResponse, FailResponse>(
    checkIToIElegibility,
  ),
  rejectIToIOffer: callAPI<typeof rejectIToIOffer, RejectIToIOfferResponse, FailResponse>(rejectIToIOffer),
  acceptIToIOffer: callAPI<typeof acceptIToIOffer, AcceptIToIOfferResponse, FailResponse>(acceptIToIOffer),
  getItoISuggestedCost: callAPI<typeof getItoISuggestedCost, GetItoISuggestedCostResponse, FailResponse>(
    getItoISuggestedCost,
  ),
  submitIToITransferRequest: callAPI<typeof submitIToITransferRequest, SubmitIToIRequestResponse, FailResponse>(
    submitIToITransferRequest,
  ),
  getIToIRequestDetails: callAPI<typeof getIToIRequestDetails, GetIToIRequestDetailsResponse, FailResponse>(
    getIToIRequestDetails,
  ),
  getIToILaborerDetails: callAPI<typeof getIToILaborerDetails, IToILaborerDetailsResponse, FailResponse>(
    getIToILaborerDetails,
  ),
  addIToIFinancialDocuments: callAPI<
    typeof addIToIFinancialDocuments,
    AddIToIFinancialDocumentsResponse,
    FailResponse
  >(addIToIFinancialDocuments),
  payForIToIRequest: callAPI<typeof payForIToIRequest, PayForIToIRequestResponse, FailResponse>(payForIToIRequest),
  getIToIBill: callAPI<typeof getIToIBill, GetIToIBillResponse, FailResponse>(getIToIBill),
  getEtoIInvoiceFile: callAPI<typeof getEtoIInvoiceFile, getInvoiceFileResponse>(getEtoIInvoiceFile),
  getItoIInvoiceFile: callAPI<typeof getItoIInvoiceFile, getInvoiceFileResponse>(getItoIInvoiceFile),
}
