import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { LborerMobileCheck } from 'common/utils/convertResponse/convertAuthApiResponse'

type State = LborerMobileCheck & {
  errorMessage: string
} & Status

const initialState = {
  isUsed: false,
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC,
    Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS,
    Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL,
    LborerMobileCheck,
    { errorMessage: string }
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC: {
      return {
        ...state,
        isUsed: false,
        errorMessage: '',
        ...action.status,
      }
    }
    case Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL: {
      return {
        ...state,
        isUsed: false,
        errorMessage: action.payload?.errorMessage || '',
        ...action.status,
      }
    }
    case Actions.CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        ...action.status,
      }
    }
    default:
      return state
  }
}
