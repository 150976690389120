import { EmploymentStatus } from 'common/types/commonTypes'
import { updateDomesticTransferForm } from 'redux/actionCreators/domesticTransferForm'
import Actions from 'redux/actions'

export type DomesticTransferFormState = {
  documents: Array<string>
  employmentStatus: EmploymentStatus
  nationality: string
  occupation: number
  token: string
}

const initialState: DomesticTransferFormState = {
  token: '',
  occupation: 0,
  nationality: '',
  employmentStatus: 0,
  documents: [],
}

export default (
  state: DomesticTransferFormState = initialState,
  action: ReturnType<typeof updateDomesticTransferForm>,
): DomesticTransferFormState => {
  switch (action.type) {
    case Actions.UPDATE_DOMESTIC_TRANSFER_FORM:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
