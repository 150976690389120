import { CallAPIAction, Status } from 'api/types'
import { FarmerTransferRequestsStatus } from 'common/types/commonTypes'
import { Bill, ObjectWithTranslation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import { FarmerTransferRequestFormBE, GetFarmerTransferRequestsListResponse } from 'api/evisaAPI/farmerTransfer'
import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import { getFarmerTransferRequestsList } from 'redux/actionCreators/evisaAPI/farmerTransfer'

export type FarmerTransferRequest = {
  id: number
  sponsorName: string
  laborerId: number
  createdAt: string
  status: FarmerTransferRequestsStatus
  userName: ObjectWithTranslation
  rejectionReasons: Array<ObjectWithTranslation>
  bill: Bill | null
  user: {
    idNumber: number
  }
}

type State = {
  farmerTransferParams: {
    status: string | FarmerTransferRequestsStatus
    page: number
  }
  requests: Array<FarmerTransferRequest>
  lastPage: number
} & Status

const initialState: State = {
  farmerTransferParams: {
    status: '',
    page: 0,
  },
  requests: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertRequestToState = (resp: FarmerTransferRequestFormBE): FarmerTransferRequest => ({
  id: resp.id,
  sponsorName: resp.sponsor.name,
  laborerId: resp.laborer_id,
  userName: {
    id: resp.user.id,
    label: resp.user.name,
    labelEn: resp.user.name_en,
  },
  createdAt: resp.created_at,
  status: resp.status,
  user: { idNumber: resp.user.id_number },
  rejectionReasons: resp.rejection_reasons?.map((reason) => formatObjectWithTranslationFromBE(reason)) || [],
  bill: resp.bill
    ? {
        billNumber: resp.bill.bill_number,
        id: resp.bill.id,
        createdAt: resp.bill.created_at,
        vat: resp.bill.vat,
        amount: resp.bill.amount,
        status: resp.bill.status,
        expiryDate: resp.bill.expiry_date,
        paidAt: resp.bill.paid_at,
        tbsId: resp.bill.tbs_id,
      }
    : null,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_ASYNC,
    Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_SUCCESS,
    Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_FAIL,
    GetFarmerTransferRequestsListResponse,
    {},
    {},
    ReturnType<typeof getFarmerTransferRequestsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_ASYNC: {
      const {
        params: { status = state.farmerTransferParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        farmerTransferParams: {
          page,
          status,
        },
        lastPage: status !== state.farmerTransferParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        requests: action.payload.paginator.data.map(convertRequestToState),
        lastPage: action.payload.paginator.last_page,
      }
    }
    case Actions.GET_FARMER_TRANSFER_REQUESTS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
