import React, { FunctionComponent } from 'react'
import clsx from 'clsx'

import { useVipRoute } from 'common/hooks/useVipRoute'

import styles from './Typography.module.scss'

type TypographyProps = {
  tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4'
  appearance: 'title' | 'subtitle' | 'h1' | 'h2' | 'h3'
  color?: 'darkBlue' | 'darkGray' | 'primary' | 'black'
  className?: string
  id?: string
}

const Typography: FunctionComponent<TypographyProps> = ({ tag = 'p', appearance, color, className, id, children }) => {
  const TypographyTag = tag
  const { isVip } = useVipRoute()
  const colorOverride = isVip && color === 'darkBlue' ? 'black' : color || 'black' // TODO: need refactoring

  return (
    <TypographyTag
      id={id}
      className={clsx(styles.root, styles[appearance], color && styles[colorOverride], className)}
    >
      {children}
    </TypographyTag>
  )
}

export default Typography
