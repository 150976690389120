import { Effect } from 'redux-saga/effects'

import prosAPISagas from './prosAPISagas'
import evisaAPISagas from './evisaAPI/evisaAPISaga'
import authEvisaAPISagas from './evisaAPI/authSaga'
import visasEvisaAPISagas from './evisaAPI/visasSaga'
import noticesSagas from './evisaAPI/noticesAPISaga'
import eContractingAPISagas from './eContractingAPISaga'
import jiraAPISagas from './jiraAPISaga'
import dlAPISagas from './evisaAPI/dlAPISaga'
import farmerVisaSaga from './evisaAPI/farmerVisaSaga'
import surveyAPISagas from './surveyAPISagas'
import farmerTransfer from './evisaAPI/farmerTransfer'
import wpsAPISagas from './wpsAPISaga'
import contractAuthAPISaga from './contractAuthAPISaga'
import newDLTransferAPISaga from './newDLTransferAPISaga'
import laborerAuthContractAPISagas from './laborerPortalAPI/authContractAPISagas'
import laborerAuthAPISagas from './laborerPortalAPI/authAPISagas'
import laborerServiceTransferAPISagas from './laborerPortalAPI/serviceTransferAPISagas'
import relationshipManagerAPISaga from './premiumUserAPI/relationshipManagerAPISaga'
import supportTicketsAPISagas from './laborerPortalAPI/supportTicketsAPISagas'

const sagas: Array<Effect> = [
  ...prosAPISagas,
  ...evisaAPISagas,
  ...authEvisaAPISagas,
  ...visasEvisaAPISagas,
  ...noticesSagas,
  ...eContractingAPISagas,
  ...jiraAPISagas,
  ...dlAPISagas,
  ...farmerVisaSaga,
  ...surveyAPISagas,
  ...farmerTransfer,
  ...wpsAPISagas,
  ...contractAuthAPISaga,
  ...newDLTransferAPISaga,
  ...laborerAuthAPISagas,
  ...laborerAuthContractAPISagas,
  ...laborerServiceTransferAPISagas,
  ...relationshipManagerAPISaga,
  ...supportTicketsAPISagas,
]

export default sagas
