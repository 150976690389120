import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { respondToComplaintSolution } from 'redux/actionCreators/jiraAPI'
import type { RespondToComplaintSolutionResponse } from 'api/jiraAPI'

type State = {
  isAccept?: boolean
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  isAccept: undefined,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.RESPOND_TO_COMPLAINT_SOLUTION_ASYNC,
    Actions.RESPOND_TO_COMPLAINT_SOLUTION_SUCCESS,
    Actions.RESPOND_TO_COMPLAINT_SOLUTION_FAIL,
    RespondToComplaintSolutionResponse,
    {},
    {},
    ReturnType<typeof respondToComplaintSolution>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.RESPOND_TO_COMPLAINT_SOLUTION_ASYNC: {
      const { callAPIActionPayload } = action
      return {
        ...state,
        ...action.status,
        isAccept: callAPIActionPayload.params.accept,
      }
    }
    case Actions.RESPOND_TO_COMPLAINT_SOLUTION_SUCCESS:
    case Actions.RESPOND_TO_COMPLAINT_SOLUTION_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
