import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { GetLaborerTransferDetailsResponse } from 'api/laborerPortalAPI/serviceTransfer'
import {
  getEmptyLaborerIToIRequest,
  convertIToIRequestFromResponse,
  IToIRequest,
} from 'common/utils/convertResponse/convertIToIResponse'

type State = {
  request: IToIRequest
} & Status

const initialState = {
  request: getEmptyLaborerIToIRequest(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_TRANSFER_DETAILS_ASYNC,
    Actions.GET_LABORER_TRANSFER_DETAILS_SUCCESS,
    Actions.GET_LABORER_TRANSFER_DETAILS_FAIL,
    GetLaborerTransferDetailsResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_TRANSFER_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        request: convertIToIRequestFromResponse(action.payload.data),
      }
    }
    case Actions.GET_LABORER_TRANSFER_DETAILS_ASYNC:
    case Actions.GET_LABORER_TRANSFER_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
