import { CreateContractAuthRequestFailResponse, CreateContractAuthRequestResponse } from 'api/contractAuthAPI'
import type { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_CONTRACT_AUTH_REQUEST_ASYNC,
    Actions.CREATE_CONTRACT_AUTH_REQUEST_SUCCESS,
    Actions.CREATE_CONTRACT_AUTH_REQUEST_FAIL,
    CreateContractAuthRequestResponse,
    CreateContractAuthRequestFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_CONTRACT_AUTH_REQUEST_ASYNC:
    case Actions.CREATE_CONTRACT_AUTH_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        errorMessage: '',
      }
    }
    case Actions.CREATE_CONTRACT_AUTH_REQUEST_FAIL: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        errorMessage: payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
