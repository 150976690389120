import { CallAPIAction, Status } from 'api/types'
import { GetItoISuggestedCostResponse } from 'api/newDLTransferAPI'
import { getItoISuggestedCost } from 'redux/actionCreators/newDLTransferAPI'
import Actions from 'redux/actions'

type State = {
  suggestedValue: string
  maximumValue: string
  minimumValue: number
} & Status

const initialState = {
  suggestedValue: '',
  maximumValue: '',
  minimumValue: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_SUGGESTED_COST_ASYNC,
    Actions.GET_I_TO_I_SUGGESTED_COST_SUCCESS,
    Actions.GET_I_TO_I_SUGGESTED_COST_FAIL,
    GetItoISuggestedCostResponse,
    ReturnType<typeof getItoISuggestedCost>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_I_SUGGESTED_COST_ASYNC:
    case Actions.GET_I_TO_I_SUGGESTED_COST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_I_TO_I_SUGGESTED_COST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        suggestedValue: payload.suggested_cost,
        maximumValue: payload.max_cost,
        minimumValue: payload.min_cost || 0,
      }
    }
    default:
      return state
  }
}
