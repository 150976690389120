import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { getIToILaborerDetails } from 'redux/actionCreators/newDLTransferAPI'
import { IToILaborerListItem, IToILaborerDetailsResponse } from 'api/newDLTransferAPI'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

type State = {
  data: Request
} & Status

const initialState = {
  data: {
    id: 0,
    name: '',
    nationality: {
      id: 0,
      label: '',
      labelEn: '',
    },
    occupation: {
      id: 0,
      label: '',
      labelEn: '',
    },
    birthDate: '',
  },
  isLoading: false,
  success: false,
  fail: false,
}

type Request = {
  id: number
  name: string
  nationality: ObjectWithTranslation
  occupation: ObjectWithTranslation
  birthDate: string
}

const convertRequestToState = (resp: IToILaborerListItem): Request => ({
  id: resp.idNumber,
  name: resp.name,
  nationality: {
    id: parseInt(resp.nationality.code),
    label: resp.nationality.label,
    labelEn: resp.nationality.labelEn,
  },
  occupation: {
    id: parseInt(resp.occupation.code),
    label: resp.occupation.label,
    labelEn: resp.occupation.labelEn,
  },
  birthDate: resp.birthDate,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_I_TO_I_LABORER_DETAILS_ASYNC,
    Actions.GET_I_TO_I_LABORER_DETAILS_SUCCESS,
    Actions.GET_I_TO_I_LABORER_DETAILS_FAIL,
    IToILaborerDetailsResponse,
    {},
    {},
    ReturnType<typeof getIToILaborerDetails>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_I_TO_I_LABORER_DETAILS_ASYNC:
    case Actions.GET_I_TO_I_LABORER_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_I_TO_I_LABORER_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: convertRequestToState(action.payload),
      }
    default:
      return state
  }
}
