import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { AcceptOfferResponse } from 'api/newDLTransferAPI'
import { EToIRequestStatus } from 'common/types/commonTypes'

type State = {
  requestStatus: string | EToIRequestStatus
} & Status

const initialState = {
  requestStatus: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_E_TO_I_OFFER_ASYNC,
    Actions.ACCEPT_E_TO_I_OFFER_SUCCESS,
    Actions.ACCEPT_E_TO_I_OFFER_FAIL,
    AcceptOfferResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_E_TO_I_OFFER_ASYNC:
    case Actions.ACCEPT_E_TO_I_OFFER_FAIL: {
      return {
        ...state,
        requestStatus: '',
        ...action.status,
      }
    }
    case Actions.ACCEPT_E_TO_I_OFFER_SUCCESS: {
      return {
        ...state,
        requestStatus: action.payload.contract.status,
        ...action.status,
      }
    }
    default:
      return state
  }
}
