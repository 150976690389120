import { GetTicketParams, rawJiraAPI } from 'api/jiraAPI'
import { GetNoticeParams, rawNoticesAPI } from 'api/evisaAPI/noticesAPI'
import { rawVisasEvisaAPI } from 'api/evisaAPI/visas'
import { rawDlAPI } from 'api/evisaAPI/dlAPI'
import { GetContractInfoParams, rawEContractingAPI } from 'api/eContractingAPI'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { Lang, VisaStatus } from 'common/types/commonTypes'

import authManager from '../auth/authManager'
import {
  convertTruncatedComplaintTicketList,
  convertComplaintTicket,
} from '../convertResponse/convertComplaintResponse'
import {
  convertVisaResponseToState,
  formatVisaIssueRequestFromBE,
} from '../convertResponse/convertVisaResponseToState'

import { t, tCommon, withAuthGuard, withFetchGuard, wrongIdGuard } from './utils'

const getComplaintTickets = async () => {
  const tickets = convertTruncatedComplaintTicketList(
    (
      await rawJiraAPI.getTicketsList({
        show: LIST_ITEMS_PER_PAGE,
        status: '',
        page: 0,
      })
    ).data,
  )

  return tickets.length
    ? tickets
        .map((ticket, index) =>
          t('complaintTicketsInfo', {
            index: index + 1,
            key: ticket.key,
            status: tCommon(`complaintTicketStatus.${ticket.fields.status.name}`),
          }),
        )
        .join('')
    : t('noResult')
}

const getRefunds = async () => {
  const refunds = (await rawEContractingAPI.getRefunds()).data.data

  return refunds.length
    ? refunds
        .map((refund, index) =>
          t('refundInfo', {
            index: index + 1,
            id: refund.id,
            status: t(`refundStatus.${refund.status}`),
          }),
        )
        .join('')
    : t('noResult')
}

const getRefundDetails = async (contractId: string) => {
  const refund = (await rawEContractingAPI.getRefundDetails({ contractId })).data.data

  return refund.length
    ? refund
        .map((item, index) =>
          t('refundInfo', {
            index: index + 1,
            id: item.id,
            status: t(`refundStatus.${item.status}`),
          }),
        )
        .join('')
    : t('noResult')
}

const getComplaintTicketStatus = async (ticketKey: GetTicketParams['ticketKey']) => {
  const ticket = convertComplaintTicket(await (await rawJiraAPI.getTicket({ ticketKey })).data)
  return t('complaintTicketStatusInfo', {
    key: ticket.key,
    status: tCommon(`complaintTicketStatus.${ticket.fields.status.name}`),
  })
}

const getNotices = async () => {
  const notices = (await rawNoticesAPI.getNoticesList({})).data.paginator.data
  return notices.length
    ? notices
        .map((notice, index) =>
          t('noticeInfo', {
            index: index + 1,
            id: notice.id,
            status: tCommon(`noticeStatus.${notice.status}`),
          }),
        )
        .join('')
    : t('noResult')
}

const getVisaRequests = async () => {
  const [visaRequests, visas] = await Promise.all([
    rawVisasEvisaAPI
      .getVisaIssueRequestsList()
      .then((resp) => resp.data.paginator.data.map(formatVisaIssueRequestFromBE)),
    rawVisasEvisaAPI
      .getAllVisasList({
        page: 0,
      })
      .then((resp) => resp.data.paginator.data.map((visa) => convertVisaResponseToState({ visa }))),
  ])
  return visaRequests.length
    ? visaRequests
        .map((request, index) => {
          const visa = visas.find((visaInfo) => visaInfo.issueRequest.id === request.id)
          const isEng = localStorage.getItem('language') === Lang.en
          const rejectionReasons = request.rejectionReasons.map((rr) => (isEng ? rr.labelEn : rr.label)).join(', \n')
          const currIndex = index + 1
          return visa?.status !== VisaStatus.unissued && visa?.followupNumber
            ? t('visaInfo', {
                index: currIndex,
                id: visa.followupNumber,
                borderNumber: visa.borderNumber,
                status: tCommon(`visaStatus.${visa?.status}`),
              })
            : t(rejectionReasons ? 'visaRequestInfoWithReason' : 'visaRequestInfo', {
                index: currIndex,
                id: request.id,
                rejectionReasons,
                status: tCommon(`visaRequestStatus.${request.status}`),
              })
        })
        .join('')
    : t('noResult')
}

const getVisaRequestStatus = async (issueRequestId: number) => {
  const [visaRequest, visas] = await Promise.all([
    rawVisasEvisaAPI
      .getVisaIssueRequestInfo({ issueRequestId })
      .then((resp) => formatVisaIssueRequestFromBE(resp.data.issueRequest)),
    rawVisasEvisaAPI
      .getAllVisasList({
        page: 0,
      })
      .then((resp) => resp.data.paginator.data.map((visa) => convertVisaResponseToState({ visa }))),
  ])
  const visa = visas.find((visaInfo) => visaInfo.issueRequest.id === visaRequest.id)
  const isEng = localStorage.getItem('language') === Lang.en
  const rejectionReasons = visaRequest.rejectionReasons.map((rr) => (isEng ? rr.labelEn : rr.label)).join(', \n')
  return visa?.status !== VisaStatus.unissued && visa?.followupNumber
    ? t('visaStatusInfo', {
        id: visa.followupNumber,
        borderNumber: visa.borderNumber,
        status: tCommon(`visaStatus.${visa?.status}`),
      })
    : t(rejectionReasons ? 'visaRequestStatusInfoWithReason' : 'visaRequestStatusInfo', {
        id: visaRequest.id,
        rejectionReasons,
        status: tCommon(`visaRequestStatus.${visaRequest.status}`),
      })
}

const getDLTransfers = async () => {
  const dlRequests = (await rawDlAPI.getEToILegacyRequests({})).data.paginator.data
  return dlRequests.length
    ? dlRequests
        .map((request, index) =>
          t('dlRequestInfo', {
            index: index + 1,
            id: request.id,
            status: tCommon(`eToILegacyRequestsStatus.${request.status}`),
          }),
        )
        .join('')
    : t('noResult')
}

const getContractStatus = async (contractId: GetContractInfoParams['contractId']) => {
  const contract = (await rawEContractingAPI.getContractInfo({ contractId })).data.contract
  return t('contractStatusInfo', {
    id: contract.id,
    status: tCommon(`contractStatus.${contract.new_status}`),
  })
}

const getContracts = async () => {
  const contracts = (await rawEContractingAPI.getContractRequestsList()).data.data.slice(0, LIST_ITEMS_PER_PAGE)
  return contracts.length
    ? contracts
        .map((request, index) => {
          const contract = request.offers.find((r) => r.contract)?.contract
          if (contract) {
            return t('contractInfo', {
              index: index + 1,
              id: contract?.id || 0,
              status: tCommon(`contractStatus.${contract?.new_status}`),
            })
          }
          return t('requestInfo', {
            index: index + 1,
            id: request.id,
            status: tCommon(`contractRequestStatus.${request.new_status}`),
          })
        })
        .join('')
    : t('noResult')
}

const getNoticeStatus = async (noticeId: GetNoticeParams['noticeId']) => {
  const notice = (await rawNoticesAPI.getNotice({ noticeId })).data.noticeRequest
  return t('noticeStatusInfo', {
    id: notice.id,
    status: tCommon(`noticeStatus.${notice.status}`),
  })
}

const userGreeting = () => t('userGreeting', { name: authManager.getUserProfileData().nic.firstName.label })

const apiForLabiba = {
  GET_COMPLAINT_TICKETS: withAuthGuard(withFetchGuard(getComplaintTickets)),
  GET_REFUNDS: withAuthGuard(withFetchGuard(getRefunds)),
  GET_REFUND_DETAILS: withAuthGuard(withFetchGuard(getRefundDetails)),
  GET_NOTICES: withAuthGuard(withFetchGuard(getNotices)),
  GET_NOTICE_STATUS: withAuthGuard(withFetchGuard(wrongIdGuard('notFoundNoticeRequest')(getNoticeStatus))),
  GET_VISA_REQUESTS: withAuthGuard(withFetchGuard(getVisaRequests)),
  GET_VISA_REQUEST_STATUS: withAuthGuard(
    withFetchGuard(wrongIdGuard('notFoundVisaRequestInfo')(getVisaRequestStatus)),
  ),
  GET_DL_TRANSFERS: withAuthGuard(withFetchGuard(getDLTransfers)),
  GET_CONTRACT_STATUS: withAuthGuard(withFetchGuard(wrongIdGuard('notFoundContract')(getContractStatus))),
  GET_CONTRACTS: withAuthGuard(withFetchGuard(getContracts)),
  GET_STATUS_FOR_COMPLAINT_TICKET: withAuthGuard(
    withFetchGuard(wrongIdGuard('notFoundComplaintTicket')(getComplaintTicketStatus)),
  ),
  USER_GREETING: withAuthGuard(userGreeting),
}
export type APIForLabiba = typeof apiForLabiba

export default apiForLabiba
