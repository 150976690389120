import type { VipPro } from 'common/types/transformedRespTypes'
import { StringRecord } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { AddSelectVipProAction, RemoveSelectVipProAction } from 'redux/actionCreators/vipMarketSelectedPros'

type State = StringRecord<VipPro>

const initialState = {}

export default (state: State = initialState, action: AddSelectVipProAction | RemoveSelectVipProAction): State => {
  switch (action.type) {
    case Actions.ADD_SELECTED_VIP_PRO: {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    }
    case Actions.REMOVE_SELECTED_VIP_PRO: {
      const filteredState = { ...state }
      delete filteredState[action.payload]
      return filteredState
    }
    default:
      return state
  }
}
