import { ProPerformance } from 'common/types/commonTypes'
import { ProPerformanceFromBE } from 'api/jiraAPI'

export const convertResponseToState = (response: ProPerformanceFromBE): ProPerformance => ({
  arrivalRate: Math.round(parseFloat(response.avg_arrival_rate)),
  commitmentRate: Math.round(parseFloat(response.avg_arrival_duration_commitment_rate)),
  resolutionRate: Math.round(parseFloat(response.avg_resolution_avg)),
  lateResolutionRate: Math.round(parseFloat(response.avg_late_contracts_resolution_rate)),
  averageRate: Math.ceil(
    (parseFloat(response.avg_arrival_rate) +
      parseFloat(response.avg_arrival_duration_commitment_rate) +
      parseFloat(response.avg_resolution_avg) +
      parseFloat(response.avg_late_contracts_resolution_rate)) /
      4,
  ),
  proId: response.pro_id,
})
