import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { GetTicketListResponse } from 'api/jiraAPI'
import { getTicketsList } from 'redux/actionCreators/jiraAPI'
import type { TicketTruncated } from 'common/utils/convertResponse/convertComplaintResponse'
import { convertTruncatedComplaintTicketList } from 'common/utils/convertResponse/convertComplaintResponse'

type State = {
  tickets: Array<TicketTruncated>
  pages: number
} & Status

const initialState: State = {
  tickets: [],
  pages: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_TICKETS_LIST_ASYNC,
    Actions.GET_TICKETS_LIST_SUCCESS,
    Actions.GET_TICKETS_LIST_FAIL,
    GetTicketListResponse,
    {},
    {},
    ReturnType<typeof getTicketsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_TICKETS_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        tickets: convertTruncatedComplaintTicketList(payload),
        pages: payload.pages,
      }
    }
    case Actions.GET_TICKETS_LIST_ASYNC: {
      const { clearPageCount } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        pages: clearPageCount ? 0 : state.pages,
      }
    }
    case Actions.GET_TICKETS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
