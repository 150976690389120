import { put, call, cancelled } from 'redux-saga/effects'
import axios, { AxiosRequestConfig } from 'axios'

import { FAIL, LOADING, SUCCESS } from 'common/constants'
import type { Await } from 'common/types/commonTypes'
import Actions from 'redux/actions'
import { CallApiFnResponse, CallApiWrapper } from 'api/axios'

export function* putLoading(action: { type: string; [key: string]: any }) {
  yield put({ ...action, status: LOADING })
}

export function* putSuccess(action: { type: string; [key: string]: any }) {
  yield put({ ...action, status: SUCCESS })
}

export function* putFail(action: { type: string; [key: string]: any }) {
  yield put({ ...action, status: FAIL })
}

export function getSimpleRequestHandler<
  F extends CallApiWrapper,
  A extends { type: Actions; payload?: { params?: unknown; config?: AxiosRequestConfig } },
>(requestParams: { actionLoading?: Actions; actionSuccess?: Actions; actionFail?: Actions; callApiFn: F }) {
  return function* simpleRequestHandlerSaga(action: A) {
    const { callApiFn, actionLoading, actionSuccess, actionFail } = requestParams
    if (actionLoading) {
      yield putLoading({ type: actionLoading, callAPIActionPayload: action.payload })
    }
    const cancelObj = axios.CancelToken.source()
    try {
      const { response, headers, status }: Await<CallApiFnResponse> = yield call<F>(
        callApiFn,
        ...([
          action?.payload?.params,
          { cancelToken: cancelObj.token, ...action?.payload?.config },
        ] as unknown as Parameters<F>),
      )
      if (status.success && actionSuccess) {
        yield put({
          type: actionSuccess,
          callAPIActionPayload: action.payload,
          payload: response,
          headers,
          status,
        })
      }
      if (status.fail && actionFail) {
        yield put({
          type: actionFail,
          callAPIActionPayload: action.payload,
          payload: response,
          headers,
          status,
        })
      }
      return { response, headers, status, callAPIActionPayload: action.payload } as Await<ReturnType<F>>
    } finally {
      const isCanceled: boolean = yield cancelled()
      if (isCanceled) {
        cancelObj.cancel()
      }
    }
  }
}
