import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

export type SupportAttachmentType = {
  attachment: string
  type: string
  filename: string
  id: string
}

type State = {
  attachments: Array<SupportAttachmentType>
} & Status

const initialState: State = {
  attachments: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_SUPPORT_ATTACHMENTS_ASYNC,
    Actions.GET_LABORER_SUPPORT_ATTACHMENTS_SUCCESS,
    Actions.GET_LABORER_SUPPORT_ATTACHMENTS_FAIL,
    Array<SupportAttachmentType>
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_SUPPORT_ATTACHMENTS_ASYNC:
    case Actions.GET_LABORER_SUPPORT_ATTACHMENTS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LABORER_SUPPORT_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        attachments: action.payload,
      }
    }
    default:
      return state
  }
}
