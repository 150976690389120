import type { AxiosRequestConfig } from 'axios'

import { callAPI, laborerAuthAPIWithCredentials } from 'api/axios'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { GeneralFailResponse, SupportComplaintCategoryIds, SupportComplaintStatuses } from 'common/types/commonTypes'
import { FileStatus } from 'ui-components/inputs/DropArea/types'

export type SupportTicketFromBE = {
  id: string
  key: string
  fields: {
    created: string
    category: {
      id: SupportComplaintCategoryIds
    }
    status: {
      name: SupportComplaintStatuses
    }
    description: string
    laborer_mobile_number: string | null
    final_decision: string | null
    attachment: Array<{ filename: string; id: string }>
  }
}

export type GetSupportTicketsParams = {
  perPage?: number
  page?: number
}

export type GetSupportTicketsResponse = {
  issues: Array<SupportTicketFromBE>
  pages: number
  startAt: number
}

const getSupportTickets = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE }: GetSupportTicketsParams,
  config?: AxiosRequestConfig,
) =>
  laborerAuthAPIWithCredentials.get<GetSupportTicketsResponse>('api/laborer/support/list', {
    params: {
      page,
      show: perPage,
    },
    ...config,
  })

export type CreateSupportTicketParams = {
  description: string
  category: string
  attachments: Map<string, FileStatus>
  mobileNumber: string
}

export type CreateSupportTicketResponse = {
  issue_key: string
}

const createSupportTicketFormData = ({
  description,
  category,
  attachments,
  mobileNumber,
}: CreateSupportTicketParams) => {
  const formData = new FormData()
  formData.append('description', description)
  formData.append('categories', category)
  formData.append('laborer_mobile_number', mobileNumber)
  Array.from(attachments.values()).forEach((value, index) => formData.append(`attachments[${index}]`, value.file))
  return formData
}

const createSupportTicket = (params: CreateSupportTicketParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.post<GetSupportTicketsResponse>(
    'api/laborer/support/create',
    createSupportTicketFormData(params),
    config,
  )

export type GetSupportAttachmentsParams = {
  id: string
}

export type GetSupportAttachmentsResponse = {
  attachment: string
  type: string
}

const getSupportAttachments = ({ id }: GetSupportAttachmentsParams, config?: AxiosRequestConfig) =>
  laborerAuthAPIWithCredentials.get<GetSupportAttachmentsResponse>(`api/laborer/support/attachment/${id}`, {
    ...config,
  })

export default {
  getSupportTickets: callAPI<typeof getSupportTickets, GetSupportTicketsResponse, GeneralFailResponse>(
    getSupportTickets,
  ),
  createSupportTicket: callAPI<typeof createSupportTicket, GetSupportTicketsResponse, GeneralFailResponse>(
    createSupportTicket,
  ),
  getSupportAttachments: callAPI<typeof getSupportAttachments, GetSupportAttachmentsResponse, GeneralFailResponse>(
    getSupportAttachments,
  ),
}
