import { RefreshLaborerTokenResponse } from 'api/laborerPortalAPI/auth'
import { CallAPIAction, Status } from 'api/types'
import authManager from 'common/utils/auth/authManager'
import Actions from 'redux/actions'

type State = {
  laborerInfo: ReturnType<typeof authManager.getLaborerProfileData>
  logOutWarning: boolean
  token: string
  expiresAt: string
  error?: string
} & Status

const initialState = {
  laborerInfo: authManager.getLaborerProfileData(),
  logOutWarning: authManager.isReadyForAutoLaborerLogOut(),
  token: '',
  expiresAt: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action:
    | {
        type: Actions.UPDATE_LABORER_AUTH_DATA
      }
    | CallAPIAction<
        Actions.REFRESH_LABORER_TOKEN_ASYNC,
        Actions.REFRESH_LABORER_TOKEN_SUCCESS,
        Actions.REFRESH_LABORER_TOKEN_FAIL,
        RefreshLaborerTokenResponse,
        { message: string }
      >,
): State => {
  switch (action.type) {
    case Actions.UPDATE_LABORER_AUTH_DATA: {
      return {
        ...state,
        logOutWarning: true,
      }
    }
    case Actions.REFRESH_LABORER_TOKEN_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.REFRESH_LABORER_TOKEN_SUCCESS: {
      const { labor, token, expires_at } = action.payload

      return {
        ...state,
        ...action.status,
        token,
        expiresAt: expires_at,
        logOutWarning: false,
        laborerInfo: {
          iqamaNumber: labor.iqama_number,
          birthDate: labor.birth_date,
          nationality: {
            id: labor.nationality.id,
            label: labor.nationality.label,
            labelEn: labor.nationality.label_en,
            abbreviation: labor.nationality.abbreviation,
          },
          occupation: {
            id: labor.occupation.id,
            label: labor.occupation.label,
            labelEn: labor.occupation.label_en,
          },
          mobileNumber: labor.mobile_number,
          religion: labor.religion,
        },
      }
    }
    case Actions.REFRESH_LABORER_TOKEN_FAIL:
      return {
        ...state,
        ...action.status,
        error: action.payload?.message,
      }
    default:
      return state
  }
}
