import { Status, CallAPIAction } from 'api/types'
import type {
  CheckEligibilityForFarmerVisaResponse,
  CheckEligibilityForFarmerVisaFailResponse,
} from 'api/evisaAPI/farmerVisa'
import { FarmerEligibilityErrorMessages, StringRecord } from 'common/types/commonTypes'
import { Credit } from 'common/types/transformedRespTypes'
import { formatOccupationsFromBE } from 'common/utils/objectWithTranslation'
import Actions from 'redux/actions'

type State = {
  credits: StringRecord<Credit>
  unallowedNationalities: Array<number>
  errorMessage: FarmerEligibilityErrorMessages | string
} & Status

const convertResponseToState = (resp: CheckEligibilityForFarmerVisaResponse) => {
  const credits = resp.credits.reduce<StringRecord<Credit>>(
    (creditsObj, credit) => ({
      [credit.CreditId]: {
        creditId: credit.CreditId,
        availableCredit: credit.AvailableCredit,
        contractExpiryDate: credit.ContractExpiryDate,
        establishmentName: credit.EstablishmentName,
        occupations: credit.occupations.map(formatOccupationsFromBE),
      },
      ...creditsObj,
    }),
    {},
  )
  return {
    credits,
    unallowedNationalities: resp.unallowed_nationalities,
  }
}

const initialState = {
  credits: {},
  unallowedNationalities: [],
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_ASYNC,
    Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_SUCCESS,
    Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_FAIL,
    CheckEligibilityForFarmerVisaResponse,
    CheckEligibilityForFarmerVisaFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.CHECK_ELIGIBILITY_FOR_FARMER_VISA_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || FarmerEligibilityErrorMessages.DEFAULT_ERROR,
      }
    }
    default:
      return state
  }
}
