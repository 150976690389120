import { EXTERNAL_SCRIPTS } from '../constants'

export const loadExternalScripts = () => {
  EXTERNAL_SCRIPTS.forEach(({ init, attributes = {}, innerHTML = '' }) => {
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    if (innerHTML) {
      script.innerHTML = innerHTML
    }
    Object.keys(attributes).forEach((attrKey) => script.setAttribute(attrKey, attributes[attrKey] as string))
    document.body.appendChild(script)
    if (typeof init === 'function') {
      init()
    }
  })
}
