import { setNewNoticeFlowData } from 'redux/actionCreators/newNoticeFlow'
import Actions from 'redux/actions'

export type State = {
  accountNumber: string
  borderNumber: string
  dateOfBirth: string
  occupation: number
  relativeMobile: string
  relativeName: string
  relativeType: string
  workerMobile: string
  workerNameEn: string
  workerSalary: string
  bank?: number
  acknowledge: boolean
}

const initialState: State = {
  accountNumber: '',
  borderNumber: '',
  dateOfBirth: '',
  occupation: 0,
  relativeMobile: '',
  relativeName: '',
  relativeType: '',
  workerMobile: '',
  workerNameEn: '',
  workerSalary: '',
  bank: undefined,
  acknowledge: false,
}

export default (state: State = initialState, action: ReturnType<typeof setNewNoticeFlowData>) => {
  switch (action.type) {
    case Actions.SET_NEW_NOTICE_FLOW_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
