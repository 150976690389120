import {
  CreateSupportTicketFailResponse,
  CreateSupportTicketResponse,
  GetSupportTicketDetailsResponse,
  GetSupportTicketsListResponse,
  SupportTicketFromBE,
  UpdateSupportTicketDetailsFailResponse,
  UpdateSupportTicketDetailsResponse,
} from 'api/jiraAPI'
import { CallAPIAction, Status } from 'api/types'
import { SupportTicketStatus } from 'common/types/commonTypes'
import Actions from 'redux/actions'

const USER_MARKER = 'musanedcomplaints'
export enum AuthorRoles {
  CLIENT = 'client',
  CUSTOMER_SUPPORT = 'customerSupport',
}

type SupportTicket = {
  id: string
  ticketNumber: string
  type: string
  status: SupportTicketStatus
  creationDate: string
}

export type Comment = {
  authorName: string
  authorEmail: string
  authorTKey: AuthorRoles
  text: string
  updateAuthorName: string
  updateAuthorEmail: string
  created: string
  updated: string
}

export type Attachment = {
  id: string
  mimeType: string
  filename: string
  url: string
}

export type TicketDetails = {
  description: string
  attachments: Array<Attachment>
  comments: Array<Comment>
} & SupportTicket

export type Ticket = {
  ticketDetails?: TicketDetails
} & Status

type State = {
  list: Array<SupportTicket>
  ticket?: Ticket
  ticketDetailsStatus?: Status
  ticketStatus?: {
    message?: string
  } & Status
  pages: number
} & Status

const initialState: State = {
  list: [],
  pages: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertSupportTicketsFromBE = (item: SupportTicketFromBE): SupportTicket => ({
  creationDate: item.fields.created.date,
  ticketNumber: item.key,
  status: item.fields.status.name,
  type: item.fields.request_type.id,
  id: item.id,
})

const convertSupportTicketDetailsFromBE = ({ id, fields, key }: GetSupportTicketDetailsResponse): TicketDetails => {
  const comments = fields.comment.comments || []
  return {
    id,
    creationDate: fields.created.date,
    status: fields.status.name,
    ticketNumber: key,
    type: fields.request_type.id,
    description: fields.description || '',
    attachments: fields.attachment.map((item) => ({
      ...item,
    })),
    comments: comments.map(
      ({ author: autho, body, created, updated, renderedBody, updateAuthor }): Comment => ({
        authorEmail: autho.emailAddress,
        authorName: autho.displayName,
        authorTKey: autho.key === USER_MARKER ? AuthorRoles.CLIENT : AuthorRoles.CUSTOMER_SUPPORT,
        created,
        updated,
        text: body,
        updateAuthorName: updateAuthor.displayName,
        updateAuthorEmail: updateAuthor.emailAddress,
      }),
    ),
  }
}

export default (
  state: State = initialState,
  action:
    | CallAPIAction<
        Actions.GET_SUPPORT_TICKETS_LIST_ASYNC,
        Actions.GET_SUPPORT_TICKETS_LIST_SUCCESS,
        Actions.GET_SUPPORT_TICKETS_LIST_FAIL,
        GetSupportTicketsListResponse
      >
    | CallAPIAction<
        Actions.GET_SUPPORT_TICKET_DETAILS_ASYNC,
        Actions.GET_SUPPORT_TICKET_DETAILS_SUCCESS,
        Actions.GET_SUPPORT_TICKET_DETAILS_FAIL,
        GetSupportTicketDetailsResponse
      >
    | CallAPIAction<
        Actions.UPDATE_SUPPORT_TICKET_DETAILS_ASYNC,
        Actions.UPDATE_SUPPORT_TICKET_DETAILS_SUCCESS,
        Actions.UPDATE_SUPPORT_TICKET_DETAILS_FAIL,
        UpdateSupportTicketDetailsResponse,
        UpdateSupportTicketDetailsFailResponse
      >
    | CallAPIAction<
        Actions.CREATE_SUPPORT_TICKET_ASYNC,
        Actions.CREATE_SUPPORT_TICKET_SUCCESS,
        Actions.CREATE_SUPPORT_TICKET_FAIL,
        CreateSupportTicketResponse,
        CreateSupportTicketFailResponse
      >,
): State => {
  switch (action.type) {
    case Actions.GET_SUPPORT_TICKETS_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        list: action.payload.issues.map(convertSupportTicketsFromBE),
        pages: action.payload.pages,
      }
    case Actions.GET_SUPPORT_TICKETS_LIST_ASYNC:
    case Actions.GET_SUPPORT_TICKETS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }

    case Actions.GET_SUPPORT_TICKET_DETAILS_ASYNC:
    case Actions.GET_SUPPORT_TICKET_DETAILS_FAIL:
      return {
        ...state,
        ticket: {
          ...action.status,
        },
      }
    case Actions.GET_SUPPORT_TICKET_DETAILS_SUCCESS: {
      return {
        ...state,
        ticket: {
          ...action.status,
          ticketDetails: convertSupportTicketDetailsFromBE(action.payload),
        },
      }
    }

    case Actions.CREATE_SUPPORT_TICKET_ASYNC:
    case Actions.CREATE_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        ticketStatus: {
          ...action.status,
        },
      }

    case Actions.UPDATE_SUPPORT_TICKET_DETAILS_ASYNC:
    case Actions.UPDATE_SUPPORT_TICKET_DETAILS_SUCCESS:
    case Actions.UPDATE_SUPPORT_TICKET_DETAILS_FAIL:
      return {
        ...state,
        ticketDetailsStatus: {
          ...action.status,
        },
      }

    case Actions.CREATE_SUPPORT_TICKET_FAIL:
      return {
        ...state,
        ticketStatus: {
          message: action.payload?.message,
          ...action.status,
        },
      }
    default:
      return state
  }
}
