import React, { FunctionComponent, ReactNode } from 'react'
import clsx from 'clsx'

import { ReactComponent as CloseIcon } from 'images/close-circle.svg'
import { ReactComponent as FailIcon } from 'images/vip/fail-icon.svg'
import { ReactComponent as WarningIcon } from 'images/vip/warning-icon.svg'
import { ReactComponent as SuccessIcon } from 'images/vip/success-icon.svg'
import { ReactComponent as PendingIcon } from 'images/vip/pending-icon.svg'

import styles from './MessageContent.module.scss'

export type MessageContentProps = {
  title?: string | React.ReactNode
  description?: string | ReactNode
  actions?: string | React.ReactNode
  type?: 'success' | 'error' | 'warn' | 'pending'
  className?: string
  onClose?: () => void
}

const MessageContent: FunctionComponent<MessageContentProps> = ({
  title,
  description,
  type,
  actions,
  className,
  onClose,
}) => (
  <div className={clsx(styles.root, className)}>
    <div className={styles.container}>
      <div className={styles.heading}>
        {type === 'error' && (
          <div className={styles.failContainer}>
            <FailIcon className={styles.fail} />
          </div>
        )}
        {type === 'success' && (
          <div className={styles.successContainer}>
            <SuccessIcon className={styles.success} />
          </div>
        )}
        {type === 'warn' && (
          <div className={styles.warningContainer}>
            <WarningIcon className={styles.warning} />
          </div>
        )}
        {type === 'pending' && (
          <div className={styles.pendingContainer}>
            <PendingIcon className={styles.pending} />
          </div>
        )}
      </div>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.description}>{description}</div>
      <div className={styles.actionsContainer}>{actions}</div>
      <CloseIcon onClick={onClose} className={styles.close} />
    </div>
  </div>
)

export default MessageContent
