import { LaborerFromBE, SponsorFromBE, TransactionFromBE } from 'api/wpsAPI'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

import { formatDateTimeForUI } from './common'

export type Commitment = 'committed' | 'uncommitted' | ''

export type Laborer = {
  laborName: string
  laborID: string
  laborNationality: string
  occupation: ObjectWithTranslation
  contractNo: string
  commitmentPercentage: Commitment
  payrollAmt: string
}

export type Transaction = {
  id: number
  paymentReference: string
  transactionType: string
  month: string | null | [string, string]
  amount: number
  payrollAmount: number
  commitmentPercentage: Commitment
  paymentTimestamp: string
  notes: string
  sponsorBank: string
  laborerBank: string
  deductedAmount: number | string
  percentageOfDeduction: string
  violationType: string
  deductionJustification: string
}

const getCommitment = (commitment: number | null): Commitment => {
  switch (commitment) {
    case 100:
      return 'committed'
    case 0:
      return 'uncommitted'
    default:
      return ''
  }
}

export type Sponsor = {
  sponsorId: string
  sponsorName: string
}

export const getEmptySponsor = (): Sponsor => ({
  sponsorId: '',
  sponsorName: '',
})

export const getEmptyTransaction = (): Transaction => ({
  id: 0,
  paymentReference: '',
  transactionType: '',
  month: '',
  amount: 0,
  payrollAmount: 0,
  commitmentPercentage: '',
  paymentTimestamp: '',
  notes: '',
  sponsorBank: '',
  laborerBank: '',
  deductedAmount: 0,
  percentageOfDeduction: '',
  violationType: '',
  deductionJustification: '',
})

export const getEmptyLaborer = (): Laborer => ({
  laborName: '',
  laborID: '',
  laborNationality: '',
  occupation: {
    label: '',
    labelEn: '',
    id: 0,
  },
  contractNo: '',
  commitmentPercentage: '',
  payrollAmt: '',
})

export const convertLaborerFromResponse = (laborFromBE: LaborerFromBE): Laborer => ({
  laborName: laborFromBE.LaborName,
  laborID: laborFromBE.LaborID || laborFromBE.LaborBorder,
  laborNationality: laborFromBE.LaborNationality,
  occupation: {
    label: laborFromBE.OccupationName,
    labelEn: laborFromBE.OccupationNameEn,
    id: 0,
  },
  contractNo: laborFromBE.ContractNo || '',
  commitmentPercentage: getCommitment(laborFromBE.commitment_percentage),
  payrollAmt: laborFromBE.PayrollAmt,
})

export const convertTransactionFromResponse = (transactionFromBE: TransactionFromBE): Transaction => ({
  id: transactionFromBE.id,
  paymentReference: transactionFromBE.payment_reference,
  transactionType: transactionFromBE.type,
  month: transactionFromBE.for_month,
  payrollAmount: transactionFromBE.payroll_amount,
  amount: transactionFromBE.amount,
  commitmentPercentage: getCommitment(transactionFromBE.commitment_percentage),
  paymentTimestamp: formatDateTimeForUI(transactionFromBE.payment_timestamp),
  notes: transactionFromBE.notes || '',
  sponsorBank: transactionFromBE.channel,
  laborerBank: transactionFromBE.bank_code,
  deductedAmount: transactionFromBE.deducted_amount || '',
  percentageOfDeduction:
    transactionFromBE.percentage_of_deduction === null ? '' : `${transactionFromBE.percentage_of_deduction}%`,
  violationType: transactionFromBE.violation_type || '',
  deductionJustification: transactionFromBE.justification_description || '',
})

export const convertSponsorFromResponse = (sponsorFromBE: SponsorFromBE): Sponsor => ({
  sponsorId: sponsorFromBE.SponsorId,
  sponsorName: sponsorFromBE.SponsorName,
})
