import { FarmerVisaIssueRequestFromBE } from 'api/evisaAPI/farmerVisa'
import { formatOccupationsFromBE, formatNationalityFromBE } from 'common/utils/objectWithTranslation'

import { FarmerVisaIssueRequest } from '../../types/transformedRespTypes'
import { formatObjectWithTranslationFromBE } from '../objectWithTranslation'

export const formatFarmerVisaRequestFromBE = (request: FarmerVisaIssueRequestFromBE): FarmerVisaIssueRequest => ({
  id: request.id,
  status: request.status,
  createdAt: request.created_at,
  occupation: formatOccupationsFromBE(request.occupation),
  nationality: formatNationalityFromBE(request.nationality),
  visaIssuePlace: formatObjectWithTranslationFromBE(request.visaIssuePlace),
  rejectionReasons: request.rejectionReasons?.map((reason) => formatObjectWithTranslationFromBE(reason)) || [],
  farmerVisa: request.farmerVisa
    ? {
        id: request.farmerVisa.id,
        borderNumber: request.farmerVisa.border_number,
        createdAt: request.farmerVisa.created_at,
        followupNumber: request.farmerVisa.followup_number,
        followupDate: request.farmerVisa.followup_date,
        status: request.farmerVisa.status,
        user: {
          name: request.farmerVisa.user.name,
          idNumber: request.farmerVisa.user.id_number,
        },
        pdfId: request.farmerVisa.pdf_id || '',
      }
    : null,
  bill: request.bill
    ? {
        amount: request.bill.amount,
        billNumber: request.bill.bill_number,
        createdAt: request.bill.created_at,
        id: request.bill.id,
        paidAt: request.bill.paid_at,
        paymentMethod: request.bill.payment_method,
        status: request.bill.status,
        vat: request.bill.vat,
        tbsId: request.bill.tbs_id,
      }
    : null,
})
