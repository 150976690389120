import { setLaborerIToIRelativeInfo } from 'redux/actionCreators/laborerPortalAPI/serviceTransfer'
import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'

import Actions from '../actions'

export type State = {
  relation: string
  relationText: string
  name: string
  mobileNumber: { value: string; location?: PhoneData }
}

const initialState: State = {
  relation: '',
  relationText: '',
  name: '',
  mobileNumber: { value: '', location: undefined },
}

export default (state: State = initialState, action: ReturnType<typeof setLaborerIToIRelativeInfo>) => {
  switch (action.type) {
    case Actions.SET_LABORER_I_TO_I_RELATIVE_INFO:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
