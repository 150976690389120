import { COOKIES_ACCEPTED_KEY } from 'common/constants'
import { closeCookiesDisclaimerBar } from 'redux/actionCreators/closeCookiesDisclaimerBar'
import Actions from 'redux/actions'

type State = {
  isVisible: boolean
}

const initialState: State = {
  isVisible: !localStorage.getItem(COOKIES_ACCEPTED_KEY),
}

export default (state: State = initialState, action: ReturnType<typeof closeCookiesDisclaimerBar>): State => {
  switch (action.type) {
    case Actions.CLOSE_COOKIES_DISCLAIMER_BAR: {
      localStorage.setItem(COOKIES_ACCEPTED_KEY, 'true')

      return {
        isVisible: false,
      }
    }
    default:
      return state
  }
}
