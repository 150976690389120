import { Status, CallAPIAction } from 'api/types'
import type { GetOccupationDescriptionsResponse } from 'api/evisaAPI/evisaAPI'
import Actions from 'redux/actions'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'

const convertResponseToState = (resp: GetOccupationDescriptionsResponse) =>
  resp.occupation_descriptions.map((desc) => ({
    id: desc.id,
    label: desc.label_ar,
    labelEn: desc.label_en,
  }))

type State = {
  descriptions: Array<ObjectWithTranslation>
} & Status

const initialState = {
  descriptions: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_OCCUPATION_DESCRIPTIONS_ASYNC,
    Actions.GET_OCCUPATION_DESCRIPTIONS_SUCCESS,
    Actions.GET_OCCUPATION_DESCRIPTIONS_FAIL,
    GetOccupationDescriptionsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_OCCUPATION_DESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        descriptions: convertResponseToState(action.payload),
        ...action.status,
      }
    }
    case Actions.GET_OCCUPATION_DESCRIPTIONS_ASYNC:
    case Actions.GET_OCCUPATION_DESCRIPTIONS_FAIL: {
      return {
        ...state,
        descriptions: [],
        ...action.status,
      }
    }
    default:
      return state
  }
}
