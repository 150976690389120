import { CallAPIAction, Status } from 'api/types'
import { GetAvailableVisasResponse, VisaInfoFromBE } from 'api/evisaAPI/visas'
import { StringRecord } from 'common/types/commonTypes'
import { Visa } from 'common/types/transformedRespTypes'
import { convertVisaResponseToState } from 'common/utils/convertResponse/convertVisaResponseToState'
import Actions from 'redux/actions'

type State = {
  visas: StringRecord<Visa>
} & Status

const convertToVisaRecord = (visa: VisaInfoFromBE) => {
  const visaId = visa.id
  return { [visaId]: convertVisaResponseToState({ visa }) }
}

const convertAvailableVisasResponseToState = (resp: Record<number, VisaInfoFromBE>) => {
  const visas = Object.values(resp).reduce<StringRecord<Visa>>((allVisasAcc, visa) => {
    const visaRecord = convertToVisaRecord(visa)
    return {
      ...allVisasAcc,
      ...visaRecord,
    }
  }, {})

  return visas
}

const initialState = {
  visas: {},
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_AVAILABLE_VISAS_ASYNC,
    Actions.GET_AVAILABLE_VISAS_SUCCESS,
    Actions.GET_AVAILABLE_VISAS_FAIL,
    GetAvailableVisasResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_AVAILABLE_VISAS_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_AVAILABLE_VISAS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        visas: convertAvailableVisasResponseToState(payload.visas),
      }
    }
    case Actions.GET_AVAILABLE_VISAS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
