import { CallAPIAction, Status } from 'api/types'
import { GetBanksInfoResponse } from 'api/eContractingAPI'
import { InstallmentFee, InstallmentKeysType, InstallmentsPlansIssuerDetail } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  installmentsFees: Array<InstallmentFee>
  installmentsPlans: Array<InstallmentsPlansIssuerDetail>
} & Status

const initialState = {
  installmentsPlans: [],
  installmentsFees: [],
  isLoading: false,
  success: false,
  fail: false,
}

const numberOfInstallmentOptions: Record<InstallmentKeysType, number> = {
  three_months_installments_fees: 3,
  six_months_installments_fees: 6,
  twelve_months_installments_fees: 12,
}

const convertResponseToState = (resp: GetBanksInfoResponse) => ({
  installmentsFees: resp.installmentsFees.map((fee) => ({
    id: fee.id,
    value: parseInt(fee.value, 10),
    key: fee.key,
    numberOfInstallment: numberOfInstallmentOptions[fee.key] || 0,
  })),
  installmentsPlans: resp.installmentsPlans.issuer_detail.map((issuerDetail) => ({
    bins: issuerDetail.bins,
    formula: issuerDetail.formula,
    planDetails: issuerDetail.plan_details.map((planDetails) => ({
      minimumAmount: planDetails.minimum_amount ? planDetails.minimum_amount / 100 : 0,
      maximumAmount: planDetails.maximum_amount ? planDetails.maximum_amount / 100 : 0,
      numberOfInstallment: planDetails.number_of_installment,
      planCode: planDetails.plan_code,
      feeAmount: planDetails.fees_amount,
      feeDisplayValue: planDetails.fee_display_value,
      feeType: planDetails.fees_type,
      rateType: planDetails.rate_type,
    })),
    processingFeesMessage: {
      id: 0,
      label: issuerDetail.processing_fees_message_ar,
      labelEn: issuerDetail.processing_fees_message_en,
    },
    issuerCode: issuerDetail.issuer_code,
    issuerLogoAr: issuerDetail.issuer_logo_ar,
    issuerLogoEn: issuerDetail.issuer_logo_en,
    termsAndConditionLinkEn: issuerDetail.terms_and_condition_en,
    termsAndConditionLinkAr: issuerDetail.terms_and_condition_ar,
    issuerName: {
      id: 0,
      label: issuerDetail.issuer_name_ar,
      labelEn: issuerDetail.issuer_name_en,
    },
  })),
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_BANKS_INFO_ASYNC,
    Actions.GET_BANKS_INFO_SUCCESS,
    Actions.GET_BANKS_INFO_FAIL,
    GetBanksInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_BANKS_INFO_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_BANKS_INFO_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_BANKS_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
