import { NodeSelectOption } from 'ui-components/inputs/NodeSelect/NodeSelect'
import Actions from 'redux/actions'
import { SetPreferencesFormDataAction } from 'redux/actionCreators/preferencesForm'

interface State {
  ageRange?: number
  workExperience?: number
  extraPreferences: Array<NodeSelectOption>
  yearsExperience?: number
  contractDuration?: number
  skill?: number
  rangeOfAges?: string
  additionalPreferences?: string
}

const initialState: State = {
  ageRange: undefined,
  workExperience: undefined,
  extraPreferences: [],
  yearsExperience: undefined,
  contractDuration: undefined,
  skill: undefined,
  rangeOfAges: undefined,
  additionalPreferences: '',
}

export default (state: State = initialState, action: SetPreferencesFormDataAction) => {
  switch (action.type) {
    case Actions.SET_PREFERENCES_FORM_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
