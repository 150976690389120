import { LoginNafathResponse } from 'api/evisaAPI/auth'
import { FailResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  random?: number
  errorMessage?: string
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  random: undefined,
  errorMessage: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.LOGIN_NAFATH_ASYNC,
    Actions.LOGIN_NAFATH_SUCCESS,
    Actions.LOGIN_NAFATH_FAIL,
    LoginNafathResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.LOGIN_NAFATH_SUCCESS:
      return {
        ...state,
        ...action.status,
        random: action.payload.random,
      }
    case Actions.LOGIN_NAFATH_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.LOGIN_NAFATH_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error.message || '',
      }
    default:
      return state
  }
}
