import React, { memo, FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { RootState } from 'redux/rootReducer'
import { keepAlive, logOut, updateAuth } from 'redux/actionCreators/evisaAPI/auth'
import ModalMessageRegular from 'components/Messages/ModalMessage/ModalMessage'
import ModalMessageVip from 'containers/VipUser/components/ModalMessage/ModalMessage'
import ButtonWithLoading from 'ui-components/buttons/ButtonWithLoading/ButtonWithLoading'
import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'
import authManager, { AuthListener } from 'common/utils/auth/authManager'
import { useVipRoute } from 'common/hooks/useVipRoute'

import keepAliveModalEn from './KeepAliveModal_en.json'
import keepAliveModalAr from './KeepAliveModal_ar.json'

const CHECK_INTERVAL_LEN = 7 * 1000 // every 7 seconds

const KeepAliveModal: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { isVip } = useVipRoute()
  const { t } = useTranslation(TranslationNamespace.keepAliveModal)
  const auth = useSelector<RootState, RootState['auth']>((state) => state.auth)
  const ModalMessage = isVip ? ModalMessageVip : ModalMessageRegular

  useEffect(() => {
    const onAuthUpdate: AuthListener = (event, params) => {
      switch (event) {
        case 'DATA_UPDATE': {
          dispatch(
            updateAuth({
              loggedIn: authManager.isAuthenticated(),
              logOutWarning: authManager.isReadyForAutoLogOut(),
              userProfile: authManager.getUserProfileData(),
            }),
          )
          break
        }
        case 'LOG_OUT': {
          dispatch(logOut(params.autoLoggedOut))
          break
        }
      }
    }
    authManager.addListener(onAuthUpdate)
    return () => authManager.removeListener(onAuthUpdate)
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => {
      if (authManager.isReadyForAutoLogOut()) {
        dispatch(
          updateAuth({
            loggedIn: authManager.isAuthenticated(),
            logOutWarning: authManager.isReadyForAutoLogOut(),
            userProfile: authManager.getUserProfileData(),
          }),
        )
      }
      if (auth.logOutWarning && !authManager.isAuthenticated()) {
        dispatch(logOut(true))
      }
    }, CHECK_INTERVAL_LEN)
    return () => clearInterval(interval)
  }, [dispatch, auth.logOutWarning])

  return (
    <ModalMessage
      open={auth.logOutWarning}
      title={t('warning')}
      description={t('description')}
      type='warn'
      actions={
        <ButtonWithLoading
          isLoading={auth.tokensUpdateStatus.isLoading}
          dataTestid='continue-usage-button'
          onClick={() => dispatch(keepAlive())}
        >
          {t('continue')}
        </ButtonWithLoading>
      }
    />
  )
}

export default memo(KeepAliveModal)

addTranslationNamespace(TranslationNamespace.keepAliveModal, keepAliveModalEn, keepAliveModalAr)
