import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import { CancelVisaResponse } from 'api/evisaAPI/evisaAPI'
import { GeneralFailResponse } from 'common/types/commonTypes'

type State = {
  cancellationStatus: string
  errorMessage: string
} & Status

const initialState = {
  cancellationStatus: '',
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CANCEL_VISA_ASYNC,
    Actions.CANCEL_VISA_SUCCESS,
    Actions.CANCEL_VISA_FAIL,
    CancelVisaResponse,
    GeneralFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CANCEL_VISA_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.CANCEL_VISA_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        cancellationStatus: payload.status,
      }
    }
    case Actions.CANCEL_VISA_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }
    default:
      return state
  }
}
