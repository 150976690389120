import type { CallAPIAction, Status } from 'api/types'
import { GetContractAuthRequestsListResponse } from 'api/contractAuthAPI'
import { getContractAuthRequestsList } from 'redux/actionCreators/contractAuthAPI'
import Actions from 'redux/actions'
import {
  ContractAuthRequest,
  convertContractAuthRequestFromResponse,
} from 'common/utils/convertResponse/convertAuthApiResponse'

type State = {
  authRequestsParams: {
    page: number
    status: string
  }
  authRequests: Array<ContractAuthRequest>
  lastPage: number
} & Status

const convertResponseToState = (resp: GetContractAuthRequestsListResponse) => ({
  lastPage: resp.last_page,
  authRequests: resp.data.map(convertContractAuthRequestFromResponse),
})

const initialState: State = {
  authRequests: [],
  authRequestsParams: {
    page: 0,
    status: '',
  },
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC,
    Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS,
    Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL,
    GetContractAuthRequestsListResponse,
    {},
    {},
    ReturnType<typeof getContractAuthRequestsList>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC: {
      const {
        params: { status = state.authRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        authRequestsParams: {
          page: page,
          status: status,
        },
        lastPage: status !== state.authRequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        ...action.status,
        ...convertResponseToState(payload),
      }
    }
    case Actions.GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
