import React, { FunctionComponent, useState } from 'react'

import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'

import errorPageEn from './ErrorPage_en.json'
import errorPageAr from './ErrorPage_ar.json'
import ErrorContent from './components/ErrorContent/ErrorContent'

type ErrorPageProps = {
  error: string
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ error }) => {
  const [isOnline] = useState<boolean>(navigator.onLine)

  switch (error) {
    case 'ChunkLoadError':
      return isOnline ? (
        <ErrorContent title='sorry' description='pleaseRefreshThePage' />
      ) : (
        <ErrorContent title='noConnection' description='checkInternetConnection' type='offline' withEventId={false} />
      )
    default:
      return <ErrorContent title='sorry' description='somethingWentWrong' />
  }
}

export default ErrorPage

addTranslationNamespace(TranslationNamespace.errorPage, errorPageEn, errorPageAr)
