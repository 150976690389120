import { GetProStatisticResponse } from 'api/surveyAPI'
import { CallAPIAction, Status } from 'api/types'
import { ProStatistic } from 'common/types/commonTypes'
import Actions from 'redux/actions'

type State = {
  prosRating: { [key: string]: ProStatistic }
} & Status

const initialState: State = {
  prosRating: {},
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: GetProStatisticResponse) =>
  resp.reduce(
    (acc, proStat) => ({
      ...acc,
      [proStat.pro_id]: {
        id: proStat.pro_id,
        arrivalDurationRating: Math.round(proStat.arrival_duration_rating || 0),
        arrivalDurationRatingsCount: proStat.arrival_duration_ratings_count || 0,
        recommendabilityRating: Math.round(proStat.recommendability_rating || 0),
        recommendabilityRatingsCount: proStat.recommendability_ratings_count || 0,
        responsivenessRating: Math.round(proStat.responsiveness_rating || 0),
        responsivenessRatingsCount: proStat.responsiveness_ratings_count || 0,
        conformityRating: Math.round(proStat.conformity_rating || 0),
        conformityRatingsCount: proStat.conformity_ratings_count || 0,
      },
    }),
    {},
  )

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PRO_STATISTIC_ASYNC,
    Actions.GET_PRO_STATISTIC_SUCCESS,
    Actions.GET_PRO_STATISTIC_FAIL,
    GetProStatisticResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_PRO_STATISTIC_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        prosRating: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_PRO_STATISTIC_ASYNC:
    case Actions.GET_PRO_STATISTIC_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
