import { formatObjectWithTranslationFromBE } from 'common/utils/objectWithTranslation'
import { EToILegacyRequestFormBE, GetEtoILegacyRequestsResponse } from 'api/evisaAPI/dlAPI'
import { CallAPIAction, Status } from 'api/types'
import { EToILegacyRequestsStatus } from 'common/types/commonTypes'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import { getEToILegacyRequests } from 'redux/actionCreators/evisaAPI/dlAPI'

type Request = {
  id: number
  laborerIqamaId: string
  currentSponsor: string
  createdAt: string
  status: EToILegacyRequestsStatus
  userName: ObjectWithTranslation
  rejectionReasons: Array<ObjectWithTranslation>
}

type State = {
  eToILegacyRequestsParams: {
    page: number
    status: string | EToILegacyRequestsStatus
  }
  requests: Array<Request>
  lastPage: number
} & Status

const initialState: State = {
  eToILegacyRequestsParams: {
    page: 0,
    status: '',
  },
  requests: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertRequestToState = (resp: EToILegacyRequestFormBE): Request => ({
  id: resp.id,
  laborerIqamaId: resp.laborer_iqama,
  userName: {
    id: resp.user.id,
    label: resp.user.name,
    labelEn: resp.user.name_en,
  },
  currentSponsor: resp.current_sponsor,
  createdAt: resp.created_at,
  status: resp.status,
  rejectionReasons: resp.rejectionReasons.map((reason) => formatObjectWithTranslationFromBE(reason)),
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_E_TO_I_LEGACY_REQUESTS_ASYNC,
    Actions.GET_E_TO_I_LEGACY_REQUESTS_SUCCESS,
    Actions.GET_E_TO_I_LEGACY_REQUESTS_FAIL,
    GetEtoILegacyRequestsResponse,
    {},
    {},
    ReturnType<typeof getEToILegacyRequests>['payload']
  >,
): State => {
  switch (action.type) {
    case Actions.GET_E_TO_I_LEGACY_REQUESTS_ASYNC: {
      const {
        params: { status = state.eToILegacyRequestsParams.status, page = 0 },
      } = action.callAPIActionPayload
      return {
        ...state,
        ...action.status,
        eToILegacyRequestsParams: {
          page,
          status,
        },
        lastPage: status !== state.eToILegacyRequestsParams.status ? 0 : state.lastPage,
      }
    }
    case Actions.GET_E_TO_I_LEGACY_REQUESTS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        requests: action.payload.paginator.data.map(convertRequestToState),
        lastPage: action.payload.paginator.last_page,
      }
    }
    case Actions.GET_E_TO_I_LEGACY_REQUESTS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
