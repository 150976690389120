import React, { Component, ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser'

import authManager from 'common/utils/auth/authManager'

import ErrorPage from './ErrorPage/ErrorPage'
import styles from './ErrorBoundary.module.scss'

type IState = {
  hasError: boolean
  error: string
}

class ErrorBoundary extends Component<{}, IState> {
  constructor(props = {}) {
    super(props)
    this.state = {
      hasError: false,
      error: '',
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error.name }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setUser({ id: authManager.getUserNameFromEvisaToken() })
      scope.setLevel(Sentry.Severity.Debug)
      scope.setTransactionName('UITopLevelException')
      scope.setExtra('errorInfo', errorInfo)
      Sentry.captureException(error)
    })
  }

  public render() {
    const { hasError, error } = this.state
    if (hasError) {
      return (
        <div className={styles.container}>
          <ErrorPage error={error} />
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
