import { Dispatch } from 'redux'

import type {
  HasVisasParams,
  UploadVisasBody,
  GetVisaInfoParams,
  GetVisasListParams,
  GetVisaIssueRequestsListParams,
  IssueNewVisaRequestBody,
  IssueNewDisabilityVisaBody,
  ReplaceVisasBody,
  GetVisaIssueRequestInfoParams,
  SubmitVisaWithContractRequestBodyWithUserParams,
  OnlinePaymentVisaBody,
  VisaInquiryParams,
  CheckEligibilityVisaParams,
  getVisaPdfLinkParams,
  GetVipVisaListParams,
} from 'api/evisaAPI/visas'

import Actions from '../../actions'

type CheckEligibilityParams = {
  type?: 'DL_TRANSFER'
  issueType?: string
}

export const checkEligibility = (params?: CheckEligibilityParams) => ({
  type: Actions.CHECK_ELIGIBILITY,
  payload: {
    params,
  },
})

export const checkEligibilityForDisabilityVisa = () => ({
  type: Actions.CHECK_ELIGIBILITY_FOR_DISABILITY_VISA,
})

export const hasVisas = (params: HasVisasParams) => ({
  type: Actions.HAS_VISAS,
  payload: { params },
})

export type UpdateProgressAction = {
  type: Actions.UPDATE_PROGRESS
  payload: {
    fileName: string
    progress: number
    timeLeft: number
  }
}

export const uploadVisasFile =
  (body: UploadVisasBody, useOCR = false) =>
  (dispatch: Dispatch) => {
    const timeStart = new Date().getTime()
    return dispatch({
      type: Actions.UPLOAD_VISAS_FILES,
      payload: {
        params: body,
        useOCR,
        config: {
          onUploadProgress: (event: ProgressEvent) => {
            const { loaded, total } = event
            const timeElapsed = new Date().getTime() - timeStart
            const uploadingSpeed = loaded / timeElapsed
            const bytesLeft = total - loaded
            const timeLeft = bytesLeft / uploadingSpeed

            dispatch({
              type: Actions.UPDATE_PROGRESS,
              payload: {
                fileName: body.file.name,
                progress: ((loaded * 100) / total).toFixed(1),
                timeLeft: timeLeft.toFixed(1),
              },
            })
          },
        },
      },
    })
  }

export type RemoveFileAction = {
  type: Actions.REMOVE_VISAS_FILE
  payload: {
    fileName: string
  }
}

export const removeVisaFile = (fileName: string): RemoveFileAction => ({
  type: Actions.REMOVE_VISAS_FILE,
  payload: {
    fileName,
  },
})

export const submitVisaWithContractRequest = (body: SubmitVisaWithContractRequestBodyWithUserParams) => ({
  type: Actions.SUBMIT_VISA_WITH_CONTRACT_REQUEST,
  payload: { params: body },
})

export const getVisaInfo = (params: GetVisaInfoParams) => ({
  type: Actions.GET_VISA_INFO,
  payload: { params },
})

export const getAllVisasList = (params: GetVisasListParams = {}) => ({
  type: Actions.GET_VISAS_LIST,
  payload: { params },
})

export const getVisaIssueRequestsList = (params: GetVisaIssueRequestsListParams, clearPageCount = false) => ({
  type: Actions.GET_VISA_ISSUE_REQUESTS_LIST,
  payload: { params, clearPageCount },
})

export const getAvailableVisas = () => ({
  type: Actions.GET_AVAILABLE_VISAS,
})

export const issueNewVisaRequest = (body: IssueNewVisaRequestBody) => ({
  type: Actions.ISSUE_NEW_VISA_REQUEST,
  payload: { params: body },
})

export const issueNewDisabilityVisa = (body: IssueNewDisabilityVisaBody) => ({
  type: Actions.ISSUE_NEW_DISABILITY_VISA,
  payload: { params: body },
})

export const checkEligibilityForAlternativeVisa = (body: CheckEligibilityVisaParams) => ({
  type: Actions.CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA,
  payload: { params: body },
})

export const replaceVisas = (body: ReplaceVisasBody) => ({
  type: Actions.REPLACE_VISAS,
  payload: { params: body },
})

export const getVisaIssueRequestInfo = (params: GetVisaIssueRequestInfoParams) => ({
  type: Actions.GET_VISA_ISSUE_REQUEST_INFO,
  payload: { params },
})

export const onlinePaymentVisa = (body: OnlinePaymentVisaBody) => ({
  type: Actions.ONLINE_PAYMENT_VISA,
  payload: { params: body },
})

export const visaInquiry = (params: VisaInquiryParams) => ({
  type: Actions.VISA_INQUIRY,
  payload: { params },
})

export const getLastFourVisas = () => ({
  type: Actions.GET_LAST_FOUR_VISAS,
})

export const getVisaPdfLink = (params: getVisaPdfLinkParams) => ({
  type: Actions.GET_VISA_PDF_URL,
  payload: { params },
})

export const getVipVisaList = (params: GetVipVisaListParams, clearPageCount = false) => ({
  type: Actions.GET_VIP_VISA_LIST,
  payload: { params, clearPageCount },
})
