import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { addTranslationNamespace, TranslationNamespace } from 'common/utils/translation'
import LottieAnimation from 'components/LottieAnimation/LottieAnimation'
import { ENABLE_NEW_ICONS } from 'common/envConstants'

import Success from 'images/lotties/success.json'
import FailIcon from 'images/fail-icon.svg'
import SuccessIcon from 'images/success-icon.svg'
import WarnIcon from 'images/warning-icon.svg'
import InfoIcon from 'images/info-icon.svg'

import messageContentEn from './MessageContent_en.json'
import messageContentAr from './MessageContent_ar.json'

export type MessageContentProps = {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  otherContent?: string | React.ReactNode
  type?: 'success' | 'error' | 'warn' | 'info' | 'none'
  icon?: React.ReactNode
  classNameRoot?: string
  classNameIcon?: string
  classNameTitle?: string
  classNameDescription?: string
  classNameOtherContent?: string
}

const MessageContent: FunctionComponent<MessageContentProps> = ({
  title,
  description,
  otherContent,
  type,
  classNameRoot,
  classNameDescription,
  classNameTitle,
  classNameIcon,
  classNameOtherContent,
  icon,
}) => {
  const { t } = useTranslation(TranslationNamespace.messageContent)
  return (
    <div className={classNameRoot}>
      {type === 'error' && <img src={FailIcon} className={classNameIcon} alt={t('error')} />}
      {type === 'success' &&
        (ENABLE_NEW_ICONS ? (
          <LottieAnimation animationData={Success} width={178} height={161} />
        ) : (
          <img src={SuccessIcon} className={classNameIcon} alt={t('success')} />
        ))}
      {type === 'warn' && <img src={WarnIcon} className={classNameIcon} alt={t('warning')} />}
      {type === 'info' && <img src={InfoIcon} className={classNameIcon} alt={t('info')} />}
      {icon}
      <p className={classNameTitle}>{title}</p>
      <span className={classNameDescription}>{description}</span>
      {otherContent && <span className={classNameOtherContent}>{otherContent}</span>}
    </div>
  )
}

export default MessageContent

addTranslationNamespace(TranslationNamespace.messageContent, messageContentEn, messageContentAr)
