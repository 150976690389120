import { GetSkillsResponse } from 'api/evisaAPI/evisaAPI'
import type { ObjectWithTranslation, AlternativeObjectWithTranslationFromBE } from 'common/types/transformedRespTypes'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  skills: Array<ObjectWithTranslation>
} & Status

export const formatAlternativeObjectWithTranslationFromBE = (labels: AlternativeObjectWithTranslationFromBE) => ({
  id: labels.id,
  label: labels.label_ar,
  labelEn: labels.label_en,
})

const initialState: State = {
  skills: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_SKILLS_ASYNC,
    Actions.GET_SKILLS_SUCCESS,
    Actions.GET_SKILLS_FAIL,
    GetSkillsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_SKILLS_ASYNC:
    case Actions.GET_SKILLS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_SKILLS_SUCCESS:
      return {
        ...state,
        ...action.status,
        skills: action.payload.skills.map(formatAlternativeObjectWithTranslationFromBE),
      }
    default:
      return state
  }
}
