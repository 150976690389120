import { DEFAULT_FONT_SIZE } from 'common/constants'

import Actions from '../actions'

interface ToggleContrastAction {
  type: Actions.TOGGLE_CONTRAST
}

interface IncreaseZoomAction {
  type: Actions.INCREASE_ZOOM
}

interface DecreaseZoomAction {
  type: Actions.DECREASE_ZOOM
}

type AppAction = ToggleContrastAction | IncreaseZoomAction | DecreaseZoomAction

interface AppState {
  contrastEnabled: boolean
  zoomSize: number
}

const initialState = {
  contrastEnabled: false,
  zoomSize: DEFAULT_FONT_SIZE,
}

export default (state: AppState = initialState, action: AppAction) => {
  switch (action.type) {
    case Actions.TOGGLE_CONTRAST:
      return {
        ...state,
        contrastEnabled: !state.contrastEnabled,
      }
    case Actions.INCREASE_ZOOM:
      return {
        ...state,
        zoomSize: Math.min(initialState.zoomSize + 1, state.zoomSize + 1),
      }
    case Actions.DECREASE_ZOOM:
      return {
        ...state,
        zoomSize: Math.max(initialState.zoomSize - 1, state.zoomSize - 1),
      }
    default:
      return state
  }
}
