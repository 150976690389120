import Actions from '../actions'
import { RootState } from '../rootReducer'

/**
 * Clear all reducers if no params.
 */
export const resetReducerState = (reducersToReset?: Array<keyof RootState>) => ({
  type: Actions.RESET_STATE,
  payload: reducersToReset ? new Set(reducersToReset) : null,
})
