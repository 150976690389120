import { setContractAuthRequestData } from 'redux/actionCreators/contractAuthRequest'
import { NodeSelectOption } from 'ui-components/inputs/NodeSelect/NodeSelect'
import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'

import Actions from '../actions'

export type State = {
  acknowledge: boolean
  acceptTerms: boolean
  laborerId: string
  contractDuration: string
  salary: string
  selectedOccDescriptions: Array<NodeSelectOption>
  placeOfResidency: string
  residencyAddress: string
  residencyDescription: string
  countOfPeopleSharedWith: string
  residencyTextDescription: string
  laborerPassportNumber: string
  laborerPassportExpiryDate: string
  laborerPhone: { value: string; location?: PhoneData }
  relativeName: string
  relativeMobileNumber: { value: string; location?: PhoneData }
  relativeRelation: string
  relativeRelationText: string
  region: string
  city: string
  district: string
  street: string
  buildingNumber: string
  additionalNumber: string
  postalCode: string
  uuid: string
  createdAt: string
}

const initialState: State = {
  acknowledge: false,
  acceptTerms: false,
  laborerId: '',
  contractDuration: '',
  salary: '',
  selectedOccDescriptions: [],
  placeOfResidency: '',
  residencyAddress: '',
  residencyDescription: '',
  countOfPeopleSharedWith: '',
  residencyTextDescription: '',
  laborerPassportNumber: '',
  laborerPassportExpiryDate: '',
  laborerPhone: { value: '', location: undefined },
  relativeName: '',
  relativeMobileNumber: { value: '', location: undefined },
  relativeRelation: '',
  relativeRelationText: '',
  region: '',
  city: '',
  district: '',
  street: '',
  buildingNumber: '',
  additionalNumber: '',
  postalCode: '',
  uuid: '',
  createdAt: '',
}

export default (state: State = initialState, action: ReturnType<typeof setContractAuthRequestData>) => {
  switch (action.type) {
    case Actions.SET_CONTRACT_AUTH_REQUEST_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
