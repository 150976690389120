import Actions from 'redux/actions'
import { SetPreferencesFormDataAction } from 'redux/actionCreators/cvByPreferences'

type State = {
  all: Array<string[]>
  sorted: Array<string[]>
}

const initialState: State = {
  all: [],
  sorted: [],
}

export default (state: State = initialState, action: SetPreferencesFormDataAction) => {
  switch (action.type) {
    case Actions.SET_CV_BY_PREFERENCES:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
